<template>
  <app-view>
    <app-confirm-promise ref="confirm" />

    <app-box>
      <transition name="fade-up" mode="out-in">
        <app-loader
          v-if="!ready"
          key="loading"
          :active="loading.active"
          :error="loading.error"
          :response="loading.response"
          v-on:reload="init()"
          background
          :class="$style.loading"
        />

        <form action="/" v-on:submit.prevent="submit" v-else>
          <div :class="$style.padding">
            <app-box margin>
              <app-title label="Informações do usuário" />
            </app-box>

            <div class="row">
              <div class="col">
                <app-box margin>
                  <app-box background title="Dados Pessoais">
                    <div class="row">
                      <div class="col col-md-3">
                        <app-input
                          label="Primeiro Nome"
                          required
                          v-model:value="account.firstName"
                          :maxlength="150"
                          :id="$style.id_firstName"
                        />
                      </div>

                      <div class="col col-md-3">
                        <app-input
                          label="Sobrenome"
                          required
                          v-model:value="account.lastName"
                          :maxlength="250"
                          :id="$style.id_lastName"
                        />
                      </div>

                      <div class="col col-md-3">
                        <app-input
                          label="Nome para Exibição"
                          required
                          v-model:value="account.displayName"
                          :maxlength="20"
                          :id="$style.id_displayName"
                        />
                      </div>

                      <div class="col col-md-3">
                        <app-input
                          label="Telefone"
                          required
                          :mask="'tel'"
                          v-model:value="account.phoneNumber"
                          :maxlength="20"
                          :id="$style.id_displayName"
                        />
                      </div>
                    </div>
                  </app-box>
                </app-box>

                <app-box margin>
                  <app-box background title="Imagem do perfil">
                    <div class="row">
                      <div class="col-12 col-xl-7">
                        <upload-image
                          v-on:change-file="changeFile"
                          v-on:remove="removeFile"
                          :image="user.image"
                        />
                      </div>
                    </div>
                  </app-box>
                </app-box>

                <app-box margin>
                  <app-box background title="Dados de Acesso">
                    <div class="row">
                      <div class="col col-md-4">
                        <app-input
                          label="Senha atual"
                          type="password"
                          v-model:value="account.password"
                          :maxlength="150"
                          ref="password"
                        />
                        <label
                          :class="$style.validation"
                          v-if="errorPasswordIncorret"
                          >Senha inválida</label
                        >
                      </div>
                    </div>

                    <div class="row">
                      <div class="col col-md-4">
                        <app-input
                          label="Nova senha"
                          type="password"
                          tooltip="Digite uma senha <b>forte</b> com no mínimo <b>10 caracteres</b>."
                          v-model:value="account.new_password"
                          :maxlength="150"
                          minlength="3"
                          ref="newPassword"
                        />
                        <label
                          :class="$style.validation"
                          v-if="account.new_password !== account.cnew_password"
                          >Senhas não conferem</label
                        >
                        <label
                          :class="$style.validation"
                          v-if="
                            account.new_password == account.cnew_password &&
                            account.new_password &&
                            strongPassword < 3
                          "
                          >
                          Esta senha não é segura o suficiente
                        </label>

                        <password-checker
                          v-if="account.new_password"
                          :password="account.new_password"
                          v-on:change-password="changeStrongPassword"
                        />
                      </div>

                      <div class="col col-md-4">
                        <app-input
                          label="Confirmar nova senha"
                          type="password"
                          v-model:value="account.cnew_password"
                          :maxlength="150"
                          minlength="3"
                          ref="newCPassword"
                        />
                        <label
                          :class="$style.validation"
                          v-if="account.new_password !== account.cnew_password"
                          >Senhas não conferem</label
                        >
                      </div>
                    </div>
                  </app-box>
                </app-box>

                <app-box>
                  <app-box background title="Notificações">
                    <div class="row">
                      <div class="col-md-12">
                        <user-notifications-matrix
                          :notifications="notifications"
                          :channels="channels"
                          :userNotifications="userNotifications"
                          :filteredNotifications="filteredNotifications"
                          v-on:change-check="changeCheck"
                        />
                      </div>
                    </div>
                  </app-box>
                </app-box>
              </div>
            </div>
          </div>

          <app-actions-box>
            <div class="row justify-content-center">
              <div class="col-auto">
                <app-button
                  label="Salvar"
                  icon="save"
                  type="submit"
                  :id="$style.id_submit"
                  :loading="loadingSubmit"
                />
              </div>

              <div class="col-auto">
                <app-button
                  label="Cancelar"
                  icon="close"
                  color="red-500"
                  :id="$style.id_cancel"
                  v-on:click="closePage"
                />
              </div>
            </div>
          </app-actions-box>
        </form>
      </transition>
    </app-box>
  </app-view>
</template>

<script>
import UserNotificationsMatrix from './UserNotificationsMatrix.vue'
import uploadImage from '@/components/UploadImage.vue'
import passwordChecker from '@/components/passwordChecker/PasswordChecker'

export default {
  components: {
    UserNotificationsMatrix,
    uploadImage,
    passwordChecker
  },

  data: () => ({
    ready: false,
    loading: {
      active: true,
      error: false,
      response: {}
    },

    loadingSubmit: false,

    account: null,
    user: null,

    errorPasswordIncorret: false,
    strongPassword: null,

    channels: [],
    notifications: [],
    roomNotifications: [],
    userNotifications: [],
    filteredNotifications: []
  }),

  created () {
    this.init()
  },

  methods: {
    async init () {
      const _this = this

      _this.ready = false
      _this.loading.active = true
      _this.loading.error = false

      try {
        await _this.callChannels()
        await _this.callAccountDetails()
        await _this.callNotifications()

        _this.ready = true
        _this.loading.active = false
      } catch (error) {
        console.log(error)

        this.$store.dispatch('notification', {
          type: 'error',
          text:
            'Ocorreu um erro no carregamento da página. Por favor, tente novamente.',
          error
        })

        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error.response
      }
    },

    /* */

    callAccountDetails () {
      return new Promise((resolve, reject) => {
        this.user = this.$store.getters.user.data

        this.$http
          .get(this.$store.getters.api + '/account/' + this.user.accountId)
          .then(response => {
            this.account = response.data

            const account = {
              name: response.data.displayName,
              email: response.data.email,
              image: response.data.profileImage,
              attributes: response.data.attributes
            }

            this.$store.commit('setUserInfo', account)

            if (response.data.notifications.length > 0) {
              this.userNotifications = response.data.notifications
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    callRooms () {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.$store.getters.api + '/Room', {
            params: {
              fetch: this.room.pagination.per_page,
              page: this.room.pagination.page
            }
          })
          .then(response => {
            this.room.data = response.data.itens

            this.room.pagination.total = response.data.totalResults
            this.room.pagination.page = response.data.currentPage
            this.room.pagination.totalPage = Math.ceil(
              response.data.totalResults / this.room.pagination.per_page
            )

            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    callChannels () {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${this.$store.getters.api}/company/channels`)
          .then(({ data }) => {
            this.channels = data.filter(item => item.isEnable)

            resolve()
          })
          .catch(error => reject(error))
      })
    },

    callNotifications () {
      return Promise.resolve()
        .then(() => {
          return this.$http
            .get(`${this.$store.getters.api}/Room/notification`)
            .then(({ data }) => {
              const notifications = data

              this.roomNotifications = notifications
            })
        })
        .then(() => {
          return this.$http
            .get(`${this.$store.getters.api}/notification`)
            .then(({ data }) => {
              const allNotifications = data.itens || []
              const filteredNotifications = []
              const arr = []

              this.roomNotifications.forEach(channel => {
                channel.notifications.forEach(notification =>
                  filteredNotifications.push(notification.notificationId)
                )
              })
              this.notifications = allNotifications.filter(notification => {
                return filteredNotifications.find(
                  item => item == notification.notificationId
                )
              })

              this.channels.forEach((ch, index) => {
                arr.push({
                  channel: { channelId: ch.channelId, isEnable: ch.isEnable },
                  notifications: []
                })
                this.notifications.forEach(notification => {
                  const channel = notification.notificationChannels.find(
                    notificationChannel =>
                      notificationChannel.channelId == ch.channelId
                  )
                  // Verifica se canal está habilitado para notificação
                  if (channel) {
                    this.roomNotifications.forEach(roomChannel => {
                      if (roomChannel.channel.channelId == channel.channelId) {
                        const roomNotification = roomChannel.notifications.find(
                          not =>
                            not.notificationId == notification.notificationId
                        )

                        // Verifica se notificação está habilitada naquele perfil de acesso e se é obrigatório
                        if (roomNotification) {
                          arr[index].notifications.push({
                            notificationId: notification.notificationId,
                            isEnable: roomNotification.isEnable,
                            isRequired: roomNotification.isRequired
                          })
                        } else {
                          arr[index].notifications.push({
                            notificationId: notification.notificationId,
                            isEnable: false,
                            isRequired: false
                          })
                        }
                      }
                    })
                  } else {
                    arr[index].notifications.push({
                      notificationId: notification.notificationId,
                      isEnable: false,
                      isRequired: false
                    })
                  }
                })
              })

              this.filteredNotifications = arr

              if (!this.userNotifications.length) {
                this.userNotifications = this.filteredNotifications.map(x => {
                  return {
                    channel: x.channel,
                    notifications: x.notifications.map(y => {
                      return {
                        notificationId: y.notificationId,
                        isEnable: false
                      }
                    })
                  }
                })
              } else {
                this.filteredNotifications.forEach(channel => {
                  const indexChannel = this.userNotifications.findIndex(
                    uChannel =>
                      uChannel.channel.channelId == channel.channel.channelId
                  )

                  if (indexChannel > -1) {
                    channel.notifications.forEach(channelNotification => {
                      if (
                        !this.userNotifications[
                          indexChannel
                        ].notifications.find(
                          item =>
                            item.notificationId ==
                            channelNotification.notificationId
                        )
                      ) {
                        this.userNotifications[indexChannel].notifications.push(
                          {
                            notificationId: channelNotification.notificationId,
                            isEnable: false
                          }
                        )
                      }
                    })
                  } else {
                    this.userNotifications.push({
                      channel: channel.channel,
                      notifications: channel.notifications.map(item => {
                        return {
                          notificationId: item.notificationId,
                          isEnable: false
                        }
                      })
                    })
                  }
                })
              }
            })
            .catch(error => {
              return Promise.reject(error)
            })
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeCheck (args) {
      // args.notificationId
      // args.channelId
      this.userNotifications.forEach(item => {
        if (item.channel.channelId == args.channelId) {
          const index = item.notifications.findIndex(
            notification => notification.notificationId == args.notificationId
          )

          item.notifications[index].isEnable = !item.notifications[index].isEnable
        }
      })
    },

    changeFile (file) {
      this.account.image = file
    },

    removeFile () {
      this.account.image = ''
      this.$store.commit('removeUserImage')
    },

    changeStrongPassword (args) {
      this.strongPassword = args
    },

    closePage () {
      this.$store.commit('closePage', '/usuario')
    },

    /* */

    submit () {
      this.errorPasswordIncorret = false

      if (this.account.new_password != this.account.cnew_password) {
        this.$refs.newPassword.$refs.input.focus()
      } else if (
        (!!this.account.new_password || !!this.account.cnew_password) &&
        !this.account.password
      ) {
        this.$refs.password.$refs.input.focus()

        this.errorPasswordIncorret = true
      } else if (this.account.new_password && this.strongPassword < 3) {
        this.$refs.newPassword.$refs.input.focus()
      } else {
        this.loadingSubmit = true

        const ableNotifications = []

        this.filteredNotifications.forEach((item, index) => {
          ableNotifications.push({ channel: item.channel, notifications: [] })

          item.notifications.forEach(notification => {
            if (notification.isEnable && !notification.isRequired) {
              ableNotifications[index].notifications.push({
                notificationId: notification.notificationId
              })
            }
          })
        })

        ableNotifications.forEach(item => {
          const indexChannel = this.userNotifications.findIndex(
            un => un.channel.channelId == item.channel.channelId
          )

          item.notifications.forEach(notification => {
            const isEnable = this.userNotifications[
              indexChannel
            ].notifications.find(
              uNot => uNot.notificationId == notification.notificationId
            ).isEnable

            notification.isEnable = isEnable
          })
        })

        const account = {}
        account.displayName = this.account.displayName
        account.firstName = this.account.firstName
        account.lastName = this.account.lastName
        account.phoneNumber = this.account.phoneNumber
        account.profileImage = typeof this.account.image !== 'undefined'
          ? this.account.image
          : this.account.profileImage
        account.currentPassword = this.account.password || ''
        account.newPassword = this.account.new_password || ''
        account.notifications = ableNotifications.filter(x => x.notifications.length > 0)

        this.$http
          .put(`${this.$store.getters.api}/account/` + this.account.accountId, account)
          .then(response => {
            this.loadingSubmit = false

            const setAccount = {
              name: account.displayName,
              email: this.account.email,
              image: account.profileImage == null ? '' : account.profileImage,
              attributes: response.data?.attributes
            }

            this.$store.commit('setUserInfo', setAccount)

            this.$store.commit('closePage', '/usuario')

            this.$store.dispatch('notification', {
              text: 'Dados do usuário editado com sucesso!'
            })
          })
          .catch(error => {
            this.loadingSubmit = false

            if (error.status == 409) {
              this.errorPasswordIncorret = true
              this.$refs.password.$refs.input.focus()
            } else {
              this.$store.dispatch('notification', {
                type: 'error',
                text: 'Ocorreu um erro ao editar os dados do usuário.'
              })
            }
          })
      }
    }
  },

  watch: {
    'account.password': function () {
      this.errorPasswordIncorret = false
    },

    'account.new_password': function () {
      if (this.$refs.newPassword) this.$refs.newPassword.$refs.input.setCustomValidity('')
    },

    'account.cnew_password': function () {
      if (this.$refs.newCPassword) this.$refs.newCPassword.$refs.input.setCustomValidity('')
    }
  }
}
</script>

<style lang="scss" module>

/* */

#id {
  &_form,
  &_firstName,
  &_lastName,
  &_displayName,
  &_appName,
  &_email,
  &_accountType,
  &_companyId,
  &_submit,
  &_roomId,
  &_cancel,
  &_clear {
    text-decoration: none;
  }
}

.validation {
  position: absolute;
  top: 60px;
  right: 20px;

  margin: 0;
  padding: 3px 6px;

  background: red;
  color: white;
  @include font-sizer(11);
  border-radius: 7px;
}

.loading {
  min-height: calc(100vh - 65px) !important;
}

.padding {
  padding: $theme-padding * 4 $theme-padding * 5 $theme-padding * 8;
  min-height: calc(100vh - 135px);
}
</style>
