<template>
  <div :class="$style.grid" id="grid_columns_template_report">
    <app-confirm-promise ref="confirm" />

    <transition name="fade">
      <teleport to="body">
        <app-modal
          v-if="modal.example.active"
          size="auto"
          title="Pré-visualização"
          :overflow="false"
          @close="modal.example.active = false"
        >
          <div :class="$style.scroll">
            <div :class="$style.preview">
              <div :class="$style.previewLetter">
                <div :class="$style.previewLetterCorner"></div>
                <div
                  v-for="(item, index) in exampleData"
                  :key="index"
                  :class="$style.previewLetterCell"
                  :style="{ width: item.width * 7 + 'px' }"
                >
                  <div>{{ capitalLetters(index) }}</div>
                </div>
              </div>

              <div :class="$style.previewBody">
                <div :class="$style.previewBodyLine">
                  <div
                    :class="[
                      $style.previewBodyCell,
                      $style.previewBodyCellIndex
                    ]"
                  >
                    1
                  </div>
                  <div
                    v-for="(item, index) in exampleData"
                    :key="index"
                    :class="[
                      $style.previewBodyCell,
                      $style.previewBodyCellTitle
                    ]"
                    :style="{ width: item.width * 7 + 'px' }"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div v-for="i in 5" :key="i" :class="$style.previewBodyLine">
                  <div
                    :class="[$style.previewBodyCell, $style.previewBodyCellIndex]"
                  >
                    {{ i + 1 }}
                  </div>
                  <div
                    v-for="(item, index) in exampleData"
                    :key="index"
                    :class="$style.previewBodyCell"
                    :style="{
                      width: item.width * 7 + 'px',
                      backgroundColor: item.backgroundColor,
                      color: item.fontColor,
                    }"
                  >
                    <div
                      v-for="(value, index) in item.values"
                      :key="index"
                      v-html="value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-modal>
      </teleport>
    </transition>

    <div :class="$style.buttons">
      <app-button
        v-if="!readonly"
        label="Adicionar coluna"
        color="green"
        small
        light
        icon="add"
        :disabled="subjectList.length == 0"
        @click="addColumn()"
      />

      <app-button
        label="Pré-visualização"
        small
        light
        icon="table_chart"
        :disabled="subjectList.length == 0 || data.columns.length == 0"
        @click="modal.example.active = true"
      />

      <input
        ref="targetInputValidateColumn"
        type="text"
        :class="$style.buttonsInput"
      >
    </div>

    <div
      ref="scroll"
      :class="$style.scroll"
    >
      <Sortable
        :list="data.columns"
        :item-key="'columnIdentifier'"
        :class="$style.gridDrag"
        :options="{
          animation: 150,
          handle: '.draggable-handler',
        }"
        @end="sortColumns"
      >
        <template #item="{ element, index }">
          <div
            :class="$style.gridColumn"
            :key="element.columnIdentifier"
          >
            <app-columns
              :data="element"
              :subjectList="subjectList"
              :index="index"
              :readonly="readonly"
              @remove-column="openModalRemoveColumn(index)"
              @change-attr="changeAttr(index, $event)"
              @change-subject="changeSubject(index, $event)"
              @change-name="changeName(index, $event)"
              @change-width="changeWidth(index, $event)"
              @preview-font-color="previewFontColor(index, $event)"
              @select-font-color="selectFontColor(index, $event)"
              @preview-bg-color="previewBgColor(index, $event)"
              @select-bg-color="selectBgColor(index, $event)"
            />
          </div>
        </template>
      </Sortable>
    </div>
  </div>
</template>

<script>
import { camelCase } from 'lodash-es'
import { Sortable } from 'sortablejs-vue3'
import sortArray from '@/composables/useSortable'
import AppColumns from '@/talkture/scenario/components/model/columns/Column.vue'

export default {
  components: {
    AppColumns,
    Sortable
  },

  props: {
    columns: Array,
    focus: Boolean,
    talkId: Number,
    config: Object
  },

  emits: [
    'change-value',
    'change-data-columns'
  ],

  computed: {
    subjectList () {
      return this.$store.getters.getReportModelColumnSubjects
    },
    exampleData () {
      return this.data.columns.map(({ attributes, configs }) => {
        const attributesValue = []

        attributes.forEach((item, index) => {
          const regexp = /\$\{([A-Za-z0-9.]*)\}/g
          const matches = Array.from(item.value.matchAll(regexp))
          let value = item.value

          matches.forEach((match) => {
            const [subjectIdentifier, attributeIdentifier] = match[0].replace(regexp, '$1').split('.')
            const subject = this.subjectList.find((item) => item.subjectIdentifier == subjectIdentifier)
            const attribute = subject.subjectAttributes.find((item) => item.subjectAttributeIdentifier == attributeIdentifier)

            const valueExample = () => {
              switch (attribute.componentId) {
                case 2:
                  return '99.999.999/9999-99'
                case 5:
                  return '9999.99'
                case 6:
                  return '(99) 99999-9999'
                case 11:
                  return '99/99/9999 99:99'
                case 19:
                  return '99:99'
                case 32:
                  return '99/99/9999'
                case 10:
                case 27:
                  return 'Sim'
                case 17:
                case 36:
                  return 'exemplo@exemplo.com.br'
                default:
                  return attribute.name
              }
            }

            value = value.replace(match[0], valueExample)
          })

          if (item.isGrouped) {
            for (let index = 1; index <= 3; index++) {
              attributesValue.push(`${value.replace(/\/n/g, '<br>')} - ${index}`)
            }
          } else {
            attributesValue.push(value.replace(/\/n/g, '<br>'))
          }
        })

        const obj = {
          values: attributesValue
        }

        const data = configs
          .filter(({ configIdentifier }) =>
            ['name', 'width', 'backgroundcolor', 'fontcolor'].includes(
              configIdentifier.toLowerCase()
            )
          )
          .map(({ configIdentifier, value }) => ({ configIdentifier, value }))

        data.forEach((config) => {
          obj[camelCase(config.configIdentifier)] = config.value
        })

        return obj
      })
    },
    readonly () {
      return (
        this.config.readonly ||
          ((this.talkId != 0) & (typeof this.talkId != 'undefined')
            ? !this.config.actions.update
            : !this.config.actions.insert)
      )
    }
  },

  data () {
    return {
      columnId: 99999,
      data: {
        columns: []
      },
      modal: {
        example: {
          active: false
        }
      }
    }
  },

  mounted () {
    this.init()
  },

  beforeUnmount() {
    this.$store.commit('SET_REPORT_MODEL_COLUMN_SUBJECTS', [])
  },

  methods: {
    sortColumns ({ oldIndex, newIndex }) {
      const dataColumns = JSON.parse(JSON.stringify(this.data.columns))
      this.data.columns = sortArray(dataColumns, oldIndex, newIndex)
    },

    init () {
      this.data.columns = this.columns
      this.data.columns.forEach((item, index) => {
        this.columnId += 1
        item.columnIdentifier = this.columnId
      })
    },

    selectBgColor (index, color) {
      const indexConfig = this.data.columns[index].configs.findIndex(
        (x) => x.configIdentifier.toLowerCase() == 'backgroundcolor'
      )

      this.data.columns[index].configs[indexConfig].value = color
      this.data.columns[index].previewBgColor = false
    },

    selectFontColor (index, color) {
      const indexConfig = this.data.columns[index].configs.findIndex(
        (x) => x.configIdentifier.toLowerCase() == 'fontcolor'
      )

      this.data.columns[index].configs[indexConfig].value = color
      this.data.columns[index].previewFontColor = false
    },

    previewBgColor (index, color) {
      this.data.columns[index].previewBgColor = color
    },

    previewFontColor (index, color) {
      this.data.columns[index].previewFontColor = color
    },

    /* */

    changeName (index, value) {
      const indexConfig = this.data.columns[index].configs.findIndex(
        (x) => x.configIdentifier.toLowerCase() == 'name'
      )

      this.data.columns[index].configs[indexConfig].value = value
    },

    changeWidth (index, value) {
      const indexConfig = this.data.columns[index].configs.findIndex(
        (x) => x.configIdentifier.toLowerCase() == 'width'
      )

      this.data.columns[index].configs[indexConfig].value = value
    },

    addAttr (index) {
      const newAttr = {
        value: '',
        isGrouped: false
      }

      this.data.columns[index].attributes.push(newAttr)
    },

    async openModalRemoveColumn (indexColumn) {
      const confirm = this.$refs.confirm
      await confirm
        .open('Você deseja realmente remover essa coluna?', 'red')
        .then((response) => {
          this.removeColumn(indexColumn)
        })
        .catch((error) => false)
      confirm.close()
    },

    addColumn () {
      this.columnId += 1

      const newCol = {
        columnIdentifier: this.columnId,
        focus: true,
        configs: [
          {
            configIdentifier: 'name',
            value: ''
          },
          {
            configIdentifier: 'width',
            value: 25
          },
          {
            configIdentifier: 'backgroundColor',
            value: '#ffffff'
          },
          {
            configIdentifier: 'fontColor',
            value: '#000000'
          }
        ],

        attributes: [
          {
            value: '',
            isGrouped: false
          }
        ]
      }

      this.data.columns.push(newCol)

      this.$nextTick(() => {
        this.$refs.scroll.scrollLeft += 400
      })
    },

    removeColumn (index) {
      this.data.columns.splice(index, 1)
    },

    /* */

    changeAttr (index, obj) {
      if (typeof obj.data.value !== 'undefined') {
        this.data.columns[index].attributes[obj.indexAttr].value = obj.data.value
      }

      if (typeof obj.data.isGrouped !== 'undefined') {
        this.data.columns[index].attributes[obj.indexAttr].isGrouped = obj.data.isGrouped
      }
    },

    changeSubject (index, obj) {
      this.data.columns[index].attributes[obj.indexAttr].subjectId = obj.id
    },

    setColumn () {
      this.$emit('change-value', this.data.columns)
    },

    capitalLetters (index) {
      const letters = [...Array(26)].map((_, i) => String.fromCharCode(i + 65))

      if (index > 25) {
        const repeat = parseInt(index / 26)
        return letters[repeat - 1] + letters[index - repeat * 26]
      }
      return letters[index]
    }
  },

  watch: {
    'data.columns': {
      handler: function (newValue) {
        if (newValue.length > 0) {
          this.$refs.targetInputValidateColumn.setCustomValidity('')
        } else {
          this.$refs.targetInputValidateColumn.setCustomValidity('Adicione pelo menos uma coluna')
        }
      },

      deep: true
    },

    data: {
      handler: function () {
        if (this.subjectList.length !== 0) {
          this.setColumn()
        }
      },

      deep: true
    },

    subjectList: {
      handler: function () {
        this.setColumn()
        const tempData = JSON.parse(JSON.stringify(this.data.columns))

        const indexRemove = []

        tempData.forEach((col, indexCol) => {
          col.attributes.forEach((attr, indexAttr) => {
            if (this.subjectList.length == 0) {
              indexRemove.unshift({ indexCol, indexAttr })
            } else {
              if (attr.value) {
                const array = attr.value.match(/\$\{([a-zA-Z0-9.]*)\}/g)

                array?.forEach((item) => {
                  const attributeName = item.match(/([a-zA-Z0-9]*)/g)[2]
                  const subjectId = this.subjectList.find((item) => item.subjectIdentifier === attributeName)

                  if (typeof subjectId === 'undefined') {
                    indexRemove.unshift({ indexCol, indexAttr })
                  }
                })
              }
            }
          })
        })

        if (indexRemove.length > 0) {
          indexRemove.forEach((e) => {
            this.data.columns[e.indexCol].attributes.splice(e.indexAttr, 1)
          })
        }

        this.data.columns.forEach((column, columnIndex) => {
          if (column.attributes.length == 0) {
            this.addAttr(columnIndex)
          }
        })
      },

      deep: true
    }
  }
}
</script>

<style lang="scss" module>
.buttons {
  position: relative;
  display: flex;

  &__input{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.grid {
  width: 100%;

  &__drag {
    display: flex;
    padding: $theme-padding * 2 0;
    margin-top: $theme-padding;
  }

  &__column {
    padding-right: $theme-padding * 2;

    &_add {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 40px;
      width: 40px;
      margin-left: $theme-padding;

      background: $md-grey-300;
      border-radius: 6px;

      cursor: pointer;

      transition: 0.4s;
      transition-property: background;

      &:hover {
        background: $md-grey-500;
      }

      :global(.nightlymode) & {
        background: $md-grey-600;

        &:hover {
          background: $md-grey-800;
        }
      }

      &__icon {
        fill: $md-grey-800;
        width: 40px;

        :global(.nightlymode) & {
          fill: white;
        }
      }
    }
  }
}

.scroll {
  overflow: auto;
  min-width: 440px;
  max-width: calc(100vw - 40px);

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;

    &-thumb {
      background-color: $md-grey-400;

      :global(.nightlymode) & {
        background-color: $md-grey-700;
      }
    }
  }
}

.preview {
  padding: $theme-padding * 2;
  margin: 0 auto;
  width: min-content;

  &__letter {
    display: flex;

    &__cell {
      flex-shrink: 0;
      background-color: $md-grey-200;
      border: solid $md-grey-400;
      border-width: 1px 1px 1px 0;
      font-size: 1.1rem;
      text-align: center;
    }

    &__corner {
      flex-shrink: 0;
      width: 30px;
      border: solid $md-grey-400;
      border-width: 0 1px 1px 0;
    }
  }

  &__body {
    &__line {
      display: flex;
    }

    &__cell {
      flex-shrink: 0;
      padding: 2px 4px;
      min-height: 24px;
      font-size: 1.3rem;
      border: solid $md-grey-300;
      border-width: 0 1px 1px 0;
      word-wrap: break-word;

      &--index {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        background-color: $md-grey-200;
        border-color: $md-grey-400;
        border-width: 0 1px 1px;
        font-size: 1.1rem;
      }

      &--title {
        font-weight: 500
      }
    }
  }
}
</style>
