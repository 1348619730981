<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'

const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  focus: Boolean,
  mode: {
    type: String,
    default: ''
  },
  config: {
    type: Object,
    default: () => {}
  },
  talkId: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:value'])

const targetInput = ref(null)
const setFocus = ref(false)

const emails = computed(() => (
  props.value !== '' && typeof props.value !== 'undefined'
    ? props.value.split(';')
    : ['']
))

const readonly = computed(() => {
  const actionsPermission = props.talkId !== 0 && typeof props.talkId !== 'undefined'
    ? !props.config.actions.update
    : !props.config.actions.insert

  return props.config.readonly || actionsPermission
})

onMounted(() => {
  if (props.mode !== 'form' || props.focus) {
    nextTick(() => {
      setFocus.value = true
    })
  }
})

const changeValue = (index, event) => {
  emails.value[index] = event.target.value
  updateValue()
}

const addEmail = () => {
  emails.value.push('')
  updateValue()
}

const removeEmail = (index) => {
  emails.value.splice(index, 1)
  updateValue()
}

const updateValue = () => {
  emit('update:value', emails.value.join(';'))
}
</script>

<template>
  <div :class="$style.multipleEmail">
    <transition-group
      name="fade-down"
      tag="span"
    >
      <div
        v-for="(email, index) in emails"
        :key="index"
        :class="$style.multipleEmailItem"
      >
        <div :class="$style.multipleEmailInput">
          <app-input-label
            required
            :readonly="props.config.readonly"
          >
            E-mail
          </app-input-label>

          <app-input
            ref="targetInput"
            required
            type="email"
            :readonly="readonly"
            :value="email"
            :focus="setFocus"
            @input="changeValue(index, $event)"
            @blur="validateEmail(index)"
          />
        </div>

        <div
          v-if="emails.length > 1 && !readonly"
        >
          <button
            v-tooltip.left="'Remover e-mail'"
            :class="$style.multipleEmailRemove"
            type="button"
            @click="removeEmail(index)"
          >
            <app-icon
              :class="$style.multipleEmailRemoveIcon"
              glyph="delete"
            />
          </button>
        </div>
      </div>
    </transition-group>

    <button
      v-if="!readonly"
      type="button"
      :class="$style.multipleEmailAdd"
      @click="addEmail"
    >
      <app-icon
        :class="$style.multipleEmailAddIcon"
        glyph="add"
        size="16"
      />
      <span>Adicionar destinatários</span>
    </button>
  </div>
</template>

<style lang="scss" module>
.multiple_email {
  margin-top: $theme-padding + 5px;

  &__item {
    display: flex;
    column-gap: 15px;
    align-items: center;
    padding-bottom: 5px;
  }

  &__input {
    flex-grow: 1;
  }

  &__add {
    display: flex;
    column-gap: 4px;
    padding: 0;
    background: none;
    color: $md-grey-800;
    font-family: 'dinpro', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity .2s;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }

    :global(.nightlymode) & {
      color: #fff;
    }

    &__icon {
      fill: $md-grey-800;

      :global(.nightlymode) & {
        fill: #fff;
      }
    }
  }

  &__remove {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 10px 0 0;
    width: 24px;
    height: 24px;
    background: none;
    cursor: pointer;

    &:focus{
      outline: 0;
    }

    &__icon {
      fill: $md-grey-600;
      transition: fill 0.2s;

      :global(.nightlymode) & {
        fill: #fff;
      }

      &:hover {
        fill: $md-red-500 !important;
      }
    }
  }
}
</style>
