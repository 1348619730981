<template>
  <input
    :class="[
      $style.input,
      mode == 'form' || mode == 'filter-attr' ? $style.inputForm : null,
      mode == 'form' && oldValue == '' ? $style.inputEmpty : null,
    ]"
    :value="value.toUpperCase()"
    v-on:input="$emit('change', $event.target.value)"
    @change.stop
    v-on:blur="blur"
    ref="input"
    :maxlength="config.maxlength"
    :required="config.required"
    :readonly="readonly"
    v-maska
    data-maska="SSS-#X##"
    v-on:keydown.tab="tab"
    v-on:keydown.enter="enter"
  />
</template>

<script>
import EventBus from '../../eventBus'

export default {
  data () {
    return {
      delay: null,

      oldValue: null
    }
  },

  props: {
    mode: String,
    value: String,
    config: {},
    talkId: Number,

    focus: Boolean
  },

  beforeMount () {
    if (this.value) {
      this.oldValue = JSON.parse(JSON.stringify(this.value).toUpperCase())
    }
  },

  mounted () {
    if (this.mode !== 'form' || this.focus) {
      setTimeout(
        () => {
          this.$refs.input.focus()
        },
        1,
        this
      )
    }
  },

  computed: {
    readonly () {
      return (
        this.config.readonly ||
        ((this.talkId != 0) & (typeof this.talkId != 'undefined')
          ? !this.config.actions.update
          : !this.config.actions.insert)
      )
    }
  },

  methods: {
    blur () {
      if (this.oldValue != this.value) {
        this.$emit('blur')
      }
    },

    tab () {
      EventBus.$emit('tab')
    },

    enter () {
      EventBus.$emit('enter')
    }
  }
}
</script>

<style lang="scss" module>

$input-color: black;
$input-color-nightly: white;
$input-background: white;
$input-background-nightly: $md-grey-900;

.input {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: math.div($theme-padding, 2) $theme-padding;

  font-family: 'Roboto', sans-serif;
  @include font-sizer(12);
  font-weight: 400;
  color: $input-color;

  background: $input-background;

  box-shadow: inset 0 0 0 2px $md-blue-500;
  border-radius: 5px;

  :global(.nightlymode) & {
    color: $input-color-nightly;

    background: $input-background-nightly;
  }

  &[readonly] {
    background: $md-grey-100;

    box-shadow: inset 0 0 0 2px $md-grey-500;

    :global(.nightlymode) & {
      background: $md-grey-800;

      box-shadow: inset 0 0 0 2px $md-grey-500;
    }

    cursor: default;
  }

  &--form {
    height: 50px;
    position: relative;

    box-shadow: inset 0 0 0 2px $md-grey-400;

    transition: 0.2s;
    transition-property: box-shadow;

    :global(.nightlymode) & {
      box-shadow: inset 0 0 0 2px $md-grey-600;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $md-blue-500;
    }
  }

  &--empty {
    padding-top: math.div($theme-padding, 2);
    padding-bottom: math.div($theme-padding, 2);
    padding-left: math.div($theme-padding, 2);
    padding-right: 40px;
  }
}
</style>
