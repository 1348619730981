<template>
  <div>
    <input
      v-maska
      :data-maska="mask"
      ref="input"
      :class="$style.input"
      :placeholder="placeholder"
      v-model="inputValue"
      :minlength="0"
      :maxlength="config?.maxlength || 800"
      :required="config.required"
      :readonly="readonly"
      :type="type"
      :min="type === 'number' ? -2147483648 : null"
      :max="type === 'number' ? 2147483648 : null"
      :tabindex="readonly ? -1 : null"
      @keypress=";(type == 'number' && verifyNumber($event))"
      @wheel="handleWheel"
    />
    <span v-if="!isValid.status && showMessage" :class="$style.msgvalid">
      {{ isValid.message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    value: [String, Number],
    type: {
      type: String,
      default: 'text'
    },
    config: {
      type: Object,
      default: () => {}
    },
    talkId: Number
  },
  emits: ['input', 'change'],
  data () {
    return {
      delay: null,
      showMessage: false,
      customTokens: {
        mask: 'HH:Mm:Mm',
        tokens: {
          H: {
            pattern: /[0-9]/
          },
          M: {
            pattern: /[0-9]/,
            transform: function (v) {
              return +v > 5 ? 5 : v
            }
          },
          m: {
            pattern: /[0-9]/
          }
        }
      }
    }
  },
  computed: {
    inputValue: {
      get () { return this.value },
      set (value) {
        clearTimeout(this.delay)
        this.delay = setTimeout(() => {
          if (value === this.value) return
          this.$emit('change', value)
          this.showMessage = true
        }, 300)
      }
    },
    mask () {
      return this.config?.componentConfigs?.inputMask
        ? this.config.componentConfigs.inputMask === '##:##:##'
          ? this.customTokens
          : this.config.componentConfigs.inputMask
        : ''
    },
    placeholder () {
      return this.mask === '##:##:##' || this.mask === this.customTokens ? '__:__:__' : ''
    },
    readonly () {
      return (
        this.config.readonly ||
        (this.talkId !== 0 && !this.config.actions.update) ||
        (this.talkId === 0 && !this.config.actions.insert)
      )
    },
    numberDI () {
      return this.inputValue.replace(/\D/g, '').replace(/(-\d{9})\d+?$/, '$1')
    },
    isValid () {
      let validation = {
        status: true,
        message: ''
      }

      const lengthCompare = this.config.required ? 0 : 1

      if (this.config?.minChars && this.inputValue.length >= lengthCompare) {
        if (this.inputValue.length < this.config.minChars) {
          validation.status = false
          validation.message = `${this.name} deve conter pelo menos ${this.config.minChars} caracteres`
        }
      }

      if (this.config?.minWords && this.inputValue.length >= lengthCompare) {
        const words = this.inputValue.split(' ').filter(Boolean)
        if (words.length < this.config.minWords) {
          validation.status = false
          validation.message = `${this.name} deve conter pelo menos ${this.config.minWords} palavras`
        }
      }

      if (this.config?.componentConfigs?.inBetween) {
        const duration = this.config?.componentConfigs.inBetween.fieldValue

        const invalidDuration = {
          status: false,
          message: 'Duração inválida'
        }

        if (duration.length >= 5) {
          const minutes = Number(duration[3] + duration[4])
          const hoursInMinutes = Number(duration[0] + duration[1]) * 60
          const secondsInMinutes = Number(duration[6] + duration[7]) / 60

          const tooBigDuration = {
            status: false,
            message: 'A duração não pode ser maior do que o período entre a previsão de início e a previsão de término'
          }

          switch (duration.length) {
            case 5:
              if (this.config?.componentConfigs.inBetween.difference < (minutes + hoursInMinutes)) {
                validation = tooBigDuration
              }
              break
            case 7:
              validation = invalidDuration
              break
            case 8:
              if (
                this.config?.componentConfigs.inBetween.difference <
                (minutes + hoursInMinutes + secondsInMinutes)
              ) {
                validation = tooBigDuration
              }
              break
          }
        } else {
          validation = invalidDuration
        }
      }

      return validation
    }
  },
  methods: {
    verifyNumber (value) {
      if (
        value.charCode !== 48 &&
        value.charCode !== 49 &&
        value.charCode !== 50 &&
        value.charCode !== 51 &&
        value.charCode !== 52 &&
        value.charCode !== 53 &&
        value.charCode !== 54 &&
        value.charCode !== 55 &&
        value.charCode !== 56 &&
        value.charCode !== 57
      ) {
        value.preventDefault()
      }
    },

    handleWheel (event) {
      if (this.type === 'number') {
        event.target.blur()
      }
    }
  },
  watch: {
    inputValue: {
      handler: function () {
        this.$nextTick(() => {
          if (!this.isValid.status) {
            if (this.$refs.input.validationMessage !== this.isValid.message) this.$refs.input.setCustomValidity(this.isValid.message)
          } else {
            this.showError = false
            this.$refs.input?.setCustomValidity('')
          }
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" module>

$input-color: black;
$input-color-nightly: white;
$input-background: white;
$input-background-nightly: $md-grey-900;

.input {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: math.div($theme-padding, 2) $theme-padding;
  font-family: 'Roboto', sans-serif;
  @include font-sizer(12);
  font-weight: 400;
  color: $input-color;
  background: $input-background;
  border-radius: 5px;
  box-shadow: inset 0 0 0 2px $md-grey-400;
  transition: 0.2s;
  transition-property: box-shadow;

  :global(.nightlymode) & {
    color: $input-color-nightly;
    background: $input-background-nightly;
    box-shadow: inset 0 0 0 2px $md-grey-600;
  }

  &:not([readonly]):focus {
    box-shadow: inset 0 0 0 2px var(--theme-color-1);

    :global(.nightlymode) & {
      box-shadow: inset 0 0 0 2px var(--theme-color-1);
    }
  }

  &[readonly] {
    background: $md-grey-100;
    cursor: default;

    :global(.nightlymode) & {
      background: $md-grey-800;
    }

  }

}

.msgvalid {
    position: relative;

    width: auto;

    padding: 5px;
    margin-top: 0px;
    margin-left: 8px;

    background: $md-red-700;
    border-radius: 3px;

    @include font-sizer(12);
    line-height: 12px;
    color: #fff;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  }
</style>
