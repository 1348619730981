<template>
  <div
    :class="[
      $style.checkbox,
      mode == 'form' ? $style.checkboxForm : '',
      readonly ? $style.checkboxDisabled : '',
    ]"
    name="checkbox"
  >
    <!-- <app-switch :active="controller" v-on:change="changeState" ref="input" /> -->

    <input
      type="checkbox"
      :checked="controller != '' ? true : false"
      :required="config.required"
      :readonly="readonly"
      :style="{
        position: 'absolute',
        'z-index': -1,
        'pointer-events': 'none',
        opacity: 0,
        left: '5px',
      }"
      @click.prevent
      tabindex="-1"
    />

    <button
      type="button"
      :class="$style.checkboxItem"
      v-on:click="changeState('false')"
      name="nao"
    >
      <app-icon
        :glyph="controller == 'false' ? 'check_box_outline' : 'check_box_outline_blank'"
      />
      <label>Não</label>
    </button>

    <button
      type="button"
      :class="$style.checkboxItem"
      v-on:click="changeState('true')"
      name="sim"
    >
      <app-icon
        :glyph="controller == 'true' ? 'check_box_outline' : 'check_box_outline_blank'"
      />
      <label>Sim</label>
    </button>

    <button
      v-if="!config.required"
      type="button"
      :class="$style.checkboxItem"
      v-on:click="changeState('')"
      name="vazio"
    >
      <app-icon
        :glyph="controller == '' ? 'check_box_outline' : 'check_box_outline_blank'"
      />
      <label>Não preenchido</label>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Boolean],
      default: () => ''
    },

    mode: String,

    focus: Boolean,

    emitInit: Boolean,

    config: Object,

    talkId: Number
  },

  data () {
    return {
      controller: ''
    }
  },

  beforeMount () {
    this.init()
  },

  methods: {
    init () {
      if (this.value === true || this.value === 'true') {
        this.controller = 'true'
      } else if (this.value === false || this.value === 'false') {
        this.controller = 'false'
      } else if (this.config.defaultValue === false) {
        this.controller = 'false'
      } else {
        this.controller = ''
      }
    },

    changeState (payload) {
      this.controller = payload

      this.$emit('change-input', payload)
    }
  },

  computed: {
    readonly () {
      return (
        this.config.readonly ||
        ((this.talkId != 0) & (typeof this.talkId != 'undefined')
          ? !this.config.actions.update
          : !this.config.actions.insert)
      )
    }
  },

  watch: {
    value: function () {
      this.init()
    }
  }
}
</script>

<style lang="scss" module>

.checkbox {
  $checkbox-background: white;
  $checkbox-background-nightly: darken($md-grey-800, 10%);

  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: $checkbox-background;

  display: flex;
  align-items: center;
  justify-content: center;

  :global(.nightlymode) & {
    background: $checkbox-background-nightly;
  }

  &--form {
    height: 40px;

    position: relative;

    background: transparent !important;

    justify-content: flex-start;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
    cursor: pointer;

    label {
      margin: 2px 24px 0 4px;

      font-family: 'Roboto', sans-serif;
      font-size: 1.4rem;
      color: $md-grey-900;

      cursor: pointer;

      :global(.nightlymode) & {
        color: $md-grey-100;
      }
    }

    svg {
      fill: $md-grey-700;

      cursor: pointer;

      :global(.nightlymode) & {
        fill: $md-grey-100;
      }
    }

    &:focus {
      outline: 0;

      svg {
        border-radius: 4px;
        box-shadow: 0 0 0 2px var(--theme-color-1) inset;
      }
    }
  }
}
</style>
