<template>
  <app-config>
    <app-confirm-promise ref="confirm" />
    <app-loader
      v-if="loading.active || loading.error"
      :error="loading.error"
      :response="loading.response"
      resourceType="notification"
      key="loading"
      :active="loading.active"
      v-on:reload="init()"
    />
    <div v-else>
      <app-box>
        <form action="/" v-on:submit.prevent="save">
          <div :class="$style.padding">
            <app-box margin>
              <app-title label="Cadastrar Notificações" />
            </app-box>

            <div class="row">
              <div class="col">
                <app-box margin class="mb-5">
                  <app-box background title="Notificação">
                    <div class="row">
                      <div class="col-12">
                        <app-input
                          v-model:value="notification.notificationName"
                          name="notificationName"
                          label="Nome da Notificação"
                          :maxlength="100"
                          required
                        />

                        <app-input-label
                          >Descrição da Notificação</app-input-label
                        >
                        <textarea
                          v-model="notification.notificationDescription"
                          maxlength="500"
                          name="notificationDescription"
                          rows="5"
                          :class="$style.textarea"
                          class="mb-3"
                        />

                        <app-input-label>Assunto</app-input-label>
                        <select-box
                          :options="subjects"
                          :selected="notification.subjectId"
                          v-on:select="selectSubject"
                          :loading="loadingSubjects"
                        />
                      </div>
                    </div>
                  </app-box>
                </app-box>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <app-box margin class="mb-5">
                  <app-box background title="Templates">
                    <app-input-label required
                      >Template do Título</app-input-label
                    >
                    <input
                      :class="$style.input"
                      v-model="notification.titleTemplate"
                      name="titleTemplate"
                      maxlength="100"
                      required
                      v-on:input="handleText($event)"
                      rows="1"
                    />

                    <app-input-label required
                      >Template da Mensagem</app-input-label
                    >
                    <textarea
                      v-model="notification.messageTemplate"
                      name="notificationMessageTemplate"
                      maxlength="250"
                      required
                      rows="5"
                      :class="$style.textarea"
                      class="mb-3"
                      v-on:input="handleText($event)"
                    />

                    <transition name="fade">
                      <app-modal
                        v-if="tagsSelector"
                        title="Seletor de Variáveis"
                        size="480"
                        disable-resize
                        :overflow="false"
                        v-on:close="closeTagsSelector()"
                      >
                        <variables-selector
                          :variables="variables"
                          v-on:select="selectVariable"
                        />
                      </app-modal>
                    </transition>

                    <div class="row no-gutters align-items-center mt-4">
                      <div class="col-auto">
                        <app-switch
                          v-on:change="
                            notification.attachReferences =
                              !notification.attachReferences
                          "
                          :active="notification.attachReferences"
                          :class="$style.containerSwitch"
                        />
                      </div>
                      <div class="col ml-4">
                        <app-input-label class="mb-0">
                          <span
                            >Anexar links de referência:
                            <span :class="$style.optionLabel">{{
                              notification.attachReferences ? 'Sim' : 'Não'
                            }}</span></span
                          >
                        </app-input-label>
                      </div>
                    </div>
                  </app-box></app-box
                >
              </div>
            </div>

            <!--  -->
            <!--  -->
            <!--  -->
            <div class="row">
              <div class="col">
                <app-box margin>
                  <app-box
                    background
                    title="Canais"
                    :warn="
                      warn
                        ? '* É obrigatório informar pelo menos um canal'
                        : null
                    "
                  >
                    <div class="row">
                      <div class="col-12">
                        <ChannelMatrix
                          v-on:channelMatrixChecked="channelMatrixChecked"
                          :channels="notification.notificationChannels"
                        />
                      </div>
                    </div>
                  </app-box>
                </app-box>
              </div>
            </div>
          </div>
          <app-actions-box>
            <div class="row justify-content-center">
              <div class="col-auto">
                <app-button
                  :label="getNotificationId ? 'Atualizar' : 'Salvar'"
                  icon="save"
                  type="submit"
                  name="button_ok"
                  :loading="loadingSave"
                  :disabled="
                    (notification.notificationName &&
                      notification.titleTemplate &&
                      notification.messageTemplate) == ''
                      ? true
                      : false
                  "
                />
              </div>

              <div class="col-auto">
                <app-button
                  label="Cancelar"
                  icon="close"
                  color="red-500"
                  name="button_cancel"
                  v-on:click="closePage"
                />
              </div>
            </div>
          </app-actions-box>
        </form>
      </app-box>
    </div>
  </app-config>
</template>

<script>
import SelectBox from '@/components/select/SelectBox.vue'
import VariablesSelector from '@/views/automation/components/HttpRequestVariablesSelector.vue'
import ChannelMatrix from '@/views/config/access-profile/ChannelMatrix.vue'
import AppConfig from '@/views/config/Index.vue'

export default {
  components: {
    AppConfig,
    SelectBox,
    VariablesSelector,
    ChannelMatrix
  },

  data () {
    return {
      notification: {
        notificationName: '',
        notificationDescription: '',
        subjectId: null,
        messageTemplate: '',
        titleTemplate: '',
        attachReferences: true,
        notificationChannels: []
      },

      chars: {
        '"': '"',
        '{': '}',
        '[': ']'
      },
      tagsSelector: false,
      cursorLastPosition: 0,
      input: '',

      dataAttributes: [],
      actionsAttributes: [],

      loading: {
        active: false,
        error: false
      },

      loadingSearch: {
        active: false,
        error: false,
        done: false
      },

      loadingSubjects: false,
      loadingSave: false,
      loadingNotification: false
    }
  },
  computed: {
    subjects () {
      return this.$store.getters.getTables
    },
    getNotificationId () {
      return this.$route.params.notificationId
    },

    variables () {
      const array = []

      array.push({
        id: 1,
        label: 'Usuário / E-mail',
        variable: 'User.Email'
      })

      array.push({
        id: 2,
        label: 'Usuário / Nome',
        variable: 'User.Name'
      })

      array.push({
        id: 3,
        label: 'Identificador da Conversa',
        variable: 'IdentificadorConversa'
      })

      const subject = this.subjects.find(
        (subject) => subject.id == this.notification.subjectId
      )

      if (subject) {
        subject.columns.forEach((column) => {
          array.push({
            id: `${subject.id}${column.id}`,
            label: `${subject.label} / ${column.label}`,
            variable: column.identifier
          })

          if (typeof column.relatedSubject !== 'undefined') {
            const relatedSubject = this.$store.getters.getTables.find(
              ({ id }) => id == column.relatedSubject
            )

            if (typeof relatedSubject !== 'undefined') {
              relatedSubject.columns.forEach((relatedColumn) => {
                array.push({
                  id: `${subject.id}${column.id}${relatedSubject.id}${relatedColumn.id}`,
                  label: `${subject.label} / ${column.label} / ${relatedColumn.label}`,
                  variable: `${column.identifier}.${relatedColumn.identifier}`
                })
              })
            }
          }
        })
      }
      return array
    },

    warn () {
      return !(this.notification.notificationChannels.length > 0)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      try {
        this.loading.active = true
        await this.getSubjects()
        if (this.getNotificationId) {
          this.getNotificationDetails()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading.active = false
      }
    },

    async getSubjects () {
      try {
        if (!this.$store.getters.getTables.length) {
          this.loadingSubjects = true

          await this.$store.dispatch('loadTables')
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingSubjects = false
      }
    },

    async selectSubject (id) {
      let clear = false

      this.variables.forEach((value) => {
        const exp = new RegExp(`[$]{${value.variable}}`, 'gi')
        if (
          this.notification.titleTemplate.match(exp) ||
            this.notification.messageTemplate.match(exp)
        ) {
          clear = true
        }
      })

      if (clear && id !== this.notification.subjectId) {
        let promise = Promise.resolve()
        const confirmPromise = this.$refs.confirm

        promise = confirmPromise.open(
            `Você está alterando um assunto cujas variáveis foram utilizadas nos campos
            <b> "Template do Título" </b> e/ou <b> "Template da Mensagem" </b>.
            As variáveis serão apagadas. Deseja continuar?`,
            'orange'
        )

        await promise
          .then(() => {
            this.variables.forEach((value) => {
              const exp = new RegExp(`[$]{${value.variable}}`, 'gi')
              if (this.notification.titleTemplate.match(exp)) {
                this.notification.titleTemplate =
                    this.notification.titleTemplate.replace(exp, '')
              }
              if (this.notification.messageTemplate.match(exp)) {
                this.notification.messageTemplate =
                    this.notification.messageTemplate.replace(exp, '')
              }
            })
            this.notification.subjectId = id
          })
          .catch((error) => {
            console.log(error)
          })

        confirmPromise.close()
      } else {
        this.notification.subjectId = id
      }
    },

    async getNotificationDetails () {
      // this.loading.active = true
      this.loadingNotification = true
      await this.$http
        .get(
            `${this.$store.getters.api}/notification/${this.$route.params.notificationId}`
        )
        .then((response) => {
          this.notification = response.data
        })
        .catch((error) => {
          this.loading.error = true
          this.loading.response = error.response
        })
        // this.loading.active = false

      this.loadingNotification = false
    },

    channelMatrixChecked (args) {
      this.notification.notificationChannels = args
    },

    handleText (e) {
      const input = e.target
      const start = input.selectionStart
      const close = this.chars[e.data]

      this.cursorLastPosition = start
      this.input = input

      if (e.data) {
        if (input.value.substr(start - 2, 2) == '${') {
          this.openTagsSelector()
        }
        // Verifica se o caracter digitado é um parêntese, aspas duplas ou colchete
        // Caso for, fecha automaticamente as aspas, o parêntese ou o colchete e posiciona o cursor entre eles
        if (
          input.value.substr(start, 1) == e.data &&
            Object.keys(this.chars).includes(e.data)
        ) {
          if (this.input.name == 'titleTemplate') {
            this.notification.titleTemplate =
                input.value.substr(0, start) + input.value.substr(start + 1)
          } else {
            this.notification.messageTemplate =
                input.value.substr(0, start) + input.value.substr(start + 1)
          }

          this.$nextTick(() => {
            input.setSelectionRange(start, start)
          })
        } else if (Object.keys(this.chars).includes(e.data)) {
          if (this.input.name == 'titleTemplate') {
            this.notification.titleTemplate =
                input.value.substr(0, start) + close + input.value.substr(start)
          } else {
            this.notification.messageTemplate =
                input.value.substr(0, start) + close + input.value.substr(start)
          }

          this.$nextTick(() => {
            input.setSelectionRange(start, start)
          })
        }
      }
    },

    openTagsSelector () {
      this.tagsSelector = true
    },

    closeTagsSelector () {
      this.tagsSelector = false
    },

    selectVariable (variable) {
      if (this.input.name == 'titleTemplate') {
        this.notification.titleTemplate =
            this.notification.titleTemplate.substr(0, this.cursorLastPosition) +
            variable +
            this.notification.titleTemplate.substr(this.cursorLastPosition)
      } else {
        this.notification.messageTemplate =
            this.notification.messageTemplate.substr(
              0,
              this.cursorLastPosition
            ) +
            variable +
            this.notification.messageTemplate.substr(this.cursorLastPosition)
      }
      this.$nextTick(() => {
        this.input.focus()
        this.input.setSelectionRange(
          this.cursorLastPosition + variable.length + 1,
          this.cursorLastPosition + variable.length + 1
        )
      })

      this.closeTagsSelector()
    },

    async save () {
      if (this.notification.notificationChannels.length > 0) {
        this.loadingSave = true

        const http = this.getNotificationId
          ? this.$http.put(
                `${this.$store.getters.api}/notification/${this.$route.params.notificationId}`,
                this.notification
          )
          : this.$http.post(
                `${this.$store.getters.api}/notification`,
                this.notification
          )
        await http
          .then(({ data }) => {
            this.$store.dispatch('notification', {
              text: `Notificação ${this.notification.notificationName} ${
                  this.getNotificationId ? 'atualizada' : 'inserida'
                } com sucesso!`,
              color: 'green'
            })

            this.$store.commit('closePage', '/notification/list')
            this.$store.commit('closePage', '/notification')
            this.$store.commit(
              'closePage',
                `/notification/${this.notification.notificationId}`
            )

            this.$router.push({
              name: 'notification_list',
              params: {
                refresh: true
              }
            })

            this.loadingSave = false
          })
          .catch((error) => {
            console.log(error)

            this.loadingSave = false

            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro ao inserir a notificação. Por favor, tente novamente.',
              error
            })
          })
      } else {
        this.loadingSave = false

        this.$store.dispatch('notification', {
          type: 'error',
          text: 'Por favor, selecione pelo menos um canal.'
        })
      }
    },

    closePage () {
      this.$store.commit('closePage', this.$route.fullPath)
    }
  }
}
</script>

<style lang="scss" module>
  .padding {
    padding: $theme-padding * 4 $theme-padding * 5 $theme-padding * 14;
    min-height: calc(100vh - 135px);
  }

  .option {
    &__label {
      color: black;
      font-weight: bold;
      font-size: 1.5rem;

      :global(.nightlymode) & {
        color: white;
      }
    }
  }

  /*#region TEXTAREA*/

  $textarea-color: $md-grey-900;
  $textarea-color-nightly: $md-grey-100;

  $textarea-background: #fff;
  $textarea-background-nightly: #212121;

  .textarea {
    width: 100%;
    min-height: 100px;
    resize: none;

    font-family: "Roboto", sans-serif;
    @include font-sizer(14);
    line-height: 20px;
    color: $textarea-color;

    background: $textarea-background;

    padding: $theme-padding;
    border-radius: 5px;

    box-shadow: inset 0 0 0 2px $md-grey-400;

    transition: 0.4s;
    transition-property: box-shadow;

    margin-bottom: $theme-padding;

    &::placeholder {
      color: $textarea-color;
    }

    :global(.nightlymode) & {
      color: $textarea-color-nightly;

      background: $textarea-background-nightly;

      box-shadow: inset 0 0 0 2px $md-grey-600;

      &::placeholder {
        color: $textarea-color-nightly;
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--theme-color-1) inset;
    }
  }

  /*#endregion */

  .input {
    margin-bottom: $theme-padding;
    max-width: 100%;
    min-width: 100%;
    padding: 13px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 400;
    color: black;
    background: white;
    border-radius: 5px;
    box-shadow: inset 0 0 0 2px $md-grey-400;
    transition: box-shadow 0.2s ease;

    :global(.nightlymode) & {
      color: $textarea-color-nightly;
      background: $textarea-background-nightly;
      box-shadow: inset 0 0 0 2px $md-grey-600;

      &::placeholder {
        color: $textarea-color-nightly;
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--theme-color-1) inset;
    }
  }
</style>
