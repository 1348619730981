<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  image: {
    type: [Object, String],
    default: null
  },
  clear: Boolean
})

const emit = defineEmits(['remove', 'change-file'])

const targetFileInput = ref(null)

const error = ref(false)
const extensions = ref(['.png', '.jpeg', '.jpg'])

const file = reactive({
  name: null,
  type: null,
  url: null,
  data: null
})

const buttonLabel = computed(() => (file.data || props.image?.name ? 'Alterar arquivo' : 'Selecionar arquivo'))

const fileLabel = computed(() => {
  if (file.data) {
    return file.name
  }
  if (props.image?.name) {
    return props.image.name
  }

  return 'Selecionar arquivo'
})

watch(() => props.clear, (value) => {
  if (value) {
    file.name = null
    file.type = null
    file.url = null
    file.data = null
  }
})

function openFile () {
  targetFileInput.value.click()
}

function remove () {
  if (file.name) {
    file.name = null
    file.type = null
    file.data = null
    file.url = null

    targetFileInput.value.value = ''
  }

  emit('remove')
}

function updateFile (e) {
  error.value = false
  const uploadedFile = e.target.files[0]
  const type = `.${uploadedFile.type.split('/')[1]}`

  if (extensions.value.includes(type)) {
    const FILE_READER = new FileReader()

    FILE_READER.readAsDataURL(uploadedFile)

    FILE_READER.onload = () => {
      file.name = uploadedFile.name
      file.type = uploadedFile.type
      // eslint-disable-next-line prefer-destructuring
      file.data = FILE_READER.result.split('base64,')[1]
    }

    emit('change-file', file)
  } else {
    error.value = true

    setTimeout(() => {
      error.value = false
    }, 3000)
  }
}
</script>

<template>
  <div :class="$style.container">
    <transition name="fade-up">
      <div :class="$style['image-container']">
        <img
          v-if="file.data"
          :src="'data:image/png;base64, ' + file.data"
          alt="Imagem do perfil"
        >
        <img
          v-else-if="typeof image == 'object' && image != null"
          :class="$style.headerUserAvatarImg"
          :src="
            image.url
              ? store.getters.api + image.url
              : 'data:image/png;base64, ' + image.data
          "
          alt="Imagem do perfil"
        >
        <img
          v-else
          src="../assets/images/placeholder-user.png"
          alt="Imagem do perfil"
        >
      </div>
    </transition>

    <div :class="$style.file">
      <div :class="$style.fileInputBox">
        <app-button
          full
          color="blue"
          :label="buttonLabel"
          icon="attach_file"
          @click="openFile"
        />
        <input
          ref="targetFileInput"
          :class="$style.fileInput"
          type="file"
          :accept="extensions.join(',')"
          @change="updateFile($event)"
        >
      </div>

      <div :class="$style.fileLabelBox">
        <div :class="$style.fileLabel">
          <span>{{ fileLabel }}</span>
        </div>

        <transition name="fade-up">
          <label
            v-if="error"
            :class="$style.fileError"
          >
            Extensão não suportada
          </label>
        </transition>

        <transition name="fade-up">
          <app-icon
            v-if="
              file.data || (typeof image !== 'string' && image !== null)
            "
            glyph="delete"
            :class="$style.fileRemove"
            @click="remove"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  background: var(--theme-color-1);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.file {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 50px 15px 15px 50px;
  background: rgba(158, 158, 158, 0.1);

  &__input-box {
    width: 40%;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }

  &__label-box {
    width: 50%;
    padding-left: 25px;
  }

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    padding-right: $theme-padding;
    margin: 0;
    color: black;
    line-height: 40px;
    font-family: 'Raleway', sans-serif;
    white-space: nowrap;
    font-size: 1.5rem;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__remove {
    position: absolute;
    right: 16px;
    top: 8px;
    border-radius: 50%;
    fill: red;
    cursor: pointer;
  }

  &__error {
    position: absolute;
    bottom: 8px;
    right: 60px;
    padding: 3px 6px;
    border-radius: 6px;
    margin: 0;
    background: red;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
  }
}
</style>
