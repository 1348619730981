<template>
  <div>
    <table :class="$style.notificationsTable" v-if="itens.length">
      <thead :class="$style.notificationsTableHeader">
        <tr>
          <th :class="$style.notificationsTableHeaderTitle" colspan="2">
            <label>Notificações</label>
          </th>

          <th
            v-for="(channel, index) in channels"
            :key="index"
            :class="$style.notificationsTableHeaderTitle"
          >
            <label :class="$style.notificationsTableHeaderActions">{{
              channel.channelDescription
            }}</label>
          </th>
        </tr>
      </thead>
      <tbody :class="$style.notificationsTableBody">
        <tr
          v-for="(notification, index) in itens"
          :key="index"
          :class="$style.notificationsTableBodyLine"
        >
          <td :class="$style.notificationsTableBodyTitle" :colspan="1">
            <label>{{ notification.notificationName }}</label>
          </td>
          <th></th>
          <td
            v-for="(channel, index) in channels"
            :key="index"
            :class="$style.notificationsTableBodyCell"
          >
            <div class="d-flex justify-content-center">
              <app-tooltip
                style="z-index: 1"
                bottom
                :label="
                  notification.user.find(x => x.channelId == channel.channelId)
                    .isRequired
                    ? 'Canal obrigatório no seu perfil de acesso'
                    : notification.user.find(
                        x => x.channelId == channel.channelId,
                      ).isEnableRoom
                    ? notification.user.find(
                        x => x.channelId == channel.channelId,
                      ).isEnable
                      ? 'Desmarcar item'
                      : 'Selecionar item'
                    : 'Canal desabilitado'
                "
                :class="$style.notificationsTableTootip"
              >
                <app-icon
                  :glyph="
                    notification.user.find(
                      x => x.channelId == channel.channelId,
                    ).isRequired
                      ? 'check_box'
                      : notification.user.find(
                          x => x.channelId == channel.channelId,
                        ).isEnableRoom
                      ? notification.user.find(
                          x => x.channelId == channel.channelId,
                        ).isEnable
                        ? 'check_box'
                        : 'check_box_outline_blank'
                      : 'check_box_outline_blank'
                  "
                  :class="
                    notification.user.find(
                      x => x.channelId == channel.channelId,
                    ).isRequired
                      ? $style.notificationsTableCheckboxDisabled
                      : notification.user.find(
                          x => x.channelId == channel.channelId,
                        ).isEnableRoom
                      ? notification.user.find(
                          x => x.channelId == channel.channelId,
                        ).isEnable
                        ? $style.notificationsTableCheckboxActived
                        : $style.notificationsTableCheckbox
                      : $style.notificationsTableCheckboxDisabled
                  "
                  :name="'check_resource_' + channel.channelId"
                  v-on:click="
                    changeCheck(notification, channel.channelId)
                  "
                />
              </app-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div :class="$style.notificationsEmpty" v-else>
      <p>
        Não há notificações configuráveis para o seu usuário por alguma das
        opções abaixo:
      </p>
      <ol :class="$style.notificationsEmptyList">
        <li>Todas as notificações do seu perfil de acesso são obrigatórias;</li>
        <li>Não há notificações habilitadas para o seu perfil de acesso;</li>
        <li>Não há canais habilitados para o seu perfil de acesso.</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notifications: Array,
    channels: Array,
    userNotifications: Array,
    filteredNotifications: Array
  },

  methods: {
    changeCheck (notification, channel) {
      if (
        !notification.user.find(
          notification => notification.channelId == channel
        ).isRequired &&
        notification.user.find(
          notification => notification.channelId == channel
        ).isEnableRoom
      ) {
        this.$emit('change-check', {
          notificationId: notification.notificationId,
          channelId: channel
        })
      }
    }
  },

  computed: {
    itens () {
      const notifications = this.notifications
      notifications.forEach(notification => {
        const channels = []
        this.filteredNotifications.forEach(x => {
          const item = x.notifications.find(
            x => x.notificationId == notification.notificationId
          )

          const obj = {
            channelId: x.channel.channelId,
            isEnableRoom: item ? item.isEnable : false,
            isRequired: item ? item.isRequired : false
          }

          const enable = this.userNotifications.find(
            notification =>
              notification.channel.channelId == x.channel.channelId
          )
            ? this.userNotifications
              .find(
                notification =>
                  notification.channel.channelId == x.channel.channelId
              )
              .notifications.find(
                not => not.notificationId == notification.notificationId
              )
            : null

          obj.isEnable = enable ? enable.isEnable : false

          channels.push(obj)

          notification.user = channels
        })
      })

      return notifications
    }
  }
}
</script>

<style lang="scss" module>

$background-header: $md-grey-200;
$background-header-nightly: lighten($md-grey-900, 5%);

$background-header-checkcol: darken($md-grey-200, 4%);
$background-header-checkcol-nightly: lighten($md-grey-900, 2%);

$background-lines: $md-grey-100;
$background-lines-nightly: darken($md-grey-800, 16%);

$border-lines: $md-grey-200;
$border-lines-nightly: lighten($md-grey-900, 5%);

$color-label: $md-grey-700;
$color-label-nightly: $md-grey-100;

$color-checkbox: $md-grey-600;
$color-checkbox-nightly: $md-grey-300;

.notifications {
  &--modal {
    overflow: hidden;
  }

  &__table {
    width: 100%;

    border: 2px solid $background-header;

    :global(.nightlymode) & {
      border: 2px solid $background-header-nightly;
    }

    &__header {
      background: $background-header;

      :global(.nightlymode) & {
        background: $background-header-nightly;
      }

      tr:first-child th {
        position: sticky;
        top: -1px;
        background: $background-header;
        z-index: 98;

        :global(.nightlymode) & {
          background: $background-header-nightly;
        }
      }

      &__checkcol {
        height: 50px;

        background: $background-header-checkcol;

        :global(.nightlymode) & {
          background: $background-header-checkcol-nightly;
        }
      }

      &__cell {
        text-align: center;
      }

      &__actions {
        padding: $theme-padding;
        text-align: center;
        width: 100%;
        margin-bottom: 0;

        font-family: 'Raleway', sans-serif;
        @include font-sizer(13);
        font-weight: 600;
        color: $color-label;

        :global(.nightlymode) & {
          color: $color-label-nightly;
        }
      }

      &__title {
        padding: $theme-padding $theme-padding * 2;

        font-family: 'Raleway', sans-serif;
        @include font-sizer(13);
        font-weight: 600;
        color: $color-label;

        :global(.nightlymode) & {
          color: $color-label-nightly;
        }

        label {
          margin-bottom: 0;
        }
      }
    }

    &__body {
      &__cell {
        position: relative;

        border-right: 1px solid $border-lines;

        text-align: center;

        &:last-child {
          border-right: 0px solid $border-lines;
        }

        :global(.nightlymode) & {
          border-right: 1px solid $border-lines-nightly;

          &:last-child {
            border-right: 0px solid $border-lines-nightly;
          }
        }
      }

      &__line {
        background: transparent;
        border-bottom: 1px solid $border-lines;

        &:nth-of-type(2n) {
          background: $background-lines;
        }

        &:last-child {
          border-bottom: 0px solid $border-lines;
        }

        :global(.nightlymode) & {
          border-bottom: 1px solid $border-lines-nightly;

          &:nth-of-type(2n) {
            background: $background-lines-nightly;
          }

          &:last-child {
            border-bottom: 0px solid $border-lines-nightly;
          }
        }
      }

      &__title {
        padding: $theme-padding $theme-padding * 2;

        font-family: 'Raleway', sans-serif;
        @include font-sizer(13);
        font-weight: 600;
        color: $color-label;

        border-right: 1px solid $border-lines;

        label {
          margin-bottom: 0;
        }

        :global(.nightlymode) & {
          color: $color-label-nightly;

          border-right: 1px solid $border-lines-nightly;
        }
      }

      &__icon {
        fill: $color-label;

        cursor: pointer;

        width: 20px;
        height: 20px;

        :global(.nightlymode) & {
          fill: $color-label-nightly;
        }
      }

      &__options {
        position: absolute;
        top: 90%;
        left: calc(50% - 12px);

        min-width: 180px;
        padding: math.div($theme-padding, 2);

        background: $md-grey-900;
        border-radius: 5px;
        -webkit-transition-delay: 0s !important;
        transition-delay: 0s !important;
        list-style: none;

        z-index: 9;

        :global(.nightlymode) & {
          background: $md-grey-800;
        }

        &:before {
          content: '';
          position: absolute;
          top: -14px;
          left: 15px;
          margin-left: -10px;
          border: 7px solid transparent;
          border-bottom-color: $md-grey-900;

          :global(.nightlymode) & {
            border-bottom-color: $md-grey-800;
          }
        }

        &__link {
          width: 100%;

          text-align: center;
          font-family: 'Raleway', sans-serif;
          @include font-sizer(12);
          color: white;

          cursor: pointer;
        }
      }
    }

    &__tooltip {
      width: 24px;
      margin: 0 auto;
    }

    &__checkbox {
      margin: 0 auto;

      fill: $color-checkbox;

      cursor: pointer;

      :global(.nightlymode) & {
        fill: $color-checkbox-nightly;
      }

      &--actived {
        fill: $md-blue-500;
        cursor: pointer;
      }

      &--disabled {
        fill: #bbbbbb;
      }
    }

    &__switch {
      position: absolute;
      display: flex;
      margin-right: 5px;
      width: 100%;
      height: 24px;
      align-items: center;
      justify-content: flex-end;
      z-index: 0;
      color: #6a6a6a;

      &__box {
        margin-bottom: 5px;
      }
    }
  }

  &__empty {
    font-family: 'Raleway', sans-serif;
    @include font-sizer(14);
    font-weight: 400;
    line-height: 2;
    color: #000;

    :global(.nightlymode) & {
      color: #fff;
    }

    &__list {
      li::marker {
        color: var(--theme-color-1);
        font-weight: bold;
      }
    }
  }
}
.checkBoxLine {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
