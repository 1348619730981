<template>
  <div
    :class="[
      $style.wrapper,
      ['form', 'filter-attr'].includes(mode) ? $style.wrapperForm : null,
      [27].includes(attributeData.component.type) ? $style.wrapperFlex : null,
    ]"
    ref="wrapper"
    v-on="attributeData.component.type !== 15
      ? {
          'keydown.enter.prevent': activate(),
          click: activate()
        }
      : {}
    "
    :name="`attr_${attributeData.id}`"
    v-if="!attributeData.component.config.isHidden"
  >
    <app-input-label
      v-if="mode == 'form' && ![17, 18, 33].includes(attributeData.component.type)"
      :class="[
        $style.wrapperLabel,
        attributeData.component.type == 14 ? $style.wrapperLabelNomarge : null,
      ]"
      :tooltip="attributeData.component.config?.tooltip || null"
      :warning="attributeData.component.config?.warning || null"
      :required="attributeData.component.config.required && !attributeData.component.config.readonly &&
        ((talkId && attributeData.component.config.actions.update) ||
        (!talkId && attributeData.component.config.actions.insert))
      "
      :readonly="attributeData.component.config.readonly"
      :name="attributeData.name"
    >
      <span>{{ attributeData.name }}</span>
      <app-icon
        glyph="link"
        :class="$style.wrapperLinked"
        v-if="attributeData.component.config.related"
      />
    </app-input-label>

    <single-line-text-edit
      v-if="
        (attributeData.component.type == 1 || attributeData.component.type == 3 || attributeData.component.type == 13) &&
          activeEdit &&
          attributeData.identifier !== 'Identificacao'
      "
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      :type="attributeData.component.type == 3 ? 'number' : attributeData.component.type == 13 ? 'email' : null"
      :talk-id="talkId"
      :name="attributeData.name"
      v-on:change="updateValue"
    />

    <cnpj-cpf-edit
      v-else-if="(attributeData.component.type == 2 || attributeData.identifier == 'Identificacao') && activeEdit"
      :mode="mode"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      :subject="subject"
      :subjectIdentifier="subjectIdentifier"
      :attribute="attributeData"
      :talk-id="talkId"
      :focus="index == 0 ? true : false"
      :type-input="typeInput == null || attributeData.component.type == 2 ? 'Pessoa Jurídica' : typeInput"
      v-on:change="updateValue"
      v-on:blur="blur"
    />

    <select-edit
      v-else-if="attributeData.component.type == 4 && activeEdit"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      :itensSelect="attributeData.component.itens"
      :index="index"
      :opened="mode === 'filter-attr' ? true : false"
      :isMultiple="attributeData.component.type !== 4 ? true: false"
      :attrId="attributeData.id"
      :talkId="talkId"
      :identifier="attributeData.identifier"
      v-on:change="updateValueSelect"
      v-on:change-attribute-itens="changeAttributeItens"
      v-on:add-attribute-itens="addAttributeItens"
      v-on:change-zindex="changeZindex"
    />

    <measurement-edit
      v-else-if="
        (attributeData.component.type == 5 ||
          attributeData.component.type == 23 ||
          attributeData.component.type == 24 ||
          attributeData.component.type == 25) &&
          activeEdit
      "
      :mode="mode"
      :value="
        `${
          typeof data != 'undefined'
            ? typeof data.value != 'undefined'
              ? data.value
              : data
            : '0'
        }`
      "
      :config="attributeData.component.config"
      :talkId="talkId"
      :focus="index == 0 ? true : false"
      :type="attributeData.component.type"
      v-on:change="updateValue"
      v-on:blur="blur"
    />

    <phone-edit
      v-else-if="attributeData.component.type == 6 && activeEdit"
      :mode="mode"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      :focus="index == 0 ? true : false"
      :talkId="talkId"
      v-on:change="updateValue"
      v-on:blur="blur"
    />

    <license-edit
      v-else-if="attributeData.component.type == 31 && activeEdit"
      :mode="mode"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      v-on:change="updateValue"
      v-on:blur="blur"
    />

    <checkbox-edit
      v-else-if="attributeData.component.type == 10 && activeEdit"
      :value="
        typeof data !== 'undefined' && data !== null
          ? typeof data.value !== 'undefined'
            ? data.value
            : data === ''
            ? ''
            : data
          : ''
      "
      :config="attributeData.component.config"
      :mode="mode"
      :talkId="talkId"
      :focus="index == 0 ? true : false"
      v-on:change-input="updateValue"
      :emit-init="emitInit"
    />

    <switch-edit
      v-else-if="attributeData.component.type == 27 && activeEdit"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data == ''
            ? false
            : data
          : false
      "
      :config="attributeData.component.config"
      :mode="mode"
      :talkId="talkId"
      v-on:change-input="updateValue"
      :emit-init="emitInit"
    />

    <date-time-edit
      v-else-if="attributeData.component.type == 11 || attributeData.component.type == 32"
      :value="
        data
          ? typeof data != 'undefined'
            ? typeof data.value != 'undefined'
              ? data.value
              : data
            : null
          : null
      "
      :activeHours="attributeData.component.type == 11"
      :talkId="talkId"
      :config="attributeData.component.config"
      :identifier="attributeData.identifier"
      :rules="attributeData.rules"
      :mode="mode"
      :focus="index == 0 ? true : false"
      :index="index"
      :block="block"
      v-on:change-input="updateValue"
    />

    <filter-component
      v-else-if="attributeData.component.type == 12"
      mode="subject"
      :lockFilter="lockFilter"
      :config="attributeData.component.config"
      :filterParent="filterParent"
      :data="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? { filter: JSON.parse(data.value), filterJson: [{}] }
            : { filter: data, filterJson: [{}] }
          : null
      "
      :focus="index == 0 ? true : false"
      v-on:change-filter="updateValue"
      v-on:blur="blur"
    />

    <related-subject-tree
      v-else-if="attributeData.component.type == 14"
      :mode="mode"
      :value="
        typeof data != 'undefined' ? (data.value ? data.value : data) : null
      "
      :talkId="talkId"
      :config="attributeData.component.config"
      v-on:change="updateValue"
    />

    <model-grid-columns
      v-else-if="attributeData.component.type == 15"
      :mode="mode"
      :config="attributeData.component.config"
      :focus="index == 0 ? true : false"
      :talkId="talkId"
      :columns="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : []
          : []
      "
      v-on:change-value="updateValue"
      v-on:change-data-columns="changeDataColumns"
    />

    <select-filter
      v-else-if="attributeData.component.type == 16"
      :mode="mode"
      :focus="index == 0 ? true : false"
      :talkId="talkId"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value == ''
              ? 0
              : data.value
            : data == ''
            ? 0
            : data
          : 0
      "
      :config="attributeData.component.config"
      v-on:change="updateValue"
    />

    <multiple-email-edit
      v-else-if="
        attributeData.component.type == 17 || attributeData.component.type == 33
      "
      :mode="mode"
      :focus="index == 0 ? true : false"
      :talkId="talkId"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      v-on:update:value="updateValue"
    />

    <frequency
      v-else-if="attributeData.component.type == 18"
      :mode="mode"
      :focus="index == 0 ? true : false"
      :talkId="talkId"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      v-on:change-input="updateValue"
    />

    <time-edit
      v-else-if="attributeData.component.type == 19 && activeEdit"
      :mode="mode"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      :focus="index == 0 ? true : false"
      v-on:change="updateValue"
      v-on:blur="blur"
    />

    <select-model
      v-else-if="attributeData.component.type == 20"
      :mode="mode"
      :focus="index == 0 ? true : false"
      :talkId="talkId"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value == ''
              ? 0
              : data.value
            : data == ''
            ? 0
            : data
          : 0
      "
      :config="attributeData.component.config"
      v-on:change="updateValue"
    />

    <file-edit
      v-else-if="attributeData.component.type == 22"
      v-on:change="updateValue"
      v-on:change-zindex="changeZindex"
      v-on:delete-file="deleteFile"
      v-on:delete-file-index="deleteFileIndex"
      :value="data"
      :config="attributeData.component.config"
      :talkId="talkId"
      :focus="index == 0 ? true : false"
      :attributeId="attributeData.id"
      :subjectIdentifier="subjectIdentifier"
      :mode="mode"
    />

    <file-on-demand
      v-else-if="attributeData.component.type == 34"
      v-on:change="updateValue"
      v-on:change-zindex="changeZindex"
      v-on:delete-file="deleteFile"
      v-on:delete-file-index="deleteFileIndex"
      :value="data"
      :config="attributeData.component.config"
      :talkId="talkId"
      :focus="index == 0 ? true : false"
      :attributeId="attributeData.id"
      :subjectIdentifier="subjectIdentifier"
      :mode="mode"
    />

    <multiple-line-text-edit
      v-else-if="attributeData.component.type == 26 && activeEdit"
      :mode="mode"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      :config="attributeData.component.config"
      :identifier="attributeData.identifier"
      :talk-id="talkId"
      :focus="index == 0 ? true : false"
      v-on:update:value="updateValue"
      :style="{
        paddingRight: mode == 'form' ? '35px' : null,
      }"
    />

    <carrier-edit
      v-else-if="
        attributeData.component.type == 29 &&
          activeEdit &&
          attributeData.component.configsRelationship &&
          attributeData.component.configsRelationshipAttributes
      "
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
            ? data
            : []
          : []
      "
      :config="attributeData.component.config"
      :configsRelationship="attributeData.component.configsRelationship"
      :configsRelationshipAttributes="
        attributeData.component.configsRelationshipAttributes
      "
      :talk-id="talkId"
      v-on:change="updateValue"
      v-on:change-zindex="changeZindex"
    />

    <time-window
      v-else-if="attributeData.component.type == 28 && activeEdit"
      :value="
        typeof data != 'undefined'
          ? typeof data.value != 'undefined'
            ? data.value
            : data
          : ''
      "
      v-on:change-zindex="changeZindex"
      v-on:change="updateValue"
      v-on:change-contingency="changeContingency"
    />

  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

import singleLineTextEdit from '@/talkture/scenario/components/single-line-text/SingleLineTextEdit.vue'
import multipleLineTextEdit from '@/talkture/scenario/components/multiple-line-text/MultipleLineTextEdit.vue'
import checkboxEdit from '@/talkture/scenario/components/checkbox/CheckboxEdit.vue'
import switchEdit from '@/talkture/scenario/components/switch/SwitchEdit.vue'
import dateTimeEdit from '@/talkture/scenario/components/date-time/DateTimeEdit.vue'
import selectEdit from '@/talkture/scenario/components/select/SelectEdit.vue'
import phoneEdit from '@/talkture/scenario/components/phone/PhoneEdit.vue'
import licenseEdit from '@/talkture/scenario/components/license-plates/LicenseEdit.vue'
import cnpjCpfEdit from '@/talkture/scenario/components/cnpjcpf/CnpjCpfEdit.vue'
import measurementEdit from '@/talkture/scenario/components/measurement/MeasurementEdit.vue'
import fileEdit from '@/talkture/scenario/components/file/FileEdit.vue'
import fileOnDemand from '@/talkture/scenario/components/file/FileOnDemand.vue'
import modelGridColumns from '@/talkture/scenario/components/model/columns/GridColumns.vue'
import frequency from '@/talkture/scenario/components/frequency/Frequency.vue'
import multipleEmailEdit from '@/components/AppMultipleEmail.vue'
import selectModel from '@/talkture/scenario/components/select/SelectModel.vue'
import selectFilter from '@/talkture/scenario/components/select/SelectFilter.vue'
import timeWindow from '@/talkture/scenario/components/time-window/TimeWindowEdit.vue'
import timeEdit from '@/talkture/scenario/components/time/TimeEdit.vue'
const carrierEdit = defineAsyncComponent(() => import('@/talkture/scenario/components/carrier/CarrierEdit.vue'))

export default {
  components: {
    singleLineTextEdit,
    multipleLineTextEdit,
    checkboxEdit,
    switchEdit,
    dateTimeEdit,
    selectEdit,
    phoneEdit,
    licenseEdit,
    cnpjCpfEdit,
    measurementEdit,
    fileEdit,
    fileOnDemand,
    modelGridColumns,
    frequency,
    multipleEmailEdit,
    selectModel,
    selectFilter,
    timeWindow,
    carrierEdit,
    timeEdit
  },

  emits: [
    'change-attribute-itens',
    'change-zindex',
    'delete-file-index',
    'change-contingency',
    'blur',
    'change',
    'change-select',
    'change-attribute-itens',
    'add-attribute-itens',
    'change-data-columns',
    'delete-file'
  ],

  data () {
    return {
      activeEdit: false,
      zindex: 0,
      attributeData: this.attribute
    }
  },

  props: {
    typeInput: String,
    templateName: String,

    block: Boolean,

    data: [Object, String, Boolean, Number, Array, Date],

    attribute: {
      type: Object,
      required: true
    },

    talkId: Number,

    mode: {
      type: String,
      default: 'grid'
    },

    active: Boolean,

    subject: String,
    subjectIdentifier: String,

    filterParent: Object,

    index: Number,
    lockFilter: Boolean,

    emitInit: Boolean
  },

  mounted () {
    if (!this.attributeData.component.config.actions.delete && this.data) {
      this.attributeData.component.config.required = true
    }

    if (this.mode == 'form' || this.mode == 'filter-attr' || this.active) {
      this.activeEdit = true
    }

    if (
      this.attributeData.component.config.isHidden &&
      this.attributeData.component.config.defaultValue
    ) {
      if (this.attributeData.component.config.type == 4) {
        this.updateValueSelect(this.attributeData.component.config.defaultValue)
      } else {
        this.updateValue(this.attributeData.component.config.defaultValue)
      }
    }
  },

  methods: {
    deleteFileIndex (id) {
      this.$emit('delete-file-index', id)
    },
    changeContingency (data) {
      this.$emit('change-contingency', data)
    },

    changeZindex (data) {
      this.$emit('change-zindex', data)
    },

    activate () {
      this.activeEdit = true
    },

    blur () {
      this.$emit('blur')
    },

    updateValue (value) {
      this.$emit('change', JSON.parse(JSON.stringify(value)))
    },

    updateValueSelect (value) {
      this.$emit('change-select', JSON.parse(JSON.stringify(value)))
    },

    getData () {
      return false
    },

    changeAttributeItens (itens) {
      this.$emit('change-attribute-itens', itens)
    },

    addAttributeItens (itens) {
      this.$emit('add-attribute-itens', itens)
    },

    changeDataColumns (data) {
      this.$emit('change-data-columns', data)
    },

    deleteFile (file) {
      this.$emit('delete-file', file)
    }
  }
}
</script>

<style lang="scss" module>

$background: white;
$background-nightly: lighten($md-grey-900, 5%);

/* */

.container {
  padding: $theme-padding * 2;

  &_info {
    color: #17a2b8;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    width: 100%;

    @include font-sizer(17);
  }
}

.small {
  $size: 14px !important;
}

.wrapper {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 3px;
  box-shadow: 0 0 0 2px transparent;

  outline: none;

  // z-index: 1000;

  &--flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  &__linked {
    width: 18px;
    height: 18px;
    fill: #424242;
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    margin-left: 8px;
  }

  &:focus {
    background: var(--theme-color-2);

    box-shadow: 0 0 0 2px var(--theme-color-1);

    animation: box-shadow 1.4s ease infinite;

    @keyframes box-shadow {
      0% {
        box-shadow: 0 0 0 2px transparent;
      }

      50% {
        box-shadow: 0 0 0 2px var(--theme-color-1);
      }

      100% {
        box-shadow: 0 0 0 2px transparent;
      }
    }
  }

  &--form {
    position: relative;

    &:focus {
      background: transparent;

      box-shadow: 0 0 0 2px transparent;

      animation: none;
    }
  }

  &--focus {
    z-index: 9999;
  }

  &__user {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__label {
    margin-top: $theme-padding + 5px;

    &__tag {
      padding: 3px;
      margin-left: 5px;

      background: $md-grey-500;

      font-family: 'Barlow', sans-serif;
      @include font-sizer(8);
      line-height: 1;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 400;
      color: white;

      border-radius: 5px;

      &--readonly {
        background: $md-teal-500;
      }
    }

    &--nomarge {
      margin-bottom: 0px !important;
    }
  }
}
</style>
