import axios from 'axios'

const modulePermissions = {
  actions: {
    async loadSubjectPermissions ({ getters }, subject) {
      const payload = {
        SubjectIdentifiers: [...subject]
      }
      const { data } = await axios.post(`${getters.api}/room/subjectpermissions/`, payload)
      const transformedData = data.map(subject => {
        const obj = {
          actions: {
            insert: !!subject.actions.create,
            update: !!subject.actions.update,
            delete: !!subject.actions.delete,
            deactivate: !!subject.actions.deactivate,
            read: !!subject.actions.read
          },
          subjectIdentifier: subject.subjectIdentifier.toLowerCase()
        }

        return obj
      })

      return transformedData
    }
  }
}

export default modulePermissions
