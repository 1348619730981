<template>
  <div>
    <input
      :class="[
        $style.input,
        mode == 'form' || mode == 'filter-attr' ? $style.inputForm : null,
        mode == 'filter-attr' ? $style.inputForm : null,
      ]"
      maxlength="20"
      :required="config.required"
      :readonly="
        readonly ||
          (talkId != 0 && !config.actions.update) ||
          (talkId == 0 && !config.actions.insert)
      "
      :value="
        value && value != ''
          ? value.indexOf('.') !== -1 || value.indexOf(',') !== -1
            ? value
            : parseFloat(value).toFixed(money.precision)
          : '0'
      "
      v-money="money"
      v-on:keydown="checkKey($event)"
      v-on:keyup="change($event)"
      @change.stop
      v-on:blur="blur"
      v-on:keydown.tab="tab"
      v-on:keydown.enter="enter"
      v-on:keydown.delete="backspace"
      ref="input"
    />
  </div>
</template>

<script>
import { Money3Directive } from 'v-money3'

import EventBus from '../../eventBus'

export default {
  directives: { money: Money3Directive },

  data () {
    return {
      delay: null,

      money: {
        thousands: '.',
        decimal: ',',
        precision: 2,
        masked: false
      },

      checkKeyStatus: true
    }
  },

  props: {
    type: [String, Number],

    mode: String,
    value: [String, Number],

    config: Object,

    subject: String,
    talkId: {
      type: Number,
      default: 0
    },

    focus: Boolean
  },

  beforeMount () {
    this.money = {
      thousands: this.config.componentConfigs
        ? this.config.componentConfigs.thousands
        : '.',
      decimal: this.config.componentConfigs
        ? this.config.componentConfigs.decimal
        : ',',
      precision: this.config.componentConfigs
        ? this.config.componentConfigs.precision
        : 2,
      masked: false
    }

    if (!this.value) {
      this.config.required === true ? this.$emit('change', '0') : false
    }
  },

  mounted () {
    if (this.mode !== 'form' || this.focus) {
      setTimeout(
        () => {
          this.$refs.input.focus()
        },
        1,
        this
      )
    }
  },

  methods: {
    checkKey (e) {
      if (![189, 109].includes(e.keyCode)) {
        if (
          e.keyCode > 31 &&
          (e.keyCode < 48 || e.keyCode > 57) &&
          e.keyCode !== 46 &&
          (e.keyCode < 37 || e.keyCode > 39) &&
          (e.keyCode < 96 || e.keyCode > 105)
        ) {
          this.checkKeyStatus = false

          e.preventDefault()
        } else {
          this.checkKeyStatus = true
        }
      } else {
        this.checkKeyStatus = true
      }
    },

    change (e) {
      if (this.checkKeyStatus) {
        const value = e.target.value

        if (this.money.thousands === '.') {
          this.$emit('change', value.split('.').join(''))
        } else {
          this.$emit('change', value.split(',').join(''))
        }
      }
    },

    blur () {
      this.$emit('blur')
    },

    tab () {
      EventBus.$emit('tab')
    },

    enter () {
      EventBus.$emit('enter')
    },

    backspace () {
      if (parseFloat(this.value).toFixed(10) === 0.0) {
        this.$emit('change', '0')
      }
    }
  },

  computed: {
    readonly () {
      return (
        this.config.readonly ||
        (this.talkId != 0 ? !this.config.actions.update : false)
      )
    }
  },

  watch: {
    value: function (val) {
      this.mutableValue = val.replace(/,/, '.').toString()

      this.$emit('change', this.mutableValue)
    }
  }
}
</script>

<style lang="scss" module>

$input-color: black;
$input-color-nightly: white;
$input-background: white;
$input-background-nightly: $md-grey-900;

.input {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: math.div($theme-padding, 2) $theme-padding;

  font-family: 'Roboto', sans-serif;
  @include font-sizer(12);
  font-weight: 400;
  color: $input-color;

  background: $input-background;

  box-shadow: inset 0 0 0 2px $md-blue-500;
  border-radius: 5px;

  :global(.nightlymode) & {
    color: $input-color-nightly;

    background: $input-background-nightly;
  }

  &--form {
    height: 50px;
    position: relative;

    box-shadow: inset 0 0 0 2px $md-grey-400;

    transition: 0.2s;
    transition-property: box-shadow;

    :global(.nightlymode) & {
      box-shadow: inset 0 0 0 2px $md-grey-600;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $md-blue-500;
    }
  }

  &[readonly] {
    background: $md-grey-100;
    box-shadow: inset 0 0 0 2px $md-grey-500;

    :global(.nightlymode) & {
      background: $md-grey-800;
    }
    cursor: default;
  }
}
</style>
