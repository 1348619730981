<template>
  <app-view>
    <transition name="fade">
      <app-modal
        title="Filtro"
        v-if="modal.filter.active"
        fullscreen
        v-on:close="closeEditFilter()"
      >
        <form
          v-on:submit.prevent="applyEditFilter()"
          :class="$style.filterModal"
        >
          <app-input-label>Filtros disponíveis</app-input-label>

          <select-box
            class="mt-2 mb-4"
            :options="filters"
            :selected="modal.filter.data.filterId"
            :loading="loadingFilter"
            placeholder="Selecione um filtro já cadastrado"
            v-on:select="selectFilter"
            unselect
          />

          <app-input
            label="Nome do filtro"
            v-model:value="modal.filter.data.filterName"
            required
            :maxlength="100"
          />

          <app-input-label required>Filtro</app-input-label>

          <filter-component
            :data="{
              filter: JSON.parse(modal.filter.data.filter),
              filterJson: [{}],
            }"
            :style="{
              position: 'relative',
              padding: 0,
              marginBottom: '30px',
            }"
            v-on:change-filter="updateValue"
            :key="filterKey"
          />

          <!-- <template v-if="!!getSubjectAttributesCurrentFilter">

                        <app-input-label>Aplicar ao atributo</app-input-label>

                    </template> -->

          <automation-footer v-on:cancel="closeEditFilter()" />
        </form>
      </app-modal>
    </transition>

    <form :class="$style.tagsView" v-on:submit.prevent="save()">
      <app-box padding style="padding-bottom: 350px">
        <app-box>
          <app-loader
            v-if="loading.active || loading.error"
            :error="loading.error"
            :response="loading.response"
            :active="loading.active"
            key="loading"
            v-on:reload="init()"
          />

          <div
            class="row justify-content-center"
            v-if="!loading.active && !loading.error"
          >
            <div class="col-12 col-xl-8 mb-5">
              <app-box margin>
                <app-title
                  :label="!getTagId ? 'Criar nova tag' : 'Editar tag'"
                />
              </app-box>
            </div>

            <div class="col-12 col-xl-8 mb-2">
              <app-input
                label="Texto"
                required
                v-model:value="tag.tagName"
                :maxlength="50"
              />
            </div>

            <div class="col-12 col-xl-8 mb-4">
              <div class="row">
                <div class="col-md-auto">
                  <app-input-label>Cor</app-input-label>

                  <div :class="$style.tagsColorWrapper">
                    <app-tooltip
                      label="Cor da tag"
                      :class="$style.tagsColor"
                      v-on:click="toggleColorPicker()"
                    >
                      <svg viewBox="0 0 32 32">
                        <path
                          :class="$style.tagsColorBucket"
                          d="M22.080 11.92l-10.987-10.987c-0.52-0.52-1.36-0.52-1.88 0v0c-0.52 0.52-0.52 1.36 0 1.88l2.24 2.24-6.867 6.867c-0.787 0.787-0.787 2.053 0 2.827l7.333 7.333c0.387 0.387 0.907 0.587 1.413 0.587s1.027-0.2 1.413-0.587l7.333-7.333c0.787-0.773 0.787-2.040 0-2.827zM6.947 13.333l6.387-6.387 6.387 6.387h-12.773zM25.333 15.333c0 0-2.667 2.893-2.667 4.667 0 1.467 1.2 2.667 2.667 2.667s2.667-1.2 2.667-2.667c0-1.773-2.667-4.667-2.667-4.667z"
                        ></path>

                        <path
                          :class="$style.tagsColorBucketBorder"
                          opacity="1"
                          :style="{
                            fill: colorPickerPreview || tag.tagColor,
                          }"
                          d="M2.667 26.667h26.667c1.467 0 2.667 1.2 2.667 2.667v0c0 1.467-1.2 2.667-2.667 2.667h-26.667c-1.467 0-2.667-1.2-2.667-2.667v0c0-1.467 1.2-2.667 2.667-2.667z"
                        ></path>
                      </svg>
                    </app-tooltip>

                    <transition name="fade-down-short">
                      <div
                        :class="$style.tagsColorPicker"
                        v-if="colorPicker"
                        ref="target"
                      >
                        <color-picker
                          v-on:click.stop
                          v-on:preview="previewColor"
                          v-on:select="selectColor"
                          :selected="tag.tagColor"
                          default-color="#000000"
                        />
                      </div>
                    </transition>
                  </div>
                </div>

                <div class="col-md-6 mt-sm-3 mt-md-0">
                  <app-input-label
                    >Exemplo de aplicação na célula</app-input-label
                  >
                  <div
                    :class="$style.tagsExample"
                    :style="{
                      background: (colorPickerPreview || tag.tagColor) + '33',
                      boxShadow: `inset 0 0 0 2px ${
                        colorPickerPreview || tag.tagColor
                      }`,
                    }"
                  >
                    <div class="row align-items-center no-gutters flex-nowrap">
                      <div class="col-auto pr-2">
                        <div
                          :class="$style.tagsExampleCircle"
                          :style="{
                            background: colorPickerPreview || tag.tagColor,
                          }"
                        ></div>
                      </div>

                      <div class="col">
                        <div :class="$style.tagsExampleLabel">
                          Contribuir para o sucesso com soluções inovadoras,
                          transformando vidas e fortalecendo as relações
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-xl-8 mb-2">
              <div class="row">
                <div class="col-12">
                  <app-input-label>Filtros</app-input-label>

                  <ul :class="$style.filterRow">
                    <li
                      :class="$style.filterList"
                      v-for="(data, index) in tagFilters"
                      :key="data.uuid"
                      v-on:dblclick="editFilter(index)"
                    >
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto pl-2 pr-3">
                          <app-icon
                            :class="[$style.filterIcon]"
                            glyph="account_tree"
                          />
                        </div>

                        <div class="col">
                          <div
                            :class="[
                              $style.filterName,
                              !data.filterName ? $style.filterNameEmpty : null,
                            ]"
                          >
                            {{ data.filterName || 'Filtro sem título' }}
                          </div>
                        </div>

                        <div
                          class="col-4"
                          :style="{
                            opacity: !data.filter ? 0.5 : 1,
                          }"
                        >
                          <select-box
                            :options="
                              !!getTables.find(
                                ({ identifier }) =>
                                  identifier ===
                                  getSubjectAttributesFilter[index]
                              )
                                ? getTables.find(
                                    ({ identifier }) =>
                                      identifier ===
                                      getSubjectAttributesFilter[index]
                                  ).columns
                                : []
                            "
                            :selected="data.subjectAttributeId"
                            :loading="loadingSubjects"
                            :placeholder="
                              data.filter ? 'Selecione um atributo' : ''
                            "
                            v-on:select="selectSubjectAttribute(index, $event)"
                            unselect
                            small
                            :disabled="!data.filter"
                          />
                        </div>

                        <div class="col-auto pl-3">
                          <app-tooltip
                            label="Editar filtro"
                            v-on:click="editFilter(index)"
                          >
                            <app-icon
                              :class="[
                                $style.filterIcon,
                                $style.filterIconAction,
                              ]"
                              glyph="edit"
                            />
                          </app-tooltip>
                        </div>

                        <div
                          class="col-auto pl-3 pr-2"
                          v-if="tagFilters.length > 1"
                        >
                          <app-tooltip
                            label="Excluir filtro"
                            v-on:click="removeFilter(index)"
                          >
                            <app-icon
                              :class="[
                                $style.filterIcon,
                                $style.filterIconAction,
                                $style.filterIconActionDelete,
                              ]"
                              glyph="delete"
                            />
                          </app-tooltip>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <app-button
                    label="Adicionar Filtro"
                    icon="add"
                    small
                    color="purple"
                    v-on:click="addFilter()"
                  />
                </div>
              </div>
            </div>
          </div>
        </app-box>
      </app-box>

      <app-actions-box v-if="!loading.active && !loading.error">
        <div class="row justify-content-center">
          <div class="col-auto">
            <app-button
              label="Salvar"
              type="submit"
              icon="save"
              :loading="saveLoading"
            />
          </div>

          <div class="col-auto">
            <app-button
              label="Cancelar"
              icon="close"
              color="red-500"
              v-on:click="cancel"
            />
          </div>
        </div>
      </app-actions-box>
    </form>

    <app-confirm-promise ref="confirm" />
  </app-view>
</template>

<script>
import { isEqual } from 'lodash-es'
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import AutomationFooter from '@/views/automation/components/AutomationFooter.vue'
import SelectBox from '@/components/select/SelectBox.vue'
import ColorPicker from '@/components/colorPicker/ColorPicker.vue'
import getUrl from '@/mixins/getUrlFilter'
import { v4 as uuid } from 'uuid'

export default {
  setup () {
    const target = ref(null)
    const colorPicker = ref(false)
    const closeColorPicker = () => {
      colorPicker.value = false
    }

    onClickOutside(target, () => closeColorPicker())

    return { target, colorPicker, closeColorPicker }
  },
  components: {
    ColorPicker,
    AutomationFooter,
    SelectBox
  },

  data: () => ({
    tag: {
      tagName: null,
      tagColor: '#000000'
    },
    loading: {
      active: false,
      error: false
    },
    saveLoading: false,
    colorPickerPreview: false,
    colorPickerEvent: false,
    tagFilters: [
      // {
      //     uuid: uuid(),
      //     filterName: null,
      //     filter : null,
      //     filterId: null,
      //     subjectAttributeId: null
      // }
    ],
    filterKey: +new Date(),
    filters: [],
    loadingFilter: false,
    loadingSubjects: false,
    modal: {
      filter: {
        active: false,
        filterId: null,
        index: null,
        data: {}
      }
    }
  }),

  created () {
    this.init()
    this.getFilter()
    this.loadTables()
  },

  computed: {
    getTagId () {
      return this.$route.params.tagId
    },

    getTables () {
      return this.$store.getters.getTables
    },

    getSubjectAttributesFilter () {
      return this.tagFilters.map(
        ({ filter }) => {
          const json = JSON.parse(filter)
          return json ? json[0].identifier : ''
        }
      )
    }
  },

  methods: {
    async init () {
      if (this.getTagId) {
        this.loading.active = true

        await this.$http
          .get(`${this.$store.getters.api}/presentation/tag/${this.getTagId}`)
          .then(({ data }) => {
            this.tag = {
              tagId: data.tagId,
              tagName: data.tagName,
              tagColor: data.tagColor
            }

            if (data.tagFilters.length) {
              this.tagFilters = data.tagFilters.map((val) =>
                Object.assign({}, val, { uuid: uuid() })
              )
            }
          })
          .catch((error) => {
            this.loading.response = error.response

            this.loading.active = false
            this.loading.error = true
          })

        this.loading.active = false
      }
    },

    async save () {
      const data = {
        ...this.tag,
        tagFilters: this.tagFilters.map(
          ({ filterName, filter, filterId, subjectAttributeId }) => ({
            filterName,
            filter,
            filterId,
            subjectAttributeId
          })
        )
      }

      if (!this.getTagId) await this.createTag(data)
      else await this.editTag(data)
    },

    async createTag (payload) {
      try {
        this.saveLoading = true

        await this.$store.dispatch('createTag', payload)

        this.$store.dispatch('notification', {
          text: 'Tag <b>cadastrada</b> com sucesso!'
        })
        this.$store.commit('closePage', '/tags/editor')

        this.$router.push({
          name: 'tags_list',
          params: {
            refresh: true
          }
        })
      } catch (e) {
        console.log(e)
        this.$store.dispatch('notification', {
          type: 'error',
          text: 'Ocorreu um erro ao tentar <b>cadastrar</b> a tag. Por favor, tente novamente.',
          error: e
        })
      } finally {
        this.saveLoading = false
      }
    },

    async editTag (payload) {
      try {
        this.saveLoading = true

        await this.$store.dispatch('editTag', { id: this.getTagId, payload })

        this.$store.dispatch('notification', {
          text: 'Tag <b>alterada</b> com sucesso!'
        })
        this.$store.commit(
          'closePage',
              `/tags/editor/${this.getTagId}`
        )
      } catch (e) {
        console.log(e)
        this.$store.dispatch('notification', {
          type: 'error',
          text: 'Ocorreu um erro ao tentar <b>alterar</b> a tag. Por favor, tente novamente.',
          error: e
        })
      } finally {
        this.saveLoading = false
      }
    },

    cancel () {
      this.$store.commit(
        'closePage',
        '/tags/editor' + (this.getTagId ? `/${this.getTagId}` : '')
      )
    },

    toggleColorPicker () {
      this.colorPicker = !this.colorPicker
    },

    previewColor (color) {
      this.colorPickerPreview = color
    },

    selectColor (color) {
      this.tag.tagColor = color
      this.colorPickerPreview = false

      this.closeColorPicker()
    },

    addFilter () {
      this.tagFilters.push({
        uuid: uuid(),
        filterName: null,
        filter: null,
        filterId: null,
        subjectAttributeId: null
      })
    },

    applyEditFilter () {
      const filter = this.tagFilters[this.modal.filter.index]
      const currentSubjectIdentifier = []
      const futureSubjectIdentifier = []
      const currentfilter = JSON.parse(filter.filter) || []

      currentfilter.forEach((f) => {
        currentSubjectIdentifier.push(f.identifier)
      })

      const futurefilter = JSON.parse(this.modal.filter.data.filter) || []

      futurefilter.forEach((f) => {
        futureSubjectIdentifier.push(f.identifier)
      })

      filter.filter = this.modal.filter.data.filter

      filter.filterName = this.modal.filter.data.filterName
      filter.filterId = this.modal.filter.data.filterId

      if (!isEqual(currentSubjectIdentifier, futureSubjectIdentifier)) {
        filter.subjectAttributeId = null
      }

      this.modal.filter.data = null
      this.modal.filter.active = false
    },

    editFilter (index) {
      this.modal.filter.data = JSON.parse(
        JSON.stringify(this.tagFilters[index])
      )
      this.modal.filter.active = true
      this.modal.filter.index = index
    },

    async selectFilter (value) {
      if (value) {
        const filter = this.filters.find((x) => x.id == value)

        this.modal.filter.data.filter = filter.json
        this.modal.filter.data.filterName = filter.label
        this.modal.filter.data.filterId = filter.id

        this.filterKey = +new Date()
      } else {
        const confirm = this.$refs.confirm

        await confirm
          .open(
            'Removendo o filtro selecionado, a árvore abaixo será cadastrada como um novo filtro. Deseja realmente continuar?',
            'orange'
          )
          .then((response) => {
            this.modal.filter.data.filterId = null
          })
          .catch((error) => false)

        confirm.close()
      }
    },

    selectSubjectAttribute (index, value) {
      if (value) {
        let subjectAttribute

        this.getTables.forEach((table) => {
          const attribute = table.columns.find((data) => data.id === value)

          if (attribute) {
            this.tagFilters[index].subjectAttributeId = attribute.id
          }
        })
      } else {
        this.tagFilters[index].subjectAttributeId = null
      }
    },

    getFilter () {
      this.loadingFilter = true

      let jsonAttrId, nameAttrId

      return this.$http
        .get(`${this.$store.getters.api}/context/web/grid/filtro/`)
        .then(({ data }) => {
          jsonAttrId = data.subject.subjectAttributes.find(
            (x) => x.subjectAttributeName.toLowerCase() == 'filtro'
          ).subjectAttributeId
          nameAttrId = data.subject.subjectAttributes.find(
            (x) => x.subjectAttributeName.toLowerCase() == 'nome'
          ).subjectAttributeId

          let array = []

          const origin = getUrl()

          return this.$http
            .post(
                  `${this.$store.getters.api}/talk/filter/filtro?origin=${origin}`,
                  {
                    fetch: 10000,
                    filterIds: [],
                    isActive: true,
                    page: 1
                  }
            )
            .then(({ data }) => {
              array = data.itens.map((item) => {
                return {
                  id: item.talkId,
                  label: item.talkValues.find(
                    (x) => x.subjectAttributeId == nameAttrId
                  ).value,
                  json: item.talkValues.find(
                    (x) => x.subjectAttributeId == jsonAttrId
                  ).value
                }
              })

              this.filters = array

              this.loadingFilter = false
            })
            .catch((error) => {})
        })
        .catch((error) => {})
    },

    async removeFilter (index) {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente remover esse filtro?', 'orange')
        .then((response) => {
          this.tagFilters.splice(index, 1)
        })
        .catch((error) => false)

      confirm.close()
    },

    async closeEditFilter () {
      if (
        !isEqual(
          this.modal.filter.data,
          this.tagFilters[this.modal.filter.index]
        )
      ) {
        const confirm = this.$refs.confirm

        await confirm
          .open(
            'Você tem dados não salvos. Deseja realmente continuar?',
            'orange'
          )
          .then((response) => {
            this.modal.filter.data = null
            this.modal.filter.active = false
          })
          .catch((error) => false)

        confirm.close()
      } else {
        this.modal.filter.data = null
        this.modal.filter.active = false
      }
    },

    updateValue (data) {
      const json = JSON.parse(data).map((item) => {
        delete item.raw
        return item
      })
      this.modal.filter.data.filter = JSON.stringify(json)
    },

    async loadTables () {
      try {
        if (!this.$store.getters.getTables.length) {
          this.loadingSubjects = true

          await this.$store.dispatch('loadTables')
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingSubjects = false
      }
    }
  },

  watch: {
    colorPicker: function (value) {
      if (value) {
        setTimeout(() => {
          this.colorPickerEvent = true
        }, 1)
      } else {
        this.colorPickerEvent = false
      }
    }
  }
}
</script>

<style lang="scss" module>
  /* */

  .tags {
    &__example {
      height: 40px;

      padding: 0 math.div($theme-padding, 2);

      border-radius: 5px;

      &__label {
        width: calc(100% - 20px);

        font-family: 'Roboto', sans-serif;
        @include font-sizer(12);
        line-height: 40px;
        font-weight: 400;

        color: black;

        white-space: nowrap;
        text-overflow: ellipsis;

        overflow: hidden;

        :global(.nightlymode) & {
          color: white;
        }
      }

      &__circle {
        height: 10px;
        width: 10px;

        border-radius: 50%;
      }
    }

    &__view {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    &__color {
      cursor: pointer;

      &__wrapper {
        position: relative;
        width: 40px;
        height: 40px;
        padding: math.div($theme-padding, 2);

        border-radius: 6px;
        border: 1px solid $md-grey-400;

        :global(.nightlymode) & {
          border-color: $md-grey-800;
        }
      }

      &__bucket {
        fill: $md-grey-600;

        :global(.nightlymode) & {
          fill: white;
        }

        &__border {
          stroke: black;
          stroke-width: 0.6px;
          stroke-opacity: 0.5;

          :global(.nightlymode) & {
            stroke: white;
          }
        }
      }

      &__picker {
        position: absolute;

        top: 100%;
        left: 0;

        margin-top: math.div($theme-padding, 2);

        background: white;
        padding: $theme-padding;

        border-radius: 7px;

        z-index: 999;

        filter: drop-shadow(0 0 30px rgba(black, 0.1));

        :global(.nightlymode) & {
          background: darken($md-grey-800, 5%);
        }

        &::before {
          $size: $theme-padding;

          content: '';

          position: absolute;

          top: -$size;
          left: $size - 2;

          width: $size;
          height: $size;

          border: math.div($size, 2) solid transparent;
          border-bottom-color: white;

          :global(.nightlymode) & {
            border-bottom-color: darken($md-grey-800, 5%);
          }
        }
      }
    }
  }

  .filter {
    &__row {
      margin: 0;
      margin-bottom: 20px;
      padding: 0;

      list-style: none;
    }

    &__list {
      padding: 10px math.div($theme-padding, 2);

      background: white;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }

      border-radius: 10px;

      &:not(:last-of-type) {
        margin-bottom: $theme-padding;
      }
    }

    &__icon {
      $size: 20px;

      width: $size;
      height: $size;

      fill: $md-grey-500;

      &--action {
        cursor: pointer;

        transition: 0.2s ease;
        transition-property: fill;

        &:hover {
          fill: $md-grey-700;

          :global(.nightlymode) & {
            fill: $md-grey-400;
          }
        }

        &--delete {
          fill: $md-red-500;

          &:hover {
            fill: $md-red-700;
          }
        }
      }
    }

    &__description {
      margin-bottom: $theme-padding * 2;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      line-height: 1;
      color: black;

      :global(.nightlymode) & {
        color: white;
      }
    }

    &__name {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      color: black;

      :global(.nightlymode) & {
        color: white;
      }

      &--empty {
        font-style: italic;
        color: $md-grey-500;
      }
    }

    &__wrapper {
      padding: $theme-padding * 1.5;
    }

    &__modal {
      padding: $theme-padding * 2;
    }
  }
</style>
