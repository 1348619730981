<script setup>
import Dashboard from '@/views/Dashboard.vue'
import SearchResults from '@/views/SearchResults.vue'
import TagsList from '@/views/tags/List.vue'
import TagsEditor from '@/views/tags/Editor.vue'
import AutomationsList from '@/views/automation/List.vue'
import AutomationEditor from '@/views/automation/Editor.vue'
import UsersList from '@/views/config/users/List.vue'
import UserAdd from '@/views/config/users/Add.vue'
import UserEdit from '@/views/config/users/Edit.vue'
import UserConfig from '@/views/config/users/User.vue'
import RoomsList from '@/views/config/access-profile/List.vue'
import RoomEditor from '@/views/config/access-profile/Room.vue'
import TemplatesCustomList from '@/views/template/TemplateListCustom.vue'
import TemplatesDefaultList from '@/views/template/TemplateListDefault.vue'
import TemplatesUsersList from '@/views/template/TemplateListUsers.vue'
import TemplateEditor from '@/views/template/TemplateEditor.vue'
import NotificationsList from '@/views/notification/NotificationList.vue'
import NotificationEditor from '@/views/notification/Notification.vue'
import ChannelsList from '@/views/channels/channelsList.vue'
import MenuEditor from '@/views/menu/Menu.vue'
import ThemeEditor from '@/views/config/theme/Index.vue'
import DarkMode from '@/views/config/theme/DarkMode.vue'
import Context from '@/talkture/context/Context.vue'
import ContextNotifications from '@/talkture/context/ContextNotifications.vue'
import AdminArea from '@/talkture/admin/Index.vue'
import NotFound from '@/views/404.vue'
import { ref, markRaw, onActivated, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const name = route.name
const currentComponent = () => {
  switch (name) {
    case 'dashboard':
      return markRaw(Dashboard)
    case 'search':
      return markRaw(SearchResults)
    case 'tags_list':
      return markRaw(TagsList)
    case 'tags_editor':
      return markRaw(TagsEditor)
    case 'automation_list':
      return markRaw(AutomationsList)
    case 'automation_editor':
      return markRaw(AutomationEditor)
    case 'config_users_list':
      return markRaw(UsersList)
    case 'config_users_add':
      return markRaw(UserAdd)
    case 'config_users_edit':
      return markRaw(UserEdit)
    case 'user':
      return markRaw(UserConfig)
    case 'config_access_profile_list':
      return markRaw(RoomsList)
    case 'config_access_profile_add':
      return markRaw(RoomEditor)
    case 'config_access_profile_edit':
      return markRaw(RoomEditor)
    case 'template_list_custom':
      return markRaw(TemplatesCustomList)
    case 'template_list_default':
      return markRaw(TemplatesDefaultList)
    case 'template_list_users':
      return markRaw(TemplatesUsersList)
    case 'template_editor':
      return markRaw(TemplateEditor)
    case 'template_editor_new':
      return markRaw(TemplateEditor)
    case 'notification_list':
      return markRaw(NotificationsList)
    case 'notificationPage':
      return markRaw(NotificationEditor)
    case 'channels':
      return markRaw(ChannelsList)
    case 'menu':
      return markRaw(MenuEditor)
    case 'config_theme':
      return markRaw(ThemeEditor)
    case 'config_dark_mode':
      return markRaw(DarkMode)
    case 'context_id':
      return markRaw(Context)
    case 'context_template_filter':
      return markRaw(Context)
    case 'context_filter':
      return markRaw(Context)
    case 'context':
      return markRaw(Context)
    case 'notifications':
      return markRaw(ContextNotifications)
    case 'admin_mode':
      return markRaw(AdminArea)
    case '404':
      return markRaw(NotFound)
    default:
      return markRaw(NotFound)
  }
}

const key = ref(0)
const currentPath = route.fullPath
const refreshPages = computed(() => store.getters.refreshPages)

onActivated(() => {
  if (refreshPages.value.find((path) => path === currentPath)) {
    key.value++
    store.commit('removeFromRefreshPages', currentPath)
  }
})
</script>

<template>
  <component :is="currentComponent()" :key="key" />
</template>

<style lang="scss" module></style>
