<template>
  <div>
    <input
      :class="[
        $style.input,
        mode == 'form' || mode == 'filter-attr' ? $style.inputForm : null,
        mode == 'form' && oldValue == '' ? $style.inputEmpty : null,
        !isValid || !isNaturalValid ? $style.inputError : null,
      ]"
      :maxlength="config.maxlength"
      :required="config.required"
      :readonly="readonly"
      :value="inputValue"
      v-on:keydown.tab="tab"
      v-on:keydown.enter="enter"
      v-on:change.stop="$emit('change', $event.target.value)"
      v-on:input="checkvalid($event.target.value)"
      v-on:blur="blur"
      v-maska
      :data-maska="maskInput"
      ref="input"
    />

    <div v-if="!isValid" :class="$style.msgvalid">
      {{ errorMessageValidation }}
    </div>

    <natural-validation
      v-if="isValid && value.length > 0 && config.natural && edited"
      :value="value"
      :mode="mode"
      :attribute="attribute"
      :talk-id="talkId"
      :subject="subject"
      :subject-identifier="subjectIdentifier"
      :error-message="errorMessageNatural"
      v-on:validation-natural="validationNatural"
      v-on:load-validation-natural="loadValidationNatural"
    />

    <div style="clear: both"></div>
  </div>
</template>

<script>
import EventBus from '../../eventBus'
import { defineAsyncComponent } from 'vue'
const naturalValidation = defineAsyncComponent(() => import('@/talkture/scenario/components/natural-validation/NaturalValidation.vue'))

export default {
  components: {
    naturalValidation
  },
  data () {
    return {
      delay: null,
      isValid: true,
      isNaturalValid: true,
      oldValue: '',
      edited: false,
      changed: null,
      timeout: null
    }
  },

  props: {
    mode: String,
    value: String,
    config: {},
    subject: String,
    subjectIdentifier: String,
    talkId: Number,
    focus: Boolean,
    attribute: Object,
    typeInput: {
      type: String,
      default: 'Pessoa Jurídica'
    }
  },

  computed: {
    maskInput () {
      return this.typeInput === 'Pessoa Jurídica'
        ? '##.###.###/####-##'
        : this.typeInput == 'Cliente do exterior'
          ? '####################'
          : '###.###.###-##'
    },

    typeInputMessage () {
      return this.typeInput === 'Pessoa Jurídica'
        ? 'número do CNPJ'
        : this.typeInput === 'Cliente do exterior'
          ? 'número de identificação'
          : 'número do CPF'
    },

    errorMessageNatural () {
      return `O ${this.typeInputMessage} informado já está sendo usado`
    },

    errorMessageValidation () {
      return `O ${this.typeInputMessage} informado é inválido`
    },

    readonly () {
      return (
        this.config.readonly ||
        (this.talkId != 0
          ? !this.config.actions.update
          : !this.config.actions.insert)
      )
    },

    inputValue () {
      if (this.changed) {
        return ''
      } else {
        return this.value
      }
    }
  },

  beforeMount () {
    if (this.value) {
      this.isValid = true
      this.oldValue = JSON.parse(JSON.stringify(this.value))
    }
  },

  methods: {
    blur () {
      if (this.oldValue !== this.value && this.isValid) {
        this.$emit('blur')
      }
    },

    tab () {
      EventBus.$emit('tab')
    },

    enter () {
      EventBus.$emit('enter')
    },

    checkvalid (value) {
      if (
        (this.typeInput == 'Pessoa Física' && value.length == 14) ||
        (this.typeInput == 'Pessoa Jurídica' && value.length == 18)
      ) {
        this.isValid = false
        this.validationNatural(false)
        this.$emit('change', value)
        this.validation()
      }
    },

    validation () {
      this.changed = false
      this.edited = true
      this.isValid = false

      if (
        (this.typeInput == 'Pessoa Física' && this.value.length < 14) ||
        (this.typeInput == 'Pessoa Jurídica' && this.value.length < 18)
      ) {
        this.isValid = false
      } else {
        this.isValid = true
        this.$refs.input.setCustomValidity('')
      }

      if (!this.isValid) {
        this.$refs.input.setCustomValidity(
          `O ${this.typeInputMessage} informado é inválido`
        )
      } else {
        this.$refs.input.setCustomValidity('')
      }
    },

    loadValidationNatural () {
      this.$refs.input.setCustomValidity(
        `Verificando o ${this.typeInputMessage}...`
      )
    },

    validationNatural (natural) {
      if (natural) {
        this.isNaturalValid = false

        this.$refs.input.setCustomValidity(
          `O ${this.typeInputMessage} informado já existe`
        )
      } else {
        this.isNaturalValid = true

        this.$refs.input.setCustomValidity('')
      }
    }

  },

  watch: {
    value: function () {
      this.validation()
    },
    typeInput (newValue, oldValue) {
      this.changed = true
      this.isValid = true
      this.validationNatural(false)
    }
  }
}
</script>

<style lang="scss" module>

$input-color: black;
$input-color-nightly: white;
$input-background: white;
$input-background-nightly: $md-grey-900;

.input {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: math.div($theme-padding, 2) $theme-padding;

  font-family: 'Roboto', sans-serif;
  @include font-sizer(12);
  font-weight: 400;
  color: $input-color;

  background: $input-background;

  box-shadow: inset 0 0 0 2px $md-blue-500;
  border-radius: 5px;

  :global(.nightlymode) & {
    color: $input-color-nightly;

    background: $input-background-nightly;
  }

  &[readonly] {
    background: $md-grey-100;

    :global(.nightlymode) & {
      background: $md-grey-700;
    }

    box-shadow: inset 0 0 0 2px transparent;

    cursor: default;
  }

  &--form {
    height: 50px;
    position: relative;

    box-shadow: inset 0 0 0 2px $md-grey-400;

    transition: 0.2s;
    transition-property: box-shadow;

    :global(.nightlymode) & {
      box-shadow: inset 0 0 0 2px $md-grey-600;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $md-blue-500;
    }
  }

  &--empty {
    padding-top: math.div($theme-padding, 2);
    padding-bottom: math.div($theme-padding, 2);
    padding-left: $theme-padding;
    padding-right: 40px;
  }

  &--error {
    box-shadow: inset 0 0 0 2px $md-red-700 !important;
  }
}

.msgvalid {
  transform: translateY(-11px);

  max-width: calc(100% - 16px);
  width: auto;
  float: left;

  padding: 5px;
  margin-top: 0px;
  margin-left: 8px;

  background: $md-red-700;
  border-radius: 3px;

  @include font-sizer(12);
  line-height: 12px;
  color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}
</style>
