import axios from 'axios'

const moduleAttribute = {
  actions: {
    async loadAttributesSelect ({ getters }) {
      const { data } = await axios.get(`${getters.api}/account/attributes/`)

      return data.message
    }
  }
}

export default moduleAttribute
