import axios from 'axios'

const moduleTag = {
  state: () => ({
    tags: {
      items: [],
      total: 0
    }
  }),
  getters: {
    getTags: (state) => state.tags
  },
  mutations: {
    SET_TAGS (state, { tags, total }) {
      state.tags.items = tags
      state.tags.total = total
    },
    ADD_TAG (state, tag) {
      state.tags.items.unshift(tag)
      state.tags.total++
    },
    EDIT_TAG (state, tag) {
      const index = state.tags.items.findIndex(({ tagId }) => tagId === tag.tagId)
      if (index > -1) {
        state.tags.items[index] = tag
      }
    },
    DELETE_TAG (state, id) {
      const index = state.tags.items.findIndex((tag) => tag.tagId === id)
      if (index > -1) {
        state.tags.items.splice(index, 1)
        state.tags.total--
      }
    }
  },
  actions: {
    async getTag ({ getters }, id) {
      const { data } = await axios.get(`${getters.api}/presentation/tag/${id}`)
      return data
    },
    async loadTags ({ getters, commit }, { page, fetch, term }) {
      await axios
        .get(`${getters.api}/presentation/tag`, {
          params: {
            page,
            fetch,
            term
          }
        })
        .then(({ data }) => {
          commit('SET_TAGS', {
            tags: data.itens,
            total: data.totalResults
          })
        })
        .catch((e) => {
          throw e
        })
    },
    async createTag ({ getters, dispatch, commit }, payload) {
      await axios
        .post(`${getters.api}/presentation/tag`, payload)
        .then(async ({ data }) => {
          const tag = await dispatch('getTag', data.resourceId)
          commit('ADD_TAG', tag)
        })
        .catch((e) => {
          throw e
        })
    },
    async editTag ({ getters, dispatch, commit }, { id, payload }) {
      await axios
        .put(`${getters.api}/presentation/tag/${id}`, payload)
        .then(async () => {
          const tag = await dispatch('getTag', id)
          commit('EDIT_TAG', tag)
        })
        .catch((e) => {
          throw e
        })
    },
    async deleteTag ({ getters, commit }, id) {
      await axios
        .delete(`${getters.api}/presentation/tag/${id}`)
        .then(() => {
          commit('DELETE_TAG', id)
        })
        .catch((e) => {
          throw e
        })
    }
  }
}

export default moduleTag
