<template>
  <div :class="$style.view">
    <app-loader
      v-if="!loading.ready && !loading.render"
      :active="loading.active"
      :error="loading.error"
      v-on:reload="init()"
      key="loading"
    />

    <app-box v-else padding>
      <app-box margin>
        <div class="row no-gutters align-items-center">
          <div class="col">
            <app-title label="Notificações" />
          </div>

          <div class="col-auto" v-if="$slots['header-actions']">
            <slot name="header-actions"></slot>
          </div>
        </div>
      </app-box>

      <transition name="fade-up" mode="out-in">
        <app-loader
          v-if="loading.active"
          key="loading"
          :active="loading.active"
          :error="loading.error"
          v-on:reload="init()"
          background
        />

        <app-box v-else-if="data.length" key="results">
          <div :class="$style.list">
            <ul :class="$style.listRow" :id="$style.id_list">
              <li
                :class="$style.listItens"
                v-for="item in data"
                :key="item.id"
                :style="{
                  cursor: item.subject || item.talkId ? 'pointer' : '',
                }"
                v-on:click="
                  notificationRead(item.id, item.subject, item.talkId)
                "
              >
                <div
                  :class="[
                    $style.listBox,
                    item.inativo ? $style.listBoxDisabled : null,
                  ]"
                >
                  <div class="row align-items-center no-gutters">
                    <div class="col-auto pr-4">
                      <div
                        :class="$style.listBoxicon"
                        :style="{
                          background: item.color
                            ? /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(
                                item.color,
                              )
                              ? item.color
                              : 'var(--theme-color-1)'
                            : 'var(--theme-color-1)',
                        }"
                      >
                        <app-icon
                          :glyph="
                            item.subjectIcon
                              ? item.subjectIcon
                              : 'notification_important'
                          "
                          :class="$style.listIcon"
                        />
                      </div>
                    </div>

                    <div class="col">
                      <div :class="$style.listTitle">
                        {{ item.title }}
                        <span :class="$style.listDate">{{
                          getDate(item.data)
                        }}</span>
                      </div>
                      <div :class="$style.listDescription">
                        {{ item.description }}
                      </div>
                    </div>

                    <div class="col-1">
                      <div :class="$style.listActions">
                        <ul :class="$style.listActionsRow">
                          <li :class="$style.listActionsList">
                            <app-icon
                              :glyph="item.status ? 'done_all' : 'done'"
                              :class="[
                                $style.listActionsIcon,
                                item.status ? $style.listActionsIconCheck : '',
                              ]"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div class="row justify-content-center mt-5">
              <div class="col-auto">
                <app-pagination
                  :length="pagination.total"
                  :current-page="pagination.page"
                  :per-page="pagination.per_page"
                  :option-per-page="pagination.option_per_page"
                  v-on:change-page="changePage"
                  v-on:change-per-page="changePerPage"
                />
              </div>
            </div>
          </div>
        </app-box>

        <app-text
          v-else
          key="empty"
          name="list_empty"
          >Nenhum registro encontrado</app-text
        >
      </transition>
    </app-box>
  </div>
</template>

<script>
export default {
  data () {
    return {
      disable: {
        modal: false,
        index: null,
        data: null,
        period: {
          modal: false,
          in: null,
          out: null,
          undetermined: false
        }
      },

      timeout: null
    }
  },

  props: {
    data: Array,

    scenario: String,

    subject: String,

    pagination: {
      type: Object,
      default: function () {
        return {
          page: 1,
          per_page: 10,
          total: 0,
          option_per_page: [10, 25, 50, 100, 200, 500]
        }
      }
    },

    loading: {
      type: Object,
      default: function () {
        return {
          ready: true,
          render: false,
          active: false,
          error: false
        }
      }
    }
  },

  watch: {
    'pagination.per_page': function () {
      this.$emit('change-page')
    },

    'pagination.page': function () {
      this.$emit('change-page')
    }
  },

  methods: {
    notificationRead (id, subject, talk) {
      const obj = {
        id: id,
        subject: subject,
        talk: talk
      }

      this.$emit('notification-read', obj)
    },

    changePage (value) {
      this.pagination.page = value
    },

    changePerPage (value) {
      this.pagination.per_page = value
    },

    getDate (date) {
      const dataFull = date.split('T')

      const data = dataFull[0] ? dataFull[0].split('-') : '06-05-2019'.split('/')

      const months = {
        '01': 'Jan',
        '02': 'Fev',
        '03': 'Mar',
        '04': 'Abr',
        '05': 'Mai',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Ago',
        '09': 'Set',
        10: 'Out',
        11: 'Nov',
        12: 'Dez'
      }

      return data[2] + ' ' + months[data[1]] + ' de ' + data[0]
    }
  }
}
</script>

<style lang="scss" module>

#id {
  &_delete_confirm,
  &_list {
    text-decoration: none;
  }
}

.view {
  width: 75%;
  margin: 0 auto;
}

.list {
  &__row {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__itens {
    margin-bottom: $theme-padding;
  }

  &__box {
    padding: $theme-padding $theme-padding * 2.5 $theme-padding $theme-padding;

    background: white;

    border-radius: 5px;

    &--disabled {
      opacity: 0.5;
    }

    :global(.nightlymode) & {
      background: $md-grey-900;

      &--disabled {
        opacity: 0.3;
      }
    }
  }

  &__image {
    width: 100%;

    padding-bottom: 100%;

    background: $md-grey-200;
    background-size: cover;

    border-radius: 100%;
  }

  &__type {
    padding: 3px 10px;
    margin-bottom: 5px;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(9);
    text-transform: uppercase;
    line-height: 2;
    letter-spacing: 1px;
    color: white;
    font-weight: 400;

    background: $md-purple-500;

    border-radius: 20px;

    display: inline-block;
  }

  &__title {
    display: flex;
    align-items: center;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(22);
    line-height: 2.5rem;
    color: black;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__date {
    font-family: 'Raleway', sans-serif;
    @include font-sizer(12);
    color: white;
    font-weight: 600;
    background: $md-blue-500;

    padding: 0 math.div($theme-padding, 3);
    border-radius: 3px;
    margin-left: math.div($theme-padding, 3);

    height: 2.2rem;
  }

  &__description {
    margin-top: 5px;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(14);
    line-height: 1.5;
    color: $md-grey-500;
    font-weight: 300;
  }

  &__boxicon {
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
  }

  &__icon {
    width: 24px;
    height: 24px;

    fill: white;
  }

  &__actions {
    &__row {
      margin: 0;
      padding: 0;

      list-style: none;

      display: flex;
      justify-content: flex-end;
    }

    &__list {
      display: inline-block;

      &:not(:last-of-type) {
        margin-right: $theme-padding;
      }

      &__block {
        pointer-events: none;
      }
    }

    &__icon {
      $size: 28px;

      width: $size;
      height: $size;

      fill: $md-grey-700;

      opacity: 0.5;

      cursor: pointer;

      :global(.nightlymode) & {
        fill: white;
      }

      &--check {
        opacity: 1;
        fill: $md-blue-500 !important;
      }
    }
  }
}
</style>
