<template>
  <textarea
    ref="textarea"
    :class="[$style.textarea, mode == 'form' ? $style.textareaForm : '']"
    :style="{
      height: identifier == 'inputMessageDialog' ? '50px' : '',
      backgroundColor:
        identifier == 'inputMessageDialog' && storeNightlyMode
          ? '#353535'
          : null,
    }"
    :maxlength="config.maxlength ? config.maxlength : 800"
    :required="config.required"
    :readonly="
      readonly ||
        (talkId != 0 && !config.actions.update) ||
        (talkId == 0 && !config.actions.insert)
    "
    v-model="inputValue"
    @keydown.enter.stop
    @blur="blur"
  />
</template>

<script>
export default {
  data () {
    return {
      delay: null
    }
  },

  props: {
    value: String,
    mode: String,
    config: {
      type: Object,
      default: () => {}
    },

    talkId: Number,

    focus: Boolean,

    identifier: String
  },

  emits: ['update:value', 'blur'],

  computed: {
    inputValue: {
      get () { return this.value },
      set (value) {
        clearTimeout(this.delay)
        this.delay = setTimeout(() => {
          this.$emit('update:value', value)
        }, 300)
      }
    },

    readonly () {
      return (
        this.config.readonly ||
        (this.talkId != 0 ? !this.config.actions.update : false)
      )
    },

    storeNightlyMode () {
      return this.$store.getters.nightlyMode
    }
  },

  methods: {
    blur () {
      if (this.oldValue != this.value) {
        this.$emit('blur')
      }
    }
  }
}
</script>

<style lang="scss" module>

$textarea-color: black;
$textarea-color-nightly: white;
$textarea-background: white;
$textarea-background-nightly: $md-grey-900;

.textarea {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  min-height: 100px;

  padding: (math.div($theme-padding, 2) + 3px) math.div($theme-padding, 2);

  font-family: 'Roboto', sans-serif;
  @include font-sizer(12);
  font-weight: 400;
  color: $textarea-color;

  background: $textarea-background;

  box-shadow: inset 0 0 0 2px $md-blue-500;
  border-radius: 5px;

  z-index: 100;

  :global(.nightlymode) & {
    color: $textarea-color-nightly;

    background: $textarea-background-nightly;
  }

  &--form {
    height: 150px;
    position: relative;

    transition: 0.4s;
    transition-property: box-shadow;

    resize: none;

    box-shadow: inset 0 0 0 2px $md-grey-400;

    :global(.nightlymode) & {
      box-shadow: inset 0 0 0 2px $md-grey-600;
    }

    &:not([readonly]):focus {
      box-shadow: inset 0 0 0 2px var(--theme-color-1);

      :global(.nightlymode) & {
        box-shadow: inset 0 0 0 2px var(--theme-color-1);
      }
    }

    &[readonly] {
      background: $md-grey-100;
      cursor: default;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }

    }
  }
}
</style>
