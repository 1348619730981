<template>
  <div>
    <app-select
      reference="filter"
      :errorMessage="
        value == '' && data.model == ''
          ? 'Selecione um modelo primeiro'
          : noresult
          ? 'Não há filtros disponíves para o modelo selecionado'
          : ''
      "
      :value="idFilter"
      :value-object="value"
      :data="dataFilter"
      :object-key="{
        id: 'filterId',
        label: 'filterName',
      }"
      :required="readonly ? false : config.required"
      :readonly="readonly"
      :haveSearch="true"
      :loadingPage="loadingFilter"
      :expandable="false"
      @search="search"
      @change-value="changeFilter"
      @expand="openRuleSummary"
    />

    <Teleport to="body">
      <transition name="fade-up">
        <app-rule-summary
          v-if="ruleSummary.active"
          :filter="ruleSummary.filter"
          @close="closeRuleSummary"
        />
      </transition>
    </Teleport>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import EventBus from '../../eventBus'
import getUrl from '@/mixins/getUrlFilter'
const AppRuleSummary = defineAsyncComponent(() => import('@/components/AppRuleSummary.vue'))

export default {
  components: {
    AppRuleSummary
  },

  props: {
    value: [Number, String, Object],

    focus: Boolean,

    config: Object,

    mode: String,

    talkId: Number
  },

  data () {
    return {
      data: {
        itens: [],

        model: ''
      },

      noresult: false,

      term: '',

      loadingFilter: false,

      ruleSummary: {
        active: false,
        filter: null
      }
    }
  },

  methods: {
    changeFilter (value) {
      this.$emit('change', value)
    },

    search (term) {
      this.term = term
    },

    openRuleSummary (obj) {
      this.ruleSummary.active = true
      this.ruleSummary.filter = obj
    },

    closeRuleSummary (data) {
      this.ruleSummary.active = false
    }
  },

  computed: {
    idFilter () {
      if (typeof this.value == 'object') {
        return this.value.id
      }

      return this.value
    },

    dataFilter () {
      const itens = this.data.itens.filter(filter =>
        filter?.filterName?.toLowerCase().includes(this.term.toLowerCase())
      )

      return itens
    },

    readonly () {
      const permission = this.talkId !== 0 && typeof this.talkId != 'undefined'
        ? !this.config.actions.update
        : !this.config.actions.insert

      return permission ||
        this.config.readonly ||
        (this.value == '' && this.data.model == '')
    }
  },

  mounted () {
    this.$emit('change', this.value)

    EventBus.$on('change-model', model => {
      this.loadingFilter = true

      this.data.model = model

      this.data.itens = []

      const origin = getUrl()

      this.$http
        .get(`${this.$store.getters.api}/Filter/${model}?origin/${origin}`)
        .then(({ data }) => {
          if (data.totalResults == 0) {
            this.noresult = true
          } else {
            this.noresult = false
          }

          this.data.itens = data.itens

          this.loadingFilter = false
        })
        .catch(error => {
          this.data.itens = []
        })
    })

    if (this.mode !== 'form' || this.focus) {
      setTimeout(
        () => {
          this.$refs.input.focus()
        },
        1,
        this
      )
    }
  },

  beforeUnmount () {
    EventBus.$off('change-model')
  },

  watch: {
    value: function (value) {
      if (value == 0) {
        this.data.model = ''

        this.$emit('change', '')
      }
    }
  }
}
</script>
