const striptags = require('striptags')

export default {
  methods: {
    getSubjectAttributeIdentifier (subjectAttributeId) {
      const attribute = this.data.attributes.find(
        ({ id }) => id === subjectAttributeId
      )

      return typeof attribute !== 'undefined'
        ? attribute.identifier
        : subjectAttributeId == 0
          ? 'timer'
          : null
    },
    getSubjectAttributeId (subjectAttributeIdentifier) {
      const attribute = this.data.attributes.find(
        ({ identifier }) => identifier === subjectAttributeIdentifier
      )

      return typeof attribute !== 'undefined' ? attribute.id : null
    },
    transformAttributes (attributes) {
      const attrs = attributes.map((attr) => {
        return {
          component: {
            config: {
              actions: {
                delete: attr.actions.delete || false,
                insert: attr.actions.create || false,
                update: attr.actions.update || false
              },
              componentConfigs: attr.componentConfigs || null,
              defaultValue: attr.defaultValue || null,
              isMultiple: attr.isMultipleValues || false,
              isOrderable: attr.isOrderable || false,
              isInternal: attr.isInternal || false,
              maxlength: attr.length || 0,
              required: attr.isRequired || false
            },

            type: attr.componentId
          },
          id: attr.subjectAttributeId,
          identifier: attr.subjectAttributeIdentifier,
          name: attr.subjectAttributeName
        }
      })

      return attrs
    },
    transformTalkValuesArrayInObject (values) {
      const objEntries = {}

      values.map((item) => {
        const o = {}

        Object.defineProperty(o, item.subjectAttributeIdentifier, {
          value: item.value,
          enumerable: true
        })
        Object.assign(objEntries, o)
      })

      return objEntries
    },
    setDefaultValues (talk, attributes) {
      attributes.forEach((attr) => {
        if (attr?.component?.config?.defaultValue) {
          talk[attr.id] =
            attr.component.type === 4
              ? [attr.component.config.defaultValue]
              : attr.component.config.defaultValue
        }
      })
    },
    transformTalkToSync (talk, id, attributes, subject) {
      let array

      const newTalk = {
        subject: subject,
        talkValues: []
      }

      if (id) {
        newTalk.talkId = id

        array = talk.values.map((item, key) => {
          const attr = attributes.find((x) => x.id == key)
          return attr?.component?.config?.actions?.update ? item : null
        })

        array.forEach((value, index) => {
          if (value) {
            const subjectAttribute = attributes.find((x) => x.id == index)

            if (!subjectAttribute.component.config.isInternal) {
              if (
                (subjectAttribute.component.type != 22 &&
                  subjectAttribute.component.type != 34) ||
                ((subjectAttribute.component.type == 22 ||
                  subjectAttribute.component.type == 34) &&
                  value.value.length)
              ) {
                const valueT =
                  typeof value.id !== 'undefined'
                    ? value.id
                    : value.tags
                      ? value.value
                      : value

                newTalk.talkValues.push({
                  subjectAttributeId: index,
                  value: valueT || null
                })
              }
            }
          }
        })
      } else {
        talk.forEach((value, index) => {
          if (value) {
            let newValue = ''

            if (typeof value.tags != 'undefined') {
              newValue = value.value
            }

            newTalk.talkValues.push({
              subjectAttributeId: index,
              value:
                typeof value.id !== 'undefined'
                  ? value.id
                  : newValue == '' && newValue !== false
                    ? value
                    : newValue
            })
          }
        })
      }

      newTalk.talkValues = newTalk.talkValues
        .filter((x) => typeof x !== 'undefined')
        .map((value) => {
          const subjectAttribute = attributes.find(
            ({ id }) => id == value.subjectAttributeId
          )

          if (subjectAttribute) {
            if (subjectAttribute.component.type == 12) {
              const json = JSON.parse(value.value)

              json.map((val) => {
                delete val.raw

                val.attributes.map((attr) => {
                  if (attr.operators) {
                    attr.operators.map((o) => {
                      o.values.map((v) => {
                        delete v.attribute.list
                      })
                    })
                  }
                })
              })

              value.value = striptags(JSON.stringify(json))
            } else if (subjectAttribute.component.type === 4) {
              if (!subjectAttribute.component.config.isMultiple) {
                const id = value.value
                  ? value.value.id
                    ? value.value.id
                    : value.value
                  : null

                value.value = id
              } else {
                if (!value.value.length) value.value = null
              }
            }
          }
          return value
        })

      newTalk.templateId = this.$store.getters.templateId

      if (
        this.$store.getters.bloqueiaProtocolo.subjectAttributeId.length != 0
      ) {
        newTalk.talkValues.push(this.$store.getters.bloqueiaProtocolo)
      }

      if (
        this.$store.getters.bloqueiaRetProtocolo.subjectAttributeId.length != 0
      ) {
        newTalk.talkValues.push(this.$store.getters.bloqueiaRetProtocolo)
      }

      this.$store.getters.nomeCertificadoId &&
      !newTalk.talkValues.some(
        (item) =>
          item.subjectAttributeId === this.$store.getters.nomeCertificadoId
      )
        ? newTalk.talkValues.push({
          subjectAttributeId: this.$store.getters.nomeCertificadoId,
          value: this.$store.getters.nomeCertificado
        })
        : false
      this.$store.getters.cpfCertificadoId &&
      !newTalk.talkValues.some(
        (item) =>
          item.subjectAttributeId === this.$store.getters.cpfCertificadoId
      )
        ? newTalk.talkValues.push({
          subjectAttributeId: this.$store.getters.cpfCertificadoId,
          value: this.$store.getters.cpfCertificado
        })
        : false

      return newTalk
    },
    getTalk (subject, id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${this.$store.getters.api}/talk/${subject}/${id}`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject({ status: 1, details: error })
          })
      })
    },
    addAttributeItens (component, array, itens) {
      let obj

      if (typeof itens === 'undefined') {
        obj = component
      } else {
        obj = {
          component,
          itens
        }
      }

      const index = array.findIndex((x) => x.id === component.id)

      const itensAttr = array[index].component.itens

      itens.forEach((element) => {
        itensAttr.push(element)
      })

      array[index].component.itens = itensAttr
    },
    changeAttributeItens (values, array, args) {
      let object = null

      if (typeof args == 'undefined') {
        object = values
      } else {
        object = {
          attributeId: values.id,
          itens: args
        }
      }

      const index = array.findIndex((x) => x.id === object.attributeId)

      array[index].component.itens = object.itens
    },
    updateValue (id, array, value) {
      array[id] = value
    }
  }
}
