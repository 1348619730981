<template>
  <app-view>
    <scenario-list-view
      :data="data.talks"
      :scenario="getRouteScenario"
      :subject="getRouteSubject"
      :pagination="data.pagination"
      :loading="loading"
      v-on:notification-read="notificationRead"
      v-on:change-page="changePage"
    >
    </scenario-list-view>
  </app-view>
</template>

<script>
import EventBus from '../../eventBus'
import ScenarioListView from '@/talkture/scenario/list/ListView.vue'
const signalR = require('@microsoft/signalr')

export default {
  components: {
    ScenarioListView
  },

  data () {
    return {
      initialized: false,

      context: null,
      subjectData: {},
      talks: {},
      template: [],

      data: {
        context: null,
        attributes: [],
        subject: {},
        talks: [],
        pagination: {
          page: 1,
          per_page: 100,
          total: 0,
          option_per_page: [10, 25, 50, 100]
        }
      },

      loading: {
        ready: false,
        render: false,
        active: false,
        error: false
      },

      view: 1,

      connection: null
    }
  },

  created () {
    this.init()
    const token = this.$store.getters.token

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.$store.getters.api}/websocket`, {
        accessTokenFactory: () => token,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build()

    this.connection.start()
  },

  beforeMount () {
    if (this.attributes) {
      this.data.attributes = this.attributes
    }

    if (this.talk) {
      this.data.talks = this.talk
    } else if (
      this.$route.params.talk != 'add' &&
      typeof this.$route.params.talk != 'undefined'
    ) {
      this.data.talks = parseInt(this.$route.params.talk)
    }
  },

  mounted () {
    this.connection.on('SendNotification', data => {
      this.init()
    })

    EventBus.$on('read-notification', id => {
      const index = this.data.talks.findIndex(x => x.id == id)

      if (index != -1) this.data.talks[index].status = true
    })
  },

  methods: {
    selectView (id) {
      this.view = id

      this.init()
    },

    init () {
      this.loading.active = true
      this.loading.ready = false
      this.loading.render = false
      this.loading.error = false

      const params = {
        page: this.data.pagination.page,
        fetch: this.data.pagination.per_page,
        view: this.view
      }

      this.$http
        .post(`${this.$store.getters.api}/notifications/list/`, params)
        .then(({ data }) => {
          this.data.talks = data.itens

          this.data.pagination.total = data.totalResults

          this.loading.ready = true
          this.loading.active = false
        })
        .catch(error => {
          this.loading.active = false
          this.loading.error = true
        })
    },

    /* */

    notificationRead (obj) {
      const index = this.data.talks.findIndex(x => x.id === obj.id)

      if (index != -1) this.data.talks[index].status = true

      const params = {
        id: obj.id
      }

      this.$http
        .post(`${this.$store.getters.api}/notifications/read/`, params)
        .then(({ data }) => {
          EventBus.$emit('read-notification', obj.id)
        })
        .catch(error => {
          this.$store.dispatch('notification', {
            type: 'alert',
            text: 'Ocorreu um erro ao tentar marcar a notificação como lida.',
            error
          })
        })

      if (obj.subject && obj.talk) {
        this.$router.push({
          name: 'context_id',
          params: {
            scenario: 'talk',
            subject: obj.subject,
            talk: obj.talk
          }
        })
      } else if (obj.subject && !obj.talk) {
        this.$router.push({
          name: 'context_id',
          params: {
            scenario: 'grid',
            subject: obj.subject
          }
        })
      }
    },

    changePage () {
      this.init()
    },

    resetContext () {
      this.initialized = false
      this.context = null
      this.subjectData = {}
      this.talks = {}
      this.template = []
      this.data = {
        context: null,
        attributes: [],
        subject: {},
        talks: [],
        pagination: {
          page: 1,
          per_page: 100,
          total: 0,
          option_per_page: [10, 25, 50, 100]
        }
      }
      this.loading = {
        ready: false,
        render: false,
        active: false,
        error: false
      }
      this.connection = null
    }
  },

  computed: {
    getRouteScenario () {
      return this.scenario ? this.scenario : this.$route.params.scenario
    },

    getRouteSubject () {
      return this.subjectProp ? this.subjectProp : this.$route.params.subject
    },

    getRouteTalk () {
      return this.talk
        ? this.talk
        : this.$route.params.talk != 'add' &&
          typeof this.$route.params.talk != 'undefined'
          ? this.$route.params.talk
          : 0
    },

    getRouteQueryStatus () {
      return this.$route.query.isActive ? this.$route.query.isActive : 'true'
    }
  },

  watch: {
    scenario: function () {
      this.init()
    },

    filterJson: function () {
      this.callGridApi(true)
    }
  }
}
</script>
