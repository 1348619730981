<template>
  <ul :class="$style.list">
    <app-confirm-promise ref="confirm" />
    <Sortable
      :options="{ group: menuParent }"
      :list="menus"
      :itemKey="'id'"
      @start="dragging = true"
      @end="sortMenus"
    >
      <template #item="{ element, index }">
        <li
          :class="
            menus[index + 1] && !parent
              ? $style.listRelative
              : menus[index + 1] && parent
              ? $style.listParent
              : parent
              ? $style.listCircle
              : null
          "
        >
          <div
            :class="[
              $style.listItem,
              children ? $style.listItemChildren : null,
            ]"
          >
            <div :class="$style.listItemLabel" v-on:click="openEdit(index)">
              <label>{{ element.menuName || 'Menu sem título' }} </label>

              <div
                :class="$style.listItemInvalid"
                v-if="errorMessage(element) != null"
              >
                {{ errorMessage(element) }}
              </div>
            </div>

            <app-icon
              glyph="add"
              :class="$style.listItemAdd"
              v-on:click="newSubitem(element)"
            />
          </div>

          <transition name="fade-down">
            <app-menu
              v-if="element.menus.length > 0"
              :menus="element.menus"
              :menuParent="element"
              children
            />
          </transition>
        </li>
      </template>
    </Sortable>
  </ul>
</template>

<script>
import EventBus from '../../eventBus'
import { Sortable } from 'sortablejs-vue3'
import sortArray from '@/composables/useSortable'
import { defineAsyncComponent } from 'vue'
const AppMenu = defineAsyncComponent(() =>
  import('@/views/menu/components/Menu.vue')
)

export default {
  components: {
    AppMenu,
    Sortable
  },
  emits: ['update:menus'],
  props: {
    menus: Array,
    children: {
      type: Boolean,
      default: false
    },
    parent: {
      type: Boolean,
      default: false
    },
    menuParent: Object
  },

  inject: ['menuNewSubitem', 'transformParent'],

  methods: {
    sortMenus ({ oldIndex, newIndex }) {
      const menus = sortArray(this.menus, oldIndex, newIndex)
      this.$emit('update:menus', menus)
      this.dragging = false
    },
    openEdit (key) {
      const obj = {
        parent: this.menuParent ? this.menuParent : null,
        menu: this.menus[key],
        menuKey: key
      }

      EventBus.$emit('open-edit', obj)
    },

    async newSubitem (menu) {
      if (menu.menus.length == 0) {
        const confirm = this.$refs.confirm

        await confirm
          .open(
            'Deseja realmente transformar esse menu em um menu pai?',
            'red'
          )
          .then((response) => {
            this.transformParent(menu)
          })
          .catch((error) => false)

        confirm.close()
      } else {
        this.menuNewSubitem(menu)
      }
    },

    changeValue (key, e) {
      this.menus[key].menuName = e.target.value
    },

    errorMessage (menu) {
      if (menu.menus.length > 0) {
        if (!menu.menuName) return 'Nome inválido'
      } else {
        if (!menu.menuName) return 'Preencha o nome'
        if (!menu.scenario) return 'Selecione um cenário'
        if (!menu.subject) return 'Selecione um assunto'
        if (menu.filterIds.length > 0) {
          const some = menu.filterIds.some((x) => x == 0)

          if (some) {
            return 'Há campos de filtros vazios'
          }
        }
      }

      return null
    }
  }
}
</script>

<style lang="scss" module>
  $color-border: #bfbfbf;
  $color-border-nightly: $md-grey-900;

  .list {
    list-style: none;
    padding-left: $theme-padding * 2;

    background: $md-grey-300;

    :global(.nightlymode) & {
      background: $md-grey-800;
    }

    &--relative {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -22px;
        top: 5px;
        width: 3px;
        height: calc(100% - 10px);
        background: $color-border;

        :global(.nightlymode) & {
          background: $color-border-nightly;
        }
      }
    }

    &--circle {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        background: $color-border;
        border-radius: 50%;
        top: 15px;
        left: -15px;

        :global(.nightlymode) & {
          background: $color-border-nightly;
        }
      }
    }

    &--parent {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        background: $color-border;
        border-radius: 50%;
        top: 15px;
        left: -15px;

        :global(.nightlymode) & {
          background: $color-border-nightly;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: -12px;
        top: 23px;
        width: 3px;
        height: 100%;
        background: $color-border;

        :global(.nightlymode) & {
          background: $color-border-nightly;
        }
      }
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;

      padding: math.div($theme-padding, 2) 0;

      &--children {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -5px;
          left: -22px;
          width: 20px;
          height: 27px;
          border-left: 3px solid $color-border;
          border-bottom: 3px solid $color-border;
          border-bottom-left-radius: 50%;
          pointer-events: none;
          z-index: 0;

          :global(.nightlymode) & {
            border-left: 3px solid $color-border-nightly;
            border-bottom: 3px solid $color-border-nightly;
          }
        }
      }

      &__invalid {
        padding: 1px 5px;
        background: $md-red-500;
        color: white;
        font-family: 'Raleway';
        font-size: 1.1rem;
        font-weight: 400;
        border-radius: 4px;

        position: absolute;
        top: 2px;
        right: 0;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        transform: translate3d(100%, 1px, 10px);
        margin-right: -15px;
        min-width: 155px;
        text-align: center;

        &::before {
          content: '';
          position: absolute;
          top: 4px;
          left: 4px;
          margin-left: -14px;
          border: 5px solid transparent;
          border-right-color: $md-red-500;
        }
      }

      &:hover &__invalid {
        margin-right: -35px;
      }

      &__label {
        margin: 0;
        height: 25px;
        padding: math.div($theme-padding, 5) math.div($theme-padding, 2);

        color: black;
        @include font-sizer(13);
        font-family: 'Raleway', sans-serif;

        border-radius: 8px;
        background: white;

        cursor: pointer;

        //max-width: calc(100% - 500px);
        // text-overflow: ellipsis;
        // overflow: hidden;

        position: relative;

        :global(.nightlymode) & {
          background: $md-grey-900;
          color: white;
          font-weight: 500;
        }

        label {
          margin: 0;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;

          cursor: pointer;
        }
      }

      &__add {
        opacity: 0;

        $size: 17px;

        width: $size;
        height: $size;
        border-radius: 50%;
        margin-left: 5px;

        cursor: pointer;
        background: $color-border;

        fill: white;

        transition: 0.4s;

        :global(.nightlymode) & {
          background: $md-grey-900;
        }
      }

      &:hover &__add {
        opacity: 1;
      }
    }
  }
</style>
