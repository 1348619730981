<template>
  <div>
    <app-confirm-promise ref="confirm" />

    <div class="row no-gutters">
      <div class="col" :class="$style.file">
        <div class="row align-items-center no-gutters flex-nowrap">
          <div class="col-12">
            <app-button
              small
              icon="attach_file"
              label="Selecionar Arquivo"
              v-on:click="openFile()"
              :disabled="readonly"
            />
            <input
              :class="$style.fileInput"
              type="file"
              ref="file"
              v-on:change.stop="updateFile($event)"
              :accept="extensions.join(', ')"
              :readonly="readonly"
              :multiple="config.isMultiple"
            />
          </div>
        </div>
      </div>

      <div class="col-auto" v-if="listCurrent.length">
        <app-tooltip
          left
          label="Baixar todos os arquivos"
          v-on:click="downloadAllFile()"
          :class="$style.fileDownload"
        >
          <app-icon v-if="value.value" glyph="get_app" />
        </app-tooltip>
      </div>
    </div>

    <div v-if="list">
      <div :class="$style.list" v-if="listCurrent.length">
        <div
          class="row no-gutters flex-nowrap"
          v-for="(item, index) in listCurrent"
          :key="index"
          :class="[$style.listItem, item.error ? $style.listItemError : null]"
        >
          <div class="col" :class="$style.listName">
            <p>{{ item.name }}</p>
          </div>

          <div class="col-auto" :class="$style.listIcon" v-if="item.url">
            <app-tooltip
              label="Baixar arquivo"
              left
              v-on:click="downloadFile(item)"
            >
              <app-icon glyph="get_app" :class="$style.listIconDownload" />
            </app-tooltip>
          </div>

          <div class="col-auto" :class="$style.listIcon" v-if="!readonly">
            <app-tooltip
              label="Remover arquivo"
              left
              v-on:click="removeFile(index)"
            >
              <app-icon glyph="delete" :class="$style.listIconDelete" />
            </app-tooltip>
          </div>
        </div>
      </div>

      <template v-if="listNew.length">
        <p :class="$style.newfiles" v-if="config.isMultiple">Novos arquivos</p>
        <p :class="$style.newfiles" v-else>Novo arquivo</p>
      </template>

      <div :class="$style.list" v-if="listNew.length">
        <div
          class="row no-gutters flex-nowrap"
          v-for="(item, index) in listNew"
          :key="index"
          :class="[$style.listItem, item.error ? $style.listItemError : null]"
        >
          <div class="col-auto" :class="$style.listIcon" v-if="item.error">
            <app-tooltip
              right
              :label="
                'O upload desse arquivo não será realizado, pois a extensão é inválida. Envie arquivos ' +
                  extensions.join(', ') +
                  '.'
              "
              limited
            >
              <app-icon glyph="warning" :class="$style.listIconError" />
            </app-tooltip>
          </div>

          <div class="col" :class="$style.listName">
            <p>{{ item.name }}</p>
          </div>

          <div class="col-auto" :class="$style.listIcon" v-if="item.url">
            <app-tooltip
              label="Baixar arquivo"
              left
              v-on:click="downloadFile(item)"
            >
              <app-icon glyph="get_app" :class="$style.listIconDownload" />
            </app-tooltip>
          </div>

          <div class="col-auto" :class="$style.listIcon" v-if="!readonly">
            <app-tooltip
              label="Remover arquivo"
              left
              v-on:click="removeFileNew(index)"
            >
              <app-icon glyph="delete" :class="$style.listIconDelete" />
            </app-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <input

    :class="[

    $style.input,
    mode == 'form' ?  $style.inputForm : null,
    mode == 'form' && oldValue == '' ? $style.inputEmpty : null

    ]"

    :value="value"
    v-on:input="$emit('change-input', $event.target.value)"
    v-on:blur="blur"

    ref="input"

    :maxlength="config.maxlength"
    :required="config.required"
    :readonly="readonly"

    v-on:keydown.tab="tab"
    v-on:keydown.enter="enter"

    :type="type"
    :min="type == 'number' ? 1 : null"

    /> -->
</template>

<script>
import EventBus from '../../eventBus'

export default {
  data () {
    return {
      file: {
        name: null,
        type: null,
        url: null
      },

      list: [],
      forDelete: [],
      extensions: [
        '.pdf',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.xml',
        '.jpg',
        '.jpeg',
        '.png',
        '.bmp',
        '.gif'
      ]
    }
  },

  props: {
    mode: String,
    value: [String, Object, Array],

    attributeId: [String, Number],

    subjectIdentifier: String,

    config: {
      type: Object,
      default: () => {}
    },

    talkId: Number
  },

  beforeMount () {
    this.getList()
  },

  mounted () {
    this.checkValidity()
  },

  watch: {
    value: function (val) {
      if (!val) {
        this.clearFile()
      } else {
        this.getList()
      }
    },

    list: {
      handler: function (val) {
        this.checkValidity()
      },

      deep: true
    }
  },

  computed: {
    listCurrent () {
      return this.list.filter(x => x.url)
    },

    listNew () {
      return this.list.filter(x => x.url == null)
    },

    readonly () {
      return (
        this.config.readonly ||
        ((this.talkId != 0) & (typeof this.talkId != 'undefined')
          ? !this.config.actions.update
          : !this.config.actions.insert)
      )
    }
  },

  methods: {
    getList () {
      if (this.talkId && this.value) {
        if (!Array.isArray(this.value.value)) {
          this.list = this.value.value ? [this.value.value] : []
        } else {
          this.list = this.value.value ? this.value.value : []
        }
      } else {
        if (!Array.isArray(this.value)) {
          this.list = this.value ? [this.value] : []
        } else {
          this.list = this.value ? this.value : []
        }
      }
    },

    openFile () {
      this.$refs.file.click()
    },

    updateFile (e) {
      const _this = this
      const files = e.target.files
      let value =
        this.talkId && this.value
          ? this.value.value
            ? !Array.isArray(this.value.value)
                ? [this.value.value]
                : this.value.value
            : this.value
              ? this.value
              : []
          : this.value && Array.isArray(this.value)
            ? this.value
            : this.value
              ? [this.value]
              : []

      /* */

      const filesNumber = files.length < 20 ? files.length : 20

      for (let index = 0; index < filesNumber; index++) {
        const file = e.target.files[index]

        const FILE_READER = new FileReader()

        FILE_READER.readAsDataURL(file)

        if (value.findIndex(x => x.name == file.name) == -1) {
          FILE_READER.onload = () => {
            const checkExtension = file.name.split('.')

            const extension = checkExtension[
              checkExtension.length - 1
            ].toLowerCase()

            const error = this.extensions.includes('.' + extension)

            /* */

            if (!this.config.isMultiple) value = []

            value.push({
              name: file.name,
              type: file.type,
              url: null,
              data: error ? FILE_READER.result.split('base64,')[1] : null,
              error: !error
            })

            _this.$emit('change', value)
          }
        }
      }

      setTimeout(() => {
        this.getList()
      }, 300)
    },

    async removeFile (index) {
      const confirm = this.$refs.confirm

      this.$emit('change-zindex', 1000)

      await confirm
        .open('Deseja realmente remover esse arquivo?', 'red')
        .then(response => {
          setTimeout(() => {
            this.$emit('change-zindex', 0)
          }, 300)

          if (this.talkId) {
            if (!Array.isArray(this.value.value)) {
              this.$emit('delete-file', this.value.value)

              this.value.value = []
            } else {
              if (this.value.value[index].url) {
                this.forDelete.push(this.value.value[index].url)

                this.$emit('delete-file', this.value.value[index])
              }

              this.$delete(this.value.value, index)
            }
          } else {
            this.$delete(this.value, index)
          }
          this.$emit('delete-file-index', this.attributeId)
        })
        .catch(error => false)

      setTimeout(() => {
        this.$emit('change-zindex', 0)
      }, 300)

      /* */

      confirm.close()
      this.getList()
    },

    async removeFileNew (index) {
      const confirm = this.$refs.confirm

      this.$emit('change-zindex', 1000)

      await confirm
        .open('Deseja realmente remover esse arquivo?', 'red')
        .then(response => {
          setTimeout(() => {
            this.$emit('change-zindex', 0)
          }, 300)

          const data = this.listNew[index].data

          const indexFile = this.list.findIndex(x => x.data == data && !x.url)

          if (this.talkId) {
            this.$delete(this.value.value, indexFile)
          } else {
            this.$delete(this.value, indexFile)
          }
          this.$emit('delete-file-index', this.attributeId)
        })
        .catch(error => false)

      setTimeout(() => {
        this.$emit('change-zindex', 0)
      }, 300)

      /* */

      confirm.close()
      this.getList()
    },

    clearFile () {
      this.$refs.file.value = ''

      this.file.name = null
      this.file.type = null
      this.file.data = null

      this.$emit('change', null)
    },

    downloadAllFile () {
      this.$http
        .get(
          `${this.$store.getters.api}/value/file/${this.subjectIdentifier}/${this.talkId}/${this.attributeId}`,
          {
            responseType: 'blob'
          }
        )
        .then(({ data }) => {
          const a = document.createElement('a')

          const file = new Blob([data], {
            type: data.type
          })

          a.href = URL.createObjectURL(file)

          if (this.list.length == 1) {
            a.download = this.list[0].name
          } else {
            a.download = 'eMix.zip' // ${+new Date()}
          }

          a.click()
        })
        .catch(error => {})
    },

    downloadFile (item) {
      const name = item.name
      const url = item.url

      this.$http
        .get(`${this.$store.getters.api}${url}`, {
          responseType: 'blob'
        })
        .then(({ data }) => {
          const a = document.createElement('a')

          const file = new Blob([data], {
            type: data.type
          })

          a.href = URL.createObjectURL(file)

          a.download = `eMix_${name}` // ${+new Date()}

          a.click()
        })
        .catch(error => {
          console.log(error)
        })
    },

    checkValidity () {
      if (this.config.required) {
        Promise.resolve()
          .then(() => {
            const value = this.talkId ? this.value.value : this.value

            /* */

            if (typeof value == 'undefined') return Promise.reject()

            if (Array.isArray(value)) {
              if (!value.length) return Promise.reject()
            } else if (typeof value == 'object') {
              if (typeof value.url == 'undefined') return Promise.reject()
            } else {
              return Promise.reject()
            }

            this.$refs.file.setCustomValidity('')
          })
          .catch(() => {
            this.$refs.file.setCustomValidity('Selecione um arquivo')
          })
      }
    }
  }
}
</script>

<style lang="scss" module>

/* */

.newfiles {
  font-family: 'Roboto', sans-serif;
  @include font-sizer(13);
  color: black;
  font-weight: 500;

  margin: 10px;

  :global(.nightlymode) & {
    color: $md-grey-300;
  }
}

.file {
  position: relative;

  margin-top: math.div($theme-padding, 2);
  padding-right: $theme-padding * 2.5;

  border-radius: 50px 15px 15px 50px;

  transition: 0.2s ease;
  transition-property: background, border-color;

  &--active {
    background: rgba($md-blue-500, 0.2);
    border-color: $md-blue-500;
  }

  &__icon {
    position: relative;

    width: 100%;

    padding-bottom: 115%;

    background: $md-green-500;

    border-radius: 5px;
  }

  &__clear {
    $size: 18px;

    width: $size;
    height: $size;

    fill: $md-red-500;
    margin-right: math.div($theme-padding, 3);

    cursor: pointer;

    &--margin {
      margin-right: $theme-padding * 2;
    }
  }

  &__input {
    position: absolute;

    top: 0;
    left: 0;

    font-size: 2.3rem;

    opacity: 0;

    pointer-events: none;
  }

  &__label {
    padding-left: $theme-padding;

    font-family: 'Roboto', sans-serif;
    @include font-sizer(14);
    color: $md-grey-700;
    font-weight: 500;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__download {
    margin: 15px 0 0 10px;

    cursor: pointer;

    svg {
      fill: var(--theme-color-1);

      height: 28px;
      width: 28px;
    }
  }
}

.list {
  margin: 10px 0 0 0;
  border: 1px solid $md-grey-700;
  border-radius: 5px;

  &__item {
    border-bottom: 1px solid $md-grey-700;
    display: flex;
    align-items: center;

    :global(.nightlymode) & {
      border-bottom: 1px solid $md-grey-600;
    }

    &:last-child {
      border: 0px;
    }

    &--error {
      background: #ff4d5f42;
    }
  }

  &__name {
    display: flex;
    align-items: center;

    overflow: hidden;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(12);
    padding: 5px !important;

    color: #000;

    :global(.nightlymode) & {
      color: white;
    }

    p {
      margin: 0;
    }
  }

  &__icon {
    padding: 1px 0 1px 5px !important;
    cursor: pointer;

    display: flex;
    align-items: center;
    // border-left: 1px solid $md-grey-700;

    // :global(.nightlymode) & {

    //     border-left: 1px solid  $md-grey-600;

    // }

    &--delete {
      fill: $md-red-500;
      height: 22px;
      width: 22px;
    }
    &--download {
      fill: $md-green-500;
      height: 22px;
      width: 22px;
    }
    &--error {
      fill: $md-red-500;
      height: 22px;
      width: 22px;
    }
  }
}
</style>
