<template>
  <app-view>
    <transition name="fade">
      <app-modal
        size="720"
        v-if="talk.modal"
        v-on:close="closeTalk()"
        title="Resultado da Busca"
        :mode="talk.id ? 'talk' : null"
        :overflow="false"
      >
        <context
          name="scenario_talk"
          scenario="talk"
          mode="modal"
          :talk="talk.id"
          :subject-prop="talk.subject"
          v-on:close="closeTalk"
        />
      </app-modal>
    </transition>

    <app-box padding>
      <app-box margin>
        <app-title :label="`Resultados da Busca`" />
      </app-box>

      <transition name="fade-up" mode="out-in">
        <app-loader
          v-if="!ready"
          key="loading"
          :active="loading"
          :error="loadingError"
          v-on:reload="init()"
        />

        <app-box margin v-else-if="search.results.length" key="results">
          <ul :class="$style.resultsRow">
            <li
              :class="$style.resultsList"
              v-for="(result, index) in search.results"
              :key="result.id || index"
              v-on:click="openTalk(result)"
            >
              <div class="row align-items-center">
                <!-- <div class="col-auto pr-0">

                                    <app-icon glyph="search" :class="$style.resultsIcon" />

                                </div> -->

                <div class="col-12" :class="$style.resultsSubject">
                  {{ result.subjectName }}
                </div>

                <div
                  class="col"
                  v-for="data in result.values"
                  :key="data.subjectAttributeId"
                >
                  <div :class="$style.resultsLabel">
                    {{ data.attributeName }}
                  </div>
                  <div :class="$style.resultsText">{{ data.value }}</div>
                </div>
              </div>
            </li>
          </ul>

          <div class="row justify-content-center mt-5">
            <div class="col-auto">
              <app-pagination
                :length="search.total"
                :current-page="pagination.page"
                :per-page="pagination.per_page"
                :option-per-page="pagination.option_per_page"
                v-on:change-page="changePage"
                v-on:change-per-page="changePerPage"
              />
            </div>
          </div>
        </app-box>
      </transition>
    </app-box>
  </app-view>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Context = defineAsyncComponent(() => import('@/talkture/context/Context.vue'))

export default {
  components: {
    Context
  },

  data: () => ({
    subject: 0,
    subjectAttribute: 0,
    attribute: 0,

    list: false,

    search: {
      q: '',
      results: [],
      loading: false,
      timeout: null,
      fetch: 8,
      total: 0
    },

    talk: {
      modal: false,
      id: null
    },

    ready: false,
    loading: true,
    loadingError: false,

    /* */

    pagination: {
      page: 1,
      per_page: 10,
      total: 0,
      option_per_page: [10, 25, 50, 100, 200, 500]
    }
  }),

  beforeMount () {
    Promise.resolve()
      .then(() => {
        this.search.q = this.$route.query.q

        this.subject = this.$route.query.subj
        this.subjectAttribute = this.$route.query.subj_attr
        this.attribute = this.$route.query.attr

        return Promise.resolve()
      })
      .then(() => {
        this.init()
      })
  },

  methods: {
    openTalk (talk) {
      this.talk.modal = true

      this.talk.id = talk.id
      this.talk.subject = talk.subject.toLowerCase()
    },

    closeTalk (payload) {
      this.talk.modal = false

      this.talk.id = null
      this.talk.subject = null

      if (payload) {
        if (['update', 'delete'].includes(payload.action)) {
          this.init()
        }
      }
    },

    init () {
      this.ready = false
      this.loading = true
      this.loadingError = false

      /* */

      this.search.total = 0

      /* */

      clearTimeout(this.search.timeout)

      this.search.timeout = setTimeout(
        () => {
          if (this.search.q.length) {
            this.$http
              .get(`${this.$store.getters.api}/talk/search`, {
                params: {
                  subjectId: this.subject,
                  subjectAttributeId: this.subject ? this.subjectAttribute : 0,
                  attributeId:
                    !this.subject && this.subjectAttribute
                      ? this.subjectAttribute
                      : 0,
                  page: this.pagination.page,
                  term: this.search.q,
                  fetch: this.pagination.per_page
                }
              })
              .then(({ data }) => {
                this.search.total = data.totalResults

                const results = data.itens.map(
                  ({ subjectName, subject, talkId, talkValues }) => ({
                    id: talkId,
                    subject,
                    subjectName,
                    values: talkValues.map(
                      ({ value, subjectAttributeId, attributeName }) => ({
                        attributeName,
                        subjectAttributeId,
                        value
                      })
                    )
                  })
                )

                /* */

                this.search.results = results.slice(0, this.pagination.per_page)

                /* */

                this.ready = true
                this.loading = false
                this.loadingError = false
              })
              .catch(error => {
                this.loading = false
                this.loadingError = true
              })
          } else {
            this.loading = false
            this.loadingError = true
          }
        },
        500,
        this
      )
    },

    initDelay () {
      clearTimeout(this.search.timeout)

      this.search.timeout = setTimeout(
        () => {
          this.init()
        },
        500,
        this
      )
    },

    changePage (value) {
      this.pagination.page = value
    },

    changePerPage (value) {
      this.pagination.per_page = value
    }
  },

  watch: {
    'pagination.per_page': function () {
      this.initDelay()
    },

    'pagination.page': function () {
      this.initDelay()
    }
  }
}
</script>

<style lang="scss" module>

/* */

.results {
  &__title {
    padding: $theme-padding;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(12);
    text-transform: uppercase;
    font-weight: 600;
    color: white;
  }

  &__subject {
    padding: 0 $theme-padding;
    margin-bottom: math.div($theme-padding, 2);

    font-family: 'Barlow', sans-serif;
    @include font-sizer(12);
    text-transform: uppercase;
    font-weight: 600;
    color: $md-grey-800;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__row {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__list {
    padding: $theme-padding;

    background: white;

    border-radius: 10px;

    cursor: pointer;

    :global(.nightlymode) & {
      background: darken($md-grey-800, 10%);
    }

    &:hover {
      background: $md-grey-50;

      :global(.nightlymode) & {
        background: darken($md-grey-800, 5%);
      }
    }

    &:not(:last-of-type) {
      margin-bottom: $theme-padding;
    }
  }

  &__icon {
    $size: 24px;

    width: $size;
    height: $size;

    fill: $md-grey-700;
  }

  &__label {
    margin-bottom: 3px;

    font-family: 'Roboto', sans-serif;
    @include font-sizer(12);
    font-weight: 500;
    color: $md-grey-500;
    line-height: 1;
  }

  &__text {
    font-family: 'Roboto', sans-serif;
    @include font-sizer(12);
    font-weight: 500;
    color: $md-grey-900;
    line-height: 1;

    :global(.nightlymode) & {
      color: white;
    }
  }
}
</style>
