<template>
  <app-config>
    <app-box>
      <app-loader
        v-if="loading.active || loading.error"
        :error="loading.error"
        :response="loading.response"
        key="loading"
        :active="loading.active"
        v-on:reload="init()"
      />
      <div :class="$style.padding" v-else>
        <app-box margin>
          <app-title label="Canais" />
        </app-box>
        <div :class="$style.list" class="mt-5">
          <div
            class="row no-gutters mb-4"
            :class="[$style.listItem]"
            v-for="(channel, i) in channels"
            :key="i"
          >
            <div class="col" :class="$style.listItemName">
              {{ channel.channelDescription }}
            </div>
            <div class="col-auto">
              <app-switch
                v-on:change="changeChannelState(channel.channelId, i)"
                :active="channel.isEnable"
                :class="$style.containerSwitch"
                v-if="!loaderState[i]"
              />
              <app-loader-spinner v-if="loaderState[i]" />
            </div>
          </div>
        </div>
      </div>
    </app-box>
  </app-config>
</template>

<script>
import AppConfig from '../config/Index.vue'
export default {
  components: {
    AppConfig
  },

  data () {
    return {
      testLoader: false,
      channels: {
        companyChannelId: '',
        channelId: '',
        channelDescription: '',
        isEnable: true
      },

      loaderState: [],

      loading: {
        active: false,
        error: false
      }
    }
  },

  mounted () {
    this.init()
  },
  computed: {
    checkloader () {
      return this.loaderState.every((iten) => iten == false)
    }
  },

  methods: {
    init () {
      this.callChannels()
    },
    async callChannels () {
      this.loading.active = true
      await this.$http
        .get(`${this.$store.getters.api}/Company/Channels`)
        .then((response) => {
          this.channels = response.data
          this.channels.forEach((element) => {
            this.loaderState.push(false)
          })
          this.loading.active = false
        })
    },

    async changeChannelState (companyChannelId, i) {
      if (this.loaderState.every((iten) => iten == false)) {
        this.loaderState[i] = true
        await this.$http
          .put(
              `${this.$store.getters.api}/Company/Channel/${companyChannelId}`,
              {}
          )
          .then(({ data }) => {
            this.loaderState[i] = false
            this.channels[i].isEnable = !this.channels[i].isEnable
            this.$store.dispatch('notification', {
              text: 'Estado do canal alterado com sucesso!',
              color: 'green'
            })
          })
          .catch((error) => {
            console.log(error)

            this.loaderState[i] = false

            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro ao alterar o estado do canal. Por favor, tente novamente.',
              error
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" module>
  .teste {
    width: 100%;
    height: 100vh;
    background-color: #0000005c;
    position: absolute;
    z-index: 1;
  }
  .padding {
    padding: $theme-padding * 4 $theme-padding * 5 $theme-padding * 14;
    min-height: calc(100vh - 135px);
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -15px;
  }

  .search {
    position: relative;

    &__input {
      position: absolute !important;
      right: 20px;

      width: 100%;

      z-index: 1;
    }

    &__icon {
      position: absolute;
      right: 27px;
      top: 7px;

      fill: black;
      cursor: pointer;

      z-index: 2;

      :global(.nightlymode) & {
        fill: white;
      }

      &--close {
        width: 18px;
        height: 18px;

        top: 10px;
        right: 27px * 2;
      }
    }
  }

  .scroller {
    min-width: 100%;
    overflow: auto;

    padding: 60px 75px 120px;
    min-height: calc(100vh - 65px);

    background: $md-grey-100;

    :global(.nightlymode) & {
      background: $md-grey-900;
    }
  }

  .title {
    @include font-sizer(28);
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    color: var(--theme-color-1);
  }

  .list {
    &__empty {
      @include font-sizer(12);
      font-family: 'Raleway';

      color: black;

      :global(.nightlymode) & {
        color: white;
      }
    }

    &__subject {
      @include font-sizer(16);
      font-family: 'Barlow';
      text-transform: uppercase;

      color: black;

      margin-bottom: 30px;

      :global(.nightlymode) & {
        color: white;
      }
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0px $theme-padding * 1.5;

      border-radius: 5px;

      background: white;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }

      &--active {
        background: $md-grey-400;
        box-shadow: inset 0 0 0 2px rgba(black, 0.15);

        cursor: pointer;

        :global(.nightlymode) & {
          background: lighten($md-grey-900, 5%);
          box-shadow: inset 0 0 0 2px rgba(black, 0.15);
        }
      }

      &--highlight {
        background: rgba(0, 0, 0, 0.1) !important;

        :global(.nightlymode) & {
          background: rgba(255, 255, 255, 0.5) !important;
        }
      }

      &__name {
        min-height: 60px;
        padding: 5px 0;
        display: flex;
        align-items: center;

        @include font-sizer(15);
        font-family: 'Raleway';

        color: black;
        word-break: break-word;

        cursor: pointer;

        :global(.nightlymode) & {
          color: white;
        }

        &--active {
          color: var(--theme-color-1) !important;
          font-weight: 500;
        }
      }

      &__delete {
        fill: $md-red-500;

        cursor: pointer;
      }
    }
  }

  .filter {
    position: absolute;
    top: 40px;
    right: $theme-padding;

    width: 300px;
    padding: $theme-padding * 1.5;

    border-radius: 7px;
    z-index: 9;

    background: white;

    filter: drop-shadow(0 0 40px rgba(black, 0.3));

    &::before {
      content: '';

      position: absolute;
      top: -14px;
      right: 17px;
      margin-left: -14px;
      border: 7px solid transparent;
      border-bottom-color: white;
    }

    :global(.nightlymode) & {
      background: $md-grey-700;

      &::before {
        border-bottom-color: $md-grey-700;
      }
    }
  }
</style>
