<template>
  <div>
    <div
      v-for="(area, key) in templateAreasMap"
      :key="key"
      :id="'area' + area.templateAreaId"
      :style="{
        zIndex: 500 - key + (arrayIndex[key] || 0),
        position: 'relative',
      }"
    >
      <div
        :class="[
          $style.area,
          !parent &&
          ((area.templateAreaSubjectAttributes.length > 1) ||
          (area.templateAreaSubjectAttributes.length == 1 && templateAreasMap.length > 1))
            ? $style.areaChildren
            : null,
        ]"
        v-if="area.templateAreaSubjectAttributes || area.templateAreasMap"
      >
        <legend
          :class="[$style.areaTitle, !parent ? $style.areaTitleChildren : null]"
          v-if="
            !parent && (
              (area.templateAreaName && area.templateAreasMap) ||
              (area.templateAreaSubjectAttributes.length > 1) ||
              (area.templateAreaSubjectAttributes.length == 1 && templateAreasMap.length > 1)
            )
          "
        >
          {{ area.templateAreaName }}
        </legend>

        <div
          v-if="area.templateAreaSubjectAttributes"
          :class="
            (area.templateAreaSubjectAttributes.length > 1) ||
            (area.templateAreaSubjectAttributes.length == 1 && templateAreasMap.length > 1)
              ? $style.container
              : ''
          "
        >
          <div
            v-for="(item, keyItem) in area.templateAreaSubjectAttributes"
            :key="keyItem"
          >
            <div :class="$style.containerFields">
              <scene-attribute-wrapper
                :templateName="templateName"
                :data="formValues[item.id] || ''"
                :attribute="item"
                :talkId="data.talks.length ? data.talks[0].id : 0"
                :subject="data.subject.name"
                :subjectIdentifier="data.subject.identifier"
                :filterParent="filterParent"
                :index="keyItem"
                mode="form"
                v-on:change="$emit('change', { attribute: item.id, value: $event })"
                v-on:change-select="$emit('change', { attribute: item.id, value: $event })"
                v-on:add-attribute-itens="addAttributeItens(item, $event)"
                v-on:open-modal-example="openModalExample()"
                v-on:change-data-columns="updateModalExample"
                v-on:change-attribute-itens="changeAttributeItens(item, $event)"
                v-on:change-zindex="changeZindex($event, key)"
                v-on:change-contingency="changeContingency"
              />

              <!-- funcionalidade de travar campo ocultada temporariamente -->
              <!-- <app-tooltip
                left
                :label="
                  newValuesLocked.includes(item.id)
                    ? 'Destravar campo'
                    : 'Travar campo'
                "
                :class="[
                  $style.containerFieldsLockfield,
                  newValuesLocked.includes(item.id)
                    ? $style.containerFieldsLockfieldActive
                    : null,
                ]"
                v-if="
                  ![15, 16, 17, 18, 22, 29, 31, 34].includes(
                    item.component.type,
                  ) &&
                    !item.component.config.isHidden &&
                    !data.talks.length &&
                    !item.component.config.natural &&
                    item.component.config.actions.insert
                "
                :name="'look_' + item.id"
              >
                <app-icon
                  :glyph="
                    newValuesLocked.includes(item.id) ? 'lock' : 'lock_open'
                  "
                  :size="20"
                  v-on:click="changeLockField(item.id)"
                />
              </app-tooltip> -->
            </div>
          </div>
        </div>

        <div v-else-if="area.templateAreasMap">
          <form-fieldset
            :templateName="templateName"
            :templateAreasMap="area.templateAreasMap"
            :formValues="formValues"
            :newValues="newValues"
            :data="data"
            :filterParent="filterParent"
            :newValuesLocked="newValuesLocked"
            v-on:change="$emit('change', $event)"
            v-on:change-select="$emit('change', $event)"
            v-on:add-attribute-itens="addAttributeItens($event)"
            v-on:change-attribute-itens="changeAttributeItens($event)"
            v-on:change-lock-field="changeLockField"
            v-on:change-contingency="changeContingency"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../eventBus'
import SceneAttributeWrapper from '@/talkture/scenario/components/AttributeWrapper.vue'

export default {
  components: {
    SceneAttributeWrapper
  },

  props: {
    templateName: String,
    templateAreasMap: Array,
    formValues: Array,
    data: Object,
    filterParent: Object,
    newValues: Array,
    parent: {
      type: Boolean,
      default: false
    },
    newValuesLocked: Array
  },

  data () {
    return {
      arrayIndex: []
    }
  },

  mounted () {
    EventBus.$on('move-to-area', id => {
      document
        .getElementById('area' + id)
        .scrollIntoView({ behavior: 'smooth' })
    })
  },

  methods: {
    verifyRulesChamado (attr) {
      this.$emit('verify-rules-chamado', attr)
    },

    changeContingency (data) {
      this.$emit('change-contingency', data)
    },

    changeLockField (id) {
      this.$emit('change-lock-field', id)
    },

    changeZindex (data, id) {
      this.arrayIndex.id = data
    },

    changeAttributeItens (attr, itens) {
      let object = null

      if (typeof itens == 'undefined') {
        object = attr
      } else {
        object = {
          attributeId: attr.id,
          itens: itens
        }
      }

      this.$emit('change-attribute-itens', object)
    },

    updateModalExample (value) {
      this.$emit('update-modal-example', value)
    },

    addAttributeItens (attr, itens) {
      let object

      if (typeof itens === 'undefined') {
        object = attr
      } else {
        object = {
          attributeId: attr.id,
          itens: itens
        }
      }

      this.$emit('add-attribute-itens', object)
    }

  }
}
</script>

<style lang="scss" module>

.calculo {
  margin-top: 25px;
  text-align: center;
  font-size: 1.6rem;
  color: var(--theme-color-1);
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
}

.area {
  margin-top: $theme-padding;

  &--children {
    margin-bottom: $theme-padding + 10px;

    background: rgba(0, 0, 0, 0.04);

    border-radius: 7px;

    :global(.nightlymode) & {
      background: rgba(0, 0, 0, 0.15);

      border-radius: 7px;
    }
  }

  &__title {
    // padding-left: $theme-padding;
    padding-right: $theme-padding;
    padding-top: $theme-padding;
    padding-bottom: math.div($theme-padding, 2);

    color: var(--theme-color-1);
    font-family: 'Raleway', sans-serif;

    margin: 0;

    @include font-sizer(18);

    &--children {
      padding: 25px 25px 20px;
      color: $md-grey-800;
      border-bottom: 2px solid $md-grey-300;
      font-size: 1.6rem;
      font-weight: 600;
      text-transform: uppercase;

      :global(.nightlymode) & {
        color: $md-grey-200;
        border-bottom-color: darken($md-grey-800, 7%);
      }
    }
  }
}

.container {
  padding: 10px 25px 30px;

  &__label {
    padding-bottom: $theme-padding;
  }

  &__box {
    width: 100%;

    position: relative;

    margin-bottom: $theme-padding * 2;
  }

  &__fields {
    --lockfield-opacity: 0;
    position: relative;

    &:hover{
      --lockfield-opacity: 1;
    }

    &__lockfield {
      position: absolute !important;
      right: 0px;
      top: 0px;
      width: 20px;
      height: 20px;
      fill: $md-grey-800;
      z-index: 999;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      transition-property: fill, opacity;
      opacity: var(--lockfield-opacity);

      :global(.nightlymode) & {
        fill: $md-grey-500;
      }

      &--active {
        fill: var(--theme-color-1) !important;
      }
    }
  }

  &__options {
    &__title {
      font-family: 'Barlow', sans-serif;
      @include font-sizer(20);
      text-transform: uppercase;
      line-height: 60px;
      font-weight: 600;

      color: #000;

      :global(.nightlymode) & {
        color: #fff;
      }
    }
  }
}
</style>
