<template>
  <div :class="$style.phone">
    <input
      :class="[
        $style.phoneInput,
        mode == 'form' || mode == 'filter-attr' ? $style.phoneInputForm : null,
        mode == 'form' && oldValue == '' ? $style.phoneInputEmpty : null,
      ]"
      :value="value"
      v-on:change.stop="$emit('change', $event.target.value)"
      v-on:blur="blur"
      ref="input"
      :maxlength="15"
      :required="config.required"
      :readonly="readonly"
      v-maska
      data-maska="['(##) ####-####', '(##) #####-####']"
      v-on:keydown.tab="tab"
      v-on:keydown.enter="enter"
      :tabindex="readonly ? -1 : null"
    />
    <div :class="$style.phoneLoading" v-if="loading">
      <app-loader-spinner />
    </div>
  </div>
</template>

<script>
import EventBus from '../../eventBus'

export default {
  data () {
    return {
      delay: null,
      loading: false,
      oldValue: null
    }
  },

  props: {
    mode: String,
    value: String,
    config: {},
    talkId: Number,

    focus: Boolean
  },

  beforeMount () {
    if (this.value) {
      this.oldValue = JSON.parse(JSON.stringify(this.value))
    }
  },

  mounted () {
    if ((this.mode !== 'form' || this.focus) && !this.readonly) {
      setTimeout(
        () => {
          this.$refs.input.focus()
        },
        1,
        this
      )
    }
  },

  computed: {
    readonly () {
      return (
        this.config.readonly || this.loading || ((!this.talkId && !this.config.actions.insert) || (this.talkId && !this.config.actions.update))
      )
    }
  },

  methods: {
    blur () {
      if (this.oldValue != this.value) {
        this.$emit('blur')
      }
    },

    tab () {
      EventBus.$emit('tab')
    },

    enter () {
      EventBus.$emit('enter')
    }
  },

  watch: {
    'config.params': {
      handler: function (newValue, oldValue) {
        if (this.config.params.value) {
          if (newValue.value !== oldValue?.value) {
            this.loading = true
            this.$http.get(`${this.$store.getters.api}${this.config.params.value}`)
              .then(({ data }) => {
                this.$emit('change', data.phoneNumber)
              })
              .catch((error) => {
                console.log(error)
              })
              .finally(() => {
                this.loading = false
              })
          }
        } else {
          this.$emit('change', '')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" module>

  $input-color: black;
  $input-color-nightly: white;
  $input-background: white;
  $input-background-nightly: $md-grey-900;

  .phone {
    position: relative;

    &__input{
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      padding: math.div($theme-padding, 2) $theme-padding;

      font-family: 'Roboto', sans-serif;
      @include font-sizer(12);
      font-weight: 400;
      color: $input-color;

      background: $input-background;

      box-shadow: inset 0 0 0 2px $md-blue-400;
      border-radius: 5px;

      :global(.nightlymode) & {
        color: $input-color-nightly;
        background: $input-background-nightly;
        box-shadow: inset 0 0 0 2px $md-blue-600;
      }

      &[readonly] {
        background: $md-grey-100;

        :global(.nightlymode) & {
          background: $md-grey-800;
        }

        cursor: default;
        pointer-events: none;
      }

      &--form {
        height: 50px;
        position: relative;

        box-shadow: inset 0 0 0 2px $md-grey-400;

        transition: 0.2s;
        transition-property: box-shadow;

        :global(.nightlymode) & {
          box-shadow: inset 0 0 0 2px $md-grey-600;
        }

        &:focus {
          box-shadow: inset 0 0 0 2px var(--theme-color-1);
        }
      }

      &--empty {
        padding-top: math.div($theme-padding, 2);
        padding-bottom: math.div($theme-padding, 2);
        padding-left: math.div($theme-padding, 2);
        padding-right: 40px;
      }
    }

    &__loading{
      position: absolute;
      left: 4px;
      top: 4px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      background: $md-grey-100;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }

      > div {
        justify-content: flex-start;
        padding-left: 10px;
        height: 100%;
      }
    }
  }
</style>
