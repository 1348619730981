<template>
  <app-config>
    <app-view>
      <transition name="fade">
        <app-modal size="480" v-if="modal.exclude.active" key="confirm_exclude">
          <app-confirm
            :title="modal.exclude.title"
            color="red"
            v-on:ok="handleExclude()"
            v-on:cancel="closeExcludeModal()"
            :loading="modal.exclude.loading"
            id="delete_room_confirm"
          />
        </app-modal>
      </transition>

      <app-box padding>
        <app-box margin>
          <app-title label="Todos os Perfis de Acesso" />
        </app-box>

        <div class="row">
          <div class="col">
            <app-button
              label="Novo Perfil"
              icon="group"
              small
              :class="$style.newbutton"
              v-on:click="$router.push('/config/access_profile/room')"
            />
          </div>
        </div>

        <app-loader
          v-if="loading || error.active"
          key="loading"
          :active="loading"
          :error="error.active"
          :response="error.response"
          v-on:reload="loadRooms"
        />

        <app-box v-else-if="rooms.length" key="results">
          <div :class="$style.usuarios">
            <ul :class="$style.usuariosRow" :id="$style.id_usuarios">
              <li
                :class="$style.usuariosList"
                v-for="item in rooms"
                :key="item.id"
              >
                <div :class="[$style.usuariosBox]">
                  <div class="row align-items-center no-gutters flex-nowrap">
                    <div class="col-auto col-md-11">
                      <router-link
                        :to="{
                          name: 'config_access_profile_edit',
                          params: {
                            id: item.roomId,
                          },
                        }"
                      >
                        <div
                          :class="$style.usuariosName"
                          :title="item.roomName"
                        >
                          {{ item.roomName }}
                        </div>
                      </router-link>
                    </div>

                    <div class="col-auto col-md-1">
                      <div :class="$style.usuariosActions">
                        <ul :class="$style.usuariosActionsRow">
                          <li :class="$style.usuariosActionsList">
                            <router-link
                              :to="{
                                name: 'config_access_profile_edit',
                                params: {
                                  id: item.roomId,
                                },
                              }"
                            >
                              <app-tooltip
                                label="Editar"
                                :name="'button_edit_' + item.roomId"
                              >
                                <app-icon
                                  glyph="edit"
                                  :class="$style.usuariosActionsIcon"
                                />
                              </app-tooltip>
                            </router-link>
                          </li>

                          <li :class="$style.usuariosActionsList">
                            <app-tooltip
                              label="Excluir"
                              :name="'button_delete_' + item.roomId"
                            >
                              <app-icon
                                glyph="delete"
                                :class="$style.usuariosActionsIcon"
                                v-on:click="openExcludeModal(item)"
                              />
                            </app-tooltip>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div class="row justify-content-center mt-5">
              <div class="col-auto">
                <app-pagination
                  :length="total"
                  :current-page="pagination.page"
                  :per-page="pagination.per_page"
                  :option-per-page="pagination.option_per_page"
                  v-on:change-page="changePage"
                  v-on:change-per-page="changePerPage"
                />
              </div>
            </div>
          </div>
        </app-box>

        <app-text v-else key="empty"
          >Nenhum perfil de acesso encontrado.</app-text
        >
      </app-box>
    </app-view>
  </app-config>
</template>

<script setup>
import AppConfig from '../Index.vue'
import { onKeyStroke } from '@vueuse/core'
import { useStore } from 'vuex'
import { ref, computed, isRef, unref, watchEffect } from 'vue'

const store = useStore()

const loading = ref(false)
const error = ref({ active: false, response: null })

const pagination = ref({
  page: 1,
  per_page: 10,
  option_per_page: [10, 25, 50, 100]
})
const changePage = (page) => {
  pagination.value.page = page
}
const changePerPage = (fetch) => {
  pagination.value.per_page = fetch
  changePage(1)
}

const rooms = computed(() => store.getters.getRooms.data)
const total = computed(() => store.getters.getRooms.total)

const loadRooms = async () => {
  const page = unref(pagination)?.page || 1
  const fetch = unref(pagination)?.per_page || 100

  try {
    loading.value = true
    error.value.active = false

    await store.dispatch('loadRooms', {
      page,
      fetch,
      type: 'list'
    })
  } catch (e) {
    error.value.active = true
    error.value.response = e.response
  } finally {
    loading.value = false
  }
}

isRef(pagination) ? watchEffect(loadRooms) : loadRooms()

const modal = ref({
  exclude: {
    active: false,
    title: false,
    loading: false,
    item: false
  }
})

const openExcludeModal = (item) => {
  modal.value.exclude.active = true
  modal.value.exclude.title = `Deseja excluir o perfil de acesso <b>${item.roomName}</b>?`
  modal.value.exclude.item = item
}
const closeExcludeModal = () => {
  modal.value.exclude.active = false
  modal.value.exclude.title = false
  modal.value.exclude.item = false
}

const handleExclude = async () => {
  try {
    modal.value.exclude.loading = true

    await store.dispatch('deleteRoom', modal.value.exclude.item.roomId)
    store.dispatch('notification', {
      text: `O perfil de acesso <strong>${modal.value.exclude.item.roomName}</strong> foi excluído com sucesso!`
    })

    if (pagination.value.page > 1 && rooms.value.length === 0) { pagination.value.page-- }
  } catch (e) {
    const message = e?.response?.data?.errors[0]?.Message || 'Ocorreu um erro ao excluir o perfil de acesso. Por favor, tente novamente.'

    store.dispatch('notification', {
      type: 'error',
      text: message
    })
  } finally {
    modal.value.exclude.loading = false
    closeExcludeModal()
  }
}

onKeyStroke('Escape', () => closeExcludeModal(), { eventName: 'keydown' })
</script>

<style lang="scss" module>
  #id {
    &_delete_confirm,
    &_usuarios {
      text-decoration: none;
    }
  }
  .newbutton {
    margin-bottom: $theme-padding * 2;
  }
  .usuarios {
    &__row {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__list {
      margin-bottom: $theme-padding;
    }

    &__box {
      padding: $theme-padding $theme-padding * 2.5 $theme-padding $theme-padding;
      background: white;
      border-radius: 5px;

      &--disabled {
        opacity: 0.5;
      }

      :global(.nightlymode) & {
        background: $md-grey-900;

        &--disabled {
          opacity: 0.3;
        }
      }
    }

    &__image {
      width: 100%;
      padding-bottom: 100%;
      background: $md-grey-200;
      background-size: cover;
      border-radius: 100%;
    }

    &__type {
      padding: 3px 10px;
      margin-bottom: 5px;
      font-family: 'Raleway', sans-serif;
      @include font-sizer(9);
      text-transform: uppercase;
      line-height: 2;
      letter-spacing: 1px;
      color: white;
      font-weight: 400;
      background: $md-purple-500;
      border-radius: 20px;
      display: inline-block;
    }

    &__name {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(20);
      line-height: 24px;
      color: black;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      :global(.nightlymode) & {
        color: white;
      }
    }

    &__email {
      margin-top: 5px;
      font-family: 'Raleway', sans-serif;
      @include font-sizer(14);
      line-height: 1;
      color: $md-grey-500;
      font-weight: 300;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__actions {
      &__row {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-end;
      }

      &__list {
        display: inline-block;

        &:not(:last-of-type) {
          margin-right: $theme-padding;
        }
      }

      &__icon {
        $size: 24px;
        width: $size;
        height: $size;
        fill: $md-grey-700 !important;
        cursor: pointer;
        :global(.nightlymode) & {
          fill: white !important;
        }
      }
    }
  }
</style>
