import dayjs from 'dayjs'
import ContextEventBus from '@/eventBus'

export default {
  data () {
    return {
      ticker: null,
      request: null,
      delay: null
    }
  },

  methods: {
    getSubjectAttributeId (subjectAttributeIdentifier) {
      const attribute = this.data.attributes.find(
        ({ identifier }) => identifier === subjectAttributeIdentifier
      )

      return typeof attribute !== 'undefined' ? attribute.id : null
    },

    getSubjectAttributeIdentifier (subjectAttributeId) {
      const attribute = this.data.attributes.find(
        ({ id }) => id === subjectAttributeId
      )

      return typeof attribute !== 'undefined'
        ? attribute.identifier
        : subjectAttributeId == 0
          ? 'timer'
          : null
    },

    async callContextTasks () {
      try {
        const { data } = await this.$http.get(
          `${this.$store.getters.api}/context/web/talkmodal/tarefa`
        )

        this.context = data.contextId
        this.subjectData = data.subject

        const actions = {
          insert: !!data.subject.actions.Create,
          update: !!data.subject.actions.Update,
          delete: !!data.subject.actions.Delete,
          deactivate: !!data.subject.actions.Deactivate,
          read: !!data.subject.actions.Read
        }

        if (
          data.relatedSubjects &&
          data.relatedSubjects.length > 0
        ) {
          this.subjectsRelateds = data.relatedSubjects.map((item) => {
            return {
              attributeId: item.subjectAttributeId,
              subjectIcon: item.relatedSubject.icon || 'view_column',
              subjectIdentifier: item.relatedSubject.subjectIdentifier,
              subjectName: item.relatedSubject.subjectName
            }
          })
        }

        this.tempActions = actions

        if (data.template) {
          this.$store.commit(
            'setTemplateName',
            data.template.templateName
          )
          this.$store.commit('setTemplateId', data.template.templateId)

          if (data.templateType == 6) {
            this.$store.commit(
              'setTemplateType',
              data.template.templateType
            )
          }

          this.template = data.template
          this.templateAreaTalk = this.template
            ? this.template.templateAreas
            : []
        } else {
          this.template = {
            templateAreas: []
          }

          this.templateAreaTalk = []
        }

        // Armazena resposta da requisição da estrutura do componente para não precisar refazê-la caso o usuário abra o Gerenciador de tarefas
        if (data) {
          this.$store.commit('setTaskSubjectData', data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    callTasks (search) {
      clearTimeout(this.delay)

      return new Promise((resolve, reject) => {
        this.delay = setTimeout(
          () => {
            const params = {
              isActive: true,
              page: this.data.pagination.page,
              fetch: this.data.pagination.per_page,
              scenario: 'Task'
            }

            if (search) {
              params.term = search
            }

            if (this.filterJson.length) {
              params.filterJson = JSON.stringify(this.filterJson)
            }

            if (this.talkParent != null) {
              params.talkParent = JSON.parse(JSON.stringify(this.talkParent))
            }

            if (this.getRouteSubject !== null) {
              /* ToDo: Implementar AbortController nessa requisição */
              this.$http
                .post(
                  `${this.$store.getters.api}/talk/filter/tarefa/`,
                  params
                )
                .then(({ data }) => {
                  this.talks = data

                  /* */

                  this.data.pagination.page = data.currentPage
                  this.data.pagination.total = data.totalResults

                  resolve()
                })
                .catch((error) => {
                  /* Request was manually aborted */
                  if (error?.status === 0) {
                    console.log(error)
                  } else {
                    reject(error)
                  }
                })
            }
          },
          300,
          this
        )
      })
    },

    callTask (id) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${this.$store.getters.api}/talk/tarefa/${id}`)
          .then(({ data }) => {
            resolve(this.transformTalk2(data))
          })
          .catch((error) => {
            console.log(error)

            reject({ status: 401, details: error })
          })
      })
    },

    callActiveTask () {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${this.$store.getters.api}/Talk/Task/InProgress`)
          .then(({ data }) => {
            const obj = { itens: [] }
            if (data.message) {
              obj.itens.push(data.message)

              this.data.pagination.page = 1
              this.data.pagination.total = 1
            }

            resolve(obj)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async activeTaskHandleFastTask (args) {
      if (args.init) {
        this.loading.taskInsertInit.active = true
        this.loading.taskInsertInit.caption = 'Salvando...'
      } else {
        this.loading.taskInsert = true
      }
      try {
        var talkId = await this.handleFastTask(args)

        if (args.init) {
          this.loading.taskInsertInit.caption = 'Iniciando...'
          await this.iniciar({ talkId: talkId })

          const newTalk = await this.callTask(talkId)

          this.data.talks[0] = newTalk

          window.clearInterval(this.ticker)

          this.tick(talkId)

          this.$store.dispatch('notification', {
            text: `Tarefa <strong>#${talkId}</strong> inserida e iniciada com sucesso!`
          })

          this.loading.taskInsertInit.active = false
        } else {
          this.$store.dispatch('notification', {
            text: `Tarefa <strong>#${talkId}</strong> inserida com sucesso!`
          })

          this.loading.taskInsert = false
        }

        this.$refs.active_task.toggleInsert()
      } catch (error) {
        /* */

        args.init
          ? (this.loading.taskInsertInit.active = false)
          : (this.loading.taskInsert = false)

        const _this = this
        const _window = window

        const errors = {
          300: function () {
            // Erro ao criar tarefa

            _this.$store.dispatch('notification', {
              type: 'error',
              text:
                'Tarefa não cadastrada. Tente novamente ou entre em contato com o administrador do sistema. '
            })
          },
          400: async function () {
            // Erro ao iniciar tarefa

            const task = await _this.callTask(talkId)

            _this.showErrors(error, 'activeTaskHandleFastTask')

            const attrs = _this.attributesModalEstimativas(task)

            if (attrs.length) {
              _this.$refs.active_task.openModalEstimativas(talkId, attrs)
            }

            const currentTask = await _this.callTask(_this.data.talks[0].id)

            _this.data.talks[0] = currentTask

            if (
              currentTask.values.find(
                (x) =>
                  x.subjectAttributeId ===
                    _this.getSubjectAttributeId('StatusTarefa') &&
                  x.value.name === 'Aguardando Execução'
              )
            ) {
              _window.clearInterval(_this.ticker)
            }
          },
          401: async function () {
            // Erro ao atualizar lista

            if (!args.init) {
              _this.$store.dispatch('notification', {
                text: 'O registro foi inserido com sucesso!'
              })
            } else {
              _this.$store.dispatch('notification', {
                text:
                  'A tarefa foi criada e iniciada, no entanto não foi possível atualizar o componente. Recarregando...'
              })
              _this.callTasksAPI()
            }

            _this.$refs.active_task.toggleInsert()
          }
        }

        if ([300, 400, 401].includes(error.status)) {
          errors[error.status]()
        } else {
          this.showErrors(error, null)
        }
      }
    },

    handleFastTask (args) {
      return new Promise((resolve, reject) => {
        const talk = args.talk
        const urlApiInsert = `${this.$store.getters.api}/talk/tarefa`

        // Neste caso talkId e assunto são setados automaticamente
        if (this.talkParent) {
          args.attributes.forEach((attr) => {
            if (attr.identifier.toLowerCase() == 'talkid') {
              talk[attr.id] = [
                {
                  id: this.$store.getters.activeTalkModal.id,
                  name: this.$store.getters.activeTalkModal.displayName
                }
              ]
            } else if (attr.identifier.toLowerCase() == 'assunto') {
              talk[attr.id] = [
                {
                  id: this.attributesTalkContext.subjectId,
                  name: this.attributesTalkContext.subjectName
                }
              ]
            }
          })
        }

        // Seta os campos select com o valor default vindos do backend
        args.attributes.forEach((attr) => {
          if (
            attr?.component?.config?.defaultValue &&
            ['statustarefa'].includes(
              attr.identifier.toLowerCase()
            )
          ) {
            talk[attr.id] = [attr.component.config.defaultValue]
          }
        })

        const newValues = JSON.stringify(this.transformTalkToSync(talk))

        this.$http
          .post(urlApiInsert, newValues)
          .then(({ data }) => {
            const taskId = data.resourceId

            resolve(taskId)
          })
          .catch((error) => {
            this.loading.taskInsert = false

            reject({ status: 300, details: error })
          })
      })
    },

    async startPointer (args) {
      const index = this.data.talks.findIndex(
        (value) => value.id === args.talkId
      )

      if (index > -1) {
        this.data.talks[index].loadingStatusRequest.start = true
      }

      const oldActiveTaskIndex = this.data.talks.findIndex((task) =>
        task.values.find(
          (val) =>
            val.identifier.toLowerCase() == 'statustarefa' &&
            val.value.name == 'Em Execução'
        )
      )

      try {
        await this.iniciar(args)

        const talkUpdate = await this.callTask(args.talkId)

        if (
          this.getRouteScenario == 'activetask' &&
          this.loading.taskInsertInit.active
        ) {
          this.loading.taskInsertInit.active = false
          this.$refs.active_task.toggleInsert()
        }

        if (index > -1) {
          this.data.talks[index] = talkUpdate

          this.data.talks[index].loadingStatusRequest.start = false
        } else if (this.getRouteScenario == 'activetask' && index == -1) {
          this.data.talks[0] = talkUpdate
        }

        window.clearInterval(this.ticker)

        this.tick(args.talkId)

        this.$store.dispatch('notification', {
          text: `Tarefa <strong>#${args.talkId}</strong> iniciada com sucesso!`
        })
      } catch (error) {
        /* */

        if (index > -1) {
          this.data.talks[index].loadingStatusRequest.start = false
        }

        args.init
          ? (this.loading.taskInsertInit.active = false)
          : (this.loading.taskInsert = false)

        const _this = this

        const errors = {
          400: function () {
            // Erro ao iniciar tarefa

            _this.showErrors(error, null)

            const attrs = _this.attributesModalEstimativas(args.talkId)

            if (attrs.length) {
              _this.$refs.active_task.openModalEstimativas(args.talkId, attrs)
            }
          },
          401: function () {
            // Erro ao atualizar lista

            _this.$store.dispatch('notification', {
              text: `A tarefa <strong>#${args.talkId}</strong> foi iniciada, mas não foi possível atualizar a lista. Recarregando...`
            })

            _this.callTasksAPI()
          }
        }

        if (
          typeof error != 'undefined' &&
          error.status &&
          [400, 401].includes(error.status)
        ) {
          errors[error.status]()
        } else {
          this.showErrors(error, null)
        }
      }
    },

    iniciar (params) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(`${this.$store.getters.api}/talk/tarefa/iniciar`, params)
          .then((response) => {
            resolve()
          })
          .catch((error) => {
            console.log(error)
            reject({ status: 400, details: error })
          })
      })
    },

    async pausePointer (args) {
      window.clearInterval(this.ticker)

      const index = this.data.talks.findIndex(
        (value) => value.id === args.talkId
      )

      this.data.talks[index].loadingStatusRequest.pause = true

      try {
        await this.pausar(args)
        const talkUpdate = await this.callTask(args.talkId)

        this.data.talks[index] = talkUpdate

        this.data.talks[index].loadingStatusRequest.pause = false

        this.$store.dispatch('notification', {
          text: `Tarefa <strong>#${args.talkId}</strong> pausada com sucesso!`
        })
      } catch (error) {
        console.log(error)

        if (error.status == 401) {
          this.$store.dispatch('notification', {
            text: `Tarefa <strong>#${args.talkId}</strong> pausada com sucesso!`
          })

          this.callTasksAPI()
        } else {
          this.$store.dispatch('notification', {
            type: 'error',
            text: `Tarefa <strong>#${args.talkId}</strong> não pausada. Entre em contato com o administrador ou tente novamente. `
          })
        }

        this.data.talks[index].loadingStatusRequest.pause = false
      }
    },

    pausar (params) {
      const urlApiPausePointer = `${this.$store.getters.api}/talk/tarefa/pausar`

      return new Promise((resolve, reject) => {
        this.$http
          .post(urlApiPausePointer, params)
          .then((response) => resolve())
          .catch((error) => reject({ status: 600, details: error }))
      })
    },

    async activeTaskEndTask (id) {
      const index = this.data.talks.findIndex((value) => value.id === id)

      this.data.talks[index].loadingStatusRequest.end = true

      try {
        await this.finalizar(id)

        window.clearInterval(this.ticker)

        this.data.talks.splice(0, 1)

        this.$store.dispatch('notification', {
          text: 'Registro finalizado com sucesso!'
        })
      } catch (error) {
        if (error.status == 500) {
          this.showErrors(error, null)

          const attrs = this.attributesModalMotivo(id)

          if (attrs.length) {
            this.$refs.active_task.openModalMotivo(id, attrs, true)
          }
        } else {
          this.$store.dispatch('notification', {
            type: 'error',
            text: `Não foi possível finalizar a tarefa <strong>#${id}</strong>. Tente novamente ou entre em contato com o administrador.`
          })
        }

        this.data.talks[index].loadingStatusRequest.end = false

        if (this.$refs.active_task.loadingRequest) {
          this.$refs.active_task.disableLoading()
        }
      }
    },

    finalizar (id) {
      const params = {
        talkId: id
      }

      return new Promise((resolve, reject) => {
        this.$http
          .post(`${this.$store.getters.api}/talk/tarefa/finalizar`, params)
          .then(async (response) => {
            resolve()
          })
          .catch((error) => {
            console.log(error)
            reject({ status: 500, details: error })
          })
      })
    },

    updateTaskMotivoAtraso (args) {
      const talk = args.talk

      this.attributesForm = args.attributes

      const urlApiUpdate = `${this.$store.getters.api}/talk/${talk.subject}/${talk.id}`
      const updateValues = JSON.stringify(
        this.transformTalkToSync(talk, talk.id)
      )

      return this.$http
        .put(urlApiUpdate, updateValues)
        .then(async (response) => {
          this.$store.dispatch('notification', {
            text: 'Motivo(s) atualizado(s) com sucesso!'
          })

          this.$refs.active_task.closeModalMotivo()
          await this.activeTaskEndTask(talk.id)
          this.$refs.active_task.toggleInsert()
        })
        .catch(async (error) => {
          this.$refs.active_task.disableLoading()

          this.showErrors({ details: error }, null)
        })
    },

    updateTaskEstimativas (args) {
      this.loading.taskInsertInit.active = true
      this.loading.taskInsertInit.caption = 'Atualizando...'

      const talk = args.talk

      this.attributesForm = args.attributes

      const urlApiUpdate = `${this.$store.getters.api}/talk/${talk.subject}/${talk.id}`
      const updateValues = JSON.stringify(
        this.transformTalkToSync(talk, talk.id)
      )

      return this.$http
        .put(urlApiUpdate, updateValues)
        .then(async (response) => {
          this.$refs.active_task.disableLoading()

          this.$store.dispatch('notification', {
            text: `Estimativa(s) atualizada(s) com sucesso! Iniciando tarefa <strong>#${talk.id}</strong>...`
          })

          this.$refs.active_task.closeModalEstimativas()
          this.loading.taskInsertInit.caption = 'Iniciando...'
          await this.startPointer({ talkId: talk.id })
        })
        .catch(async (error) => {
          this.$refs.active_task.disableLoading()

          this.showErrors({ details: error }, null)
        })
    },

    tick (id) {
      const index = this.data.talks.findIndex((x) => x.id == id)
      if (index > -1) {
        this.ticker = setInterval(() => {
          const seconds = this.hmsToSecondsOnly(
            this.data.talks[index].duringTime.time
          )

          this.data.talks[index].duringTime.time = this.formatTime(1 + seconds)
        }, 1000)
      }
    },

    formatTime (seconds) {
      // let measuredTime = new Date(null)
      // measuredTime.setSeconds(seconds)
      // let MHSTime = measuredTime.toISOString().substr(11, 8)

      return this.secondsToTime(seconds)
    },

    secondsToTime (secs) {
      let hours = Math.floor(secs / (60 * 60))
      hours < 10 ? (hours = `0${hours}`) : null

      const divisorForMinutes = secs % (60 * 60)
      let minutes = Math.floor(divisorForMinutes / 60)
      minutes < 10 ? (minutes = `0${minutes}`) : null

      const divisorForSeconds = divisorForMinutes % 60
      let seconds = Math.ceil(divisorForSeconds)
      seconds < 10 ? (seconds = `0${seconds}`) : null

      const obj = {
        h: hours,
        m: minutes,
        s: seconds
      }
      return `${obj.h}:${obj.m}:${obj.s}`
    },

    hmsToSecondsOnly (str) {
      const p = str.split(':')
      let s = 0
      let m = 1

      while (p.length > 0) {
        s += m * parseInt(p.pop(), 10)
        m *= 60
      }

      return s
    },

    showErrors (error, call) {
      Promise.resolve()
        .then(async () => {
          const errors =
            error.details?.response?.data?.Errors || error.details?.response?.data?.errors
          if (
            typeof errors !== 'undefined' &&
            !['activeTaskHandleFastTask', 'taskManagerHandleFastTask'].includes(
              call
            )
          ) {
            if (!errors.length) return Promise.reject()

            /* */

            const confirm = this.$refs.confirm

            confirm.alert = true

            await confirm
              .open(
                `

                            <strong>As seguintes falhas aconteceram durante a sincronização dos dados:</strong>

                            <p>

                                <ul>

                                    ${errors
                                      .map(
                                        (error) =>
                                          `<li>${error.Message ||
                                            error.message}</li>`
                                      )
                                      .join('')}

                                </ul>

                            </p>

                        `,
                'red'
              )
              .then((response) => true)
              .catch((error) => false)

            confirm.close()
          } else {
            return Promise.reject({ status: 400, details: null })
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)

          const _this = this

          const errors = {
            400: function () {
              if (
                call == 'taskManagerHandleFastTask' ||
                call == 'activeTaskHandleFastTask'
              ) {
                _this.$store.dispatch('notification', {
                  type: 'alert',
                  text:
                    'A tarefa foi criada, no entanto não foi possível iniciá-la.'
                })
              } else {
                _this.$store.dispatch('notification', {
                  type: 'error',
                  text:
                    'Ocorreu um erro ao tentar iniciar a tarefa. Por favor, tente novamente.',
                  error
                })
              }
            }
          }

          if (
            typeof error != 'undefined' &&
            error.status &&
            [400].includes(error.status)
          ) {
            errors[error.status]()
          } else {
            this.$store.dispatch('notification', {
              type: 'error',
              text:
                'Ocorreu um erro na sincronização dos dados. Por favor, tente novamente.',
              error
            })
          }
        })
    },

    attributesModalEstimativas (args) {
      const task =
        typeof args == 'object'
          ? args
          : this.data.talks.find((x) => x.id == args)

      const previsaoTermino = task.values.find(
        (x) =>
          x.subjectAttributeId ===
          this.getSubjectAttributeId('Previsaodetermino')
      )

      const duracaoEstimada = task.values.find(
        (x) =>
          x.subjectAttributeId === this.getSubjectAttributeId('Duracaoestimada')
      )

      const attrs = []

      if (!previsaoTermino) {
        attrs.push(
          this.data.attributes
            .filter(
              (attr) => attr.identifier.toLowerCase() == 'previsaodetermino'
            )
            .map((attr) => {
              attr.component.config.required = true
              return attr
            })[0]
        )
      }

      if (!duracaoEstimada) {
        attrs.push(
          this.data.attributes
            .filter(
              (attr) => attr.identifier.toLowerCase() == 'duracaoestimada'
            )
            .map((attr) => {
              this.attr.component.config.required = true
              return attr
            })[0]
        )
      }

      return attrs
    },

    attributesModalMotivo (id) {
      const task = this.data.talks.find((task) => task.id == id)

      const previsaoTermino = task.values.find(
        (item) => item.identifier.toLowerCase() == 'previsaodetermino'
      )
      const duracaoEstimada = task.values.find(
        (item) => item.identifier.toLowerCase() == 'duracaoestimada'
      )

      const attrs = []
      // console.log(previsaoTermino)
      // console.log(duracaoEstimada)

      if (previsaoTermino || duracaoEstimada) {
        // Verifica se terminou a tarefa dentro do estimado
        const prazoAtrasado = previsaoTermino
          ? dayjs().format('YYYY/MM/DD HH:mm') >
            dayjs(previsaoTermino.value).format('YYYY/MM/DD HH:mm')
          : null
        const duracaoAtrasada = task.duringTime.time > duracaoEstimada.value

        if (prazoAtrasado || duracaoAtrasada) {
          if (prazoAtrasado) {
            attrs.push(
              this.data.attributes
                .filter(
                  (attr) =>
                    attr.identifier.toLowerCase() == 'motivodeatrasodoprazo'
                )
                .map((attr) => {
                  attr.component.config.required = true
                  return attr
                })[0]
            )
          }

          if (duracaoAtrasada) {
            attrs.push(
              this.data.attributes
                .filter(
                  (attr) =>
                    attr.identifier.toLowerCase() ==
                    'motivodeatrasodaestimativa'
                )
                .map((attr) => {
                  attr.component.config.required = true
                  return attr
                })[0]
            )
          }
        }
      }

      return attrs
    }
  }
}
