export default {
  TALKTURE_ENCRYPT_HASH: '5OEAzPcqYPTd183TrjLLJExgvgUwMqi8',
  TALKTURE_NIGHTLYMODE_STORAGE: '7LS8htoFGm9zooihNUE8BWb1YsUaMhMB',
  TALKTURE_FULLSCREENMODE_STORAGE: '5AVycY9GAoOLH1QkNP7U9a7y0Hcy6rPA',
  TALKTURE_THEMECOLOR_STORAGE: 'dIBmzEm4MqjpppO62kciosUeYd17oxfI',
  TALKTURE_MENUACTIVE_STORAGE: 'mK9bdmJCCNqbuh5QL3QW2ZaMDMT6CrEo',
  TALKTURE_FONTSIZE_STORAGE: '8EXDN5bAelB9BQtA8EdCoiuiuIMj5Xm4',
  TALKTURE_TABS_STORAGE: '62NxwN5weXGFNaA6CCMdPhtlruDJZBIW',
  TALKTURE_TOKEN: 'nxRrKkJoueYU2waYj5EUyQgA7fmzGxct',
  TALKTURE_USER: '8q3D8Rzl0RE1FlihwnUibmMdghYGmRLk',
  TALKTURE_POSITION_HASH: '7a0de6e77e15f3d96ecdc116d0953047'
}
