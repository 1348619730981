<template>
  <app-view>
    <template v-slot:aside>
      <view-aside title="Configurações">
        <div :class="$style.config">
          <ul :class="$style.configRow" data-title="Usuários" v-if="isAdmin">
            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'config_users_list',
                }"
                :class="[
                  $style.configLink,
                  router == 'config_users_list' ? $style.configLinkActive : '',
                ]"
                >Todos os usuários</router-link
              >
            </li>

            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'config_users_add',
                }"
                :class="[
                  $style.configLink,
                  router == 'config_users_add' ? $style.configLinkActive : '',
                ]"
                >Adicionar novo usuário</router-link
              >
            </li>

            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'config_access_profile_list',
                }"
                :class="[
                  $style.configLink,
                  router == 'config_access_profile_list'
                    ? $style.configLinkActive
                    : '',
                ]"
                >Perfis de acesso</router-link
              >
            </li>
          </ul>

          <ul :class="$style.configRow" data-title="Cores e Acessibilidade">
            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'config_theme',
                }"
                :class="[
                  $style.configLink,
                  router == 'config_theme' ? $style.configLinkActive : '',
                ]"
                >Temas</router-link
              >
            </li>

            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'config_dark_mode',
                }"
                :class="[
                  $style.configLink,
                  router == 'config_dark_mode' ? $style.configLinkActive : '',
                ]"
                >Modo escuro</router-link
              >
            </li>
          </ul>

          <ul :class="$style.configRow" data-title="Sistema" v-if="isAdmin">
            <li :class="$style.configList" v-if="isAdminProfile">
              <router-link
                :to="{
                  name: 'automation_list',
                }"
                :class="[
                  $style.configLink,
                  router == 'automation_list' ? $style.configLinkActive : '',
                ]"
                >Automações</router-link
              >
            </li>

            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'menu',
                }"
                :class="[
                  $style.configLink,
                  router == 'menu' ? $style.configLinkActive : '',
                ]"
                >Menu</router-link
              >
            </li>

            <li :class="$style.configList" v-if="isAdminProfile">
              <router-link
                :to="{
                  name: 'tags_list',
                }"
                :class="[
                  $style.configLink,
                  router == 'tags_list' ? $style.configLinkActive : '',
                ]"
                >Tags</router-link
              >
            </li>
          </ul>

          <ul :class="$style.configRow" data-title="Templates" v-if="isAdmin">
            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'template_list_default',
                }"
                :class="[
                  $style.configLink,
                  router == 'template_list_default'
                    ? $style.configLinkActive
                    : '',
                ]"
                >Padrões</router-link
              >
            </li>

            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'template_list_custom',
                }"
                :class="[
                  $style.configLink,
                  router == 'template_list_custom'
                    ? $style.configLinkActive
                    : '',
                ]"
                >Personalizados</router-link
              >
            </li>

            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'template_list_users',
                }"
                :class="[
                  $style.configLink,
                  router == 'template_list_users'
                    ? $style.configLinkActive
                    : '',
                ]"
                >Usuários</router-link
              >
            </li>
          </ul>

          <ul
            :class="$style.configRow"
            data-title="Notificações"
            v-if="isAdmin"
          >
            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'notification_list',
                }"
                :class="[
                  $style.configLink,
                  router == 'notification_list' ? $style.configLinkActive : '',
                ]"
                >Gerenciar Notificações</router-link
              >
            </li>

            <li :class="$style.configList">
              <router-link
                :to="{
                  name: 'channels',
                }"
                :class="[
                  $style.configLink,
                  router == 'channels' ? $style.configLinkActive : '',
                ]"
                >Gerenciar Canais</router-link
              >
            </li>
          </ul>
        </div>
      </view-aside>
    </template>

    <slot />
  </app-view>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const viewAside = defineAsyncComponent(() => import('@/components/viewAside/ViewAside.vue'))

export default {
  components: { viewAside },

  computed: {
    isAdmin () {
      return this.$store.getters.user.data.isAdmin
    },

    isAdminProfile () {
      return this.$store.getters.user.data.roomId === '1'
    },

    router () {
      return this.$route.name
    },

    routerParams () {
      return this.$route.params
    }
  }
}
</script>

<style lang="scss" module>

.config {
  &__row {
    margin: 0;
    padding: 0;

    list-style: none;

    &:not(:last-of-type) {
      margin-bottom: $theme-padding * 2;
    }

    &::before {
      content: attr(data-title);

      margin-bottom: $theme-padding;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      font-weight: 700;
      color: var(--theme-color-1);
      text-transform: uppercase;

      text-align: right;

      display: block;
    }
  }

  &__list {
    &:not(:last-of-type) {
      margin-bottom: $theme-padding;
    }
  }

  &__link {
    position: relative;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(13);
    line-height: 1;
    font-weight: 400;
    color: black;
    text-align: right;

    display: block;

    transition: 0.2s ease;
    transition-property: padding-right;

    :global(.nightlymode) & {
      color: white;
    }

    &__active {
      transition: 0.4s ease;
      transition-property: padding-right;

      &::before {
        $size: 6px;

        content: '';

        position: absolute;

        top: 50%;
        right: -12px;

        width: $size;
        height: $size;

        margin-top: -2px;

        background: var(--theme-color-1);

        border-radius: $size;

        transition: 1s ease;
      }
    }
  }
}
</style>
