<template>
  <div class="row mt-5">
    <div class="col-12">
      <app-input-label :class="$style.boxLabel">Frequência</app-input-label>

      <app-select
        :fontType="2"
        :id="$style.id_frequency"
        reference="frequency"
        v-on:change-value="changeFrequency"
        :value="data.frequency"
        :data="[
          {
            id: 1,
            label: 'Semanal',
          },

          {
            id: 2,
            label: 'Mensal',
          },
        ]"
        :object-key="{
          id: 'id',
          label: 'label',
        }"
        style="z-index: 2"
        :required="readonly ? false : config.required"
        :class="readonly ? $style.disabled : null"
      />
    </div>

    <div class="col-12 mt-4 mb-4">
      <div
        v-if="data.frequency == 1"
        :class="$style.box"
        :id="$style.id_boxSemana"
      >
        <input
          type="text"
          :required="readonly ? false : true"
          :class="$style.boxHidden"
          :value="this.daysWeekSelecteds.join(';')"
          ref="inputWeeks"
        />

        <app-input-label :class="$style.boxLabel"
          >Dias da semana</app-input-label
        >

        <div
          v-for="day in daysWeek"
          :key="day.id"
          :class="[
            $style.boxDays,
            daysWeekSelecteds.includes(day.id.toString())
              ? $style.boxDaysActive
              : '',
            readonly ? $style.boxDaysDisabled : '',
          ]"
          v-on:click="selectDayWeek(day.id)"
        >
          {{ day.label }}
        </div>
      </div>

      <div
        v-else-if="data.frequency == 2"
        :class="$style.box"
        :id="$style.id_boxMes"
      >
        <input
          type="text"
          :required="readonly ? false : true"
          :class="$style.boxHidden"
          :value="this.daysMonthSelecteds.join(';')"
          ref="inputMonth"
        />

        <app-input-label :class="$style.boxLabel">Dias do mês</app-input-label>

        <div
          v-for="i in 31"
          :key="i"
          :class="[
            $style.boxDays,
            daysMonthSelecteds.includes(i.toString())
              ? $style.boxDaysActive
              : '',
            readonly ? $style.boxDaysDisabled : '',
          ]"
          v-on:click="selectDayMonth(i.toString())"
        >
          {{ i }}
        </div>

        <transition name="fade-down">
          <p
            v-if="
              daysMonthSelecteds.includes('29') ||
                daysMonthSelecteds.includes('30') ||
                daysMonthSelecteds.includes('31')
            "
            :class="$style.boxAlert"
          >
            Atenção! Em caso dos mês não possuir um dia que foi selecionado esse
            agendamento não será disparado.
          </p>
        </transition>
      </div>
    </div>

    <div class="col-12 mb-4">
      <app-input-label :class="$style.boxLabel">Período</app-input-label>

      <app-select
        :fontType="2"
        :id="$style.id_period"
        reference="period"
        v-on:change-value="changePeriod"
        :value="data.period"
        :data="[
          {
            id: 1,
            label: 'Horário especifico',
          },

          {
            id: 2,
            label: 'Intervalo de tempo',
          },
        ]"
        :object-key="{
          id: 'id',
          label: 'label',
        }"
        :required="readonly ? false : config.required"
        :class="readonly ? $style.disabled : null"
      />
    </div>

    <div class="col-12" v-if="data.period == 1">
      <!-- <app-input-label :class="$style.boxLabel">Horário</app-input-label> -->

      <app-input
        mask="time"
        :required="readonly ? false : true"
        :maxlength="10"
        :id="$style.id_hour"
        v-model:value="data.hour"
        :class="readonly ? $style.disabled : null"
      />
    </div>

    <div class="col-12" v-if="data.period == 2">
      <div class="row no-gutters align-items-center">
        <div class="col-2 mr-3">
          <app-input-label :class="$style.boxLabel">A cada</app-input-label>

          <div class="row no-gutters ">
            <div class="col-6 pr-4">
              <app-input
                :required="readonly ? false : true"
                :maxlength="10"
                :id="$style.id_timeinterval"
                v-on:input="changeTimeIntervalNumber"
                :value="data.timeinterval.number"
                :class="readonly ? $style.disabled : null"
                type="number"
                min="1"
                style="margin: 0"
              />
            </div>

            <div class="col-6">
              <app-select
                :fontType="2"
                :id="$style.id_frequency"
                reference="timeintervaltype"
                v-on:change-value="changeTimeIntervalType"
                :value="data.timeinterval.type"
                :data="[
                  {
                    id: 1,
                    label: data.timeinterval.number == '1' ? 'Hora' : 'Horas',
                  },
                  {
                    id: 2,
                    label:
                      data.timeinterval.number == '1' ? 'Minuto' : 'Minutos',
                  },
                ]"
                :object-key="{
                  id: 'id',
                  label: 'label',
                }"
                :required="readonly ? false : config.required"
                :class="readonly ? $style.disabled : null"
              />
            </div>
          </div>
        </div>

        <div class="col-1 mt-4">
          <app-input-label :class="$style.boxLabel"
            >Hora de Inicio</app-input-label
          >

          <app-input
            mask="time"
            :required="readonly ? false : true"
            :maxlength="10"
            :id="$style.id_hour"
            v-on:input="changeTimeIntervalInicialHour"
            :value="JSON.parse(JSON.stringify(data.timeinterval.inicialHour))"
            :class="readonly ? $style.disabled : null"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: {
        frequency: 1,

        hour: '',
        period: 1,

        timeinterval: {
          number: 1,
          type: 1,
          inicialHour: ''
        }
      },

      daysWeek: [
        {
          id: '1',
          label: 'Domingo'
        },
        {
          id: '2',
          label: 'Segunda-feira'
        },
        {
          id: '3',
          label: 'Terça-feira'
        },
        {
          id: '4',
          label: 'Quarta-feira'
        },
        {
          id: '5',
          label: 'Quinta-feira'
        },
        {
          id: '6',
          label: 'Sexta-feira'
        },
        {
          id: '7',
          label: 'Sábado'
        }
      ],

      daysWeekSelecteds: [],
      daysMonthSelecteds: []
    }
  },

  props: {
    value: String,

    config: Object,

    focus: Boolean,

    mode: String,

    talkId: Number
  },

  computed: {
    readonly () {
      return (
        this.config.readonly ||
        ((this.talkId != 0) & (typeof this.talkId != 'undefined')
          ? !this.config.actions.update
          : !this.config.actions.insert)
      )
    }
  },

  beforeMount () {
    // Normal
    // 0 55 15 ? * 2,5 *

    // Novo
    // 0 22 11/2 ? * 2,5 *

    // MIN
    // 0 08/3 16-23 ? * 1,3,5,7 *

    if (this.value != '') {
      const cron = this.value.split(' ')

      let split = []

      if (cron[2].includes('/')) {
        split = cron[2].split('/')

        this.data.period = 2

        this.data.timeinterval.number = split[1]
        this.data.timeinterval.type = 1
        this.data.timeinterval.inicialHour = split[0] + ':' + cron[1]
      } else if (cron[1].includes('/')) {
        split = cron[1].split('/')
        const splitHours = cron[2].split('-')

        this.data.period = 2

        this.data.timeinterval.number = split[1]
        this.data.timeinterval.type = 2
        this.data.timeinterval.inicialHour = splitHours[0] + ':' + split[0]
      } else {
        this.data.hour = cron[2] + ':' + cron[1]
      }

      if (cron[3] != '?') {
        this.data.frequency = 2

        this.daysMonthSelecteds = cron[3].split(',')
      } else {
        this.data.frequency = 1

        this.daysWeekSelecteds = cron[5].split(',')
      }
    }
  },

  mounted () {
    this.setValidationWeeks(this.daysWeekSelecteds)

    if (this.mode !== 'form' || this.focus) {
      setTimeout(
        () => {
          this.$refs.frequency.focus()
        },
        1,
        this
      )
    }
  },

  methods: {
    changePeriod (value) {
      this.data.period = value
      this.generateCron()
    },

    changeTimeIntervalNumber (value) {
      this.data.timeinterval.number = value
      this.generateCron()
    },

    changeTimeIntervalType (value) {
      this.data.timeinterval.type = value
      this.generateCron()
    },

    changeTimeIntervalInicialHour (value) {
      this.data.timeinterval.inicialHour = value
      // this.generateCron();
    },

    /* */

    changeHour (value) {
      this.data.hour = value
    },

    changeFrequency (value) {
      this.data.frequency = value

      this.generateCron()
    },

    selectDayWeek (day) {
      const index = this.daysWeekSelecteds.findIndex(x => x == day)

      if (index != -1) {
        this.daysWeekSelecteds.splice(index, 1)
      } else {
        this.daysWeekSelecteds.push(day)
      }

      this.generateCron()
    },

    selectDayMonth (day) {
      const index = this.daysMonthSelecteds.findIndex(x => x == day)

      if (index != -1) {
        this.daysMonthSelecteds.splice(index, 1)
      } else {
        this.daysMonthSelecteds.push(day)
      }

      this.generateCron()
    },

    generateCron () {
      if (this.data.period == 1) {
        const hora = JSON.parse(JSON.stringify(this.data.hour)).split(':')

        if (this.data.frequency == 1) {
          let week = ''

          const daysWeek = this.daysWeekSelecteds

          daysWeek.sort(function (a, b) {
            return a - b
          })

          week = daysWeek.join(',')

          this.$emit(
            'change-input',
            '0' +
              ' ' +
              (hora[1] ? hora[1] : '00') +
              ' ' +
              (hora[0] ? hora[0] : '00') +
              ' ? * ' +
              week +
              ' *'
          )
        } else {
          let month = ''

          const daysMonth = this.daysMonthSelecteds

          daysMonth.sort(function (a, b) {
            return a - b
          })

          month = daysMonth.join(',')

          this.$emit(
            'change-input',
            '0' +
              ' ' +
              (hora[1] ? hora[1] : '00') +
              ' ' +
              (hora[0] ? hora[0] : '00') +
              ' ' +
              month +
              ' * ? *'
          )
        }
      } else {
        const hora = JSON.parse(
          JSON.stringify(this.data.timeinterval.inicialHour)
        ).split(':')

        if (this.data.frequency == 1) {
          let week = ''

          const daysWeek = this.daysWeekSelecteds

          daysWeek.sort(function (a, b) {
            return a - b
          })

          week = daysWeek.join(',')

          if (this.data.timeinterval.type == 1) {
            this.$emit(
              'change-input',
              '0' +
                ' ' +
                (hora[1] ? hora[1] : '0') +
                ' ' +
                (hora[0] ? hora[0] : '0') +
                '/' +
                this.data.timeinterval.number +
                ' ? * ' +
                week +
                ' *'
            )
          } else if (this.data.timeinterval.type == 2) {
            this.$emit(
              'change-input',
              '0' +
                ' ' +
                (hora[1] ? hora[1] : '0') +
                '/' +
                this.data.timeinterval.number +
                ' ' +
                (hora[0] ? hora[0] : '0') +
                '-23' +
                ' ? * ' +
                week +
                ' *'
            )
          }
        } else {
          let month = ''

          const daysMonth = this.daysMonthSelecteds

          daysMonth.sort(function (a, b) {
            return a - b
          })

          month = daysMonth.join(',')

          if (this.data.timeinterval.type == 1) {
            this.$emit(
              'change-input',
              '0' +
                ' ' +
                (hora[1] ? hora[1] : '0') +
                ' ' +
                (hora[0] ? hora[0] : '0') +
                '/' +
                this.data.timeinterval.number +
                ' ' +
                month +
                ' * ? *'
            )
          } else if (this.data.timeinterval.type == 2) {
            this.$emit(
              'change-input',
              '0' +
                ' ' +
                (hora[1] ? hora[1] : '0') +
                '/' +
                this.data.timeinterval.number +
                ' ' +
                (hora[0] ? hora[0] : '0') +
                '-23' +
                ' ' +
                month +
                ' * ? *'
            )
          }
        }
      }
    },

    setValidationWeeks (value) {
      setTimeout(() => {
        if (typeof this.$refs.inputWeeks != 'undefined') {
          if (value.join(';') == '' && !this.readonly) {
            this.$refs.inputWeeks.setCustomValidity(
              'Selecione no mínimo um dia da semana'
            )
          } else {
            this.$refs.inputWeeks.setCustomValidity('')
          }
        }
      }, 200)
    },

    setValidationMonth (value) {
      setTimeout(() => {
        if (typeof this.$refs.inputMonth != 'undefined') {
          if (value.join(';') == '' && !this.readonly) {
            this.$refs.inputMonth.setCustomValidity(
              'Selecione no mínimo um dia do mês'
            )
          } else {
            this.$refs.inputMonth.setCustomValidity('')
          }
        }
      }, 200)
    }
  },

  watch: {
    value: function () {
      if (this.value == '') {
        this.data.frequency = 1
        this.data.hour = ''
        this.daysWeekSelecteds = []
        this.daysMonthSelecteds = []
      }
    },

    'data.hour': function () {
      const hora = JSON.parse(JSON.stringify(this.data.hour)).split(':')

      if (hora[0] > 23 && hora[1] > 59) {
        hora[0] = 23
        hora[1] = 59
      } else if (hora[0] > 23) {
        hora[0] = 23
      } else if (hora[1] > 59) {
        hora[1] = 59
      }
      this.data.hour = hora.join(':')

      this.generateCron()
    },

    'data.timeinterval.inicialHour': function () {
      const hora = JSON.parse(
        JSON.stringify(this.data.timeinterval.inicialHour)
      ).split(':')

      if (hora[0] > 23 && hora[1] > 59) {
        hora[0] = 23
        hora[1] = 59
      } else if (hora[0] > 23) {
        hora[0] = 23
      } else if (hora[1] > 59) {
        hora[1] = 59
      }
      this.data.timeinterval.inicialHour = hora.join(':')

      this.generateCron()
    },

    daysMonthSelecteds: {
      handler: function (value) {
        this.setValidationMonth(value)
      },
      deep: true
    },

    daysWeekSelecteds: {
      handler: function (value) {
        this.setValidationWeeks(value)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" module>

.disabled {
  pointer-events: none;

  div,
  input {
    color: #888;
    background: #eee !important;

    border-color: $md-grey-500 !important;

    :global(.nightlymode) & {
      color: #9e9e9e;
      background: #424242 !important;
      pointer-events: none;
    }
  }
}

#id {
  &_frequency,
  &_hour,
  &_boxMes,
  &_boxSemana {
    text-decoration: none;
  }
}

.box {
  display: flex;
  flex-wrap: wrap;

  &__label {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    @include font-sizer(12);
    font-weight: 400;
  }

  &__alert {
    width: 100%;
    padding-top: $theme-padding;
    margin: 0;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(12);
    font-weight: 600;
    color: $md-red-500;
  }

  &__days {
    width: 14.2%;
    padding: $theme-padding 0;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(12);
    font-weight: 500;
    color: $md-grey-900;

    text-align: center;

    border: 2px solid $md-grey-300;
    cursor: pointer;

    :global(.nightlymode) & {
      color: #ffffff;

      border: 2px solid $md-grey-700;
    }

    &--disabled {
      pointer-events: none;
    }

    &--active {
      background: var(--theme-color-1);
      color: #ffffff;
    }
  }

  &__hidden {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 50px;
    opacity: 0;
  }
}
</style>
