<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { MaskInput } from 'maska'
import Datepicker from '@vuepic/vue-datepicker'
import getScrollParent from '@/composables/useGetScrollParent'
import dayjs from 'dayjs'

const props = defineProps({
  value: {
    type: [String, Date],
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  activeHours: {
    type: Boolean,
    default: false
  },
  invalidMessage: {
    type: String,
    default: ''
  },
  disabledDates: {
    type: Function,
    default: () => {}
  },
  autoOpen: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['change'])

const store = useStore()

const format = 'dd/MM/yyyy' + (props.activeHours ? ' HH:mm' : '')
const placeholder = '__/__/____' + (props.activeHours ? ' __:__' : '')
const mask = '##/##/####' + (props.activeHours ? ' ##:##' : '')
const height = document.documentElement.clientHeight

const targetDatepicker = ref(null)
const targetContainer = ref(null)
const targetInput = ref(null)
const targetScrollParent = ref(null)

const date = computed({
  get: () => props.value,
  set: (newValue) => {
    if (dayjs(newValue).isSame(dayjs(props.value)) || newValue === props.value) return
    emit('change', newValue)
  }
})

watch(
  () => props.invalidMessage,
  (newvalue) => {
    targetInput.value.setCustomValidity(newvalue)
  }
)

onMounted(() => {
  if (props.autoOpen) {
    targetDatepicker.value?.openMenu()
  }

  targetInput.value = targetContainer.value.querySelector('input[name="datepickerInput"]')
  targetInput.value.setAttribute('placeholder', placeholder)
  const setMask = new MaskInput(targetInput.value, { mask })
})

const menuOpened = () => {
  targetScrollParent.value = getScrollParent(targetContainer.value)

  if (targetScrollParent.value) {
    targetScrollParent.value.addEventListener('scroll', closeMenuOnScroll, false)
  }
}

const closeMenuOnScroll = () => {
  targetScrollParent.value.removeEventListener('scroll', closeMenuOnScroll, false)
  targetDatepicker.value?.closeMenu()
}
</script>

<template>
  <div
    ref="targetContainer"
    :class="$style.datepicker"
  >
    <Datepicker
      ref="targetDatepicker"
      name="datepickerInput"
      locale="pt-BR"
      month-name-format="long"
      :clearable="false"
      :hide-input-icon="true"
      :input-class-name="$style.datepickerInput"
      :text-input="true"
      :hide-offset-dates="true"
      :week-start="0"
      :teleport="true"
      :format="format"
      :disabled-dates="disabledDates"
      :disabled="readonly"
      :required="required"
      :day-names="['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']"
      :position="height < 740 ? 'center' : 'left'"
      :teleport-center="height < 740"
      :enable-time-picker="activeHours"
      :dark="store.getters.nightlyMode"
      v-model="date"
      @open="menuOpened"
    >
      <template #action-select>
        <div :class="$style.datepickerActions">
          <app-tooltip label="Limpar data" right>
            <app-icon
              glyph="delete"
              @click="targetDatepicker.clearValue()"
            />
          </app-tooltip>
          <app-tooltip label="Aplicar data" right>
            <app-icon
              glyph="done"
              @click="targetDatepicker.selectDate()"
            />
          </app-tooltip>
        </div>
      </template>
    </Datepicker>
    <div v-if="invalidMessage" :class="$style.datepickerMessage">
      <span>{{ invalidMessage }}</span>
    </div>
  </div>
</template>

<style lang="scss" module>
// Mais ajustes e configurações em src/scss/_datepicker.scss e na documentação https://vue3datepicker.com/
.datepicker {
  position: relative;

  &__input {
    padding: math.div($theme-padding, 2) $theme-padding;
    width: 100%;
    height: 50px;
    background: #fff;
    color: #000;
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.2rem !important;
    border: 2px solid $md-grey-400 !important;
    border-radius: 5px;

    :global(.nightlymode) & {
      border-color: $md-grey-600 !important;
      color: #fff;
      background: $md-grey-900;
    }

    &:read-only {
      background: $md-grey-100;
      pointer-events: none;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }
    }

    &:focus {
      border-color: var(--theme-color-1) !important;
    }
  }

  &__actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    svg {
      width: 24px !important;
      height: 24px !important;
      cursor: pointer;
      fill: var(--dp-text-color);

      &:hover{
        --dp-text-color: var(--theme-color-1);
      }
    }
  }

  &__message {
    position: absolute;
    left: 15px;
    top: calc(100% - 12px);
    padding: 3px 5px;
    color: #fff;
    background: $md-red-700;
    border-radius: 3px;
    @include font-sizer(12);
    line-height: 1.2;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  }
}
</style>
