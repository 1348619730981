<template>
  <div :class="$style.step">
    <app-confirm-promise ref="confirm" />

    <div :class="$style.stepAlert" v-if="!getEventValidity">
      <div class="row no-gutters align-items-center justify-content-center">
        <div class="col-auto pr-2">
          <app-icon :class="$style.stepAlertIcon" glyph="warning" />
        </div>

        <div class="col-auto">
          <span :class="$style.stepAlertLabel"
            >Complete as configurações dos eventos acima antes de configurar as
            ações</span
          >
        </div>
      </div>
    </div>

    <div
      :class="[
        $style.stepBox,
        !getEventValidity ? $style.stepBoxDisabled : null,
      ]"
    >
      <transition name="fade">
        <app-modal
          :title="
            mode == 'events' ? 'Selecione um evento' : 'Selecione uma ação'
          "
          size="540"
          v-on:close="closeModalEventStepType"
          v-if="modal.eventOrWork.active"
        >
          <div :class="$style.stepItems">
            <ul :class="$style.stepItemsRow">
              <li
                :class="[
                  $style.stepItemsList,
                  modal.eventOrWork.data.id == data.id
                    ? $style.stepItemsListActive
                    : null,
                ]"
                v-for="(data, index) in getEventOrWork"
                :key="index"
                v-on:click="selectEventOrWork(data.id)"
              >
                <div :class="$style.stepItemsTitle">{{ data.title }}</div>
              </li>
            </ul>

            <div :class="$style.stepItemsButtons">
              <app-button label="OK" small v-on:click="applyEventOrWork()" />
            </div>
          </div>
        </app-modal>
      </transition>

      <div
        :class="[
          $style.stepHeader,
          active || !getEventOrWorkIdentifier ? $style.stepHeaderActive : null,
        ]"
        v-on:click="activateStep"
      >
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <div
              :class="[
                $style.stepHeaderIcon,
                mode == 'events'
                  ? $style.stepHeaderIconWhen
                  : $style.stepHeaderIconThen,
              ]"
            >
              <app-icon glyph="wifi" v-if="mode == 'events'" />
              <app-icon glyph="bolt" v-else-if="mode == 'works'" />
            </div>
          </div>

          <div class="col" v-if="!getEventOrWorkIdentifier">
            <div :class="$style.stepHeaderTitle">
              {{ mode == 'events' ? 'Quando acontecer' : 'Então faça' }}...
            </div>
          </div>

          <div class="col" v-else-if="getEventOrWorkIdentifier">
            <div :class="$style.stepHeaderDescription">
              {{ mode == 'events' ? 'Quando acontecer' : 'Então faça' }}...
            </div>
            <div :class="$style.stepHeaderTitle">{{ getEventOrWorkTitle }}</div>
          </div>

          <div class="col-auto pl-5" v-if="getEventValidity" @click.stop>
            <app-icon
              :class="$style.stepOptionsMore"
              glyph="more_vert"
              v-on:click="openOptions()"
            />

            <transition name="fade-up">
              <div :class="$style.stepOptions" v-if="options" ref="target">
                <ul :class="$style.stepOptionsRow">
                  <li
                    :class="$style.stepOptionsList"
                    v-on:click="activateStep()"
                  >
                    <div class="row align-items-center no-gutters">
                      <div class="col-auto pr-2">
                        <app-icon
                          glyph="settings"
                          :class="$style.stepOptionsIcon"
                        />
                      </div>

                      <div class="col">
                        <div :class="$style.stepOptionsLabel">
                          Configurações
                        </div>
                      </div>
                    </div>
                  </li>

                  <li
                    :class="$style.stepOptionsList"
                    v-on:click="openModalEventStepType()"
                  >
                    <div class="row align-items-center no-gutters">
                      <div class="col-auto pr-2">
                        <app-icon
                          glyph="autorenew"
                          :class="$style.stepOptionsIcon"
                        />
                      </div>

                      <div class="col">
                        <div :class="$style.stepOptionsLabel">
                          Alterar tipo de
                          {{ mode == 'events' ? 'evento' : 'ação' }}
                        </div>
                      </div>
                    </div>
                  </li>

                  <li
                    :class="$style.stepOptionsList"
                    v-on:click="removeStep()"
                    v-if="length > 1"
                  >
                    <div class="row align-items-center no-gutters">
                      <div class="col-auto pr-2">
                        <app-icon
                          glyph="delete"
                          :class="$style.stepOptionsIcon"
                        />
                      </div>

                      <div class="col">
                        <div :class="$style.stepOptionsLabel">
                          Remover {{ mode == 'events' ? 'evento' : 'ação' }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <transition name="fade-up-short" mode="out-in">
        <div
          :class="$style.stepEmpty"
          v-if="!getEventOrWorkIdentifier && getEventValidity"
        >
          <div :class="$style.stepEmptyText">
            {{
              mode == 'events'
                ? 'Nenhum evento configurado.'
                : 'Nenhuma ação configurada.'
            }}
          </div>

          <div :class="$style.stepEmptyWrapper">
            <app-button
              :label="mode == 'events' ? 'Adicionar evento' : 'Adicionar ação'"
              :color="mode == 'events' ? 'cyan' : 'amber-800'"
              small
              v-on:click="openModalEventStepType()"
            />
          </div>
        </div>

        <div
          :class="$style.stepConfig"
          v-else-if="getEventOrWorkIdentifier && active"
        >
          <filter-editor
            :events-data-raw="eventsDataRaw"
            :works-data-raw="worksDataRaw"
            :data="temp"
            :type-id="getEventOrWorkIdentifier"
            v-if="
              mode == 'events' &&
              [1, 3, 4, 5, 6, 7].includes(getEventOrWorkIdentifier)
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:reset-works="resetWorks()"
            v-on:validate="changeValid"
            v-on:change-cronstring="temp.cronString = $event"
            :key="`${timestamp}`"
            ref="filter"
          />

          <set-new-talk
            :events-data="eventsData"
            :data="temp"
            v-else-if="mode == 'works' && getEventOrWorkIdentifier == 'NewTalk'"
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            v-on:remove-subject-attribute="removeSubjectAttribute"
            :key="`${timestamp} NewTalk`"
          />

          <set-talk-value
            :events-data="eventsData"
            :data="temp"
            v-else-if="
              mode == 'works' &&
              ['SetTalkValueByEvent', 'SetTalkValueByFilter'].includes(
                getEventOrWorkIdentifier
              )
            "
            :filter="getEventOrWorkIdentifier == 'SetTalkValueByFilter'"
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            :key="`${timestamp} SetTalkValueByFilter`"
          />

          <set-talk-dialog
            :events-data="eventsData"
            :data="temp"
            v-else-if="
              mode == 'works' &&
              ['SetTalkDialog', 'SetTalkDialogByFilter'].includes(
                getEventOrWorkIdentifier
              )
            "
            :filter="getEventOrWorkIdentifier == 'SetTalkDialogByFilter'"
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            :key="`${timestamp} SetTalkDialogByFilter`"
          />

          <execute-parent-job
            :events-data="eventsData"
            :data="temp"
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier === 'ExecuteParentJob'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            v-on:remove-subject-attribute="removeSubjectAttribute"
            :key="`${timestamp} ExecuteParentJob`"
          />

          <set-talk-tag
            :data="temp"
            :events-data="eventsData"
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'SetTalkTag'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            v-on:remove-tag="removeTag"
            :key="`${timestamp} SetTalkTag`"
          />

          <remove-talk-tag
            :data="temp"
            :events-data="eventsData"
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'RemoveTalkTag'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            v-on:remove-tag="removeTag"
            :key="`${timestamp} RemoveTalkTag`"
          />

          <send-email
            :data="temp"
            :events-data="eventsData"
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'SendEmail'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            :key="`${timestamp} SendEmail`"
          />

          <send-notifications
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'Notification'
            "
            :data="temp"
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            :key="`${timestamp} Notification`"
          />

          <sita-integration
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'SitaIntegration'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            :key="`${timestamp} SitaIntegration`"
          />

          <comprovante-carregamento
            v-else-if="
              mode == 'works' &&
              getEventOrWorkIdentifier == 'ComprovanteCarregamento'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            :key="`${timestamp} ComprovanteCarregamento`"
          />

          <extrato-desova
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'ExtratoDesova'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            :key="`${timestamp} ExtratoDesova`"
          />

          <extrato-avaria
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'ExtratoAvaria'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            :key="`${timestamp} ExtratoAvaria`"
          />

          <comprovante-averbacao
            v-else-if="
              mode == 'works' &&
              getEventOrWorkIdentifier == 'ComprovanteAverbacao'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            :key="`${timestamp} ComprovanteAverbacao`"
          />

          <http-request
            :data="temp"
            :events-data="eventsData"
            v-else-if="
              mode == 'works' && getEventOrWorkIdentifier == 'HttpRequest'
            "
            v-on:ok="apply()"
            v-on:cancel="cancel()"
            v-on:validate="changeValid"
            v-on:remove-response="removeResponse"
            v-on:remove-condition="removeCondition"
            v-on:remove-action="removeAction"
            :key="`${timestamp} HttpRequest`"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
const SetNewTalk = defineAsyncComponent(() => import('./SetNewTalk.vue'))
const SetTalkValue = defineAsyncComponent(() => import('./SetTalkValue.vue'))
const SetTalkDialog = defineAsyncComponent(() => import('./SetTalkDialog.vue'))
const FilterEditor = defineAsyncComponent(() => import('./FilterEditor.vue'))
const SetTalkTag = defineAsyncComponent(() => import('./SetTalkTag.vue'))
const RemoveTalkTag = defineAsyncComponent(() => import('./RemoveTalkTag.vue'))
const SendEmail = defineAsyncComponent(() => import('./SendEmail.vue'))
const SendNotifications = defineAsyncComponent(() => import('./SendNotifications.vue'))
const SitaIntegration = defineAsyncComponent(() => import('./SitaIntegration.vue'))
const HttpRequest = defineAsyncComponent(() => import('./HttpRequest.vue'))
const ComprovanteCarregamento = defineAsyncComponent(() => import('./ComprovanteCarregamento.vue'))
const ComprovanteAverbacao = defineAsyncComponent(() => import('./ComprovanteAverbacao.vue'))
const ExtratoDesova = defineAsyncComponent(() => import('./ExtratoDesova'))
const ExtratoAvaria = defineAsyncComponent(() => import('./ExtratoAvaria'))
const ExecuteParentJob = defineAsyncComponent(() => import('./ExecuteParentJob'))

export default {
  setup () {
    const target = ref(null)
    const options = ref(false)
    const closeOptions = () => { options.value = false }

    onClickOutside(target, () => closeOptions())

    return { target, options, closeOptions }
  },
  components: {
    SetNewTalk,
    SetTalkValue,
    SetTalkDialog,
    FilterEditor,
    SetTalkTag,
    RemoveTalkTag,
    SendEmail,
    SendNotifications,
    SitaIntegration,
    HttpRequest,
    ComprovanteCarregamento,
    ComprovanteAverbacao,
    ExtratoDesova,
    ExtratoAvaria,
    ExecuteParentJob
  },

  data: () => ({
    valid: false,
    active: false,
    optionsTimeout: false,

    events: [
      {
        id: 1,
        title: 'Criação ou edição de registro'
      },

      {
        id: 5,
        title: 'Criação de registro'
      },

      {
        id: 6,
        title: 'Edição de registro'
      },
      {
        id: 3,
        title: 'Exclusão de registro'
      },

      {
        id: 4,
        title: 'Agendamento'
      },

      {
        id: 7,
        title: 'Criação de mensagem (dialog)'
      }

      // {
      //
      //     id : 2,
      //     title : 'Agendamento'
      //
      // }
    ],

    works: [
      {
        id: 'NewTalk',
        title: 'Cadastrar novo registro'
      },
      {
        id: 'SetTalkValueByEvent',
        title: 'Definir um valor nos registros que dispararem o evento'
      },

      {
        id: 'SetTalkValueByFilter',
        title:
              'Definir um valor nos registros que forem baseados em um filtro'
      },

      {
        id: 'SetTalkTag',
        title: 'Definir tags nos registros ou em atributos dos registros'
      },

      {
        id: 'RemoveTalkTag',
        title: 'Remover tags dos registros ou em atributos dos registros'
      },

      // {
      //
      //     id : 'Notification',
      //     title : 'Enviar uma notificação'
      //
      // },

      // {
      //
      //     id : 1,
      //     title : 'Criar um documento'
      //
      // },

      {
        id: 'SendEmail',
        title: 'Enviar um e-mail'
      },

      {
        id: 'Notification',
        title: 'Enviar notificações'
      },

      // {
      //   id: 'SitaIntegration',
      //   title: 'Integração SITA: Validar carga para averbação de DI'
      // },

      // {
      //   id: 'ComprovanteCarregamento',
      //   title: 'Gerar comprovante de agendamento em PDF'
      // },

      // {
      //   id: 'ComprovanteAverbacao',
      //   title: 'Gerar comprovante de averbação em PDF'
      // },

      // {
      //   id: 'ExtratoDesova',
      //   title: 'Gerar extrato de desova em PDF'
      // },

      {
        id: 'ExecuteParentJob',
        title: 'Executar automação para registro relacionado'
      },

      // {
      //   id: 'ExtratoAvaria',
      //   title: 'Gerar extrato de avaria em PDF'
      // },

      {
        id: 'HttpRequest',
        title: 'Requisição HTTP (JSON)'
      },

      {
        id: 'SetTalkDialog',
        title: 'Definir uma dialog nos registros que dispararem o evento'
      },

      {
        id: 'SetTalkDialogByFilter',
        title:
              'Definir uma dialog nos registros que forem baseados no filtro'
      }
    ],

    modal: {
      eventOrWork: {
        data: {},
        active: false
      }
    },

    temp: {},

    timestamp: null
  }),

  beforeMount () {
    this.timestamp = JSON.parse(JSON.stringify(+new Date()))
  },

  computed: {
    getEventValidity () {
      if (this.mode == 'works') {
        return this.eventsData.every(
          ({ eventStepType, filter, filterName }) => {
            if (!eventStepType || !filter || !filterName) return false

            return this.checkFilterValidity(filter)
          }
        )
      } else {
        return true
      }
    },

    getEventOrWork () {
      return this.mode == 'events'
        ? this.events
        : this.works.filter((val) => val)
    },

    getEventOrWorkIdentifier () {
      if (Object.entries(this.temp).length) {
        return this.mode == 'events'
          ? this.temp.eventStepType
          : this.temp.workStepType
      } else if (Object.entries(this.data).length) {
        return this.mode == 'events'
          ? this.data.eventStepType
          : this.data.workStepType
      } else {
        return null
      }
    },

    getEventOrWorkTitle () {
      let value
      if (this.getEventOrWorkIdentifier) {
        if (this.mode == 'events') {
          value = this.events.find(
            ({ id }) => id == this.getEventOrWorkIdentifier
          ).title
        } else {
          value = this.works.find(
            ({ id }) => id == this.getEventOrWorkIdentifier
          ).title
        }
      }

      return value
    }
  },

  props: {
    mode: {
      type: String,
      default: 'events'
    },

    data: {
      type: Object,
      default: () => ({})
    },

    eventsData: Array,
    eventsDataRaw: Object,
    worksDataRaw: Object,
    length: Number
  },

  methods: {
    changeValid (value) {
      this.valid = value
    },

    openOptions () {
      this.options = true
    },

    async activateStep (value) {
      if (this.active == false) {
        this.active = true
      } else {
        if (this.valid) {
          if ([1, 3, 5, 6].includes(this.getEventOrWorkIdentifier)) {
            this.$refs.filter.applyFilter()
          } else {
            this.apply()
          }
        } else if (
          this.getEventOrWorkIdentifier &&
              ![
                'SitaIntegration',
                'ComprovanteCarregamento',
                'ComprovanteAverbacao'
              ].includes(this.getEventOrWorkIdentifier) &&
              !value
        ) {
          const confirm = this.$refs.confirm

          await confirm
            .open(
              'Preencha todos os dados corretamente antes de continuar!',
              'orange',
              'alert'
            )
            .then((response) => {})
            .catch((error) => false)

          confirm.close()
        } else if (
          [
            'SitaIntegration',
            'ComprovanteCarregamento',
            'ComprovanteAverbacao',
            'ExtratoDesova',
            'ExtratoAvaria'
          ].includes(this.getEventOrWorkIdentifier)
        ) {
          this.active = false
        }
      }
    },

    openModalEventStepType () {
      this.modal.eventOrWork.active = true
    },

    closeModalEventStepType () {
      this.modal.eventOrWork.active = false
      this.modal.eventOrWork.data = {}
    },

    selectEventOrWork (id) {
      this.modal.eventOrWork.data.id = id
    },

    eventNetwork () {
      return new Promise((resolve) => {
        if (
          ['SetTalkTag', 'RemoveTalkTag'].includes(
            this.getEventOrWorkIdentifier
          ) &&
              ['SetTalkTag', 'RemoveTalkTag'].includes(
                this.modal.eventOrWork.data.id
              )
        ) {
          this.getOriginalData()

          const temp = JSON.parse(JSON.stringify(this.temp))

          temp.workStepId = null
          temp.workStepType = this.modal.eventOrWork.data.id

          this.temp = temp

          this.closeModalEventStepType()

          setTimeout(() => {
            this.timestamp = JSON.parse(JSON.stringify(+new Date()))

            resolve()
          }, 1)
        } else if (
          this.getEventOrWorkIdentifier !== this.modal.eventOrWork.data.id
        ) {
          if (this.mode == 'events') {
            const checkWorks =
                  this.modal.eventOrWork.data.id == 3 &&
                  this.getEventOrWorkIdentifier == 1 &&
                  this.worksDataRaw.steps.some(
                    (x) =>
                      !['SendEmail', 'SetTalkValueByFilter'].includes(
                        x.workStepType
                      )
                  )

            const confirm = this.$refs.confirm

            const verification = checkWorks
              ? confirm.open(
                'Algumas das ações configuradas são incompatíveis com o evento escolhido e serão removidas. Deseja realmente continuar?',
                'orange',
                'alert'
              )
              : Promise.resolve()

            const eventOrWorkData = JSON.parse(
              JSON.stringify(this.modal.eventOrWork.data)
            )

            if (checkWorks) this.closeModalEventStepType()

            verification
              .then((response) => {
                this.eventsDataRaw.once = false
                this.eventsDataRaw.negate = false

                this.temp = {
                  eventStepId: null,
                  eventStepType: eventOrWorkData.id,
                  filterName: null,
                  filter: null,
                  dialogTypeId: this.dialogTypeId ? this.dialogTypeId : null
                }

                if (checkWorks) {
                  this.worksDataRaw.steps =
                    JSON.parse(
                      JSON.stringify(
                        this.worksDataRaw.steps.filter((val) =>
                          ['SendEmail', 'SetTalkValueByFilter'].includes(
                            val.workStepType
                          )
                        )
                      )
                    )

                  if (!this.worksDataRaw.steps.length) {
                    this.worksDataRaw.steps = [
                      {
                        configs: [],
                        workStepId: null,
                        workStepType: null,
                        name: '',
                        filterName: null,
                        filter: null,
                        filterId: null
                      }
                    ]
                  }
                }
              })
              .catch((error) => false)

            confirm.close()
          } else {
            this.temp = {
              workStepId: null,
              name: '',
              workStepType: this.modal.eventOrWork.data.id,
              filter: null,
              filterName: null,
              configs: [],
              subject: null
            }
          }

          this.closeModalEventStepType()

          setTimeout(() => {
            this.timestamp = JSON.parse(JSON.stringify(+new Date()))

            resolve()
          }, 1)
        } else {
          this.closeModalEventStepType()

          resolve()
        }
      }).then(() => {
        this.active = true
      })
    },

    async applyEventOrWork () {
      return await this.eventNetwork()
    },

    apply () {
      Object.assign(this.data, JSON.parse(JSON.stringify(this.temp)))

      this.active = false
      this.temp = {}
    },

    cancel () {
      this.active = false
      this.temp = {}
    },

    removeStep () {
      this.$emit('remove')
    },

    removeSubjectAttribute (index) {
      this.data.configs.splice(index, 1)
    },

    removeTag (index) {
      this.data.configs.splice(index, 1)
    },

    removeAction ({ configIndex, responseIndex, actionIndex }) {
      this.data.configs[configIndex].onresponse[responseIndex].actions.splice(
        actionIndex,
        1
      )
    },

    removeCondition ({ configIndex, responseIndex, conditionIndex }) {
      this.data.configs[configIndex].onresponse[
        responseIndex
      ].conditions.splice(conditionIndex, 1)
    },

    removeResponse ({ configIndex, responseIndex }) {
      this.data.configs[configIndex].onresponse.splice(responseIndex, 1)
    },

    getOriginalData () {
      this.temp = Object.assign(
        {},
        this.temp,
        JSON.parse(JSON.stringify(this.data))
      )

      if (this.temp.configs) {
        this.temp.configs.forEach((config) => {
          if (typeof config.onResponse !== 'undefined') {
            config.onresponse = JSON.parse(JSON.stringify(config.onResponse))
            delete config.onResponse
          }
        })
      }
    },

    checkFilterValidity (filter) {
      if (Number.isInteger(filter)) return true

      /* */

      const subjects = JSON.parse(filter)

      if (!subjects) {
        return false
      }

      let result = ''

      subjects.map((subject) => {
        result = true

        if (subject.id == null) result = false

        if (subject.attributes) {
          subject.attributes.map((attribute) => {
            if (attribute.id == null) result = false

            if (attribute.operators) {
              attribute.operators.map((operator) => {
                if (operator.id == null) result = false

                if (operator.values) {
                  operator.values.map((value) => {
                    if ([9, 10].includes(value.type.id)) {
                      if (
                        !(
                          value.attribute.id ||
                              value.value.label ||
                              value.value.id
                        )
                      ) {
                        result = false
                      }
                    } else {
                      if (value.type.id == null) result = false
                    }
                  })
                }
              })
            }
          })
        }
      })

      return result
    },

    resetWorks () {
      this.$emit('reset-works')
    }
  },

  watch: {
    active: function (val) {
      this.$emit('toogle-active', val)

      if (val && !Object.entries(this.temp).length) {
        this.getOriginalData()
      }
    },

    data: {
      handler: function () {
        if (this.active) {
          this.getOriginalData()
        }
      },

      deep: true
    },

    options: {
      handler (val) {
        if (val) {
          clearTimeout(this.optionsTimeout)

          this.optionsTimeout = setTimeout(() => {
            this.optionsEvents = true
          }, 1)
        } else {
          this.optionsEvents = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" module>
  /* */

  .step {
    &__alert {
      padding: 5px 10px 15px;
      margin-bottom: -10px;

      background: rgba($md-red-500, 0.2);

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &__icon {
        $size: 18px;

        width: $size;
        height: $size;

        fill: $md-red-500;
      }

      &__label {
        font-family: 'Raleway', sans-serif;
        line-height: 1;
        font-weight: 400;
        @include font-sizer(13);
        color: black;

        :global(.nightlymode) & {
          color: white;
        }
      }
    }

    &__box {
      background: white;

      border-radius: 10px;
      border: 1px solid rgba(black, 0.05);

      :global(.nightlymode) & {
        background: $md-grey-900;
        border-color: $md-grey-900;
      }

      &--disabled {
        filter: grayscale(100%);

        pointer-events: none;

        border-top: none;
      }
    }

    &__header {
      padding: $theme-padding * 1.5;
      cursor: pointer;

      &--active {
        border-bottom: 1px solid rgba(black, 0.05);

        :global(.nightlymode) & {
          border-bottom-color: darken($md-grey-900, 5%);
        }
      }

      &__description {
        margin-bottom: 3px;

        font-family: 'Raleway', sans-serif;
        line-height: 1;
        font-weight: 400;
        @include font-sizer(14);
        color: $md-grey-700;

        :global(.nightlymode) & {
          color: $md-grey-400;
        }
      }

      &__title {
        font-family: 'Raleway', sans-serif;
        line-height: 1;
        font-weight: 700;
        @include font-sizer(22);
        color: black;

        :global(.nightlymode) & {
          color: white;
        }
      }

      &__icon {
        padding: 8px;
        margin-right: $theme-padding * 1.5;

        border-radius: 10px;
        border: 1px solid rgba(black, 0.1);

        :global(.nightlymode) & {
          border-color: rgba(black, 0.4);
        }

        &--when {
          transform: rotate(90deg);

          svg {
            fill: $md-cyan-500;
          }
        }

        &--then {
          svg {
            fill: $md-amber-500;
          }
        }
      }
    }

    &__empty {
      padding: $theme-padding * 2;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      &__text {
        width: 100%;

        margin-bottom: $theme-padding * 2;

        font-family: 'Raleway', sans-serif;
        line-height: 1;
        text-align: center;
        font-weight: 400;
        @include font-sizer(14);
        color: $md-grey-700;

        :global(.nightlymode) & {
          color: $md-grey-400;
        }
      }

      &__wrapper {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &__items {
      &__row {
        margin: 0;
        padding: 0;

        list-style: none;
      }

      &__list {
        padding: $theme-padding;

        cursor: pointer;

        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(black, 0.1);
        }

        &--active {
          background: rgba($md-blue-500, 0.1);
        }
      }

      &__title {
        font-family: 'Raleway', sans-serif;
        @include font-sizer(13);
        color: $md-grey-700;

        :global(.nightlymode) & {
          color: $md-grey-200;
        }
      }

      &__buttons {
        position: sticky;

        bottom: 0;

        padding: 15px;

        background: $md-grey-200;

        :global(.nightlymode) & {
          background: darken($md-grey-800, 5%);
        }
      }
    }

    &__options {
      position: absolute;

      top: 100%;
      right: 0;

      width: 200px;

      margin-top: 10px;
      margin-right: -10px;

      background: $md-grey-800;

      border-radius: 6px;

      z-index: 100;

      &::before {
        $size: 14px;

        content: '';

        position: absolute;

        top: -$size;
        right: $theme-padding;

        width: $size;
        height: $size;

        border: (math.div($size, 2)) solid transparent;
        border-bottom-color: $md-grey-800;
      }

      &__more {
        $size: 24px;

        width: $size;
        height: $size;

        fill: $md-grey-800;

        cursor: pointer;
      }

      &__row {
        margin: 0;
        padding: 0;

        list-style: none;
      }

      &__list {
        padding: 10px;

        cursor: pointer;
      }

      &__icon {
        $size: 18px;

        width: $size;
        height: $size;

        fill: white;
      }

      &__label {
        font-family: 'Raleway', sans-serif;
        @include font-sizer(12);
        color: white;
      }
    }
  }
</style>
