<template>
  <div>
    <app-confirm-promise ref="confirm" />
    <div :class="$style.root">
      <div :class="$style.input">
        <select-box
          :options="subjects"
          :selected="subject"
          :loading="loading"
          placeholder="Selecione um assunto"
          required
          v-on:select="selectModel"
        />
      </div>
      <div :class="$style.input" v-if="subject">
        <select-edit
          mode="form"
          :api="`talk/${subject}`"
          :isMultiple="true"
          :itensSelect="talks"
          :identifier="subject"
          :value="talksSelected"
          v-on:add-attribute-itens="addRelatedItens"
          v-on:change="change"
          v-on:change-attribute-itens="changeRelatedItens"
          v-on:change-obj="handleSelect"
        />
      </div>
    </div>
    <div :class="$style['button-container']">
      <app-button
        label="Salvar"
        icon="save"
        :loading="false"
        class="button"
        @click="setDelimitations"
      />
      <app-button
        label="cancelar"
        color="red"
        icon="close"
        :loading="false"
        class="button"
        @click="$emit('close')"
      />
      <app-button
        label="Limpar"
        color="grey-700"
        icon="replay"
        :loading="false"
        light
        class="button"
        @click="clear"
      />
    </div>
  </div>
</template>

<script>
import SelectBox from '@/components/select/SelectBox.vue'
import { defineAsyncComponent } from 'vue'
const selectEdit = defineAsyncComponent(() => import('@/talkture/scenario/components/select/SelectEdit.vue'))

export default {
  components: {
    SelectBox,
    selectEdit
  },
  data: function () {
    return {
      subjects: [],
      subject: '',
      talks: [],
      talk: {},
      talksSelected: [],
      loading: false
    }
  },

  props: {
    delimitation: Object
  },

  async beforeMount () {
    this.loading = true
    const { data } = await this.$http.get(`${this.$store.getters.api}/subject/`)
    this.loading = false
    this.subjects = data.itens.map(i => {
      return {
        id: i.subjectIdentifier,
        label: i.subjectName
      }
    })
  },

  methods: {
    setDelimitations () {
      if (!this.subject) {
        return this.$store.dispatch('notification', {
          text: 'Escolha um assunto para continuar',
          type: 'error'
        })
      }

      if (this.talksSelected.length === 0) {
        return this.$store.dispatch('notification', {
          text: 'Escolha pelo menos um item para continuar',
          type: 'error'
        })
      }

      const selectedSubject = this.subjects.find(
        ({ id }) => id === this.subject
      )
      this.$emit('getDelimitations', {
        identifier: this.subject,
        name: selectedSubject.label,
        itens: this.talksSelected
      })
    },

    clear () {
      this.subject = ''
      this.talks = []
      this.talksSelected = []
      this.talk = {}
    },

    async selectModel (id) {
      this.subject = id
      this.talks = []
      this.talksSelected = []
    },

    handleSelect (value) {
      const payload = {}
      if (value == '') {
        payload.value = {
          id: null,
          name: null
        }
      } else {
        const option = this.talks.find(({ id }) => id === value.id)
        payload.value = option
      }
    },

    change (value) {
      value.length > 1 ? (this.talksSelected = value) : false
    },

    changeRelatedItens (itens) {
      this.talks = []
      itens.forEach(item =>
        this.talks.push({
          id: item.talkId,
          name: item.displayName
        })
      )
    },

    addRelatedItens (itens) {
      itens.forEach(value => {
        this.talks.push({
          id: value.talkId,
          name: value.displayName
        })
      })
    }
  }
}
</script>

<style lang="scss" module>
.root {
  height: 400px;
  margin: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: initial;
}

.input {
  width: 100% !important;
  margin-bottom: 30px;
  position: relative;
}

.button-container {
  margin: auto 0 0 0 !important;
  display: flex;
  padding: 15px;
  justify-content: center;
  background: #ccc;
}
</style>
