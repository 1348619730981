import { createApp } from 'vue'
import { createStore } from '@/store'
import { createRouter } from './router/router'
import { registerGlobalComponents } from './globalComponents'
import tooltipDirective from '@/directives/tooltipDirective'
import hashes from './hashes'
import CryptoJS from 'crypto-js'
import App from './App.vue'
import axios from 'axios'
import { vMaska } from 'maska'
import { createPinia } from 'pinia'
import('@/assets/scss/styles.scss')
import('material-colors/dist/colors.var.css')

const router = createRouter()
const store = createStore(router)
const pinia = createPinia()

const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(store)

app.directive('maska', vMaska)

app.directive('tooltip', tooltipDirective)

app.config.globalProperties.$http = axios
app.config.performance = true

registerGlobalComponents(app)

// Adiciona token no cabeçalho das requisições
axios.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem('talkture_token') ||
      sessionStorage.getItem('talkture_token')

    if (token) config.headers.Authorization = `Bearer ${token}`

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

// Verifica validade do tolken
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    if (
      err.response &&
      [401, 403].includes(err.response.status) &&
      !err.response.config.url.includes('account/login') &&
      !err.response.config.url.includes('account/verifypasscode')
    ) {
      updateToken()
    }
    return Promise.reject(err)
  }
)

// Retorna token de autenticação
const updateToken = () => {
  const token =
    localStorage.getItem('talkture_token') ||
    sessionStorage.getItem('talkture_token')

  if (token) {
    axios
      .post(`${store.getters.api}/account/refresh`, { token })
      .then(({ data }) => {
        if (data.authorized) {
          const newToken = data.token

          store.getters.user.data.keepConnected
            ? localStorage.setItem('talkture_token', newToken)
            : sessionStorage.setItem('talkture_token', newToken)
          store.dispatch('setReady')
        } else throw new Error('Usuário não autorizado')
      })
      .catch(() => {
        store.dispatch('notification', {
          type: 'alert',
          text: 'Você não possui mais acesso ao sistema. Por favor, realize o login novamente.'
        })

        store.commit('logoutUser')
        store.dispatch('setReady')
      })
  } else {
    store.commit('logoutUser')
    store.dispatch('setReady')
  }
}

updateToken()

setInterval(() => {
  updateToken()
}, 60000 * 8)

// Recupera abas salvas no localStorage
const tabs = localStorage.getItem(hashes.TALKTURE_TABS_STORAGE)

if (tabs) {
  store.commit(
    'setOpenedPages',
    JSON.parse(
      CryptoJS.AES.decrypt(tabs, hashes.TALKTURE_ENCRYPT_HASH).toString(
        CryptoJS.enc.Utf8
      )
    )
  )
}

// Ativa modo escuro caso esteja configurado no perfil do usuário
const nightlyMode = localStorage.getItem(hashes.TALKTURE_NIGHTLYMODE_STORAGE)

if (nightlyMode) {
  store.commit(
    'setNightlyMode',
    CryptoJS.AES.decrypt(nightlyMode, hashes.TALKTURE_ENCRYPT_HASH).toString(
      CryptoJS.enc.Utf8
    )
  )
}

// Recupera cor base do tema do usuário
const themeColor = localStorage.getItem(hashes.TALKTURE_THEMECOLOR_STORAGE)

if (themeColor) {
  store.commit(
    'changePrimaryColor',
    JSON.parse(
      CryptoJS.AES.decrypt(themeColor, hashes.TALKTURE_ENCRYPT_HASH).toString(
        CryptoJS.enc.Utf8
      )
    )
  )
}

// Ativa modo de tela cheia caso esta configuração esteja salva no localStorage
const fullScreenMode = localStorage.getItem(
  hashes.TALKTURE_FULLSCREENMODE_STORAGE
)

if (fullScreenMode) {
  store.commit(
    'setFullscreenMode',
    CryptoJS.AES.decrypt(fullScreenMode, hashes.TALKTURE_ENCRYPT_HASH).toString(
      CryptoJS.enc.Utf8
    )
  )
}

// Recupera menu ativo salvo no localStorage
const menuActive = localStorage.getItem(hashes.TALKTURE_MENUACTIVE_STORAGE)

if (menuActive) {
  store.commit(
    'setMenuActive',
    CryptoJS.AES.decrypt(
      store.getters.fullscreenMode ? false : menuActive,
      hashes.TALKTURE_ENCRYPT_HASH
    ).toString(CryptoJS.enc.Utf8)
  )
}

// Recupera tamanho da fonte padrão escolhida pelo usuário
const fontSize = localStorage.getItem(hashes.TALKTURE_FONTSIZE_STORAGE)

if (fontSize) {
  store.commit(
    'setFontSize',
    CryptoJS.AES.decrypt(fontSize, hashes.TALKTURE_ENCRYPT_HASH).toString(
      CryptoJS.enc.Utf8
    )
  )
}

let positions = sessionStorage.getItem(hashes.TALKTURE_POSITION_HASH)

if (positions) {
  positions = CryptoJS.AES.decrypt(
    positions,
    hashes.TALKTURE_ENCRYPT_HASH
  ).toString(CryptoJS.enc.Utf8)
  positions = JSON.parse(positions)

  positions.map((item) => {
    store.commit('setPositionGrid', item)
  })
}

router.afterEach((to) => {
  if (
    ![
      'context',
      'context_id',
      'context_filter',
      'context_template_filter',
      'admin_mode'
    ].includes(to.name)
  ) {
    store.commit('openPage', to)
  }

  store.commit(
    'setCurrentPage',
    store.getters.pages.find((x) => x.fullPath == to.fullPath)
  )
})

store.commit('initialiseStore')

app.mount('#app')
