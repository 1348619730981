<template>
  <div>
    <app-select
      :id="$style.id_model"
      reference="model"
      v-on:change-value="changeModel"
      :value="idModel"
      :value-object="value"
      :data="dataFilter"
      :object-key="{
        id: 'templateId',
        label: 'templateName',
      }"
      :required="readonly ? false : config.required"
      :haveSearch="true"
      v-on:search="search"
      :class="readonly ? $style.disabled : null"
    />
  </div>
</template>

<script>
import EventBus from '../../eventBus'

export default {
  props: {
    value: [Number, String, Object],

    focus: Boolean,

    config: Object,

    mode: String,

    talkId: Number
  },

  data () {
    return {
      data: {
        value: {
          id: 0,
          label: ''
        },

        itens: []
      },

      term: ''
    }
  },

  methods: {
    changeModel (value) {
      const index = this.data.itens.findIndex(x => x.templateId == value)

      EventBus.$emit('change-model', this.data.itens[index].subjectIdentifier)
      this.$emit('change', value)
    },

    search (term) {
      this.term = term
    }
  },

  beforeMount () {
    let idModal = null

    if (typeof this.value == 'object') {
      idModal = this.value.id
    } else {
      idModal = this.value
    }

    if (this.value != 0) {
      this.$http
        .get(`${this.$store.getters.api}/report/model/${idModal}`)
        .then(({ data }) => {
          EventBus.$emit('change-model', data.subjects[0].subjectIdentifier)
          this.$emit('change', this.value)
        })
    }

    this.$http
      .get(`${this.$store.getters.api}/report/model/list/`)
      .then(({ data }) => {
        this.data.itens = data

        if (idModal != '') {
          const index = this.data.itens.findIndex(x => x.templateId == idModal)

          this.data.value.id = this.data.itens[index].templateId
          this.data.value.label = this.data.itens[index].templateName
        }
      })
  },

  mounted () {
    if (this.mode !== 'form' || this.focus) {
      setTimeout(
        () => {
          this.$refs.input.focus()
        },
        1,
        this
      )
    }
  },

  computed: {
    idModel () {
      if (typeof this.value == 'object') {
        return this.value.id
      }

      return this.value
    },

    dataFilter () {
      const itens = this.data.itens.filter(x =>
        x.templateName.toLowerCase().includes(this.term.toLowerCase())
      )

      return itens
    },

    readonly () {
      return (
        this.config.readonly ||
        ((this.talkId != 0) & (typeof this.talkId != 'undefined')
          ? !this.config.actions.update
          : !this.config.actions.insert)
      )
    }
  }
}
</script>

<style lang="scss" module>

/* */

.disabled {
  pointer-events: none;

  div {
    color: #888;
    background: #eee;

    border-color: $md-grey-500 !important;

    :global(.nightlymode) & {
      color: #9e9e9e;
      background: #424242;
      pointer-events: none;
    }
  }
}

#id {
  &_model {
    text-decoration: none;
  }
}
</style>
