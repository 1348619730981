<template>
  <div
    :class="[$style.select, disabled ? $style.selectDisabled : null]"
    :style="{
      zIndex: attributeType == 'date' ? 100000000 : null,
    }"
  >
    <transition name="fade">
      <div
        :class="[$style.selectLoader, small ? $style.selectLoaderSmall : null]"
        v-if="loading"
      >
        <div class="row no-gutters align-items-center">
          <div class="col-auto pr-3">
            <app-loader-spinner
              :style="{
                transform: small
                  ? 'scale(0.8) translate3d(-5px, -5px, 0)'
                  : null,
              }"
            />
          </div>

          <div class="col">Carregando dados...</div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        :class="[
          $style.selectSelected,
          small ? $style.selectSelectedSmall : null,
          iconPadding ? $style.selectSelectedIconPadding : null
        ]"
        v-if="
          (Object.entries(selectedData).length ||
            Object.entries(valueSelected).length) &&
            !active
        "
      >
        {{ selectedData.label || valueSelected.label }}
      </div>
    </transition>

    <select-field
      :class="[
        $style.selectInput,
        iconPadding ? $style.selectInputIconPadding : null
      ]"
      :placeholder="placeholder"
      :label="label"
      :focus="focus"
      :readonly="!filter"
      :small="small"
      v-model:value="search"
      v-on:focus="enableFilter"
      v-on:blur="disableFilter()"
      v-on:enter="selectFocused()"
      v-on:up="changeFocus('up')"
      v-on:down="changeFocus('down')"
      ref="input"
      :type="attributeType"
    />

    <div
      :class="[$style.selectIcon, small ? $style.selectIconSmall : null]"
      v-on:click="$refs.input.$refs.input.focus()"
      v-if="(options.length && !disabled) || valueSelected"
    >
      <app-icon
        :glyph="active ? 'arrow_drop_up' : 'arrow_drop_down'"
        :class="$style.selectIconGlyph"
      />
    </div>

    <transition
      :name="openToTop ? 'filter-fade-up' : 'filter-fade'"
      mode="in-out"
    >
      <div
        :class="[
          $style.selectWrapper,
          openToTop ? $style.selectWrapperTop : null,
        ]"
        v-if="active"
        ref="list"
        @mousedown.prevent
      >
        <ul
          :class="[$style.selectRow, openToTop ? $style.selectRowTop : null]"
          ref="options"
        >
          <li
            :class="[
              $style.selectList,
              itemIndex == index ? $style.selectListFocused : null,
              data.disabled ? $style.selectListDisabled : null,
            ]"
            v-for="(data, index) in optionsFiltered"
            v-on:click="!data.disabled ? handleSelected(data.id) : null"
            :key="
              `${data.id}${
                typeof data.columnTable !== 'undefined' ? data.columnTable : ''
              }`
            "
          >
            <div class="row no-gutters align-items-center">
              <div class="col">
                <app-tooltip top :label="labelDisabled" v-if="data.disabled">
                  <span
                    v-if="striptags(data.label)"
                    v-html="data.label"
                    :class="[
                      type !== 'value' && data.id == selected
                        ? $style.selectListSelected
                        : null,
                    ]"
                  ></span>

                  <span
                    v-else
                    :class="[
                      type !== 'value' && data.id == selected
                        ? $style.selectListSelected
                        : null,
                    ]"
                    >{{ data.label }}</span
                  >
                </app-tooltip>

                <div v-else>
                  <span
                    v-if="striptags(data.label)"
                    v-html="data.label"
                    :class="[
                      type !== 'value' && data.id == selected
                        ? $style.selectListSelected
                        : null,
                    ]"
                  ></span>

                  <span
                    v-else
                    :class="[
                      type !== 'value' && data.id == selected
                        ? $style.selectListSelected
                        : null,
                    ]"
                    >{{ data.label }}</span
                  >
                </div>
              </div>

              <div
                class="col-auto pl-2"
                v-if="type !== 'value' && data.id == selected && unselect"
              >
                <span v-on:click.stop="removeSelection()">
                  <app-tooltip left label="Remover seleção">
                    <app-icon glyph="close" :class="$style.selectRemove" />
                  </app-tooltip>
                </span>
              </div>
            </div>
          </li>

          <li
            :class="[$style.selectList, $style.selectListEmpty]"
            v-if="
              !optionsFiltered?.length &&
                search.length &&
                !loading &&
                type !== 'value'
            "
            key="empty_search"
          >
            Nenhum resultado encontrado para <b>"{{ search }}"</b>.

            <app-button
              label="Limpar Busca"
              color="blue"
              v-on:click="resetSearch()"
              small
              class="mt-5"
            />
          </li>

          <li
            :class="[$style.selectList, $style.selectListEmpty]"
            v-if="
              !optionsFiltered?.length &&
                !search?.length &&
                !loading &&
                type !== 'value'
            "
            key="empty"
          >
            Nenhum item para ser exibido
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import striptags from 'striptags'
import { orderBy } from 'lodash-es'
import SelectField from './SelectField.vue'

export default {
  components: {
    SelectField
  },

  data () {
    return {
      search: '',
      active: false,
      timeout: null,
      itemIndex: 0,
      validation: true,
      striptags
    }
  },

  props: {
    focus: Boolean,
    placeholder: String,
    label: String,
    labelDisabled: {
      type: String,
      default: 'Este item não pode ser selecionado.'
    },
    selected: [String, Number],
    unselect: Boolean,
    selectedRemove: Array,
    options: {
      type: [Array, Function],
      required: true
    },
    filter: {
      type: Boolean,
      default: true
    },
    filterExact: Boolean,
    loading: Boolean,
    order: Boolean,
    type: String,
    attributeType: {
      type: String,
      default: 'text'
    },
    required: Boolean,
    disabled: Boolean,
    small: Boolean,
    valueSelected: {
      type: Object,
      default: () => ({})
    },
    openToTop: {
      type: Boolean,
      default: false
    },
    iconPadding: {
      type: Boolean,
      default: false
    }
  },

  beforeMount () {
    this.getSearch()
  },

  mounted () {
    setTimeout(() => {
      if (this.required && (this.selected == 0 || this.selected == null)) {
        this.$refs.input.$refs.input.setCustomValidity(
          'É obrigatório selecionar um item da lista.'
        )
      }
    }, 1000)
  },

  computed: {
    selectedData () {
      const option = this.options.find(({ id }) => this.selected == id)

      return typeof option !== 'undefined' ? option : {}
    },
    optionsOrdered () {
      const fields = [value => value.id !== this.selected]
      const order = ['asc']

      if (this.order) {
        fields.push('label')
        order.push('asc')
      }

      return orderBy(this.options, fields, order)
    },
    optionsFiltered () {
      let array = []

      if (this.filter) {
        const _this = this
        const keywords = this.search.toLowerCase().split(' ')
        let items = this.optionsOrdered.filter(({ label }) => {
          return keywords.every(keyword =>
            label.toLowerCase().includes(keyword)
          )
        })

        if (this.search.length) {
          items = items.slice(0, 100)
        }

        array = items.map(item => ({
          id: item.id,
          type: item.type,
          label: _this.search.length
            ? item.label.replace(
              new RegExp(`(${keywords.join('|')})`, 'gi'),
              '<b>$1</b>'
            )
            : item.label,
          columnTable: item.columnTable,
          disabled: item.disabled ? item.disabled : false
        }))
      } else {
        array = this.optionsOrdered
      }

      if (this.selectedRemove) {
        return array.filter(
          ({ id }) =>
            !this.selectedRemove
              .filter(val => val !== this.selected)
              .includes(id)
        )
      } else {
        return array
      }
    }
  },

  methods: {
    selectFocused () {
      const item = this.optionsFiltered[this.itemIndex]

      if (item) {
        !item.disabled ? this.handleSelected(item.id) : null
      }
    },

    changeFocus (direction) {
      if (this.itemIndex > -1 && this.itemIndex < this.optionsFiltered.length) {
        if (direction == 'up') {
          if (this.itemIndex > 0) {
            this.itemIndex--
          }
        } else {
          if (this.itemIndex < this.optionsFiltered.length - 1) {
            this.itemIndex++
          }
        }

        const element = this.$refs.options.querySelector(
          `.${this.$style.selectListFocused}`
        )

        this.$refs.options.scroll({
          top: element.offsetTop - element.offsetHeight,
          behavior: 'smooth'
        })
      }
    },

    getSearch () {
      if (!this.filter && this.selected) {
        const option = this.options.find(({ id }) => id == this.selected)

        if (option) {
          this.search = option.label
        }
      }
    },

    handleSelected (optionId) {
      this.$emit('select', optionId)

      if (this.type == 'value' || !this.filter) {
        const option = this.options.find(({ id }) => id === optionId)

        this.search = option.label
      }

      this.$refs.input.$refs.input.blur()
    },

    removeSelection () {
      this.$emit('select', null)

      this.$refs.input.$refs.input.blur()
    },

    enableFilter (e) {
      this.active = true
    },

    disableFilter () {
      this.active = false
    },

    resetSearch () {
      this.search = ''
      this.$refs.input.$refs.input.focus()
    }
  },

  watch: {
    search: function () {
      this.$emit('input', this.search)

      this.itemIndex = 0
    },
    active: function () {
      this.itemIndex = 0
    },
    options: {
      handler: function () {
        this.getSearch()
      },

      deep: true
    },
    selected: function (value) {
      if (value) {
        this.$refs.input.$refs.input.setCustomValidity('')
      } else {
        if (this.required) {
          this.$refs.input.$refs.input.setCustomValidity(
            'É obrigatório selecionar um item da lista.'
          )
        }
      }
    },
    loading: function () {
      if (!this.loading) this.$refs.input.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" module>
:global {
  @mixin fade() {
    &-enter-active,
    &-leave-active {
      transition: 0.2s ease;
      transition-property: opacity, transform;
    }

    &-enter-from,
    &-leave-active {
      opacity: 0;
    }
  }
  .filter-fade {
    @include fade();

    &-enter-from,
    &-leave-active {
      transform: translateY(-2px);
    }
  }

  .filter-fade-up {
    @include fade();

    &-enter-from,
    &-leave-active {
      transform: translateY(2px);
    }
  }
}

.select {
  position: relative;
  display: flex;

  &--disabled {
    pointer-events: none;
  }

  &__remove {
    $size: 18px;
    width: $size;
    height: $size;
    padding: 2px;
    fill: white;
    background: $md-red-500;
    border-radius: $size;
  }

  &__selected,
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100%;
    padding: $theme-padding + 1;
    padding-right: 40px;
    background: white;
    border-radius: 5px;
    border: 2px solid $md-grey-400;
    font-family: 'Roboto', sans-serif;
    @include font-sizer(14);
    line-height: 1.1;
    font-weight: 400;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    pointer-events: none;
    :global(.nightlymode) & {
      background: $md-grey-900;
      color: white;
      border-color: $md-grey-600;
    }
  }

  &__selected,
  &__loader {
    &--small {
      height: 100%;
      padding: 11px;
      @include font-sizer(12);
    }

    :global(.nightlymode) & {
      background: $md-grey-900 !important;
      color: white !important;
      border-color: $md-grey-700 !important;
    }

    &--icon-padding{
      padding-left: 34px;
    }
  }

  &--disabled &__selected,
  &--disabled &__loader {
    background: #eee;
    :global(.nightlymode) & {
      background: #373737 !important;
    }
  }

  &__loader {
    padding: 0 $theme-padding;
    pointer-events: all;
    color: $md-grey-600;
    display: flex;
    z-index: 2;
  }

  &__input {
    position: relative;
    width: 100%;
    cursor: pointer;
    z-index: 0;

    &--icon-padding {
      input {
        padding-left: 34px;
      }
    }
  }

  &__icon {
    $size: 50px;
    position: relative;
    width: $size;
    height: $size;
    margin-left: -$size;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    pointer-events: none;

    &--small {
      $size: 40px;
      width: $size;
      height: $size;
      margin-left: -$size;
    }

    &__glyph {
      fill: black;
      :global(.nightlymode) & {
        fill: white;
      }
    }
  }

  &__wrapper {
    position: absolute;
    top: 100%;
    top: calc(100% + #{$theme-padding});
    left: 0;
    margin-bottom: 20px;
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    z-index: 10000;

    &--top {
      top: inherit;
      bottom: 100%;
      bottom: calc(100% + #{math.div($theme-padding, 2)});
      margin-bottom: 0;
    }
  }

  &__row {
    width: 100%;
    max-height: 300px;
    padding: 0;
    background: black;
    border-radius: 5px;
    z-index: 99999999;
    list-style: none;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(white, 0.3);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(white, 0.3);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::before {
      $size: $theme-padding;
      content: '';
      position: absolute;
      top: -$size;
      left: $size;
      width: $size;
      height: $size;
      border: math.div($size, 2) solid transparent;
      border-bottom-color: black;
    }

    &--top {
      &::before {
        top: inherit;
        bottom: -5px;
        border-top-color: black;
        border-bottom-color: transparent;
      }
    }
  }

  &__list {
    position: relative;
    padding: math.div($theme-padding, 1.5);
    font-family: 'Roboto', sans-serif;
    @include font-sizer(13);
    font-weight: 400;
    color: white;
    transition: all 0.2s ease;
    word-wrap: break-word;

    &--empty,
    &--loading {
      padding: $theme-padding;
      @include font-sizer(14);
      line-height: 1.2;
      color: $md-grey-500;
    }

    &--loading::before {
      $size: 24px;
      content: '';
      position: absolute;
      top: 50%;
      right: $theme-padding;
      width: $size;
      height: $size;
      margin-top: -(math.div($size, 2));
      border: 2px solid transparent;
      border-right-color: $md-blue-500;
      border-radius: $size;
      animation: rotate 0.4s linear infinite;

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }

    &--selected {
      &::before {
        content: 'SELECIONADO';
        float: right;
        padding: 3px 10px;
        background: $md-blue-500;
        font-family: 'Raleway', sans-serif;
        @include font-sizer(11);
        line-height: 1;
        font-weight: 600;
        color: white;
        border-radius: 25px;
      }
    }

    &--disabled {
      cursor: default;
      color: $md-grey-500;
    }

    b {
      color: $md-blue-500;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(white, 0.05);
    }

    &:not(&--empty):not(&--disabled):hover {
      cursor: pointer;
      background: rgba(white, 0.05);
    }

    &--focused {
      cursor: pointer;
      background: rgba($md-blue-500, 0.2);
    }
  }
}
</style>
