import { defineAsyncComponent } from 'vue'
import FormFieldset from '@/talkture/scenario/form/FormFieldset.vue'

export const registerGlobalComponents = (app) => {
  app.component('form-fieldset', FormFieldset)
  app.component(
    'app-icon',
    defineAsyncComponent(() => import('@/components/icon/Icon.vue'))
  )
  app.component(
    'app-tooltip',
    defineAsyncComponent(() => import('@/components/tooltip/Tooltip.vue'))
  )
  app.component(
    'app-view',
    defineAsyncComponent(() => import('@/components/view/View.vue'))
  )
  app.component(
    'app-title',
    defineAsyncComponent(() => import('@/components/title/Title.vue'))
  )
  app.component(
    'app-box',
    defineAsyncComponent(() => import('@/components/box/Box.vue'))
  )
  app.component(
    'app-modal',
    defineAsyncComponent(() => import('@/components/modal/Modal.vue'))
  )
  app.component(
    'app-switch',
    defineAsyncComponent(() => import('@/components/switch/Switch.vue'))
  )
  app.component(
    'app-input',
    defineAsyncComponent(() => import('@/components/input/Input.vue'))
  )
  app.component(
    'app-input-label',
    defineAsyncComponent(() => import('@/components/inputLabel/InputLabel.vue'))
  )
  app.component(
    'login-alert',
    defineAsyncComponent(() => import('@/components/LoginAlert.vue'))
  )
  app.component(
    'app-button',
    defineAsyncComponent(() => import('@/components/button/Button.vue'))
  )
  app.component(
    'app-text',
    defineAsyncComponent(() => import('@/components/text/Text.vue'))
  )
  app.component(
    'app-pagination',
    defineAsyncComponent(() => import('@/components/pagination/Pagination.vue'))
  )
  app.component(
    'app-confirm',
    defineAsyncComponent(() => import('@/components/confirm/Confirm.vue'))
  )
  app.component(
    'app-confirm-promise',
    defineAsyncComponent(() =>
      import('@/components/confirm/ConfirmPromise.vue')
    )
  )
  app.component(
    'app-loader',
    defineAsyncComponent(() => import('@/components/loader/Loader.vue'))
  )
  app.component(
    'app-loader-progress',
    defineAsyncComponent(() =>
      import('@/components/loader/Loader-progress.vue')
    )
  )
  app.component(
    'app-loader-spinner',
    defineAsyncComponent(() =>
      import('@/components/loaderSpinner/LoaderSpinner.vue')
    )
  )
  app.component(
    'app-select',
    defineAsyncComponent(() => import('@/components/select/Select.vue'))
  )
  app.component(
    'app-select-list',
    defineAsyncComponent(() => import('@/components/select/SelectList.vue'))
  )
  app.component(
    'app-actions-box',
    defineAsyncComponent(() => import('@/components/actionsBox/ActionsBox.vue'))
  )
  app.component(
    'filter-component',
    defineAsyncComponent(() =>
      import('@/talkture/scenario/components/follownet-filter/FilterTree.vue')
    )
  )
  app.component(
    'app-config-default-value',
    defineAsyncComponent(() =>
      import('@/talkture/scenario/components/ConfigDefaultValue.vue')
    )
  )
  app.component(
    'app-config-description',
    defineAsyncComponent(() =>
      import('@/talkture/scenario/components/ConfigDescription.vue')
    )
  )
  app.component(
    'app-config-input',
    defineAsyncComponent(() =>
      import('@/talkture/scenario/components/ConfigInput.vue')
    )
  )
  app.component(
    'app-config-textarea',
    defineAsyncComponent(() =>
      import('@/talkture/scenario/components/ConfigTextarea.vue')
    )
  )
  app.component(
    'app-notifications',
    defineAsyncComponent(() => import('@/layout/Notifications.vue'))
  )
  app.component(
    'app-header',
    defineAsyncComponent(() => import('@/components/LayoutHeader.vue'))
  )
  app.component(
    'app-menu',
    defineAsyncComponent(() => import('@/layout/Menu.vue'))
  )
  app.component(
    'log',
    defineAsyncComponent(() =>
      import('@/talkture/scenario/components/log/Log.vue')
    )
  )
  app.component(
    'related-subject-tree',
    defineAsyncComponent(() =>
      import(
        '@/talkture/scenario/components/related-subjects/RelatedSubjectTree.vue'
      )
    )
  )
}
