<template>
  <app-view>
    <app-box>
      <app-confirm-promise ref="confirm" />

      <transition name="fade">
        <app-modal
          size="540"
          v-if="subjectAttributeSelected"
          key="edit_attr"
          title="Edição de coluna"
          v-on:close="closeEdit"
        >
          <form
            v-on:submit.prevent="saveEdit()"
            class="row no-gutters justify-content-center"
            :class="$style.templateEdit"
          >
            <div class="col-12">
              <app-input
                :label="`Nome personalizado para o campo '${subjectAttributeSelected.label}'`"
                name="alias"
                v-model:value="subjectAttributeSelected.alias"
                small
                :maxlength="150"
              />
            </div>

            <div class="col-12">
              <app-input
                label="Tamanho (em pixels)"
                name="width"
                v-model:value="subjectAttributeSelected.width"
                type="number"
                small
                max="1000"
                min="100"
                :maxlength="5"
              />
            </div>

            <div class="col-12" :class="$style.order">
              <label :class="$style.orderLabel">
                <div>
                  <app-icon
                    :glyph="
                      subjectAttributeSelected.orderActive
                        ? 'check_box'
                        : 'check_box_outline_blank'
                    "
                  />
                  <input
                    type="checkbox"
                    v-model="subjectAttributeSelected.orderActive"
                  />
                  <p>Ordernar por essa coluna</p>
                </div>
              </label>

              <div
                :class="$style.orderOptions"
                v-if="subjectAttributeSelected.orderActive"
              >
                <select-box
                  placeholder="Selecione a direção da ordenação"
                  :options="[
                    { id: 0, label: 'Decrescente' },
                    { id: 1, label: 'Crescente' },
                  ]"
                  :selected="subjectAttributeSelected.orderDirection"
                  v-on:select="handleOrder"
                />
              </div>
            </div>

            <div class="col-auto mt-3">
              <app-button label="Aplicar" type="submit" green />
            </div>
          </form>
        </app-modal>
      </transition>

      <app-loader
        v-if="loading || loadingError"
        :error="loadingError"
        key="loading"
        :active="loading"
        v-on:reload="init()"
        :response="loadingResponse"
      />

      <div
        v-else
        :style="{
          padding: '30px',
        }"
      >
        <div class="row no-gutters">
          <div class="col-12 pl-5">
            <app-box class="mb-5">
              <app-title
                :label="
                  !getParams.templateId
                    ? 'Criar novo template'
                    : 'Editar template'
                "
              />
            </app-box>
          </div>

          <div class="col-12 col-xl-3">
            <div :class="$style.templateHeader">
              <div class="row" :class="$style.templateHeaderFields">
                <div class="col-6 col-xl-12" v-if="getParams.type != 'default'">
                  <app-input
                    v-model:value="data.templateName"
                    name="templateName"
                    label="Nome do template"
                    :maxlength="100"
                    required
                  />
                  <span
                    :class="$style.templateHeaderError"
                    v-if="errorTemplateName"
                    style="bottom: 5px"
                    >Digite um nome</span
                  >
                </div>

                <div
                  class="col-6 col-xl-12"
                  :style="{
                    cursor: getParams.templateId ? 'no-drop' : null,
                  }"
                >
                  <app-input-label required>Assunto</app-input-label>

                  <select-box
                    placeholder="Selecione ou busque aqui por um assunto..."
                    :options="subjects"
                    :selected="data.subject"
                    v-on:select="handleSubject"
                    required
                    order
                    :disabled="
                      getParams.type == 'default' || getParams.templateId
                        ? true
                        : false
                    "
                  />
                </div>

                <div
                  class="col-6 col-xl-12 mt-4"
                  v-if="
                    data.subject &&
                    getParams.type == 'users' &&
                    templatesBase.length
                  "
                  :style="{
                    cursor: getParams.templateId ? 'no-drop' : null,
                  }"
                >
                  <app-input-label required>Template Base</app-input-label>

                  <select-box
                    placeholder="Selecione ou busque aqui por um template..."
                    :options="templatesBase"
                    :selected="data.templateBase"
                    v-on:select="handleTemplateBase"
                    required
                    order
                    :disabled="getParams.templateId ? true : false"
                  />

                  <span
                    :class="$style.templateHeaderError"
                    v-if="errorTemplateBase"
                    >Selecione um template base</span
                  >
                </div>

                <div
                  class="col-6 col-xl-12 mt-4"
                  v-if="
                    data.subject &&
                    data.templateBase &&
                    getParams.type == 'users'
                  "
                >
                  <app-input-label required>Usuário</app-input-label>

                  <select-box
                    placeholder="Selecione ou busque aqui por um usuário..."
                    :options="users"
                    :selected="data.accountId"
                    v-on:select="handleAccountId"
                    required
                    order
                    :disabled="getParams.templateId ? true : false"
                  />

                  <span :class="$style.templateHeaderError" v-if="errorAccount"
                    >Selecione um usuário</span
                  >
                </div>

                <div
                  class="col-12"
                  v-if="data.subject && getParams.type == 'custom'"
                >
                  <div class="row">
                    <div class="col-12 mt-4">
                      <app-input-label>Template de edição</app-input-label>

                      <select-box
                        placeholder="Selecione um template..."
                        :options="editionTemplateFilter"
                        :selected="editionTemplate"
                        v-on:select="handleEditionTemplate"
                        order
                        unselect
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 mt-4">
                      <app-input-label>Templates de cadastro</app-input-label>

                      <div :class="$style.templateHeaderBox">
                        <select-box
                          placeholder="Selecione um template..."
                          :options="registrationTemplateFilter"
                          :selected="0"
                          v-on:select="handleRegistrationTemplate"
                          order
                        />

                        <ul :class="$style.templateHeaderBoxList">
                          <li
                            v-for="item in relatedTemplatesSelecteds"
                            :key="item.id"
                            :class="$style.templateHeaderBoxItem"
                          >
                            <div class="row align-items-center">
                              <div class="col">
                                <label>{{ item.label }}</label>
                              </div>
                              <div class="col-auto">
                                <app-tooltip
                                  label="Remover template"
                                  left
                                  v-on:click="
                                    removeRegistrationTemplate(item.id)
                                  "
                                >
                                  <app-icon
                                    glyph="delete"
                                    :class="$style.templateHeaderBoxIcon"
                                  />
                                </app-tooltip>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row no-gutters align-items-center mt-4">
                    <div class="col-auto">
                      <app-switch
                        :active="data.showTalkIdOnGrid"
                        v-on:change="handleShowIds"
                      />
                    </div>

                    <div class="col ml-2">
                      <app-input-label class="mb-0"
                        >exibir coluna de id no grid</app-input-label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-xl-9">
            <div
              :class="$style.templateContent"
              v-if="data.subject && fields.length && !loadingBase"
            >
              <div class="row flex-fill flex-nowrap">
                <div class="col-6 col-xl-6">
                  <div :class="$style.templateFields" ref="fields">
                    <div :class="$style.templateFieldsSearch">
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto pr-3">
                          <app-icon
                            glyph="search"
                            :class="$style.templateFieldsSearchIcon"
                          />
                        </div>

                        <div class="col">
                          <input
                            type="text"
                            placeholder="Busque aqui por um campo"
                            :class="$style.templateFieldsSearchInput"
                            v-model="search"
                          />
                        </div>

                        <div class="col-auto pl-3" v-if="search.length">
                          <app-tooltip
                            label="Limpar busca"
                            left
                            v-on:click="clearSearch()"
                          >
                            <app-icon
                              glyph="close"
                              :class="$style.templateFieldsSearchIcon"
                              style="cursor: pointer"
                            />
                          </app-tooltip>
                        </div>
                      </div>
                    </div>

                    <ul :class="$style.templateFieldsRow">
                      <li
                        :class="$style.templateFieldsList"
                        v-for="(data, index) in fieldsFiltered"
                        :key="data.uid || index"
                      >
                        <div class="row no-gutters align-items-center">
                          <div class="col">
                            <div
                              :class="$style.templateFieldsLabel"
                              v-html="data.alias || data.label"
                            ></div>
                          </div>

                          <div class="col-auto pl-2">
                            <app-tooltip
                              label="Selecionar campo"
                              left
                              v-on:click="selectField(data.id)"
                            >
                              <app-icon
                                glyph="last_page"
                                :class="$style.templateFieldsIcon"
                              />
                            </app-tooltip>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-auto pr-0 pl-0">
                  <div :class="$style.templateBulkactions">
                    <app-tooltip
                      label="Selecionar todos"
                      :class="[
                        $style.templateBulkactionsAction,
                        fields.length == selecteds.length
                          ? $style.templateBulkactionsActionDisabled
                          : null,
                      ]"
                      v-on:click="selectAll"
                    >
                      <app-icon glyph="fast_forward" />
                    </app-tooltip>

                    <app-tooltip
                      label="Remover todos"
                      :class="[
                        $style.templateBulkactionsAction,
                        selecteds.length == 0
                          ? $style.templateBulkactionsActionDisabled
                          : null,
                      ]"
                      v-on:click="removeAll"
                    >
                      <app-icon glyph="fast_rewind" />
                    </app-tooltip>
                  </div>
                </div>

                <div class="col">
                  <div :class="$style.templateFields">
                    <Sortable
                      tag="ul"
                      :class="$style.templateFieldsRow"
                      :list="selecteds"
                      :itemKey="'uid'"
                      :options="{ handle: '.handle' }"
                      @end="sortSelecteds"
                    >
                      <template #item="{ element, index }">
                        <li :class="$style.templateFieldsList">
                          <div class="row no-gutters align-items-center">
                            <div class="col-auto pr-2 handle">
                              <app-icon
                                glyph="drag_indicator"
                                :class="$style.templateFieldsIcon"
                              />
                            </div>

                            <div class="col">
                              <div :class="$style.templateFieldsLabel">
                                {{ element.alias || element.label }}
                              </div>
                            </div>

                            <div class="col-auto pl-2" v-if="element.orderActive">
                              <app-tooltip
                                :label="
                                  element.orderDirection == 1
                                    ? 'Ordem Crescente'
                                    : 'Ordem Decrescente'
                                "
                                left
                                style="cursor: auto"
                              >
                                <app-icon
                                  :glyph="
                                    element.orderDirection == 1
                                      ? 'arrow_upward'
                                      : 'arrow_downward'
                                  "
                                  :class="$style.templateFieldsIcon"
                                  style="cursor: auto"
                                />
                              </app-tooltip>
                            </div>

                            <div class="col-auto pl-2">
                              <app-tooltip
                                label="Editar campo"
                                left
                                v-on:click="editField(index)"
                              >
                                <app-icon
                                  glyph="edit"
                                  :class="$style.templateFieldsIcon"
                                />
                              </app-tooltip>
                            </div>

                            <div
                              class="col-auto pl-2"
                              v-if="selecteds.length > 1"
                            >
                              <app-tooltip
                                label="Remover campo"
                                left
                                v-on:click="removeField(index)"
                              >
                                <app-icon
                                  glyph="close"
                                  :class="$style.templateFieldsIcon"
                                />
                              </app-tooltip>
                            </div>
                          </div>
                        </li>
                      </template>
                    </Sortable>
                  </div>
                </div>
              </div>
            </div>

            <app-loader
              v-if="loadingBase"
              :error="false"
              key="loading"
              :active="loadingBase"
              v-on:reload="init()"
            />
          </div>
        </div>
      </div>
    </app-box>

    <app-actions-box v-if="!loading && !loadingError">
      <div class="row justify-content-center">
        <div class="col-auto">
          <app-button
            label="Salvar"
            icon="save"
            color="blue-500"
            :loading="loadingSave"
            v-on:click="saveTemplate"
            :disabled="(data.subject ? false : true) || !selecteds.length"
          />
        </div>

        <div class="col-auto">
          <app-button
            label="Cancelar"
            icon="close"
            color="red-500"
            v-on:click="closePage"
          />
        </div>
      </div>
    </app-actions-box>
  </app-view>
</template>

<script>
import { Sortable } from 'sortablejs-vue3'
import sortArray from '@/composables/useSortable'
import SelectBox from '@/components/select/SelectBox.vue'

export default {
  components: {
    Sortable,
    SelectBox
  },

  data: () => ({
    loading: false,
    loadingResponse: null,
    loadingError: false,
    loadingSave: false,
    loadingBase: false,

    fields: [],

    search: '',

    selecteds: [],

    subjects: [],
    templates: [],
    templatesBase: [],
    users: [],

    data: {
      subject: null,
      template: null,
      templateName: '',
      templateBase: null,
      accountId: null,
      showTalkIdOnGrid: false
    },

    subjectAttributeSelected: null,
    subjectAttributeSelectedIndex: null,

    errorTemplateBase: false,
    errorAccount: false,
    errorTemplateName: false,

    registrationTemplate: [],
    relatedTemplates: [],

    editionTemplate: null
  }),

  created () {
    this.init()
  },

  computed: {
    registrationTemplateFilter () {
      return this.registrationTemplate.filter(
        (x) => !this.relatedTemplates.includes(x.id) && x.type == 5
      )
    },

    editionTemplateFilter () {
      return this.registrationTemplate.filter((x) => x.type == 6)
    },

    relatedTemplatesSelecteds () {
      return this.registrationTemplate.filter(
        (x) => this.relatedTemplates.includes(x.id) && x.type == 5
      )
    },

    fieldsFiltered () {
      const _this = this
      const keywords = this.search.toLowerCase().split(' ')
      let items = this.fields.filter(({ label, alias }) => {
        return keywords.every((keyword) => {
          return (
            (label ? label.toLowerCase().includes(keyword) : false) ||
              (alias ? alias.toLowerCase().includes(keyword) : false)
          )
        })
      })

      if (this.search.length) {
        items = items.slice(0, 50)
      }

      items = items.filter(
        ({ id }) => !this.selecteds.map(({ id }) => id).includes(id)
      )

      return items.map(({ id, label, alias }) => ({
        id,
        label: _this.search.length
          ? label.replace(
            new RegExp(`(${keywords.join('|')})`, 'gi'),
            '<b>$1</b>'
          )
          : label,
        alias: alias
          ? _this.search.length
            ? alias.replace(
              new RegExp(`(${keywords.join('|')})`, 'gi'),
              '<b>$1</b>'
            )
            : alias
          : ''
      }))
    },

    getParams () {
      return this.$route.params
    },

    getQuerys () {
      return this.$route.query
    }
  },

  methods: {
    handleRegistrationTemplate (id) {
      this.relatedTemplates.push(id)
    },

    handleEditionTemplate (id) {
      this.editionTemplate = id
    },

    async removeRegistrationTemplate (id) {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente remover esse template de cadastro?', 'red')
        .then((response) => {
          const index = this.relatedTemplates.findIndex((x) => x == id)

          this.relatedTemplates.splice(index, 1)
        })
        .catch((error) => {
          console.log(error)
        })

      confirm.close()
    },

    getRegistrationTemplate (force) {
      if (!force) this.relatedTemplates = []

      this.$http
        .get(
            `${this.$store.getters.api}/template?scenarioid=2&subjectid=${this.data.subject}`
        )
        .then(({ data }) => {
          this.registrationTemplate = data.itens.map(
            ({ templateId, templateName, templateType }) => {
              return {
                id: templateId,
                label: templateName,
                type: templateType
              }
            }
          )
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async init () {
      this.loading = true
      this.loadingError = false

      await this.getSubjects()

      if (this.getParams.templateId) {
        await this.getTemplateDetails()
      } else if (this.getQuerys.subject) {
        this.handleSubject(this.getQuerys.subject, true)
      }
    },

    getTemplateDetails () {
      this.loading = true
      this.loadingError = false

      return this.$http
        .get(
            `${this.$store.getters.api}/template/${this.getParams.templateId}`
        )
        .then(({ data }) => {
          if (data) {
            this.data.templateName = data.templateName
            this.data.scenario = 'grid'
            this.data.subject = data.subjectId
            this.data.accountId = data.accountId
            this.data.showTalkIdOnGrid = data.showTalkIdOnGrid

            this.relatedTemplates = data.relatedTemplates
              .filter((x) => x.templateType == 'TalkModalCompanyCreate')
              .map(({ templateId }) => {
                return templateId
              })

            const editionTemplate = data.relatedTemplates.find(
              (x) => x.templateType == 'TalkModalCompanyEdit'
            )

            if (editionTemplate) {
              this.editionTemplate = editionTemplate.templateId
            }

            const selecteds = data.templateAreas.map((item) => {
              const width = item.templateAreaConfigs.find(
                (x) => x.configIdentifier.toLowerCase() == 'width'
              )
              const order = item.templateAreaConfigs.find(
                (x) => x.configIdentifier.toLowerCase() == 'isorderable'
              )

              return {
                uid: item.templateAreaId,
                id: item.subjectAttributeId,
                area: item.templateAreaId,
                label: item.attributeName,
                alias: item.templateAreaName,
                width: width ? width.value : null,
                widthId: width ? width.templateAreaConfigId : null,
                fieldId: item.templateAreaSubjectAttributes.length
                  ? item.templateAreaSubjectAttributes[0]
                    .templateAreaSubjectAttributeId
                  : null,
                orderActive: order != undefined ? true : null,
                orderDirection: order != undefined ? order.value : null
              }
            })

            if (
              this.getParams.templateId ||
                this.getParams.type == 'default'
            ) {
              // setTimeout(() => {

              if (this.getQuerys.subject) {
                this.handleSubject(this.getQuerys.subject, true)
              } else {
                this.handleSubject(this.data.subject, true)
              }

              // }, 200, this);
            }

            if (data.parentTemplateId) {
              setTimeout(
                () => {
                  this.handleTemplateBase(data.parentTemplateId)
                },
                400,
                this
              )
            }

            this.selecteds = selecteds

            if (this.subjects.length > 0) {
              this.loading = false
            }
          } else {
            throw new Error('Não foi possível carregar os dados do template')
          }
        })
        .catch((error) => {
          console.log(error)

          this.loading = false
          this.loadingError = true
          this.loadingResponse = error.response
        })
    },

    getSubjects () {
      return this.$http
        .get(`${this.$store.getters.api}/subject/`)
        .then(({ data }) => {
          this.subjects = data.itens
            .filter((x) => x.subjectAttributes.length > 0)
            .map((s) => {
              return {
                id: s.subjectId,
                label: s.subjectName,
                identifier: s.subjectIdentifier,
                itens: s.subjectAttributes.map((sa) => {
                  return {
                    uid: sa.subjectAttributeId,
                    id: sa.subjectAttributeId,
                    label: sa.subjectAttributeName || sa.attributeName
                  }
                })
              }
            })

          if (!this.getParams.templateId) {
            this.loading = false
          } else {
            if (this.data.subject) {
              this.loading = false
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    getUsers () {
      return this.$http
        .get(
            `${this.$store.getters.api}/template/accounts/${
              this.data.templateBase
            }${
              this.getParams.templateId
                ? '?accountId=' + this.data.accountId
                : ''
            }`
        )
        .then(({ data }) => {
          const users = data

          this.users = users.map((u) => {
            return {
              id: u.accountId,
              label: u.displayName
            }
          })
        })
        .catch((error) => {})
    },

    async handleSubject (value, force) {
      if (this.data.subject && !force && this.getParams.type !== 'users') {
        const confirm = this.$refs.confirm

        await confirm
          .open('Deseja realmente alterar o assunto desse template?', 'red')
          .then((response) => {
            this.data.subject = value

            const index = this.subjects.findIndex((x) => x.id == value)

            this.fields = this.subjects[index].itens

            this.selecteds = []

            if (this.getParams.type == 'custom' && this.data.subject) {
              this.getRegistrationTemplate(force)
            }
          })
          .catch((error) => {
            console.log(error)
          })

        confirm.close()
      } else {
        this.data.subject = value

        if (this.getParams.type == 'custom' && this.data.subject) {
          this.getRegistrationTemplate(force)
        }

        if (!this.getParams.templateId) this.data.accountId = null

        if (this.getParams.type !== 'users') {
          const index = this.subjects.findIndex((x) => x.id == value)

          if (index > -1) this.fields = this.subjects[index].itens
        }
      }

      if (this.getParams.type == 'users') {
        if (!this.getParams.templateId) {
          this.data.accountId = null
          this.data.templateBase = null
        }

        const url = `${this.$store.getters.api}/template?scenarioId=1&page=1&fetch=10000&subjectId=${this.data.subject}`

        await this.$http
          .get(url)
          .then(({ data }) => {
            this.templatesBase = data.itens
              .filter((x) => x.templateType != 2)
              .map((item) => {
                return {
                  id: item.templateId,
                  label: item.templateName || 'Sem título'
                }
              })
          })
          .catch((error) => {
            console.log(error)
          })

        // this.getUsers();
      }
    },

    handleOrder (value) {
      this.subjectAttributeSelected.orderDirection = value
    },

    handleTemplateBase (value) {
      this.errorTemplateBase = false

      this.data.templateBase = value

      this.loadingBase = true

      this.fields = []

      this.$http
        .get(`${this.$store.getters.api}/template/${value}`)
        .then(({ data }) => {
          const array = data.templateAreas.map((item) => {
            return item.subjectAttributeId
          })

          const _this = this

          var refreshSubjects = setInterval(function () {
            if (_this.subjects.length > 0) {
              const subject = _this.subjects.find(
                (x) => x.id == _this.data.subject
              )

              const itens = subject.itens.filter((x) => array.includes(x.id))

              _this.fields = itens.map((item) => {
                const _data = data.templateAreas.find(
                  (x) => x.subjectAttributeId == item.id
                )
                const width = data.viewTemplateAreaConfigs.find(
                  (x) => x.configIdentifier.toLowerCase() == 'width'
                )

                return {
                  id: item.id,
                  uid: item.id,
                  label: _data.attributeName || _data.templateAreaName,
                  alias: _data.templateAreaName,
                  width: width ? width.value : 200
                }
              })

              _this.loadingBase = false

              clearInterval(refreshSubjects)
            }
          }, 500)
        })

      /* get users */

      this.getUsers()

      // this.$http.get(`${this.$store.getters.api}/template/accounts/${value}`).then(({data}) => {

      //     this.users = data.map((item) => {

      //         return {

      //             id: item.accountId,
      //             label: item.displayName

      //         }

      //     })

      // })
    },

    handleTemplate (value) {
      this.data.template = value
    },

    handleAccountId (value) {
      this.errorAccount = false

      this.data.accountId = value
    },

    selectField (fieldId) {
      if (!this.selecteds.map(({ id }) => id).includes(fieldId)) {
        const field = this.fields.find(({ id }) => id === fieldId)

        this.selecteds.push(field)
      }
    },

    removeField (index) {
      this.selecteds.splice(index, 1)
    },

    editField (index) {
      this.subjectAttributeSelected = JSON.parse(
        JSON.stringify(this.selecteds[index])
      )
      this.subjectAttributeSelectedIndex = index
    },

    closeEdit () {
      this.subjectAttributeSelected = null
      this.subjectAttributeSelectedIndex = null
    },

    saveEdit () {
      if (this.subjectAttributeSelected.orderActive) {
        const temp = this.selecteds.map((item) => {
          if (item.area) {
            return {
              alias: item.alias || null,
              id: item.id || null,
              area: item.area,
              label: item.label || null,
              orderActive: null,
              orderDirection: null,
              uid: item.uid || null,
              width: item.width || null
            }
          } else {
            return {
              alias: item.alias || null,
              id: item.id || null,
              label: item.label || null,
              orderActive: null,
              orderDirection: null,
              uid: item.uid || null,
              width: item.width || null
            }
          }
        })

        temp[this.subjectAttributeSelectedIndex] = JSON.parse(
          JSON.stringify(this.subjectAttributeSelected)
        )

        this.selecteds = JSON.parse(JSON.stringify(temp))
      } else {
        this.selecteds[this.subjectAttributeSelectedIndex] = JSON.parse(
          JSON.stringify(this.subjectAttributeSelected)
        )
      }

      this.closeEdit()
    },

    clearSearch () {
      this.search = ''
    },

    async saveTemplate () {
      if (this.getParams.type == 'users') {
        this.errorAccount = false
        this.errorTemplateBase = false

        if (
          this.data.accountId == null ||
            this.data.templateBase == null ||
            this.data.templateName == ''
        ) {
          if (this.data.accountId == null) this.errorAccount = true
          if (this.data.templateBase == null) this.errorTemplateBase = true
          if (this.data.templateName == '') this.errorTemplateName = true
        } else {
          this.loadingSave = true

          if (this.getParams.templateId) {
            this.updateTemplate()
          } else {
            this.insertTemplate()
          }
        }
      } else if (this.getParams.type == 'custom') {
        if (this.data.templateName == '') {
          if (this.data.templateName == '') this.errorTemplateName = true
        } else {
          this.loadingSave = true

          if (this.getParams.templateId) {
            this.updateTemplate()
          } else {
            this.insertTemplate()
          }
        }
      } else {
        this.loadingSave = true

        if (this.getParams.templateId) {
          this.updateTemplate()
        } else {
          this.insertTemplate()
        }
      }
    },

    async insertTemplate () {
      const template = {}

      const templateType =
          this.getParams.type == 'custom'
            ? 'CustomTemplate'
            : this.getParams.type == 'default'
              ? 'CompanyDefault'
              : this.getParams.type == 'users'
                ? 'UserByWebInterface'
                : ''

      template.templateName = this.data.templateName
      template.companyId = this.$store.getters.user.data.companyId
      template.subjectId = this.data.subject
      template.scenario = 'grid'
      template.templateType = templateType
      template.parentTemplateId = this.data.templateBase
      template.accountId = this.data.accountId || null
      template.showTalkIdOnGrid = this.data.showTalkIdOnGrid

      const relatedTemplates = this.relatedTemplates

      if (this.editionTemplate) relatedTemplates.push(this.editionTemplate)

      if (relatedTemplates) {
        template.relatedTemplateIds = relatedTemplates
      }

      const templateAreas = this.selecteds.map((item, key) => {
        const areaConfig = []

        areaConfig.push({
          configIdentifier: 'width',
          value: item.width || 200
        })

        if (item.orderActive) {
          areaConfig.push({
            configIdentifier: 'IsOrderable',
            value: item.orderDirection != 0
          })
        }

        return {
          order: key + 1,
          subjectAttributeId: item.id,
          attributeName: item.alias || item.label,
          templateAreaName: item.alias || item.label,
          widgetId: 1, // Coluna
          templateAreaTalkModal: null,
          templateAreaConfigs: areaConfig,
          templateAreaSubjectAttributes: [
            {
              subjectAttributeId: item.id,
              templateAreaSubjectAttributeLabel: item.alias || item.label
            }
          ]
        }
      })

      template.templateAreas = templateAreas

      await this.$http
        .post(`${this.$store.getters.api}/template`, template)
        .then(({ data }) => {
          this.$store.dispatch('notification', {
            text: `Template ${this.data.templateName} inserido com sucesso!`,
            color: 'green'
          })

          const type = JSON.parse(JSON.stringify(this.getParams.type))

          this.$store.commit('closePage', '/template/list/' + type)
          this.$store.commit('closePage', `/template/editor/${type}`)

          this.$router.push({
            name: `template_list_${type}`,
            params: {
              refresh: true
            }
          })

          this.loadingSave = false
        })
        .catch((error) => {
          console.log(error)

          this.loadingSave = false

          this.$store.dispatch('notification', {
            type: 'error',
            text: 'Ocorreu um erro ao inserir o template. Por favor, tente novamente.',
            error
          })
        })
    },

    async updateTemplate () {
      const template = {}

      const templateType =
          this.getParams.type == 'custom'
            ? 'CustomTemplate'
            : this.getParams.type == 'default'
              ? 'CompanyDefault'
              : this.getParams.type == 'users'
                ? 'UserByWebInterface'
                : ''

      template.templateName = this.data.templateName
      template.companyId = this.$store.getters.user.data.companyId
      template.subjectId = this.data.subject
      template.scenario = 'grid'
      template.templateType = templateType
      template.parentTemplateId = this.data.templateBase
      template.accountId = this.data.accountId || null
      template.showTalkIdOnGrid = this.data.showTalkIdOnGrid

      const relatedTemplates = this.relatedTemplates

      if (this.editionTemplate) relatedTemplates.push(this.editionTemplate)

      if (relatedTemplates) {
        template.relatedTemplateIds = relatedTemplates
      }

      const templateAreas = this.selecteds.map((item, key) => {
        const areaConfig = []

        areaConfig.push({
          configIdentifier: 'width',
          value: item.width || 200
        })

        if (item.orderActive) {
          areaConfig.push({
            configIdentifier: 'IsOrderable',
            value: item.orderDirection
          })
        }

        return {
          order: key + 1,
          subjectAttributeId: item.id,
          templateAreaId: item.area,
          attributeName: item.alias || item.label,
          templateAreaName: item.alias || item.label,
          widgetId: 1, // Coluna
          templateAreaTalkModal: null,
          templateAreaConfigs: areaConfig,
          templateAreaSubjectAttributes: [
            {
              subjectAttributeId: item.id,
              templateAreaSubjectAttributeId: item.fieldId,
              templateAreaSubjectAttributeLabel: item.alias || item.label
            }
          ]
        }
      })

      template.templateAreas = templateAreas

      await this.$http
        .put(
            `${this.$store.getters.api}/template/${this.getParams.templateId}`,
            template
        )
        .then(({ data }) => {
          this.$store.dispatch('notification', {
            text: `Template ${this.data.templateName} atualizado com sucesso!`,
            color: 'green'
          })

          const type = JSON.parse(JSON.stringify(this.getParams.type))

          this.$store.commit('closePage', '/template/list/' + type)
          this.$store.commit(
            'closePage',
              `/template/editor/${type}/${this.getParams.templateId}`
          )

          this.$router.push({
            name: `template_list_${type}`,
            params: {
              refresh: true
            }
          })

          this.loadingSave = false
        })
        .catch((error) => {
          console.log(error)

          this.loadingSave = false

          this.$store.dispatch('notification', {
            type: 'error',
            text: 'Ocorreu um erro ao atualizar o template. Por favor, tente novamente.',
            error
          })
        })
    },

    sortSelecteds ({ oldIndex, newIndex }) {
      this.selecteds = sortArray(this.selecteds, oldIndex, newIndex)
    },

    async selectAll () {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente selecionar todos os campos?', 'red')
        .then((response) => {
          this.fields.forEach(({ id }) => {
            this.selectField(id)
          })
        })
        .catch((error) => {
          console.log(error)
        })

      confirm.close()
    },

    async removeAll () {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente remover todos os campos?', 'red')
        .then((response) => {
          this.selecteds = []
        })
        .catch((error) => {
          console.log(error)
        })

      confirm.close()
    },

    closePage () {
      this.$store.commit('closePage', this.$route.fullPath)
    },

    handleShowIds () {
      this.data.showTalkIdOnGrid = !this.data.showTalkIdOnGrid
    }
  },

  watch: {
    'subjectAttributeSelected.orderActive': function (value) {
      if (value && !this.subjectAttributeSelected.orderDirection) {
        this.handleOrder(1)
      }
    },

    'data.templateName': function (value) {
      if (value) {
        this.errorTemplateName = false
      }
    },

    search: function () {
      this.$refs.fields.scroll({
        top: 0
      })
    }
  }
}
</script>

<style lang="scss" module>
  /* */

  .order {
    display: flex;

    &__label {
      input {
        position: absolute;
        opacity: 0;
      }

      div {
        display: flex;
        align-items: center;
        height: 50px;
      }

      p {
        color: #000;
        @include font-sizer(13);
        font-family: 'Raleway';
        margin: 0;
        text-align: center;

        :global(.nightlymode) & {
          color: #fff;
        }
      }

      svg {
        margin: 0 5px;

        cursor: pointer;

        fill: $md-grey-500;

        :global(.nightlymode) & {
          fill: $md-grey-400;
        }
      }
    }

    &_options {
      flex: 1;
      padding-left: $theme-padding;
    }
  }

  .template {
    width: 100%;
    height: 100%;

    padding: 30px 0;

    display: flex;
    flex-direction: column;

    &__header {
      padding: 0 $theme-padding * 2 $theme-padding * 3;
      position: relative;
      z-index: 999;

      &__error {
        padding: 2px 7px;
        background: $md-red-500;
        border-radius: 7px;
        color: #fff;
        position: absolute;
        bottom: -7px;
        right: 30px;
        font-family: 'Raleway';
      }

      &__fields {
        background: white;
        padding: $theme-padding 0;
        border-radius: 7px;

        :global(.nightlymode) & {
          background: $md-grey-900;
        }
      }

      &__box {
        padding: $theme-padding;
        border-radius: 7px;
        background: rgba(0, 0, 0, 0.05);

        :global(.nightlymode) & {
          background: rgba(255, 255, 255, 0.05);
        }

        &__list {
          padding: 0;
          margin: 0;

          list-style: none;
        }

        &__item {
          padding: 7px;
          border-radius: 7px;
          background: rgba(0, 0, 0, 0.05);
          margin-top: 10px;

          :global(.nightlymode) & {
            background: rgba(255, 255, 255, 0.05);
          }

          label {
            color: #000;
            font-family: 'Raleway', sans-serif;
            @include font-sizer(12);

            margin: 0;

            :global(.nightlymode) & {
              color: #fff;
            }
          }
        }

        &__icon {
          fill: $md-red-500;
          cursor: pointer;
        }
      }
    }

    &__content {
      height: 600px;
      padding: 0 $theme-padding * 2;

      display: flex;
      flex-grow: 1;

      margin-bottom: $theme-padding * 3;
    }

    &__fields {
      position: relative;

      width: 100%;
      height: 100%;

      background: white;

      overflow: auto;

      border-radius: 10px;

      :global(.nightlymode) & {
        background: $md-grey-900;
      }

      &__search {
        position: sticky;

        top: 0;

        padding: 15px;

        background: white;

        border-bottom: 2px solid $md-grey-200;

        z-index: 100;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        :global(.nightlymode) & {
          background: $md-grey-900;

          border-bottom-color: darken($md-grey-900, 5%);
        }

        &__icon {
          $size: 18px;

          width: $size;
          height: $size;

          fill: $md-grey-500;
        }

        &__input {
          width: 100%;

          font-family: 'Raleway', sans-serif;
          @include font-sizer(12);
          color: black;

          background: transparent;

          :global(.nightlymode) & {
            color: white;
          }
        }
      }

      &__row {
        min-height: 100%;

        margin: 0;
        padding: 15px;

        list-style: none;
      }

      &__list {
        padding: 8px;

        background: $md-grey-100;

        border-radius: 8px;

        transition-duration: 0.4s;
        transition-property: background;

        &:not(:last-of-type) {
          margin-bottom: 15px;
        }

        &:hover {
          background: $md-grey-200;
        }

        :global(.nightlymode) & {
          background: $md-grey-700;

          &:hover {
            background: $md-grey-800;
          }
        }
      }

      &__list:global(.sortable-chosen),
      &__list:global(.sortable-ghost) {
        background: $md-blue-500 !important;
      }

      &__list:global(.sortable-drag) {
        opacity: 0;
      }

      &__icon {
        $size: 18px;

        width: $size;
        height: $size;

        fill: $md-grey-500;

        cursor: pointer;

        :global(.nightlymode) & {
          fill: $md-grey-100;
        }
      }

      &__list:global(.sortable-chosen) &__icon,
      &__list:global(.sortable-ghost) &__icon {
        fill: white !important;

        &__close {
          opacity: 0;
        }
      }

      &__label {
        font-family: 'Raleway', sans-serif;
        @include font-sizer(12);
        line-height: 1;
        color: black;

        word-break: break-word;

        b {
          color: $md-blue-500;
        }

        :global(.nightlymode) & {
          color: $md-grey-100;
        }
      }

      &__list:global(.sortable-chosen) &__label,
      &__list:global(.sortable-chosen) &__label b,
      &__list:global(.sortable-ghost) &__label,
      &__list:global(.sortable-ghost) &__label b {
        color: white !important;
      }
    }

    &__edit {
      padding: $theme-padding * 2;
    }

    &__bulkactions {
      &__action {
        cursor: pointer;
        margin-top: $theme-padding * 1.5;

        svg {
          fill: black;
          height: 28px;
          width: 28px;

          :global(.nightlymode) & {
            fill: white;
          }
        }

        &--disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
  }
</style>
