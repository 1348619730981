import axios from 'axios'

const moduleGridFilter = {
  state: () => ({
    operators: {},
  }),
  getters: {
    getOperators(state) {
      return state.operators
    },
  },
  mutations: {
    SET_OPERATORS(state, { dataId, data }) {
      state.operators[dataId] = data
    },
  },
  actions: {
    async loadOperators({ commit, getters }, dataId) {
      await axios
        .get(`${getters.api}/filter/operators/${dataId}`)
        .then(({ data }) => {
          commit('SET_OPERATORS', { dataId, data })
        })
        .catch((e) => {
          throw e
        })
    }
  },
}

export default moduleGridFilter
