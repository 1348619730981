<template>
  <app-view>
    <div class="d-flex">
      <slot name="aside">
        <view-aside title="Configurações">
          <div :class="$style.config">
            <ul :class="$style.configRow" data-title="Administrador">
              <!-- <li :class="$style.configList"> -->

              <!-- <router-link :class="[$style.configLink, routerParams.mode == 'menu' ? $style.configLinkActive : '']" :to="{

                                  name: 'admin_mode',
                                  params: { mode: 'menu' },
                                  meta: { label: 'Teste 2' }

                              }">Menus</router-link> -->

              <!-- <p :class="[$style.configLink, routerParams.mode == 'menu' ? $style.configLinkActive : '']" v-on:click="openPage('menu')">Menus</p>

                          </li> -->

              <li :class="$style.configList">
                <!-- <router-link :class="[$style.configLink, routerParams.mode == 'atributos' ? $style.configLinkActive : '']" :to="{

                                  name: 'admin_mode',
                                  params: { mode: 'atributos' },
                                  meta: { label: 'Teste' }

                              }">Atributos</router-link> -->

                <p
                  :class="[
                    $style.configLink,
                    routerParams.mode == 'atributos'
                      ? $style.configLinkActive
                      : '',
                  ]"
                  v-on:click="openPage('atributos')"
                >
                  Atributos
                </p>
              </li>
            </ul>
          </div>
        </view-aside>
      </slot>

      <div class="flex-grow-1">
        <keep-alive>
          <templates-add-edit v-if="routerParams.mode == 'templates'" />

          <adm-attributes v-else-if="routerParams.mode == 'atributos'" />

          <div v-else :class="$style.logo">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2190 512"
            >
              <path
                fill="#58c4c3"
                d="M1407.85 0h148.654v511.979h-148.654v-511.979z"
              ></path>
              <path
                fill="#58c4c3"
                d="M733.485 0v510.733h148.633v-245.084l151.126 88.349 151.084-88.349v245.083h148.633v-510.733l-299.759 175.306z"
              ></path>
              <path
                fill="#58c4c3"
                d="M605.148 204.867c-28.584 0-51.767 23.162-51.767 51.767 0 28.584 23.183 51.767 51.767 51.767 28.605 0 51.767-23.183 51.767-51.767s-23.163-51.767-51.767-51.767z"
              ></path>
              <path
                fill="#58c4c3"
                d="M409.546 69.591c-44.87-45.639-101.914-68.49-171.11-68.49-69.216 0-126.26 22.851-171.131 68.49-44.87 45.66-67.305 107.793-67.305 186.316v0.063c0 77.526 22.311 139.368 66.911 185.589 44.6 46.179 104.323 69.258 179.212 69.258 78.939 0 141.238-26.798 186.877-80.413l-0.041-0.041 0.041-0.041-106.068-72.976c-22.352 23.723-50.043 33.964-80.808 33.964-34.879 0-61.281-8.621-79.209-25.884-16.016-15.393-26.714-31.326-28.459-47.8l0.021-0.478-0.021-5.401h332.997c3.594-24.949 5.401-43.541 5.401-55.755v-0.083c-0.021-78.523-22.456-140.656-67.305-186.316zM345.585 206.549h-207.38l-0.021-0.81c-0.27-22.456 8.87-42.191 27.524-59.225 18.862-17.242 43.935-25.883 75.22-25.883 31.264 0 56.877 8.642 76.841 25.883 19.921 17.221 29.353 37.246 28.314 60.055h-0.498z"
              ></path>
              <path
                fill="#58c4c3"
                d="M1959.921 441.952l48.256 70.048h181.683l-140.033-194.729z"
              ></path>
              <path
                fill="#58c4c3"
                d="M2189.861 0h-181.684l-48.256 70.027 89.429 125.159z"
              ></path>
              <path
                fill="#f1ec67"
                d="M1820.698 0h-180.852l182.036 255.989-182.036 256.010h180.852l183.22-256.031z"
              ></path>
            </svg>
          </div>
        </keep-alive>
      </div>
    </div>
  </app-view>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const viewAside = defineAsyncComponent(() => import('@/components/viewAside/ViewAside.vue'))
const TemplatesAddEdit = defineAsyncComponent(() => import('@/talkture/admin/templates/AddEdit.vue'))
const AdmAttributes = defineAsyncComponent(() => import('@/talkture/admin/attributes/Attributes.vue'))

export default {
  components: {
    TemplatesAddEdit,
    AdmAttributes,
    viewAside
  },
  methods: {
    openPage (mode) {
      this.$router.push({
        name: 'admin_mode',
        fullPath: `/admin/${mode}`,
        params: { mode: mode },
        meta: {
          label:
            mode == 'menu'
              ? 'Menus - Adminstrador'
              : 'Atributos - Administrador'
        }
      })

      this.$store.commit('openPage', {
        name: 'admin_mode',
        fullPath: `/admin/${mode}`,
        params: { mode: mode },
        meta: {
          label:
            mode == 'menu'
              ? 'Menus - Adminstrador'
              : 'Atributos - Administrador'
        }
      })
    }
  },

  beforeMount () {
    if (this.$route.params.mode == 'menu') {
      this.openPage('menu')
    } else if (this.$route.params.mode == 'atributos') {
      this.openPage('atributos')
    }
  },

  computed: {
    router () {
      return this.$route.name
    },

    routerParams () {
      return this.$route.params
    }
  }
}
</script>

<style lang="scss" module>

.logo {
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 230px;
    height: 50px;
  }
}

.config {
  &__row {
    margin: 0;
    padding: 0;

    list-style: none;

    &:not(:last-of-type) {
      margin-bottom: $theme-padding * 2;
    }

    &::before {
      content: attr(data-title);

      margin-bottom: $theme-padding;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      font-weight: 700;
      color: var(--theme-color-1) !important;
      text-transform: uppercase;

      text-align: right;

      display: block;
    }
  }

  &__list {
    &:not(:last-of-type) {
      margin-bottom: $theme-padding;
    }
  }

  &__link {
    position: relative;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(13);
    line-height: 1;
    font-weight: 400;
    color: black !important;
    text-align: right;

    display: block;

    transition: 0.2s ease;
    transition-property: padding-right;

    cursor: pointer;

    :global(.nightlymode) & {
      color: white !important;
    }

    &__active {
      transition: 0.4s ease;
      transition-property: padding-right;

      padding-right: $theme-padding;

      &::before {
        $size: 6px;

        content: '';

        position: absolute;

        top: 50%;
        right: 0;

        width: $size;
        height: $size;

        margin-top: -(math.div($size, 2));

        background: var(--theme-color-1);

        border-radius: $size;

        transition: 1s ease;
      }
    }
  }
}
</style>
