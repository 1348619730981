<template>
  <app-box margin>
    <app-confirm-promise ref="confirm" />
    <app-box background title="Delimitar visualização">
      <div :class="$style.filterDescription">
        Defina abaixo as visualizações para esse perfil de usuário
      </div>
      <div :class="$style.filterDescription" v-if="delimitations.length == 0">
        Nenhuma delimitação vinculada a esse perfil de usuário
      </div>

      <ul
        :class="[$style.filterRow, $style.container]"
        v-for="(subject, index) in delimitations"
        :key="index"
      >
        <li
          :class="$style.filterTitle"
          :style="{
            margin: '15px !important',
            padding: '10px',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }"
        >
          {{ subject.name }}
          <div class="col-auto pl-3">
            <app-tooltip
              label="Remover delimitação"
              v-on:click="removeDelimitation(index)"
            >
              <app-icon
                :class="[$style.filterIcon, $style.filterIconAction]"
                glyph="delete"
              />
            </app-tooltip>
          </div>
        </li>

        <li
          :class="$style.filterList"
          v-for="(data, idx) in subject.talks"
          v-on:dblclick="editDelimitation(idx)"
          :key="idx"
        >
          <div class="row no-gutters align-items-center">
            <div class="col-auto pl-2 pr-3">
              <app-icon :class="$style.filterIcon" glyph="account_tree" />
            </div>

            <div class="col">
              <div
                :class="[
                  $style.filterName,
                  !data.name ? $style.labelEmpty : null,
                ]"
              >
                {{ data.name ? data.name : 'Delimitação sem título' }}
              </div>
            </div>

            <div class="col-auto pl-3">
              <app-tooltip
                label="Remover ítem da delimitação"
                v-on:click="removeItem(index, idx)"
              >
                <app-icon
                  :class="[$style.filterIcon, $style.filterIconAction]"
                  glyph="delete"
                />
              </app-tooltip>
            </div>
          </div>
        </li>
      </ul>

      <app-button
        label="Adicionar nova condição"
        icon="add"
        small
        v-on:click="modal.accessFilter.active = true"
      />
      <app-modal
        v-if="modal.accessFilter.active"
        v-on:close="closeModal()"
        title="Delimitar visualização"
      >
        <access-filter
          v-on:getDelimitations="getDelimitations"
          :delimitation="delimitationEdit"
          v-on:close="closeModal()"
        />
      </app-modal>
    </app-box>
  </app-box>
</template>

<script>
import AccessFilter from './AccessFilter'

export default {
  components: {
    AccessFilter
  },

  props: {
    sharedTalks: Array
  },

  data: function () {
    return {
      delimitationEdit: {},
      modal: {
        filter: {
          active: false,
          filterId: null,
          index: null,
          data: {}
        },

        accessFilter: {
          active: false
        }
      }
    }
  },

  methods: {
    closeModal () {
      this.modal.accessFilter.active = false
    },

    editDelimitation (index) {
      this.delimitationEdit = this.delimitations.filter(
        (item, idx) => idx === index
      )[0]
      this.modal.accessFilter.active = true
    },

    getDelimitations (data) {
      const delimitation = {
        subjectIdentifier: data.identifier,
        name: data.name,
        talks: data.itens
      }

      this.closeModal()

      this.delimitations.push(delimitation)

      return this.$emit('sharedTalks', this.delimitations)
    },

    async removeDelimitation (idx) {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente excluir esta delimitação?', 'red')
        .then(response => {
          this.delimitations.splice(idx, 1)
        })
        .catch(error => console.log(error))

      confirm.close()
    },

    async removeItem (index, idx) {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente excluir este ítem da delimitação?', 'red')
        .then(response => {
          if (this.delimitations[index].talks.length === 1) {
            return this.delimitations.splice(index, 1)
          } else {
            this.delimitations[index].talks.splice(idx, 1)
          }
        })
        .catch(error => console.log(error))

      confirm.close()
    }
  },
  computed: {
    delimitations () {
      return this.sharedTalks || []
    }
  }
}
</script>

<style lang="scss" module>

.container {
  padding: 2px 15px 20px 15px !important;
  margin: 25px 0;
  background: $md-grey-300;

  :global(.nightlymode) & {
    background: $md-grey-900;
  }

  border-radius: 10px;

  &:not(:last-of-type) {
    margin-bottom: $theme-padding;
  }
}

.filter {
  &__row {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;

    list-style: none;
  }

  &__list {
    padding: 10px math.div($theme-padding, 2);

    background: $md-grey-100;

    :global(.nightlymode) & {
      background: $md-grey-800;
    }

    border-radius: 10px;

    &:not(:last-of-type) {
      margin-bottom: $theme-padding;
    }
  }

  &__icon {
    $size: 20px;

    width: $size;
    height: $size;

    fill: $md-grey-500;

    &--action {
      cursor: pointer;

      transition: 0.2s ease;
      transition-property: fill;

      &:hover {
        fill: $md-grey-700;

        :global(.nightlymode) & {
          fill: $md-grey-400;
        }
      }

      &--delete {
        fill: $md-red-500;

        &:hover {
          fill: $md-red-700;
        }
      }
    }
  }

  &__description {
    margin-bottom: $theme-padding * 2;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(13);
    line-height: 1;
    color: black;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__title {
    margin-top: $theme-padding * 2;
    margin-bottom: $theme-padding;

    font-family: 'Raleway', sans-serif;
    @include font-sizer(13);
    line-height: 1;
    color: black;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__name {
    font-family: 'Raleway', sans-serif;
    @include font-sizer(13);
    color: black;

    :global(.nightlymode) & {
      color: white;
    }

    &--empty {
      font-style: italic;
      color: $md-grey-500;
    }
  }

  &__wrapper {
    padding: $theme-padding * 1.5;
  }
}
</style>
