
<template>
  <div :class="$style.box" >
    <app-confirm-promise ref="confirm" />

    <div class="row" v-if="attrSelecteds.length > 0">
      <div class="col-4">
        <app-input-label :class="$style.label">Campos</app-input-label>
      </div>

      <div
        class="col-8"
        v-if="attrSelecteds.filter((x) => x.attributeId !== 0).length > 0"
      >
        <app-input-label :class="$style.label">Valores</app-input-label>
      </div>
    </div>

    <div v-else :class="$style.boxMessage">
      Não há nenhum filtro por campo definido para esse usuário
    </div>

    <transition-group tag="span" name="fade-up">
      <div
        :class="$style.item"
        class="row"
        v-for="(item, index) in attrSelecteds"
        :style="{ zIndex: 999 - index }"
        :key="item.attributeId"
      >
        <div class="col-4">
          <app-select
            :reference="'attr' + index"
            v-on:change-value="selectAttr($event, index)"
            :value="attrSelecteds[index].subjectAttributeId"
            :data="attributes"
            :object-key="{
              id: 'subjectAttributeId',
              label: 'attributeName',
            }"
            :required="index == 0 ? false : true"
          />
        </div>

        <transition name="fade-right">
          <div class="col-7" v-if="attrSelecteds[index].attributeId !== 0">
            <div :class="$style.itemValues">
              <scene-attribute-wrapper
                :data="item.value"
                :attribute="item.data"
                :index="index"
                :active="true"
                mode="filter-attr"
                @change="selectAttrValue($event, index)"
                @change-select="selectAttrValue($event, index)"
                @add-attribute-itens="addAttributeValues($event, index)"
                @change-attribute-itens="changeAttributeValues($event, index)"
              />
            </div>
          </div>
        </transition>

        <div class="col-1 d-flex align-items-center">
          <app-tooltip
            label="Remover campo"
            v-on:click="deleteAttr(index)"
          >
            <app-icon glyph="delete" :class="$style.itemRemove" />
          </app-tooltip>
        </div>
      </div>
    </transition-group>

    <app-button icon="add" label="Novo campo" small @click="emit('add-attr')" />
  </div>
</template>

<script setup>
/* eslint-disable no-undef */
import SceneAttributeWrapper from '@/talkture/scenario/components/AttributeWrapper.vue'
import { useStore } from 'vuex'
import { ref } from 'vue'

const store = useStore()

const emit = defineEmits(['add-attr', 'load-attr-values', 'select-attr', 'delete-attr', 'add-attr-values', 'change-attr-values', 'select-attr-value'])
const props = defineProps({
  attributes: {
    type: Array,
    required: true,
    default: () => ([])
  },
  attrSelecteds: {
    type: Array,
    required: true,
    default: () => ([])
  }
})

const selectAttr = (id, index) => {
  let attr = props.attributes.filter((x) => x.subjectAttributeId == id)[0]
  attr = JSON.parse(JSON.stringify(attr))
  attr.value = attr.data.component.type === 10 ? true : ''
  attr.data.component.itens = []

  emit('select-attr', { attr, index })
}

const confirm = ref(null)
const deleteAttr = async (index) => {
  await confirm.value
    .open('Deseja realmente excluir esse campo?', 'red')
    .then((response) => {
      confirm.value.loading = true
      emit('delete-attr', index)
    })
    .catch((error) => false)

  confirm.value.close()
}

const addAttributeValues = (itens, index) => {
  emit('add-attr-values', { itens, index })
}

const changeAttributeValues = (itens, index) => {
  emit('change-attr-values', { itens, index })
}

const selectAttrValue = (value, index) => {
  emit('select-attr-value', { value, index })
}
</script>

<style lang="scss" module>
  .box {
    padding: $theme-padding;
    background: rgba(0, 0, 0, 0.045);

    border-radius: 7px;

    :global(.nightlymode) & {
      background: darken($md-grey-900, 7%);
    }

    &__message {
      margin-bottom: $theme-padding;

      @include font-sizer(15);
      color: #000;

      :global(.nightlymode) & {
        color: $md-grey-400;
      }
    }
  }

  .item {
    margin-bottom: $theme-padding;

    &__values {
      height: 100%;

      display: flex;
      align-items: center;

      @include font-sizer(14);
      font-weight: 600;
      font-family: 'Raleway', sans-serif;
      text-align: left;

      cursor: pointer;

      p {
        margin: 0;
        color: black;

        :global(.nightlymode) & {
          color: white;
        }
      }
    }

    &__remove {
      fill: $md-red-500;

      cursor: pointer;
    }

    &__add {
      cursor: pointer;
    }
  }

  .values {
    padding: 0 $theme-padding;
    min-height: 50vh;

    &__add {
      margin-top: math.div($theme-padding, 2);

      @include font-sizer(13);
      font-family: 'Raleway', sans-serif;

      color: #fff;
      cursor: pointer;
    }

    &__item {
      height: 50px;
    }

    &__remove {
      fill: $md-red-500;

      cursor: pointer;
    }
  }
</style>
