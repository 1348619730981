export default {
  methods: {
    transformValues (value, component, configs) {
      const config = typeof configs !== 'undefined' ? configs : {}

      switch (component.type) {
        case 1: // Texto Simples
        case 2: // CNPJ
        case 3: // Número Inteiro
        case 6: // Telefone
        case 15: // Colunas Relatório
        case 16: // Lista de Filtro
        case 18: // String do Cron
        case 19: // Time
        case 20: // Lista de Modelos
        case 26: // Texto Multi Linhas
        case 30: // CPF
          return this.transformString(value)
        case 4: // Campo Relacionado
        case 14: // Lista de Assuntos
          return this.transformSelect(value, config)
        case 5: // Unidade de medida
        case 23: // Peso
        case 24: // Moeda
        case 25: // Temperatura Celsius
          return this.transformMeasurement(value, component)
        case 10: // Checkbox
        case 27: // Switch
          return this.transformBoolean(value)
        case 11: // Data / Hora
        case 28: // janela
          return this.transformDateTime(value)
        case 17: // Texto multiplo
          return this.transformMultipleText(value)
        case 31: // Placa
          return this.transformLicense(value)
        case 33: // E-mails multiplos
          return this.transformMultipleText(value)
        case 32: // Data
          return this.transformDate(value)
        case 22: // Arquivo
          return this.transformFile(value, config)
        case 34: // Arquivo
          return this.transformFile(value, config)
        case 29:
          return this.transformJoinObject(value)

        default:
          return Object.prototype.hasOwnProperty.call(value, 'value') ? value.value : value
      }
    },

    transformFile (item, config) {
      if (config.fileNames) {
        const extensions = [
          '.pdf',
          '.xls',
          '.xlsx',
          '.doc',
          '.docx',
          '.xml',
          '.jpg',
          '.jpeg',
          '.png',
          '.bmp',
          '.gif'
        ]

        const list = []

        if (item.value) {
          const filesArray = Array.isArray(item.value)
            ? item.value
            : [item.value]

          filesArray.forEach((file) => {
            const pos = file.name.split('.').length - 1 || 0
            const extension = file.name.split('.')[pos].toLowerCase()
            const valid = extensions.includes('.' + extension)

            if (valid) list.push(file.name)
          })
        }
        return list
      } else {
        return item.value
      }
    },

    transformDateTime (value) {
      const _this = value

      if (_this.value) {
        const date = new Date(_this.value)
        const day = ('0' + date.getDate()).slice(-2)
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const year = date.getFullYear()
        const hour = ('0' + date.getHours()).slice(-2)
        const minutes = ('0' + date.getMinutes()).slice(-2)

        return `${day}/${month}/${year} ${hour}:${minutes}`
      }
    },

    transformDate (value) {
      const _this = value

      if (_this.value) {
        const date = new Date(_this.value)
        const day = ('0' + date.getDate()).slice(-2)
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const year = date.getFullYear()

        return `${day}/${month}/${year}`
      }
    },

    transformMultipleText (value) {
      const _this = value

      if (_this.value) {
        return _this.value.split(';').join('; ')
      }
    },

    transformString (value) {
      const _this = value

      if (typeof _this.value == 'object') {
        return _this.value.name
      } else if (Array.isArray(_this.value)) {
        return _this.value[0].subjectName
      } else {
        return _this.value
      }
    },

    transformSelect (value, config) {
      if (typeof value === 'object' && value.value !== null) {
        if (Array.isArray(value.value) && value.value.length) {
          if (value.value[0].subjectName) {
            return value.value[0].subjectName
          } else {
            return config.isArray
              ? value.value.map(({ name }) => name)
              : value.value.map(({ name }) => name).join(', ')
          }
        }

        if (Object.prototype.hasOwnProperty.call(value, 'value')) {
          if (Object.prototype.hasOwnProperty.call(value.value, 'name')) return value.value.name
          if (Object.prototype.hasOwnProperty.call(value.value, 'value')) return value.value.value
          return value.value
        } else {
          return value
        }
      }
    },

    transformMeasurement (value, component) {
      const precision = component?.config?.componentConfigs?.precision || 2
      const locale =
        component?.config?.componentConfigs?.thousands === ','
          ? 'en-US'
          : 'pt-BR'

      if (!value) {
        return parseFloat('0').toLocaleString(locale, {
          style: 'decimal',
          minimumFractionDigits: precision
        })
      }

      const data = parseFloat(value?.value || value).toFixed(precision)

      return parseFloat(data).toLocaleString(locale, {
        style: 'decimal',
        minimumFractionDigits: precision
      })
    },

    transformBoolean (value) {
      if (typeof value.value == 'string') {
        return value.value === 'true' ? 'Sim' : 'Não'
      } else {
        return value.value ? 'Sim' : 'Não'
      }
    },

    transformJoinObject (value) {
      const list = []

      value.value.map((item) => {
        list.push(item.labelFrom.join(', ') + ' - ' + item.labelTo[0].name)
      })

      return list
    },

    transformLicense (value) {
      return this.transformString(value).toUpperCase()
    },

    getAttrTransformed (attrType) {
      switch (attrType) {
        case 4:
        case 14:
          return 'select'
        case 22:
        case 34:
          return 'file'
        default:
          return 'default'
      }
    },

    getConfirmMessage (newValue, original) {
      let fromText = ''
      let toText = ''

      const attrType = newValue.attribute.component.type

      const getMessage = {
        file: () => {
          fromText = original ? this.getConfirmMessageFile(original, newValue.attribute.component) : '(vazio)'
          toText = this.getConfirmMessageFile(newValue, newValue.attribute.component)
        },
        select: () => {
          fromText = original ? this.getConfirmMessageSelect(original, 'original', newValue.attribute.component) : '(vazio)'
          toText = this.getConfirmMessageSelect(newValue, 'new', newValue.attribute.component) || '(vazio)'
        },
        default: () => {
          fromText = original ? this.transformValues(original, newValue.attribute.component) : '(vazio)'
          toText = newValue.value ? this.transformValues(newValue, newValue.attribute.component) : '(vazio)'
        }
      }

      getMessage[this.getAttrTransformed(attrType)]()

      if (this.type === 'update' && attrType === 6 && fromText !== '(vazio)' && toText !== '(vazio)') {
        fromText = fromText.replace(/\D+/g, '')
        toText = toText.replace(/\D+/g, '')
      }

      if (this.type === 'update') {
        return fromText !== toText
          ? `<p class="mt-3 mb-0"><b>${newValue.attribute.name}:</b></p>
                <p class="mb-0">De: ${fromText}</p>
                <p class="mb-0">Para: ${toText}</p>`
          : ''
      } else {
        return `<p class="mt-3 mb-0"><b>${newValue.attribute.name}:</b></p>
                <p class="mb-0">${toText}</p>`
      }
    },

    getConfirmMessageFile (array, component) {
      let message = ''

      const files = array
        ? this.transformValues(array, component, {
          fileNames: true
        })
        : '(vazio)'

      if (files.length) {
        files.forEach((o) => {
          message += `${message} <p class="mb-0 mt-2">- ${o}</p>`
        })
      } else message = files

      return message
    },

    getConfirmMessageSelect (array, type, component) {
      let message = ''

      const condition = type === 'original' ? typeof array !== 'undefined' : JSON.stringify(array.value) !== '[]'
      let items = condition ? this.transformValues(array, component, { isArray: true }) : '(vazio)'
      items = type === 'original' && items === '' ? '(vazio)' : items

      if (items !== '(vazio)' && Array.isArray(items)) {
        if (items.length > 1) {
          items.forEach((o) => {
            message += `<p class="mb-0 mt-2">- ${o}</p>`
          })
        } else message = items[0]
      } else message = items
      return message
    }
  }
}
