<template>
  <div>
    <app-datepicker
      :readonly="readonly"
      :invalidMessage="invalidMessage"
      :activeHours="activeHours"
      :required="config.required"
      :disabledDates="disabledDates"
      :value="value"
      @change="change"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import AppDatepicker from '@/components/datepicker/AppDatepicker.vue'

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

export default {
  components: {
    AppDatepicker
  },

  emits: ['change-input'],

  props: {
    activeHours: Boolean,
    value: [Date, String, Boolean],
    mode: String,
    block: Boolean,
    talkId: Number,
    config: Object
  },

  data () {
    return {
      invalidMessage: '',
      changed: false,
    }
  },

  methods: {
    change (value) {
      this.$emit('change-input', dayjs(value).isValid() ? this.getFormattedDate(value, 'YYYY/MM/DD HH:mm') : value)
      this.changed = true
    },

    validateGreaterLesserDates(date, referenceDate, rule, validateTime) {
      const format = validateTime && this.activeHours
        ? 'YYYY-MM-DD HH:mm'
        : 'YYYY-MM-DD'

      const differenceUnit = validateTime && this.activeHours
        ? 'minute'
        : 'day'

      const formattedDate = dayjs(date).format(format)
      const formattedReferenceDate = dayjs(referenceDate).format(format)

      if (rule === 'daysNotGreaterThan') {
        return dayjs(formattedDate).diff(formattedReferenceDate, differenceUnit) < 0
      }

      if (rule === 'daysGreaterThan') {
        return dayjs(formattedDate).diff(formattedReferenceDate, differenceUnit) > 0
      }

      return false
    },

    toggleDaysNotGreaterThan (date, validateTime) {
      const referenceDate = this.config.componentConfigs.daysNotGreaterThan

      if (referenceDate) {
        return this.validateGreaterLesserDates(date, referenceDate, 'daysNotGreaterThan', validateTime)
      }

      return false
    },

    toggleDaysGreaterThan (date, validateTime) {
      const referenceDate = this.config.componentConfigs.daysGreaterThan

      if (referenceDate) {
        return this.validateGreaterLesserDates(date, referenceDate, 'daysGreaterThan', validateTime)
      }
    },

    toggleNationalHolidays (date) {
      let disable = false

      if (!this.config.componentConfigs.nationalHolidays) return disable
      else {
        const year = dayjs().year()
        // Algoritmo de Meeus/Jones/Butcher para conseguir o dia e o mês da páscoa pelo ano
        const a = year % 19
        const b = Math.floor(year / 100)
        const c = year % 100
        const d = Math.floor(b / 4)
        const e = b % 4
        const f = Math.floor((b + 8) / 25)
        const g = Math.floor((b - f + 1) / 3)
        const h = (19 * a + b - d - g + 15) % 30
        const i = Math.floor(c / 4)
        const k = c % 4
        const l = (32 + 2 * e + 2 * i - h - k) % 7
        const m = Math.floor((a + 11 * h + 22 * l) / 451)
        const month = Math.floor((h + l - 7 * m + 114) / 31)
        const day = 1 + ((h + l - 7 * m + 114) % 31)

        const value = `${month}/${day}/${year}`

        const holidays = [
          {
            day: dayjs(`${year}0101`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Confraternização Universal'
          },
          {
            day: dayjs(value)
              .subtract(47, 'days')
              .format('YYYY/MM/DD'),
            description: 'Carnaval'
          },
          {
            day: dayjs(value)
              .subtract(2, 'days')
              .format('YYYY/MM/DD'),
            description: 'Sexta-Feira Santa'
          },
          {
            day: dayjs(value).format('YYYY/MM/DD'),
            description: 'Páscoa'
          },
          {
            day: dayjs(`${year}0421`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Tiradentes'
          },
          {
            day: dayjs(`${year}0501`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Dia do Trabalho'
          },
          {
            day: dayjs(value)
              .add(60, 'days')
              .format('YYYY/MM/DD'),
            description: 'Corpus Christi'
          },
          {
            day: dayjs(`${year}0907`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Independência do Brasil'
          },
          {
            day: dayjs(`${year}1012`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Nossa Senhora Aparecida'
          },
          {
            day: dayjs(`${year}1102`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Finados'
          },
          {
            day: dayjs(`${year}1115`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Proclamação da República'
          },
          {
            day: dayjs(`${year}1225`, 'YYYY/MM/DD').format('YYYY/MM/DD'),
            description: 'Natal'
          }
        ]

        let isHoliday = 0

        holidays.map((holiday) =>
          dayjs(holiday.day, 'YYYYMMDD') === dayjs(date, 'YYYYMMDD')
            ? ++isHoliday
            : isHoliday
        )

        if (isHoliday > 0) return true

        let daysToIncrement = this.config.componentConfigs
          .businessDayToIncrement

        if (holidays.includes(date)) ++daysToIncrement

        disable = this.toggleAfterDays(date, daysToIncrement)

        return disable
      }
    },

    toggleWeekends (date) {
      return this.config.componentConfigs.weekends
        ? dayjs(date, 'dddd').toLowerCase() === 'sábado' ||
          dayjs(date, 'dddd').toLowerCase() === 'domingo'
        : false
    },

    toggleSpecificDates (date) {
      let disable = false

      const specificDates = this.config.componentConfigs.specificDates

      if (specificDates) {
        if (specificDates.length() > 0) {
          specificDates.filter((currentDate) => {
            disable = dayjs(currentDate, 'YYYYMMDD') === dayjs(date, 'YYYYMMDD')
          })
        }
      }

      return disable
    },

    toggleAfterDays (date, daysToIncrement) {
      let disable = false

      let businessDays = 0

      for (let i = 0; i <= daysToIncrement; i++) {
        const newDate = dayjs(date)
          .add(i, 'days')
          .format('YYYYMMDD')
        if (this.toggleWeekends(newDate)) {
          const finalDate = dayjs(newDate, 'dddd').toLowerCase()
          if (finalDate === 'sábado') businessDays = businessDays + 1
          else if (finalDate === 'domingo') businessDays = businessDays + 2
        }
      }

      const totalDays = daysToIncrement + businessDays

      disable =
        dayjs(date, 'YYYYMMDD') >
        dayjs(Date.now())
          .add(totalDays, 'days')
          .format('YYYYMMDD')

      return disable
    },

    getFormattedDate (date, format) {
      if (date == null && format == null) return dayjs()
      return dayjs(date).format(format)
    }
  },

  computed: {
    isValid () {
      let isValidConfig = []
      if (this.config.componentConfigs && this.value) {
        const allConfigs = Object.keys(this.config.componentConfigs)
        isValidConfig = allConfigs.map(config => {
          const validationStatus = {
            status: true,
            message: ''
          }

          switch (config) {
            case 'daysNotGreaterThanToday':
              if (this.changed) {
                validationStatus.status = dayjs(this.value).isSameOrAfter(dayjs(), 'minute')
                validationStatus.message = `A data/hora digitada não pode ser menor que data/hora atual: ${dayjs().format('DD/MM/YYYY HH:mm')}`
              }
              break
            case 'daysGreaterThan':
              validationStatus.status = !this.toggleDaysGreaterThan(this.value, true)
              validationStatus.message = `A data/hora digitada não pode ser maior que ${dayjs(
                  this.config.componentConfigs.daysGreaterThan
                ).format('DD/MM/YYYY HH:mm')}`
              break
            case 'daysNotGreaterThan':
              validationStatus.status = !this.toggleDaysNotGreaterThan(this.value, true)
              validationStatus.message = `A data/hora digitada não pode ser menor que ${dayjs(
                  this.config.componentConfigs.daysNotGreaterThan
                ).format('DD/MM/YYYY HH:mm')}`
              break
            case 'nationalHolidays':
              validationStatus.status = !this.toggleNationalHolidays(this.value)
              validationStatus.message = 'A data digitada não pode ser feriado nacional'
              break
            case 'weekends':
              validationStatus.status = !this.toggleWeekends(this.value)
              validationStatus.message = 'A data digitada não pode ser final de semana'
              break
            case 'specificDates':
              validationStatus.status = !this.toggleSpecificDates(this.value)
              validationStatus.message = 'A data digitada não pode ser a especificada'
              break
            default:
              break
          }

          return validationStatus
        })
      }
      return isValidConfig
    },

    areAllValid () {
      return this.isValid.every((item) => item.status)
    },

    readonly () {
      const permission = this.talkId != 0 && typeof this.talkId != 'undefined'
        ? this.config.actions.update
        : this.config.actions.insert

      return this.config.readonly || this.block || !permission
    },

    disabledDates: {
      get () {
        return (date) => {
          if (this.config?.componentConfigs) {
            if (this.toggleWeekends(date)) return true

            if (this.toggleNationalHolidays(date)) return true

            if (this.toggleSpecificDates(date)) return true

            const allConfigs = Object.keys(this.config.componentConfigs)

            const results = allConfigs.map(config => {
              if (this.config.componentConfigs[config]) {
                let currentResult
                switch (config) {
                  case 'daysNotGreaterThanToday':
                    currentResult = !dayjs(date).isSameOrAfter(dayjs(), 'd')
                    break
                  case 'daysNotGreaterThan':
                    currentResult = this.toggleDaysNotGreaterThan(date)
                    break
                  case 'daysGreaterThan':
                    currentResult = this.toggleDaysGreaterThan(date)
                    break
                  default:
                    currentResult = false
                }
                return currentResult
              }
              return false
            })

            return results.some((x) => x)
          }
        }
      }
    }
  },

  watch: {
    isValid: {
      handler: function (value) {
        this.$nextTick(() => {
          if (!this.areAllValid) {
            this.invalidMessage = value.find(item => !item.status)?.message
          } else {
            this.invalidMessage = ''
            this.changed = false
          }
        })
      },
      deep: true
    }
  }
}
</script>
