<template>
  <div
    :class="[
      $style.footer,
      sticky ? $style.footerSticky : null,
      !validate ? $style.footerInvalid : null,
    ]"
  >
    <div :class="$style.footerMessage" class="mb-4" v-if="!validate">
      <app-icon glyph="warning" />
      <span v-html="message" />
    </div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <app-button
          label="OK"
          color="green"
          icon="done"
          small
          type="submit"
          v-on:click="$emit('ok')"
          :disabled="!validate"
        />
      </div>

      <div class="col-auto">
        <app-button
          label="Cancelar"
          color="orange"
          icon="close"
          small
          v-on:click="$emit('cancel')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sticky: Boolean,
    validate: {
      type: Boolean,
      default: true
    },
    message: {
      type: String,
      default: 'Preencha todos os dados corretamente antes de continuar'
    }
  }
}
</script>

<style lang="scss" module>

.footer {
  padding: 20px;

  border-top: 1px solid rgba(black, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  position: relative;

  &--sticky {
    position: sticky;

    bottom: 0;

    background: white;

    :global(.nightlymode) & {
      background: $md-grey-800;
    }
  }

  &--invalid {
    padding-bottom: 40px;
  }

  &__message {
    padding: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    border-radius: 8px;
    background: rgba($md-red-500, 0.2);

    span {
      font-family: 'Raleway', sans-serif;
      line-height: 1;
      font-weight: 400;
      @include font-sizer(13);
      color: black;

      :global(.nightlymode) & {
        color: white;
      }
    }

    svg {
      width: 18px;
      height: 18px;

      margin-right: 5px;

      fill: $md-red-500;
    }
  }
}
</style>
