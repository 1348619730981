import axios from 'axios'

const moduleJob = {
  state: () => ({
    jobs: {
      data: [],
      total: 0
    }
  }),
  getters: {
    getJobs: (state) => state.jobs
  },
  mutations: {
    SET_JOBS (state, { jobs, total }) {
      state.jobs.data = jobs
      state.jobs.total = total
    },
    DELETE_JOB (state, id) {
      const index = state.jobs.data.findIndex((job) => job.jobId === id)
      if (index > -1) {
        state.jobs.data.splice(index, 1)
        state.jobs.total--
      }
    }
  },
  actions: {
    async loadJobs ({ getters, commit }, { page, fetch }) {
      await axios
        .get(`${getters.api}/job?page=${page}&fetch=${fetch}`)
        .then(({ data }) => {
          commit('SET_JOBS', { jobs: data.itens, total: data.totalResults })
        })
        .catch((e) => {
          throw e
        })
    },

    async deleteJob ({ getters, commit }, id) {
      await axios
        .delete(`${getters.api}/job/${id}`)
        .then(() => {
          commit('DELETE_JOB', id)
        })
        .catch((e) => {
          throw e
        })
    }
  }
}

export default moduleJob
