<template>
  <app-view style="height: 100%">
    <transition-group name="fade" tag="span">
      <app-modal
        v-if="modal.remove.active"
        size="480"
        key="confirm_remove"
        z-index="9999999"
      >
        <app-confirm
          :title="modal.remove.title"
          color="red"
          v-on:ok="handleRemoveTalk(modal.remove.ids)"
          v-on:cancel="closeRemoveTalkModal()"
          :loading="modal.remove.loading"
        />
      </app-modal>

        <app-modal
          size="540"
          z-index="9999999"
          v-if="modal.relatedTalks.active"
          key="related_talks"
          v-on:close="closeModalRelatedTalks()"
          :title="modal.relatedTalks.title || 'Aviso de registros relacionados'"
          bg-color="alert"
          :alert="modal.relatedTalks.again"
        >
          <related-talks
            :data="modal.relatedTalks.data"
            v-on:open-talk="openModalTalk"
            v-on:ok="handleRemoveTalk(modal.remove.ids)"
            v-on:cancel="closeModalRelatedTalks()"
            :loading="modal.remove.loading"
            :description="modal.relatedTalks.description"
          />
        </app-modal>

        <app-modal
          size="1080"
          v-if="modal.talk.active"
          z-index="9999999"
          v-on:close="closeModalTalk"
          :title="`Editar Registro: ${modal.talk.id}`"
          :mode="modal.talk.id ? 'talk' : null"
          key="edit_talk"
        >
          <context
            name="scenario_talk"
            scenario="talk"
            type="parent"
            mode="modal"
            :talk="modal.talk.id"
            :subject-prop="modal.talk.subject"
            :edit-multiple="modal.relatedTalks.data && noRender"
            v-on:handle-talk="handleTalk"
            v-on:change-title-modal="changeTitleModal"
            v-on:open-remove-talk-modal="openRemoveTalkModal"
            v-on:handle-remove-talk="handleRemoveTalk"
            v-on:update-related-talks="updateRelatedTalks"
            v-on:close="closeModalTalk"
          />
        </app-modal>
    </transition-group>

    <transition name="fade">
      <app-modal
        size="480"
        v-if="modal.toggleStatus.active"
        key="confirm_toggle_status"
      >
        <app-confirm
          :title="modal.toggleStatus.title"
          :color="modal.toggleStatus.status ? 'orange' : 'green'"
          v-on:ok="toggleStatusTalk()"
          v-on:cancel="closeStatusTalkModal()"
          :loading="modal.toggleStatus.loading"
        />
      </app-modal>
    </transition>

    <keep-alive>
      <transition name="fade" mode="out-in">
        <template v-if="!noRender">
          <scenario-grid-view
            v-if="getRouteScenario == 'grid'"
            :filterSelected="filterId"
            :data="data"
            :filter="{ filter, filterJson }"
            :loading="loading"
            :isActiveList="getRouteQueryStatus"
            :actions="actions"
            :talkUpdate="talkUpdate"
            :mode="mode"
            :talkParent="talkParent"
            :orderTalks="orderTalks"
            :subjectsRelateds="subjectsRelateds"
            :templateRelateds="templateRelateds"
            :template-grid="getRouteTemplate"
            :template-id-default="templateIdDefault"
            :show-talk-id="showTalkIdOnGrid"
            v-on:handle-talk="handleTalk"
            v-on:open-remove-talk-modal="openRemoveTalkModal"
            v-on:init="init(true)"
            v-on:attributes="updateAttributes"
            v-on:handle-remove-talk="handleRemoveTalk"
            v-on:change-attribute-itens="changeAttributeItens"
            v-on:add-attribute-itens="addAttributeItens"
            v-on:pagination="callGridApi2"
            v-on:filter="applyFilter"
            v-on:apply-fast-filter="applyFastFilter"
            v-on:clear-filter="clearFilter"
            v-on:order-grid="orderGrid"
            v-on:toggle-status-talk="openStatusTalkModal"
            v-on:change-active-registers="changeActiveRegisters"
            ref="grid"
          />

          <scenario-form-view
            v-else-if="getRouteScenario == 'form'"
            :fullScreen="fullScreen"
            :data="data"
            :loading="loading"
            :mode="mode"
            :filterParent="filterParent"
            :talkParent="talkParent"
            :templateAreas="templateAreas"
            :previousValues="previousValues"
            v-on:init="init(true)"
            v-on:handle-talk="handleTalk"
            v-on:change-attribute-itens="changeAttributeItens"
            v-on:add-attribute-itens="addAttributeItens"
            v-on:shared-tags="sharedTags"
            v-on:delete-attribute-config-property="
              deleteAttributeConfigProperty
            "
            v-on:set-attribute-config="setAttributeConfig"
            :lockFilter="lockFilter"
            :templateId="templateId"
            v-on:close="close"
            v-on:delete-filter="deleteFilter"
            v-on:close-modal-filter="closeModalFilter"
            v-on:reload="init()"
            v-on:update-form="updateTalkFormView"
            ref="form_view"
          />

          <scenario-talk-view
            v-else-if="getRouteScenario == 'talk'"
            :attributesTalk="subjectData"
            :fullScreen="fullScreen"
            :data="data"
            :idTalk="talk ? talk : parseInt(getRouteTalk)"
            :scenario="getRouteScenario"
            :subject="getRouteSubject"
            :mode="mode"
            :actions="actions"
            :talkMenu="talkMenu"
            :talkParent="talkParent"
            :templateAreas="templateAreaTalk"
            :templateConfigs="template.viewTemplateAreaConfigs"
            :lockFilter="lockFilter"
            :subjectRelated="subjectRelated"
            :templateId="
              templateId ? templateId : template ? template.templateId : null
            "
            :loading="loading"
            :openLogParent="openLog"
            :isAttachments="isAttachments"
            :isTasks="isTasks"
            :previousValues="previousValues"
            v-on:handle-talk="handleTalk"
            v-on:open-remove-talk-modal="openRemoveTalkModal"
            v-on:handle-remove-talk="handleRemoveTalk"
            v-on:change-title-modal="changeTitleModal"
            v-on:change-attribute-itens="changeAttributeItens"
            v-on:add-attribute-itens="addAttributeItens"
            v-on:close="close"
            ref="talk_view"
          />

          <scenario-timeline-view
            v-else-if="getRouteScenario == 'timeline'"
            :data="data"
            :mode="mode"
            :loading="loadingParent || loading"
            v-on:close="close"
          />

          <!-- <scenario-tracking-view
            v-else-if="getRouteScenario == 'tracking'"
            :talk-parent="talkParent"
            :mode="mode"
            v-on:close="close"
          /> -->

          <scenario-help
            v-else-if="getRouteScenario == 'help'"
            :mode="mode"
            v-on:close="close"
          />

          <scenario-attachments-view
            v-else-if="getRouteScenario == 'attachments'"
            :attachments="data.attachments"
            :loading="loading"
            mode="modal"
            v-on:init="init(true)"
            ref="attachments_view"
            :talk-id="talk"
            :subject="getRouteSubject"
            :subjectId="attributesTalkContext.subjectId"
          />

          <scenario-header-active-task
            v-else-if="getRouteScenario == 'activetask'"
            :data="data"
            :openBox="openBoxActiveTask"
            :loading="loading"
            v-on:handle-talk="handleTalk"
            v-on:active-task-handle-fast-task="activeTaskHandleFastTask"
            v-on:update-task-motivo-atraso="updateTaskMotivoAtraso"
            v-on:update-task-estimativas="updateTaskEstimativas"
            v-on:handle-remove-talk="handleRemoveTalk"
            v-on:start-pointer="startPointer"
            v-on:pause-pointer="pausePointer"
            v-on:end-task="activeTaskEndTask"
            v-on:init="init(true)"
            v-on:mouseover="openBoxActiveTask = true"
            v-on:close-task-box="openBoxActiveTask = false"
            ref="active_task"
          />
        </template>
      </transition>
    </keep-alive>

    <app-confirm-promise ref="confirm" />
  </app-view>
</template>

<script>
import ContextEventBus from '@/eventBus'
import Talks from '@/talkture/scenario/talk/mixins/Talks.js'
import Tasks from '@/mixins/Tasks'
import dayjs from 'dayjs'
import TransformValues from './transform-values'
import useTasks from '@/composables/useTasks'
import { setRulesAttributes } from '@/composables/useFormRules'
import { sortBy } from 'lodash-es'
import { onKeyStroke } from '@vueuse/core'
import { defineAsyncComponent, ref, inject } from 'vue'
import { useStore } from 'vuex'
const striptags = require('striptags')

const ScenarioGridView = defineAsyncComponent(() => import('@/talkture/scenario/grid/GridView.vue'))
const ScenarioFormView = defineAsyncComponent(() => import('@/talkture/scenario/form/FormView.vue'))
const ScenarioTalkView = defineAsyncComponent(() => import('@/talkture/scenario/talk/TalkView.vue'))
const ScenarioHelp = defineAsyncComponent(() => import('@/talkture/scenario/help/HelpList.vue'))
const ScenarioTimelineView = defineAsyncComponent(() => import('@/talkture/scenario/timeline/TimelineView.vue'))
// const ScenarioTrackingView = defineAsyncComponent(() => import('@/talkture/scenario/tracking/TrackingView.vue'))
const ScenarioAttachmentsView = defineAsyncComponent(() => import('@/talkture/scenario/attachments/AttachmentsView.vue'))
const ScenarioHeaderActiveTask = defineAsyncComponent(() => import('@/layout/TaskManager.vue'))
const Context = defineAsyncComponent(() => import('@/talkture/context/Context.vue'))
const relatedTalks = defineAsyncComponent(() => import('@/talkture/context/components/related-talks/RelatedTalks.vue'))

export default {
  setup () {
    const store = useStore()
    const { getTaskPermissions: taskPermissions, getAppointmentPermissions: appointmentPermissions } = useTasks(store)

    const modal = ref({
      remove: {
        loading: false,
        active: false,
        ids: [],
        title: ''
      },
      relatedTalks: {
        active: false,
        data: false
      },
      talk: {
        active: false,
        id: false,
        subject: false
      },
      toggleStatus: {
        loading: false,
        active: false,
        ids: null,
        status: null,
        title: ''
      }
    })
    const closeRemoveTalkModal = (related) => {
      modal.value.remove.active = false
      modal.value.remove.title = ''

      if (typeof related == 'undefined') {
        modal.value.remove.ids = []
      }
    }
    const closeStatusTalkModal = () => {
      modal.value.toggleStatus.title = ''
      modal.value.toggleStatus.active = false
      modal.value.toggleStatus.ids = null
      modal.value.toggleStatus.status = null
    }

    onKeyStroke(
      'Escape',
      () => {
        closeRemoveTalkModal()
        closeStatusTalkModal()
      },
      { eventName: 'keydown' }
    )

    const setTalkReadOnly = inject('setTalkReadOnly', null)

    return {
      taskPermissions,
      appointmentPermissions,
      modal,
      closeRemoveTalkModal,
      closeStatusTalkModal,
      setRulesAttributes,
      setTalkReadOnly
    }
  },
  inject: ['webSocket'],
  mixins: [TransformValues, Tasks, Talks],

  filters: {
    escape (value) {
      return striptags(value)
    }
  },

  components: {
    ScenarioGridView,
    ScenarioFormView,
    ScenarioTalkView,
    ScenarioTimelineView,
    // ScenarioTrackingView,
    ScenarioHelp,
    ScenarioAttachmentsView,
    ScenarioHeaderActiveTask,
    Context,
    relatedTalks
  },

  data () {
    return {
      attributesForm: [],
      isActiveList: true,
      initialized: false,
      context: null,
      subjectData: {},
      talks: {},
      template: [],
      templateNames: [],
      templateRelateds: [],
      templateIdDefault: null,
      talkMenu: [],
      lastTalk: 999999,
      data: {
        context: null,
        attributes: [],
        subject: {},
        talks: [],
        pagination: {
          page: 1,
          per_page: 50,
          total: 0,
          option_per_page: [10, 25, 50, 100]
        },
        dis: {},
        attachments: []
      },
      actions: {},
      tempActions: {},
      filter: [],
      filterJson: [],
      filterId: 0,
      loading: {
        ready: false,
        render: false,
        active: false,
        error: false,
        response: {},
        taskInsert: false,
        taskInsertInit: {
          active: false,
          caption: null
        },
        taskEnd: false
      },
      exclude: [
        'label',
        'raw',
        'collapse',
        'options',
        'conditions',
        'component'
      ],
      talkUpdate: {
        haveUpdate: false,
        talk: {}
      },
      subjectIdentifier: '',
      templateAreaTalk: [],
      orderTalks: null,
      subjectsRelateds: null,
      internalSubjectAttributes: [],
      showTalkIdOnGrid: false,
      openBoxActiveTask: false,
      selectedChatRoom: null,
      selectedMessageRoom: null,
      rooms: [
        {
          roomId: 0,
          displayName: 'Geral',
          roomName: 'Geral',
          roomColor: 'var(--theme-color-1)'
        }
      ]
    }
  },

  props: {
    attributesTalkContext: Object,
    noRender: Boolean,
    editMultiple: Boolean,
    type: {
      type: String,
      default: 'parent'
    },
    scenario: String,
    attributes: Array,
    subjectProp: [String, Object],
    filterParent: Object,
    mode: String,
    lockFilter: Boolean,
    talk: [Object, Number, Array, String],
    openLog: Boolean,
    actionsParent: null,
    talkParent: {
      type: Object,
      default: null
    },
    templateAreas: Array,
    subjectRelated: Object,
    templateId: Number,
    loadingParent: Object,
    templateConfigs: Array,
    isAttachments: Boolean,
    isTasks: Boolean,
    previousValues: Array,
    talkExpandedMenu: Boolean,
    talkExpandedChat: Boolean,
    fullModalChat: Boolean,
    fullScreen: Boolean
  },

  created () {
    this.init()
  },

  beforeMount () {
    if (this.attributes) {
      this.data.attributes = this.attributes
    }

    if (this.talk) {
      this.data.talks = this.talk
    } else if (
      this.$route.params.talk != 'add' &&
        typeof this.$route.params.talk != 'undefined'
    ) {
      this.data.talks = parseInt(this.$route.params.talk)
    }

    const config = this.$props.templateConfigs
      ? this.$props.templateConfigs.filter((item) => item.configId == 8)[0]
      : undefined

    if (config) {
      this.$store.commit('setTemplateFilterId', config)
    }
  },

  mounted () {
    ContextEventBus.$on('set_active_task_header', (data) => {
      if (this.getRouteScenario == 'activetask') {
        window.clearInterval(this.ticker)

        if (data) {
          const obj = JSON.parse(JSON.stringify(data))
          if (!this.data.talks.length) {
            this.data.talks.push(this.transformTalk2(obj))
          } else {
            this.data.talks[0] = this.transformTalk2(obj)
          }
          this.tick(this.data.talks[0].id)
        } else {
          this.data.talks.splice(0, 1)
        }
      }
    })

    ContextEventBus.$on('init', (data) => {
      if (this.getRouteScenario == 'activetask') {
        this.callTasksAPI({ pagination: 1 })
      }
    })

    if (this.getRouteScenario == 'grid') {
      this.subjectIdentifier = this.getRouteSubject

      this.webSocket.on('SendTalkUpdates', (data) => {
        const haveUpdate =
            data.subject.subjectIdentifier.toLowerCase() ==
            this.subjectIdentifier.toLowerCase()

        const user = this.$store.getters.user // JSON.parse(localStorage.getItem('talkture_user'));

        if (haveUpdate && data.updatedByAccountId != user.data.accountId) {
          this.talkUpdate = {
            haveUpdate: haveUpdate,
            talk: data
          }
        }
      })

      this.webSocket.on('PublishTalkTag', (data) => {
        const haveUpdate =
            data.subject.subjectIdentifier.toLowerCase() ==
            this.subjectIdentifier.toLowerCase()

        if (haveUpdate) {
          this.talkUpdate = {
            haveUpdate: haveUpdate
          }
        }
      })
    }
  },

  methods: {
    setAttributeConfig ({ config, index, value }) {
      switch (config) {
        case 'params':
          this.data.attributes[index].component.config.params = {
            identifier: value.identifier, value: value.value
          }
          break
        case 'actions':
          if (!this.data.attributes[index].component.config.immutableActions[value.identifier]) return
          this.data.attributes[index].component.config.actions[value.identifier] = value.value
          break
        case 'required':
          this.data.attributes[index].component.config.required = value
          break
        case 'daysGreaterThan':
          this.data.attributes[index].component.config.componentConfigs = {
            ...this.data.attributes[index].component.config.componentConfigs,
            daysGreaterThan: value
          }
          break
        case 'daysNotGreaterThan':
          this.data.attributes[index].component.config.componentConfigs = {
            ...this.data.attributes[index].component.config.componentConfigs,
            daysNotGreaterThan: value
          }
          break
        case 'related':
          this.data.attributes[index].component.config.related = Array.isArray(value) ? value[0].name : value.name
          break
        case 'name':
          this.data.attributes[index].name = value
          break
        case 'inBetween':
          this.data.attributes[index].component.config.componentConfigs = {
            ...this.data.attributes[index].component.config.componentConfigs,
            inBetween: value
          }
          break
        default:
          break
      }
    },
    getAttributesWithValues () {
      const attributes = JSON.parse(JSON.stringify(this.data.attributes))

      attributes.forEach((attribute) => {
        const talkValues = []

        const talks = JSON.parse(JSON.stringify(this.data.talks))

        talks.forEach(({ values }) => {
          const value = values.find(
            ({ subjectAttributeId }) => subjectAttributeId === attribute.id
          )

          if (value) {
            const v = JSON.parse(JSON.stringify(value))

            if (v.value !== null) {
              value.value = this.transformValues(v, attribute.component)

              talkValues.push(value)
            }
          }
        })

        attribute.values = talkValues
      })

      this.data.attributes = attributes
    },

    changeActiveRegisters () {
      this.isActiveList = !this.isActiveList
    },

    applyFilter (payload) {
      this.data.pagination.page = 1

      this.filterId = payload
    },

    applyFastFilter (filter) {
      this.data.pagination.page = 1

      this.filterJson = filter
    },

    clearFilter () {
      this.filterId = null
    },

    updateTalkFormView (talk) {
      this.talks = { itens: [talk] }
      this.transformTalks(talk.talkId)
    },

    async init (check) {
      this.$nextTick(() => {
        this.$store.commit('setCertifyError', false)

        if (this.mode == 'modal') {
          this.$store.commit('setLoadingTalkMenu', true)
        }

        if (!this.noRender) {
          this.talkUpdate = {
            haveUpdate: false,
            talk: {}
          }

          const _this = this

          const choose = {
            grid: async function () {
              await _this.callGridApi2(!!check)
            },
            talk: async function () {
              await _this.callTalkApi2()
            },
            form: async function () {
              await _this.callFormApi2()
            },
            timeline: async function () {
              await _this.callTimelineApi()
            },
            help: async function () {},
            attachments: async function () {
              await _this.callAttachmentsAPI()
            },
            activetask: async function () {
              await _this.callTasksAPI()
            }
          }

          const scenario = this.getRouteScenario.toLowerCase()

          scenario ? choose[scenario]() : null

          this.$store.commit('setLoadingTalkMenu', false)
        }
      })
    },

    verifyRepeatedValues (array, value) {
      return array.some((x) => x == value)
    },

    async callAttachmentsAPI () {
      this.resetContext()

      this.loading.active = true
      this.loading.ready = false
      this.loading.render = false
      this.loading.error = false

      const _this = this

      this.subjectData = this.attributesTalkContext

      try {
        await this.callAttachments()
        await this.callIconsAttachments()

        _this.loading.ready = true
        _this.loading.active = false
      } catch (error) {
        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error
      }
    },

    callAttachments () {
      return new Promise((resolve, reject) => {
        if (this.getRouteSubject !== null) {
          const idLink =
              typeof this.getRouteTalk != 'undefined' ? this.getRouteTalk : ''

          this.$http
            .get(
                `${this.$store.getters.api}/talk/${this.getRouteSubject}/${idLink}`
            )
            .then(({ data }) => {
              const attachments = data.talkAttachments
              attachments.forEach((x) => {
                x.value.actorName = x.actorName
                x.value.insertedIn = x.insertedIn
                x.value.subjectIdentifier = this.getRouteSubject
                x.value.talkAttachmentId = x.talkAttachmentId
                x.value.referenceId = data.talkId
                x.value.subjectId = this.subjectData.subjectId
              })

              this.data.attachments = attachments.map((x) => {
                return x.value
              })

              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },

    callIconsAttachments () {
      return new Promise((resolve, reject) => {
        if (this.data.attachments.length > 0) {
          const fileTypes = []

          this.data.attachments.forEach((file) => {
            if (!this.verifyRepeatedValues(fileTypes, file.type)) {
              fileTypes.push(file.type)
            }
          })

          this.$http
            .post(
                `${this.$store.getters.api}/Presentation/GetIconByFileTypeQuery`,
                { FileTypes: fileTypes }
            )
            .then((response) => {
              const icons = response.data.message.map((x) => {
                return {
                  fileType: x.fileType,
                  iconIdentifier: x.icon.iconIdentifier
                }
              })
              this.data.attachments.forEach((file, index) => {
                const icon = icons.find((icon) => icon.fileType == file.type)
                if (icon) {
                  this.data.attachments[index].icon = icon.iconIdentifier
                }
              })
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve()
        }
      })
    },

    async callTasksAPI (args) {
      !args?.pagination && typeof args?.search === 'undefined'
        ? this.resetContext()
        : ''

      this.loading.active = true
      this.loading.ready = false
      this.loading.render = false
      this.loading.error = false

      const _this = this

      this.subjectData = this.attributesTalkContext
      try {
        !args?.pagination && typeof args?.search === 'undefined'
          ? await this.callContextTasks()
          : ''
        !args?.pagination && typeof args?.search === 'undefined'
          ? await this.transformSubject2()
          : ''
        !args?.pagination && typeof args?.search === 'undefined'
          ? await this.transformAttributes('activetask')
          : ''

        window.clearInterval(this.ticker)

        // Só busca por tarefa ativa se usuário tem permissão para visualização
        if (this.taskPermissions.read) {
          this.talks = await this.callActiveTask()
          await this.transformTalks2()

          if (this.data.talks.length) {
            this.tick(this.data.talks[0].id)
          }
        }

        _this.loading.ready = true
        _this.loading.active = false
      } catch (error) {
        console.log(error)
        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error
      }
    },

    async callContext2 () {
      const querys = []

      this.$store.commit('setCertifyError', false)

      const services =
          this.$store.getters.user.data.requireDigitalSignature.length > 0
            ? this.$store.getters.user.data.requireDigitalSignature.map(
              (item) => item.toLowerCase()
            )
            : []

      let certificate

      if (this.getRouteTemplate) {
        if (this.getRouteScenario == 'grid') {
          querys.push('templateId=' + this.getRouteTemplate)
        } else {
          if (this.templateId) querys.push('templateid=' + this.templateId)
        }
      }

      if (this.getRouteTalk) querys.push('talkId=' + this.getRouteTalk)

      try {
        let response = ''

        if (!this.getTemplate) {
          response = await this.$http.get(
            `${this.$store.getters.api}/context/web/${
              this.getRouteScenario == 'talk' || this.getRouteScenario == 'form'
                ? 'talkmodal'
                : this.getRouteScenario
            }/${this.getRouteSubject}${
              querys.length ? '?' + querys.join('&') : ''
            }`
          )

          this.$store.commit('ADD_TEMPLATE', { subject: this.getRouteSubject, scenario: this.getRouteScenario, type: this.talkType, template: response })
        } else response = this.getTemplate

        // busca pelo certificado
        if (
          this.getRouteScenario === 'talk' &&
            services.length > 0 &&
            services.includes(this.getRouteSubject) &&
            this.$store.getters.validadeCertificado < Date.now()
        ) {
          try {
            certificate = await this.$http.get(
                `${this.$store.getters.apiClientCertificate}/certificado.aspx`
            )
            if (response.data.valid === true) {
              const user = response.data.user.split(':')
              this.$store.commit('setNomeCertificado', user[0])
              this.$store.commit('setCpfCertificado', user[1])
              this.$store.commit(
                'setValidadeCertificado',
                dayjs(Date.now()).add(24, 'h')
              )
            } else {
              switch (response.data.accountLogTypeId) {
                case 3: // Token JWT Ausente
                  this.$store.commit('setCertifyError', {
                    active: true,
                    message: `${response.data.message} - Falha na requisição do certificado.`,
                    orientation:
                        'Não foi possível realizar a requisição do certificado digital selecionado, por favor entre em contato com a Localfrio.'
                  })
                  break
                case 4: // Token JWT Inválido
                  this.$store.commit('setCertifyError', {
                    active: true,
                    message: `${response.data.message} - Falha na requisição do certificado.`,
                    orientation:
                        'Não foi possível realizar a requisição do certificado digital selecionado, por favor entre em contato com a Localfrio.'
                  })
                  break
                case 5: // Certificado não localizado na requisição
                  this.$store.commit('setCertifyError', {
                    active: true,
                    message: response.data.message,
                    orientation:
                        'Não foi encontrado um certificado digital vinculado ao seu navegador, por favor realize a instalação de um certificado digital válido para seguir com a solicitação de serviços.'
                  })
                  break
                case 6: // Certificado Inválido
                  this.$store.commit('setCertifyError', {
                    active: true,
                    message: response.data.message,
                    orientation:
                        'O certificado digital selecionado não é válido, por favor realize a validação do certificado em seu navegador para seguir com a solicitação de serviços.'
                  })
                  break
                case 7: // Sem dados ICP-Brasil
                  this.$store.commit('setCertifyError', {
                    active: true,
                    message: `${response.data.message} - Certificado não válido no ICP-Brasil`,
                    orientation:
                        'Não foi possível utilizar o certificado digital selecionado pois ele é inválido para o ICP-Brasil, por favor realize a regularização do certificado junto ao ICP-Brasil para seguir com a solicitação do serviço.'
                  })
                  break
                default:
                  this.$store.commit('setCertifyError', {
                    active: true,
                    message:
                        'Ops! O certificado digital disponível para utilização não é válido ou está inabilitado para utilização.',
                    orientation:
                        'Por favor, feche o navegador, abra-o novamente e acesse  o serviço utilizando um certificado digital válido.'
                  })
                  break
              }
              // this.$store.commit('setCertifyError', {
              //   active: true,
              //   message:
              //     'Ops! O certificado digital disponível para utilização não é válido ou está inabilitado para utilização. Por favor, feche o navegador, abra-o novamente e acesse  o serviço utilizando um certificado digital válido.',
              // });
            }
          } catch (error) {
            this.$store.commit('setCertifyError', {
              active: true,
              message: 'Ops! O certificado digital não foi informado. ',
              orientation:
                  'Por favor, feche o navegador, abra-o novamente e acesse  o serviço utilizando um certificado digital válido.'
            })
            throw new Error(400)
          }
        }

        // monta a estrutura do context
        this.context = response.data.contextId
        this.subjectData = response.data.subject

        const actions = {
          read: !!response.data.subject.actions.Read,
          insert: !!response.data.subject.actions.Create,
          update: !!response.data.subject.actions.Update,
          delete: !!response.data.subject.actions.Delete,
          deactivate: !!response.data.subject.actions.Deactivate
        }

        if (this.getRouteSubject == 'filtro') {
          actions.deactivate = false
        }

        if (
          response.data.relatedSubjects &&
            response.data.relatedSubjects.length > 0
        ) {
          this.subjectsRelateds = response.data.relatedSubjects.map(
            (item) => {
              return {
                attributeId: item.subjectAttributeId,
                subjectIcon: item.relatedSubject.icon || 'view_column',
                subjectIdentifier: item.relatedSubject.subjectIdentifier,
                subjectName: item.relatedSubject.subjectName
              }
            }
          )
        }

        this.tempActions = actions

        if (this.type === 'parent') {
          const query = this.$route.query
            ? Object.entries(this.$route.query)
              .map(([key, val]) => `${key}=${val}`)
              .join('&')
            : ''

          let url
          let context

          if (
            !this.$route.params.talk &&
              !['modal', 'filter'].includes(this.mode) &&
              this.talkParent == null
          ) {
            if (this.getRouteFilters) {
              url = `/context/${this.getRouteScenario}/${
                  this.getRouteSubject
                }${query ? `/?${query}` : ''}`

              if (this.getRouteFilters && this.getRouteTemplate) {
                context = 'context_template_filter'
              } else if (this.getRouteFilters) {
                context = 'context_filter'
              } else {
                context = 'context'
              }

              this.$store.commit('openPage', {
                name: 'context',
                fullPath: url,
                params: {
                  scenario: this.getRouteScenario,
                  subject: this.getRouteSubject
                },
                query: this.$route.query,
                meta: {
                  label: `${
                      this.$route.params.name || this.subjectData.subjectName
                    }${
                      this.getRouteQueryStatus == 'false'
                        ? ' (DESATIVADOS)'
                        : ''
                    }`,
                  icon: this.subjectData.icon
                }
              })

              if (this.talkParent == null) {
                this.$store.commit(
                  'setCurrentPage',
                  this.$store.getters.pages.find((x) => x.fullPath == url)
                )
              }
            } else {
              if (this.getRouteScenario !== null) {
                url = `/context/${this.getRouteScenario}/${
                    this.getRouteSubject
                  }${query ? `/?${query}` : '/'}`

                context = 'context'

                this.$store.commit('openPage', {
                  name: context,
                  fullPath: url,
                  params: {
                    scenario: this.getRouteScenario,
                    subject: this.getRouteSubject
                  },
                  query: this.$route.query,
                  meta: {
                    label: `${
                        this.$route.params.name || this.subjectData.subjectName
                      }${
                        this.getRouteQueryStatus == 'false'
                          ? ' (DESATIVADOS)'
                          : ''
                      }`,
                    icon: this.subjectData.icon
                  }
                })

                if (this.talkParent == null) {
                  this.$store.commit(
                    'setCurrentPage',
                    this.$store.getters.pages.find((x) => x.fullPath == url)
                  )
                }
              }
            }
          }
        }

        if (response.data.template) {
          this.$store.commit(
            'setTemplateName',
            response.data.template.templateName
          )
          this.$store.commit(
            'setTemplateId',
            response.data.template.templateId
          )

          if (response.data.templateType == 6) {
            this.$store.commit(
              'setTemplateType',
              response.data.template.templateType
            )
          }

          if (this.getRouteScenario == 'grid') {
            this.template = response.data.template.viewTemplateAreaConfigs
            this.templateIdDefault = response.data.template.templateId

            this.showTalkIdOnGrid =
                response.data.template.showTalkIdOnGrid || false

            const templateNames = response.data.template.templateAreas
            // this.templateAreas = response.data.template.templateAreas;

            if (templateNames) {
              this.templateNames = templateNames.map((item) => {
                return {
                  subjectAttributeId: item.subjectAttributeId,
                  templateAreaName: item.templateAreaName
                }
              })
            }

            const templateRelateds = response.data.template.relatedTemplates
              ? response.data.template.relatedTemplates
              : []

            if (templateRelateds.length) {
              this.templateRelateds = templateRelateds
            }

            if (response.data.template.order) {
              if (response.data.template.order.length > 0) {
                this.orderTalks = response.data.template.order[0]
              }
            }
          } else {
            this.template = response.data.template
            this.templateAreaTalk = this.template
              ? this.template.templateAreas
              : []
          }
        } else {
          this.template = {
            templateAreas: []
          }

          this.templateAreaTalk = []
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    },

    async orderGrid (data) {
      this.orderTalks = data

      await this.callGridApi2(true)
    },

    resetContext () {
      this.data = {
        attributes: [],
        talks: [],
        subject: {},
        pagination: {
          page: 1,
          per_page: 50,
          total: 0,
          option_per_page: [10, 25, 50, 100, 200, 500]
        },
        dis: {},
        attachments: []
      }
      this.subjectData = {}
      this.talks = {}

      this.modal = {
        remove: {
          loading: false,
          active: false,
          ids: null
        },

        relatedTalks: {
          active: false,
          data: false,
          again: false
        },

        talk: {
          active: false,
          id: false,
          subject: false
        },

        toggleStatus: {
          loading: false,
          active: false,
          ids: null,
          status: null
        }
      }
    },

    openRemoveTalkModal (ids) {
      if (Array.isArray(this.data.talks)) {
        const talksWD = this.data.talks
          .filter((x) => ids.includes(x.id) && x.displayName)
          .map((item) => {
            return '<p>- ' + item.displayName + '</p>'
          })

        const talksWoD = this.data.talks
          .filter((x) => ids.includes(x.id) && x.displayName == '')
          .map((item) => {
            return item.id
          })

        const getPlural = (arr) => {
          return arr > 1 ? 's' : ''
        }
        if (!talksWD.length) {
          this.modal.remove.title = `Você deseja realmente excluir ${
            talksWoD.length
          } registro${getPlural(talksWoD)} selecionado${getPlural(talksWoD)}?`
        } else {
          const others =
            talksWoD.length > 0
              ? `<p> e outro${getPlural(talksWoD)} ${talksWoD.length} registro${getPlural(talksWoD)} selecionado${getPlural(talksWoD)}</p>`
              : ''

          this.modal.remove.title = `Você deseja realmente excluir o(s) item(s) selecionado(s)${others}? <p class="mt-3"></p><b>${talksWD.join('') + '</b>'}`
        }
      } else {
        this.modal.remove.title = 'Você deseja realmente excluir este registro?'
      }

      this.modal.remove.active = true
      this.modal.remove.ids = ids
    },

    closeModalTalk () {
      this.modal.talk.active = false
    },

    openModalTalk (args) {
      this.modal.talk.active = true
      this.modal.talk.id = args.idTalk
      this.modal.talk.subject = args.subject
    },

    closeModalRelatedTalks () {
      this.modal.relatedTalks.active = false
      this.modal.relatedTalks.data = false
      this.modal.relatedTalks.again = false
    },

    openStatusTalkModal (args) {
      const talksWD = this.data.talks
        .filter((x) => args.ids.includes(x.id) && x.displayName != '')
        .map((item) => {
          return '<p>- ' + item.displayName + '</p>'
        })

      const talksWoD = this.data.talks
        .filter((x) => args.ids.includes(x.id) && x.displayName == '')
        .map((item) => {
          return item.id
        })

      let status = ''

      if (args.status) {
        status = 'desativar'
      } else {
        status = 'ativar'
      }

      if (talksWD.length == 0) {
        this.modal.toggleStatus.title = `Você deseja realmente ${status} ${
            talksWoD.length
          } registro${talksWoD.length > 1 ? 's selecionados' : ' selecionado'}`
      } else {
        const others = `<p> ${
            talksWoD.length > 0
              ? ' e outro' +
                (talksWoD.length > 1 ? 's ' : ' ') +
                talksWoD.length +
                ' registro' +
                (talksWoD.length > 1 ? 's selecionado' : ' selecionados') +
                '</p>'
              : ''
          }`

        this.modal.toggleStatus.title = `Você deseja realmente ${status} ${
            talksWD.length > 1 ? 'os itens: ' : 'o item: '
          }<p class="mt-3"></p><b>${talksWD.join('') + '</b>' + others} `
      }

      this.modal.toggleStatus.active = true
      this.modal.toggleStatus.ids = args.ids
      this.modal.toggleStatus.status = args.status
    },

    handleRemoveTalk (args) {
      if (this.noRender) {
        this.$emit('remove')
      } else {
        if (this.type === 'children') {
          this.$emit('open-remove-talk-modal', args)
        } else {
          this.modal.remove.ids = args
          this.modal.remove.loading = true
          this.modal.relatedTalks.again = false

          const urlApiDelete = this.getRouteSubject !== 'reportmodeltemplate'
            ? `${this.$store.getters.api}/talk/${this.getRouteSubject}`
            : `${this.$store.getters.api}/report/model`
          const params = this.getRouteSubject !== 'reportmodeltemplate'
            ? {
                subject: this.getRouteSubject,
                talkIds: this.modal.remove.ids
              }
            : {
                TemplateIds: this.modal.remove.ids
              }

          const _this = this
          const plural = this.modal.remove.ids.length > 1 ? 's' : ''

          this.$http
            .delete(urlApiDelete, {
              data: params
            })
            .then(() => {
              this.$store.dispatch('notification', {
                text: `O${plural} registro${plural} ${
                      this.modal.remove.ids.length > 1 ? 'foram' : 'foi'
                    } excluído${plural} com sucesso!`,
                color: 'green'
              })

              this.modal.remove.loading = false

              const action = {
                grid: () => {
                  _this.modal.remove.ids.forEach((id) => {
                    const index = _this.data.talks.findIndex(
                      (value) => value.id == id
                    )
                    if (index > -1) {
                      _this.data.talks.splice(index, 1)
                      _this.data.pagination.total--
                    }
                    _this.closeRemoveTalkModal()

                    ContextEventBus.$emit('gridCloseModalTalk', { removeChecked: true })
                  })
                },
                talk: () => {
                  if (this.mode === 'modal') { // Ocorre quando clicamos para editar uma talk dentro da modal de registros relacionados (relatedTalks)
                    _this.$emit('update-related-talks', { subject: _this.getRouteSubject, talkId: _this.getRouteTalk, action: 'delete' })
                    _this.$emit('close')
                  } else {
                    _this.$store.commit(
                      'closePage',
                      `/context/talk/${_this.getRouteSubject}/${_this.getRouteTalk}`
                    )
                  }
                }
              }

              if (action[this.getRouteScenario]) action[this.getRouteScenario]()
            })
            .catch(error => {
              if (error?.response?.status == 403) {
                this.closeRemoveTalkModal(true)
                const dataErrors = []

                error.response.data.errors.map((item) =>
                  dataErrors.push({
                    talkId: item.talkId,
                    displayName: item.displayName
                  })
                )

                this.modal.relatedTalks.title =
                      'Não é possível excluir o registro'
                this.modal.relatedTalks.description =
                      error.response.data.errors[0].message
                this.modal.relatedTalks.active = true
                this.modal.relatedTalks.data = dataErrors
                this.modal.relatedTalks.again = true

                this.$store.dispatch('notification', {
                  type: 'alert',
                  text: error.response.data.errors[0].message,
                  error: true
                })
              }

              if (error?.response?.status == 422) {
                this.closeRemoveTalkModal(true)
                this.modal.relatedTalks.active = true

                this.modal.relatedTalks.data =
                      error.response.data.errors ||
                      this.firstCharToLower(error.response.data.Errors)

                this.modal.relatedTalks.again = true

                this.$store.dispatch('notification', {
                  type: 'alert',
                  text: 'Alguns dos registros que você tentou excluir possuem outros registros relacionados.',
                  error: true
                })
              } else {
                this.$store.dispatch('notification', {
                  type: 'error',
                  text: `Ocorreu um erro ao excluir o${plural} registro${plural}. Por favor, tente novamente.`,
                  error: true
                })
              }

              this.modal.remove.loading = false
            })
        }
      }
    },

    async updateRelatedTalks ({ subject, talkId, action }) {
      const _this = this
      _this.modal.relatedTalks.data.forEach((item, key) => {
        const subjectIndex = item.relatedSubjects.findIndex(({ subjectIdentifier }) => subjectIdentifier.toLowerCase() === subject)
        if (subjectIndex > -1) {
          const talkIndex = item.relatedSubjects[subjectIndex].talks.findIndex(talk => talk.talkId === talkId)
          if (talkIndex > -1) {
            const obj = {
              update: async () => {
                const data = await _this.$store.dispatch('getTalk', { subject, talkId })
                item.relatedSubjects[subjectIndex].talks[talkIndex] = data
              },
              delete: () => {
                item.relatedSubjects[subjectIndex].talks.splice(talkIndex, 1)
              }
            }

            if (obj[action]) obj[action]()
          }
        } else {
          if (item.talkId === talkId) _this.modal.relatedTalks.data.splice(key, 1)
        }
      })
    },

    firstCharToLower (array) {
      return array.map((el) => {
        let key
        const keys = Object.keys(el)
        const newobj = {}

        keys.forEach((k) => {
          if (Array.isArray(el[k]) && el[k].length) {
            el[k] = this.firstCharToLower(el[k])
          } else {
            let n = keys.length

            while (n--) {
              key = `${keys[n].charAt(0).toLowerCase()}${keys[n].substring(
                  1,
                  keys[n].length
                )}`
              newobj[key] = el[keys[n]]
            }
          }
        })

        return newobj
      })
    },

    toggleStatusTalk (args) {
      if (this.type == 'children') {
        this.$emit('toggle-status-talk', args)
      } else {
        const plural = this.modal.toggleStatus.ids.length > 1 ? 's' : ''

        let urlApiStatus = null
        let params = null

        this.modal.toggleStatus.loading = true

        if (this.modal.toggleStatus.status) {
          // desativar

          urlApiStatus = `${this.$store.getters.api}/talk/batchDeactivate/${this.getRouteSubject}`

          params = { talkIds: this.modal.toggleStatus.ids }

          this.$http
            .post(urlApiStatus, params)
            .then((response) => {
              this.$store.dispatch('notification', {
                text: `O${plural} registro${plural} ${
                    this.modal.toggleStatus.ids.length > 1 ? 'foram' : 'foi'
                  } desativado${plural} com sucesso!`
              })

              const _this = this

              this.modal.toggleStatus.ids.forEach((id) => {
                const index = _this.data.talks.findIndex(
                  (value) => value.id === id
                )

                _this.data.talks.splice(index, 1)
              })

              this.data.pagination.total =
                  this.data.pagination.total -
                  this.modal.toggleStatus.ids.length

              this.modal.toggleStatus.loading = false

              this.closeStatusTalkModal()
            })
            .catch((error) => {
              this.$store.dispatch('notification', {
                type: 'error',
                text: `Ocorreu um erro ao desativar o${plural} registro${plural}. Por favor, tente novamente.`,
                error
              })

              this.modal.toggleStatus.loading = false

              this.closeStatusTalkModal()
            })
        } else {
          // ativar

          urlApiStatus = `${this.$store.getters.api}/talk/batchActivate/${this.getRouteSubject}`

          params = { talkIds: this.modal.toggleStatus.ids }

          this.$http
            .post(urlApiStatus, params)
            .then((response) => {
              this.$store.dispatch('notification', {
                text: `O${plural} registro${plural} ${
                    this.modal.toggleStatus.ids.length > 1 ? 'foram' : 'foi'
                  } ativado${plural} com sucesso!`
              })

              const _this = this

              this.modal.toggleStatus.ids.forEach((id) => {
                const index = _this.data.talks.findIndex(
                  (value) => value.id === id
                )

                _this.data.talks.splice(index, 1)
              })

              this.data.pagination.total =
                  this.data.pagination.total -
                  this.modal.toggleStatus.ids.length

              this.modal.toggleStatus.loading = false

              this.closeStatusTalkModal()
            })
            .catch((error) => {
              this.$store.dispatch('notification', {
                type: 'error',
                text: `Ocorreu um erro ao ativar o${plural} registro${plural}. Por favor, tente novamente.`,
                error
              })

              this.modal.toggleStatus.loading = false

              this.closeStatusTalkModal()
            })
        }
      }
    },

    closeModalGrid (clearFields, disableLoading) {
      if (clearFields) {
        ContextEventBus.$emit('formClearFields')
      } else if (disableLoading) {
        ContextEventBus.$emit('formDisableLoading')
      } else {
        ContextEventBus.$emit('gridCloseModalTalk')
      }
    },

    transformJoinTalk (idTalkParent, args) {
      const values = args.talk

      if (args.templateForm || values[0] !== null) {
        const area = args.templateForm.map((item) => {
          return item.templateAreasMap
            .filter((y) => y.templateAreaSubjectAttributes.length > 0)
            .find(
              (x) =>
                x.templateAreaSubjectAttributes[0].component
                  .configsRelationship
            )
        })

        if (typeof area !== 'undefined') {
          if (typeof area[0] !== 'undefined') {
            const talks = []

            let val = []

            if (!Array.isArray(values)) {
              val = values.values[area[0].templateAreaSubjectAttributes[0].id]
                ? values.values[area[0].templateAreaSubjectAttributes[0].id]
                  .value
                : null
            } else {
              val = values[area[0].templateAreaSubjectAttributes[0].id]
            }

            if (val) {
              val.forEach((v) => {
                const talk = []

                talk.push({
                  subjectAttributeId:
                      area[0].templateAreaSubjectAttributes[0].component
                        .configsRelationshipAttributes.component
                        .subjectAttributeId,
                  value: v.idTo.length > 0 ? v.idTo[0].id : ''
                })

                talk.push({
                  subjectAttributeId:
                      area[0].templateAreaSubjectAttributes[0].component
                        .configsRelationshipAttributes.source
                        .subjectAttributeId,
                  value: v.idFrom
                })

                talk.push({
                  subjectAttributeId:
                      area[0].templateAreaSubjectAttributes[0].component
                        .configsRelationshipAttributes.target
                        .subjectAttributeId,
                  value: idTalkParent
                })

                talks.push({
                  talkValues: talk
                })
              })

              return talks
            } else {
              return ''
            }
          } else {
            return ''
          }
        } else {
          return ''
        }
      }
    },

    transformOneToMany (idTalkParent, args) {
      const values = args.talk

      if (args.templateForm) {
        const area = args.templateForm.map((item) => {
          return item.templateAreasMap
            .filter((y) => y.templateAreaSubjectAttributes.length > 0)
            .find(
              (x) => x.templateAreaSubjectAttributes[0].component.type === 31
            )
        })

        if (typeof area !== 'undefined') {
          if (typeof area[0] !== 'undefined') {
            let talks = []

            if (Array.isArray(values)) {
              if (values[area[0].templateAreaSubjectAttributes[0].id]) {
                talks =
                    typeof values[
                      area[0].templateAreaSubjectAttributes[0].id
                    ] === 'string'
                      ? talks.push({
                        subjectAttributeId: area[0]
                          .templateAreaSubjectAttributes[0].component.config
                          .subjectParent
                          ? area[0].templateAreaSubjectAttributes[0].component
                            .config.subjectParent.attributeId
                          : '',
                        value:
                            values[area[0].templateAreaSubjectAttributes[0].id]
                      })
                      : values[area[0].templateAreaSubjectAttributes[0].id].map(
                        (item) => {
                          const temp = item.values

                          temp.push({
                            subjectAttributeId:
                                area[0].templateAreaSubjectAttributes[0]
                                  .component.config.subjectParent.attributeId,
                            value: idTalkParent
                          })

                          return temp
                        }
                      )

                return {
                  subjectIdentifier: area[0].templateAreaSubjectAttributes[0]
                    .component.config.subjectParent
                    ? area[0].templateAreaSubjectAttributes[0].component
                      .config.subjectParent.identifier
                    : '',
                  talkValues: Array.isArray(talks)
                    ? talks.map((t) => {
                      return {
                        talkValues: t
                      }
                    })
                    : { talkValues: talks }
                }
              }
            } else {
              if (
                values.values[area[0].templateAreaSubjectAttributes[0].id]
              ) {
                talks = values.values[
                  area[0].templateAreaSubjectAttributes[0].id
                ].value.map((item) => {
                  const v = item.values

                  if (!item.talkId) {
                    v.push({
                      subjectAttributeId:
                          area[0].templateAreaSubjectAttributes[0].component
                            .config.subjectParent.attributeId,
                      value: idTalkParent
                    })
                  }

                  return {
                    talkId: item.talkId,
                    talkValues: v
                  }
                })

                return {
                  subjectIdentifier:
                      area[0].templateAreaSubjectAttributes[0].component.config
                        .subjectParent.identifier,
                  talkValues: talks.map((t) => {
                    return t
                  })
                }
              }
            }
          } else {
            return ''
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },

    async handleTalk (args) {
      if (this.type == 'children') {
        this.$emit('handle-talk', args)
      } else {
        this.attributesForm = args.attributes

        const talk = args.talk

        if (talk.id) {
          if (this.getRouteScenario == 'grid') {
            var index = this.data.talks.findIndex(
              (value) => value.id === talk.id
            )

            this.data.talks[index].sync = true
            this.data.talks[index].editing = true
          }

          let urlApiUpdate = ''
          let updateValues = null

          if (this.getRouteSubject != 'reportmodeltemplate') {
            urlApiUpdate = `${this.$store.getters.api}/talk/${talk.subject}/${talk.id}`
            updateValues = this.transformTalkToSync(talk.values, talk.id)
          } else {
            urlApiUpdate = `${this.$store.getters.api}/report/model/${talk.id}`
            updateValues = this.transformTemplateToSync(talk, talk.id)
          }

          return this.$http
            .put(urlApiUpdate, updateValues)
            .then(async (response) => {
              if (args.templateForm.length) {
                const newJoinTalk = this.transformJoinTalk(talk.id, args)
                const newOneToMany = this.transformOneToMany(talk.id, args)

                if (newJoinTalk) {
                  if (newJoinTalk.length) {
                    await this.$http
                      .post(
                          `${this.$store.getters.api}/talk/averbacaocargatransportadora/many`,
                          newJoinTalk
                      )
                      .then((response) => {
                        this.$store.commit('setAverbacaoTalkId', talk.id)
                      })
                      .catch((error) => {})
                  }
                }

                if (newOneToMany) {
                  if (newOneToMany.talkValues.length) {
                    const newTalks = newOneToMany.talkValues.filter(
                      (x) => !x.talkId
                    )

                    const editTalks = newOneToMany.talkValues.filter(
                      (x) => x.talkId
                    )

                    if (newTalks.length) {
                      await this.$http
                        .post(
                            `${
                              this.$store.getters.api
                            }/talk/${newOneToMany.subjectIdentifier.toLowerCase()}/many`,
                            newTalks
                        )
                        .then((response) => {})
                        .catch((error) => {})
                    }

                    if (editTalks.length) {
                      await this.$http
                        .put(
                            `${
                              this.$store.getters.api
                            }/talk/${newOneToMany.subjectIdentifier.toLowerCase()}/many`,
                            editTalks
                        )
                        .then((response) => {})
                        .catch((error) => {})
                    }
                  }
                }
              }

              if (this.getRouteScenario == 'grid') {
                if (this.getRouteSubject != 'reportmodeltemplate') {
                  return this.$http
                    .get(
                        `${this.$store.getters.api}/talk/${talk.subject}/${talk.id}`
                    )
                    .then((response) => {
                      const talkUpdate = response.data

                      const index = this.data.talks.findIndex(
                        (value) => value.id === talk.id
                      )

                      this.data.talks[index] = this.transformTalk2(talkUpdate)

                      this.data.talks[index].sync = false
                      this.data.talks[index].editing = false

                      this.getAttributesWithValues()

                      this.$store.dispatch('notification', {
                        text: 'Registro atualizado com sucesso!'
                      })

                      if (args?.keepModalOpen) {
                        ContextEventBus.$emit('formUpdateTalk', response.data)
                      } else {
                        this.closeModalGrid(true)
                      }

                      if (this.mode !== 'modal') this.$emit('close')

                      ContextEventBus.$emit('context_handle_talk', args)
                    })
                    .catch((error) => {
                      // eslint-disable-next-line no-console
                      console.log(error)

                      this.$store.dispatch('notification', {
                        text: 'Registro alterado com sucesso, porém será necessário recarregar o grid.',
                        error
                      })

                      this.closeModalGrid()

                      this.init()
                    })
                } else {
                  return this.$http
                    .get(`${this.$store.getters.api}/report/model/${talk.id}`)
                    .then(({ data }) => {
                      const template = data

                      const object = {}

                      object.id = template.templateId
                      object.active = true
                      object.subject = 'reportmodeltemplate'
                      object.tags = []
                      object.values = []

                      const values = []
                      const ids = []

                      if (template.columnsSubjectAttributeId) {
                        ids.push(template.columnsSubjectAttributeId)
                      }
                      if (template.nameSubjectAttributeId) {
                        ids.push(template.nameSubjectAttributeId)
                      }
                      if (template.subjectsSubjectAttributeId) {
                        ids.push(template.subjectsSubjectAttributeId)
                      }

                      const maxId = Math.max.apply(null, ids)

                      for (let index = 0; index <= maxId; index++) {
                        if (index == template.nameSubjectAttributeId) {
                          values.push({
                            tags: [],
                            value: template.name,
                            subjectAttributeId:
                                template.nameSubjectAttributeId,
                            talkId: talk.id
                          })
                        } else if (
                          index == template.subjectsSubjectAttributeId
                        ) {
                          values.push({
                            tags: [],
                            value: template.subjects[0].subjectName
                              ? template.subjects[0].subjectName
                              : template.subjects[0].subjectIdentifier,
                            subjectAttributeId:
                                template.subjectsSubjectAttributeId,
                            talkId: talk.id
                          })
                        } else if (
                          index == template.columnsSubjectAttributeId
                        ) {
                          values.push({
                            tags: [],
                            value: template.columns.length,
                            subjectAttributeId:
                                template.columnsSubjectAttributeId,
                            talkId: talk.id
                          })
                        }
                      }

                      object.values = values

                      const index = this.data.talks.findIndex(
                        (value) => value.id === object.id
                      )

                      this.data.talks[index] = object
                      this.data.talks[index].sync = false
                      this.data.talks[index].editing = false

                      this.getAttributesWithValues()

                      this.$store.dispatch('notification', {
                        text: 'Registro atualizado com sucesso!'
                      })

                      this.closeModalGrid(true)

                      ContextEventBus.$emit('context_handle_talk', args)
                    })
                    .catch((error) => {
                      // eslint-disable-next-line no-console
                      console.log(error)
                      this.$store.dispatch('notification', {
                        text: 'Registro alterado com sucesso, porém será necessário recarregar o grid.',
                        error
                      })

                      this.closeModalGrid(null, true)
                    })
                }
              } else {
                if (this.getRouteSubject === 'tarefa') {
                  const task = await this.getTalk(
                    this.getRouteSubject,
                    talk.id
                  )
                  if (task) {
                    ContextEventBus.$emit('att_task', {
                      id: talk.id,
                      talk: task
                    })
                    this.$emit('close')
                  } else {
                    this.$emit('reload')
                    this.$emit('close')
                  }
                }
                this.$store.dispatch('notification', {
                  text: 'Registro atualizado com sucesso!'
                })

                this.$store.commit(
                  'closePage',
                    `/context/${this.getRouteScenario}/${this.getRouteSubject}/${talk.id}`
                )

                ContextEventBus.$emit('context_handle_talk', args)

                if (this.getRouteScenario == 'talk') {
                  this.$refs.talk_view.$refs.context.$refs.form_view.disableLoading()
                }
              }

              if (this.mode == 'modal') {
                if (this.talkParent == null || this.subjectRelated) {
                  this.$emit('update-related-talks', {
                    subject: talk.subject,
                    talkId: talk.id,
                    action: 'update'
                  })
                  this.$emit('close')
                }
              }
            })
            .catch(({ response }) => {
              // eslint-disable-next-line no-console
              console.log(response)
              Promise.resolve()
                .then(async () => {
                  const errors = response?.data?.Errors || response?.data?.errors
                  if (typeof errors !== 'undefined') {
                    if (!errors.length) return Promise.reject(new Error(400))

                    /* */

                    const confirm = this.$refs.confirm

                    confirm.alert = true

                    await confirm
                      .open(
                          `

                                        <b>As seguintes falhas aconteceram durante a sincronização dos dados:</b>

                                        <p>

                                            <ul>

                                                ${errors
                                                  .map(
                                                    (error) =>
                                                      `<li>${
                                                        error.Message ||
                                                        error.message
                                                      }</li>`
                                                  )
                                                  .join('')}

                                            </ul>

                                        </p>

                                    `,
                          'red'
                      )
                      .then((response) => true)
                      .catch((error) => false)

                    confirm.close()
                  } else {
                    return Promise.reject(new Error(400))
                  }
                })
                .catch((error) => {
                  // eslint-disable-next-line no-console
                  console.log(error)

                  this.$store.dispatch('notification', {
                    type: 'error',
                    text: 'Ocorreu um erro na sincronização dos dados. Por favor, tente novamente.',
                    error
                  })
                })
              if (this.getRouteScenario == 'talk') {
                this.$refs.talk_view.$refs.context.$refs.form_view.disableLoading()
              } else if (
                this.mode !== 'modal' ||
                  this.subjectIdentifier == 'averbacaocargatransportadora'
              ) {
                this.data.talks[index].sync = false
                this.data.talks[index].editing = false

                this.closeModalGrid(null, true)
              } else if (!this.subjectRelated && !this.editMultiple) {
                this.$emit('close')
              } else if (this.subjectRelated || this.editMultiple) {
                if (this.editMultiple) {
                  this.$refs.talk_view.$refs.context.$refs.form_view.disableLoading()
                } else {
                  this.$refs.form_view.disableLoading()
                }
              }
            })
        } else {
          if (talk.length) {
            if (this.getRouteScenario == 'grid') {
              var lastTalk = JSON.parse(JSON.stringify(this.lastTalk))

              const newValue = []

              talk.forEach((item) => {
                newValue.push({ tags: [], value: item })
              })

              const newTalk = {
                id: lastTalk,
                sync: true,
                tags: [],
                values: newValue
              }

              this.data.talks.push(newTalk)

              this.lastTalk--
            }

            let urlApiInsert = ''
            let newValues = null

            if (this.getRouteSubject != 'reportmodeltemplate') {
              urlApiInsert = `${this.$store.getters.api}/talk/${this.getRouteSubject}`
              newValues = this.transformTalkToSync(talk)
            } else {
              urlApiInsert = `${this.$store.getters.api}/report/model`
              newValues = this.transformTemplateToSync(talk)
            }
            return this.$http
              .post(urlApiInsert, newValues)
              .then(async (response) => {
                if (args.templateForm.length) {
                  const newJoinTalk = this.transformJoinTalk(
                    response.data.resourceId,
                    args
                  )

                  const newOneToMany = this.transformOneToMany(
                    response.data.resourceId,
                    args
                  )

                  if (newJoinTalk) {
                    if (newJoinTalk.length) {
                      await this.$http
                        .post(
                            `${this.$store.getters.api}/talk/averbacaocargatransportadora/many`,
                            newJoinTalk
                        )
                        .then((response) => {})
                        .catch((error) => {})
                    }
                  }

                  if (newOneToMany) {
                    if (newOneToMany.talkValues.length) {
                      await this.$http
                        .post(
                            `${
                              this.$store.getters.api
                            }/talk/${newOneToMany.subjectIdentifier.toLowerCase()}/many`,
                            newOneToMany.talkValues
                        )
                        .then((response) => {})
                        .catch((error) => {})
                    }
                  }
                }

                if (this.getRouteScenario == 'grid') {
                  if (
                    this.getRouteSubject != 'reportmodeltemplate' &&
                      Array.isArray(response.data.resourceId)
                  ) {
                    await this.callGridApi2(true)

                    this.closeModalGrid(true)

                    this.$store.commit('setBloqueiaProtocolo', {
                      value: false,
                      subjectAttributeId: ''
                    })

                    this.$store.commit('setBloqueiaRetProtocolo', {
                      subjectAttributeId: '',
                      value: ''
                    })

                    return this.$store.dispatch('notification', {
                      text: `Registro${
                          response.data.resourceId.length > 1 ? 's' : ''
                        } inserido${
                          response.data.resourceId.length > 1 ? 's' : ''
                        } com sucesso!`
                    })
                  }
                  if (this.getRouteSubject === 'chamado') {
                    this.closeModalGrid(true)

                    await this.callGridApi2(true)

                    this.$store.dispatch('notification', {
                      text: 'Registro inserido com sucesso!'
                    })
                  } else if (this.getRouteSubject != 'reportmodeltemplate') {
                    return this.$http
                      .get(
                          `${this.$store.getters.api}/talk/${this.getRouteSubject}/${response.data.resourceId}`
                      )
                      .then((response) => {
                        const talk = response.data
                        let index
                        const talkId = this.data.talks.findIndex(
                          (value) => value.id === response.data.talkId
                        )

                        if (talkId !== -1) {
                          index = talkId
                          this.data.talks.pop()
                        } else {
                          index = this.data.talks.findIndex(
                            (value) => value.id === lastTalk
                          )
                          this.data.pagination.total++
                        }

                        this.data.talks[index] = this.transformTalk2(talk)

                        this.getAttributesWithValues()
                        this.$store.dispatch('notification', {
                          text: 'Registro inserido com sucesso!'
                        })
                        this.$store.commit('setBloqueiaProtocolo', {
                          value: false,
                          subjectAttributeId: ''
                        })

                        this.closeModalGrid(true)

                        ContextEventBus.$emit('context_handle_talk', args)
                      })
                      .catch((error) => {
                        console.log(error)

                        this.$store.dispatch('notification', {
                          text: 'Registro inserido com sucesso, porém será necessário recarregar o grid.'
                        })

                        this.data.talks.pop()

                        this.closeModalGrid()

                        this.init()
                      })
                  } else {
                    const talkId = response?.data?.resourceId || null

                    if (talkId) {
                      return this.$http
                        .get(
                          `${this.$store.getters.api}/report/model/${response.data.resourceId}`
                        )
                        .then((response) => {
                          const template = response.data

                          const object = {}

                          object.id = template.templateId
                          object.active = true
                          object.subject = 'reportmodeltemplate'
                          object.tags = []
                          object.values = []

                          const values = []
                          const ids = []

                          if (template.columnsSubjectAttributeId) {
                            ids.push(template.columnsSubjectAttributeId)
                          }

                          if (template.nameSubjectAttributeId) {
                            ids.push(template.nameSubjectAttributeId)
                          }

                          if (template.subjectsSubjectAttributeId) {
                            ids.push(template.subjectsSubjectAttributeId)
                          }

                          const maxId = Math.max.apply(null, ids)

                          for (let index = 0; index <= maxId; index++) {
                            if (index == template.nameSubjectAttributeId) {
                              values.push({
                                tags: [],
                                value: template.name,
                                subjectAttributeId:
                                  template.nameSubjectAttributeId,
                                talkId
                              })
                            } else if (
                              index == template.subjectsSubjectAttributeId
                            ) {
                              values.push({
                                tags: [],
                                value: template.subjects[0].subjectName
                                  ? template.subjects[0].subjectName
                                  : template.subjects[0].subjectIdentifier,
                                subjectAttributeId:
                                  template.subjectsSubjectAttributeId,
                                talkId
                              })
                            } else if (
                              index == template.columnsSubjectAttributeId
                            ) {
                              values.push({
                                tags: [],
                                value: template.columns.length,
                                subjectAttributeId:
                                  template.columnsSubjectAttributeId,
                                talkId
                              })
                            }
                          }

                          object.values = values

                          let index

                          const talkIdResource = this.data.talks.findIndex(
                            (value) => value.id === talkId
                          )

                          if (talkIdResource !== -1) {
                            index = talkIdResource

                            this.data.talks.pop()
                          } else {
                            index = this.data.talks.findIndex(
                              (value) => value.id === lastTalk
                            )

                            this.data.pagination.total++
                          }

                          this.data.talks[index] = object

                          /* */

                          this.getAttributesWithValues()

                          this.$store.dispatch('notification', {
                            text: 'Registro inserido com sucesso!'
                          })

                          this.$store.commit('setBloqueiaProtocolo', {
                            value: false,
                            subjectAttributeId: ''
                          })

                          this.closeModalGrid(true)

                          ContextEventBus.$emit('context_handle_talk', args)
                        })
                        .catch((error) => {
                        // eslint-disable-next-line no-console
                          console.log(error)

                          this.$store.dispatch('notification', {
                            text: 'Registro inserido com sucesso, porém será necessário recarregar o grid.'
                          })

                          this.closeModalGrid()

                          this.data.talks.pop()
                        })
                    } else {
                      throw response.data
                    }
                  }
                } else {
                  if (this.getRouteSubject == 'filtro') {
                    this.$emit('close-modal', true)

                    this.$store.dispatch('notification', {
                      text: 'Registro inserido com sucesso!'
                    })

                    this.$store.commit('setBloqueiaProtocolo', {
                      value: false,
                      subjectAttributeId: ''
                    })

                    ContextEventBus.$emit('context_handle_talk', args)
                  } else if (this.getRouteSubject === 'tarefa') {
                    const task = await this.getTalk(
                      this.getRouteSubject,
                      response.data.resourceId
                    )
                    if (task) {
                      ContextEventBus.$emit('att_task', {
                        id: response.data.resourceId,
                        talk: task
                      })
                      this.$emit('close')
                    } else {
                      this.$emit('reload')
                      this.$emit('close')
                    }
                  }

                  if (!args.lockPage && this.getRouteSubject != 'filtro') {
                    const url = response.headers
                      .get('Location')
                      .toLowerCase()
                      .split('/')

                    this.$router.push({
                      name: 'context_id',
                      params: {
                        scenario: this.getRouteScenario,
                        subject: url[2],
                        talk: url[3]
                      }
                    })
                  }
                }
              })
              .catch((response) => {
                console.log(response)
                Promise.resolve()
                  .then(async () => {
                    const errors =
                        response?.data?.Errors || response?.data?.errors
                    if (typeof errors !== 'undefined') {
                      if (!errors.length) {
                        return Promise.reject(new Error(400))
                      }

                      /* */

                      const confirm = this.$refs.confirm

                      confirm.alert = true

                      await confirm
                        .open(
                            `

                                        <b>As seguintes falhas aconteceram durante a sincronização dos dados:</b>

                                        <p>

                                            <ul>

                                                ${errors
                                                  .map(
                                                    (error) =>
                                                      `<li>${
                                                        error.Message ||
                                                        error.message
                                                      }</li>`
                                                  )
                                                  .join('')}

                                            </ul>

                                        </p>

                                    `,
                            'red'
                        )
                        .then((response) => true)
                        .catch((error) => false)

                      confirm.close()
                    } else {
                      return Promise.reject(new Error(400))
                    }
                  })
                  .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error)

                    this.$store.dispatch('notification', {
                      type: 'error',
                      text: 'Ocorreu um erro na sincronização dos dados. Por favor, tente novamente.',
                      error: true
                    })
                  })

                if (this.getRouteScenario == 'talk') {
                  this.$refs.talk_view.$refs.context.$refs.form_view.disableLoading()
                } else if (this.getRouteScenario == 'form') {
                  this.$refs.form_view.disableLoading()
                } else {
                  this.closeModalGrid(null, true)
                }

                this.data.talks.pop()
              })
          }
        }
      }
      this.data.attributes.map((item) =>
        item.identifier === 'CPFCertificado'
          ? this.$store.commit('setCpfCertificadoId', '')
          : false
      )
      this.data.attributes.map((item) =>
        item.identifier === 'NomeCertificado'
          ? this.$store.commit('setNomeCertificadoId', '')
          : false
      )
    },

    transformTemplateToSync (template, id) {
      const newTemplate = {
        name: '',
        subjects: [],
        relatedSubjects: [],
        columns: []
      }

      if (typeof id !== 'undefined') {
        newTemplate.talkId = id
      }

      /* */

      (typeof id !== 'undefined' ? template.values : template).forEach(
        (value, index) => {
          if (value) {
            let newValue = null

            if (value.tags) {
              newValue = value.value
            } else {
              newValue = value
            }

            const attr = this.data.attributes.filter((x) => x.id == index)[0]

            if (attr.component.type == 1) {
              // name

              newTemplate.name = newValue
            } else if (attr.component.type == 14) {
              // subject (main subject)
              newTemplate.subjects.push({
                subjectId: newValue[0].subjectId
              })

              // relatedSubjects
              newValue.forEach((item, index) => {
                if (index > 0) {
                  newTemplate.relatedSubjects.push({
                    relatedSubjectId: item.relatedSubjectId,
                    relationshipSubjectAttributeId:
                        item.relationshipSubjectAttributeId,
                    relationshipOwnerSubjectId:
                        item.relationshipOwnerSubjectId,
                    order: item.order
                  })
                }
              })
            } else if (attr.component.type == 15) {
              // columns

              newValue.forEach((column, columnIndex) => {
                column.order = columnIndex

                column.attributes.forEach((attribute, attributeIndex) => {
                  attribute.order = attributeIndex
                  delete attribute.id
                })

                newTemplate.columns.push(column)
              })
            }
          }
        }
      )

      /* */

      const objTemplate = {
        reportModel: newTemplate
      }

      return objTemplate
    },

    transformTalkToSync (values, id) {
      const newTalk = {
        subject: this.getRouteSubject,
        talkValues: []
      }

      values.forEach((value, index) => {
        const config = this.subjectData.subjectAttributes.find(
          (x) => x.subjectAttributeId === index
        )
        // Condição: value e config devem ser diferentes de undefined. Caso for formulário de criação, valor deve estar preenchido.
        // Caso contrário, atributo referente ao valor deve ter permissão para ser editado e não pode ser do tipo arquivo e estar vazio (revisar)
        const condition = typeof value !== 'undefined' && ((!id && value) || (id && config?.actions?.update && (![22, 34].includes(config?.componentId) || value?.value.length)))
        if (condition) {
          let transformedValue

          switch (config.componentId) {
            case 4:
              transformedValue = this.transformValueSelectToSync(value, config.isMultipleValues)
              break
            case 12:
              transformedValue = this.transformValueFilterToSync(value)
              break
            default:
              transformedValue = value?.id || (typeof value == 'undefined' ? null : value)
              break
          }

          if (id) newTalk.talkId = id
          newTalk.talkValues.push({
            subjectAttributeId: index,
            value: transformedValue
          })
        }
      })

      newTalk.talkValues = this.addCertificadoDigital(newTalk.talkValues)
      newTalk.talkValues = newTalk.talkValues.filter(
        (x) => typeof x !== 'undefined'
      )

      newTalk.templateId = this.$store.getters.templateId

      return newTalk
    },

    transformValueSelectToSync (value, isMultipleValues) {
      if (!isMultipleValues) {
        if (Array.isArray(value)) {
          value = value.length ? value : null
        } else value = value?.id || value
      } else {
        if (value?.length === 0) value = null
      }
      return value
    },

    transformValueFilterToSync (value) {
      const json = JSON.parse(value)

      json.map((val) => {
        delete val.raw

        val.attributes.map((attr) => {
          if (attr.operators) {
            attr.operators.map((o) => {
              o.values.map((v) => {
                delete v.attribute.list
              })
            })
          }
        })
      })

      value = striptags(JSON.stringify(json))

      return value
    },

    addCertificadoDigital (values) {
      if (this.$store.getters.nomeCertificadoId &&
        !values.some(
          (item) =>
            item.subjectAttributeId === this.$store.getters.nomeCertificadoId
        )) {
        values.push({
          subjectAttributeId: this.$store.getters.nomeCertificadoId,
          value: this.$store.getters.nomeCertificado
        })
      }

      if (this.$store.getters.cpfCertificadoId &&
        !values.some(
          (item) =>
            item.subjectAttributeId === this.$store.getters.cpfCertificadoId
        )) {
        values.push({
          subjectAttributeId: this.$store.getters.cpfCertificadoId,
          value: this.$store.getters.cpfCertificado
        })
      }

      return values
    },

    updateAttributes (attributes) {
      this.data.attributes = attributes
    },

    /* */

    transformAttributes (scenario) {
      const _this = this

      return new Promise((resolve) => {
        let array = []

        const subjectAttributes = this.talkParent && this.talkParent.subject !== 'averbacaoservico'
          ? this.subjectData.subjectAttributes.filter((x) => x.subjectAttributeId != this.talkParent.attributeId)
          : this.subjectData.subjectAttributes

        subjectAttributes.forEach((value, key) => {
          const attributes = {}
          const alias = this.templateNames.find((x) => x.subjectAttributeId == value.subjectAttributeId)

          attributes.id = value.subjectAttributeId
          attributes.identifier = value.subjectAttributeIdentifier
          attributes.name = alias?.templateAreaName || value.subjectAttributeName || value.attributeName
          attributes.icon = value.icon

          const template = Array.isArray(_this.template)
            ? _this.template.filter(({ subjectAttributeId }) => subjectAttributeId === value.subjectAttributeId)
            : []

          attributes.styles = {}

          if (
            (scenario == 'grid' && template.length && value.componentId != 12 && value.componentId != 18) ||
            ((scenario == 'form' || scenario == 'activetask') && !value.isInternal)
          ) {
            let width = 150
            let fixed = false
            let order = 0

            if (template.length > 0) {
              width = template.find(({ configIdentifier }) => configIdentifier === 'Width')?.value || 150
              fixed = template.find(({ configIdentifier }) => configIdentifier === 'IsFixed')?.value === 'True' || false
              order = template[0].order || 0
            }

            attributes.component = {}
            attributes.component.config = {}

            if (this.templateConfigs) {
              const isRequired = this.templateConfigs.find(
                ({ configIdentifier, subjectAttributeId }) =>
                  configIdentifier.toLowerCase() == 'isrequired' &&
                    subjectAttributeId == value.subjectAttributeId
              )
              const isHiddenDefaultValue = this.templateConfigs.find(
                ({ configIdentifier, subjectAttributeId }) =>
                  configIdentifier.toLowerCase() ==
                      'hiddenfielddefaultvalue' &&
                    subjectAttributeId == value.subjectAttributeId
              )
              const isHidden = this.templateConfigs.find(
                ({ configIdentifier, subjectAttributeId }) =>
                  configIdentifier.toLowerCase() == 'ishidden' &&
                    subjectAttributeId == value.subjectAttributeId
              )
              const preSelect = this.templateConfigs.find(
                ({ configIdentifier, subjectAttributeId }) =>
                  configIdentifier.toLowerCase() == 'preselect' &&
                    subjectAttributeId == value.subjectAttributeId
              )

              if (isRequired) {
                attributes.component.config.required = isRequired
                  ? isRequired.value
                  : !!value.isRequired
              } else {
                attributes.component.config.required = !!value.isRequired
              }
              if (preSelect) {
                attributes.component.config.preSelect = preSelect
                  ? preSelect.value
                  : !!value.preSelect
              } else {
                attributes.component.config.preSelect = !!value.preSelect
              }

              if (isHiddenDefaultValue) {
                attributes.component.config.isHidden = true
                attributes.component.config.defaultValue =
                    isHiddenDefaultValue.value
              }

              if (isHidden) {
                attributes.component.config.isHidden = true
              }
            } else {
              attributes.component.config.required = !!value.isRequired
            }
            attributes.component.config.defaultValue = value.defaultValue

            attributes.styles.width = parseInt(width)
            attributes.styles.fixed = fixed
            attributes.styles.order = order

            attributes.component.type = value.componentId

            if (
              this.talkParent &&
                value.subjectAttributeId == this.talkParent.attributeId
            ) {
              attributes.component.config.isHidden = true
            }

            if (
              this.talkParent &&
                value.subjectAttributeId == this.talkParent.attributeId
            ) {
              attributes.component.config.isHidden = true
            }

            if (!value.isEditable) {
              attributes.component.config.readonly = !value.isEditable
            }
            if (value.isNaturalKey) {
              attributes.component.config.natural = value.isNaturalKey
            }

            attributes.component.config.maxlength = value.length
            attributes.component.config.isOrderable =
                value.isOrderable || false
            attributes.component.config.isMultiple = value.isMultipleValues
            attributes.component.config.componentConfigs =
                value.componentConfigs

            attributes.component.config.actions = {
              insert: value.actions?.create || false,
              update: value.actions?.update || false,
              delete: value.actions?.delete || false
            }

            // A propriedade immutableActions é usada para armazenar as ações como vêm do BE.
            // É usada para verificar se a action é editável ou não.
            attributes.component.config.immutableActions = JSON.parse(JSON.stringify(attributes.component.config.actions))
            /* */

            array.push(attributes)
          }
        })
        array = sortBy(array, 'styles.order')

        this.data.attributes = array

        resolve()
      }).catch((error) => console.log(error))
    },

    transformTalks (id) {
      return new Promise((resolve) => {
        if (id == 0) {
          this.data.attributes = this.setRulesAttributes(this.getRouteSubject, this.data.attributes, [], this.talkParent, this.$store)
          resolve()
        } else {
          const _this = this
          const array = []

          this.talks.itens.forEach((value) => {
            array.push(_this.transformTalk(value))
          })

          this.data.attributes = this.setRulesAttributes(this.getRouteSubject, this.data.attributes, array, this.talkParent, this.$store)

          this.data.talks = array

          resolve()
        }
      })
    },

    transformTalk (talk) {
      const object = {}

      object.id = talk.talkId
      object.active = talk.isActive
      object.subject = talk.subject.toLowerCase()

      const tagsTemp = JSON.parse(JSON.stringify(talk.tags))

      object.values = []

      talk.talkValues.forEach((j) => {
        const value = {
          tags: j.tags,
          value: typeof j.value == 'undefined' ? '' : j.value,
          ...(j.subjectAttributeIdentifier ? { subjectAttributeIdentifier: j.subjectAttributeIdentifier } : {})
        }

        j.tags.forEach((item) => {
          const obj = JSON.parse(JSON.stringify(item))

          const attr = this.data.attributes.find(
            (x) => x.id == j.subjectAttributeId
          )

          if (attr) {
            obj.subjectAttribute = attr.name
          } else {
            obj.subjectAttribute = ''
          }

          tagsTemp.push(obj)
        })

        object.tags = tagsTemp

        object.values[j.subjectAttributeId] = value
      })

      return object
    },

    transformTalks2 (id) {
      return new Promise((resolve) => {
        if (id == 0) {
          resolve()
        } else {
          const _this = this
          const array = []

          /* */

          this.talks.itens.forEach((value) => {
            array.push(this.transformTalk2(value))
          })

          this.data.talks = array

          const attributes = JSON.parse(JSON.stringify(this.data.attributes))
          const talks = JSON.parse(JSON.stringify(this.data.talks))

          attributes.forEach((attribute) => {
            const talkValues = []

            talks.forEach(({ values }) => {
              const value = values.find(
                ({ subjectAttributeId }) =>
                  subjectAttributeId === attribute.id
              )

              if (value) {
                const v = JSON.parse(JSON.stringify(value))

                if (v.value !== null) {
                  value.value = this.transformValues(v, attribute.component)
                } else {
                  value.value = ''
                }
                talkValues.push(value)
              }
            })

            attribute.values = talkValues
          })

          this.data.attributes = attributes
          resolve()
        }
      })
    },

    transformTalk2 (talk) {
      const object = {}
      object.id = talk.talkId
      object.active = talk.isActive
      object.subject = talk.subject.toLowerCase()
      object.displayName = talk.displayName

      this.getRouteScenario === 'activetask'
        ? (object.loadingStatusRequest = {
            start: false,
            pause: false,
            remove: false,
            end: false
          })
        : null

      const tagsTemp = JSON.parse(JSON.stringify(talk.tags))

      object.values = []

      talk.talkValues.forEach((j) => {
        let i = 0

        j.tags.forEach((item) => {
          const obj = JSON.parse(JSON.stringify(item))

          const attr = this.data.attributes.find(
            (x) => x.id == j.subjectAttributeId
          )

          if (attr) {
            obj.subjectAttribute = attr.name
            obj.subjectAttributeId = attr.id
          } else {
            obj.subjectAttribute = ''
            obj.subjectAttributeId = i
          }

          tagsTemp.push(obj)

          i++
        })

        object.tags = tagsTemp

        // AQUI

        object.values.push({
          talkId: talk.talkId,
          subjectAttributeId: j.subjectAttributeId,
          tags: j.tags,
          value: typeof j.value == 'undefined' ? '' : j.value
        })

        const subjectIdentifier = j.subjectAttributeIdentifier?.toLowerCase() || ''

        if (['timer', 'duracaototal', 'duração total'].includes(subjectIdentifier)) {
          object.duringTime = {
            time: j.value
          }
        }
      })

      if (this.getRouteScenario == 'activetask' && !object.duringTime) {
        object.duringTime = {
          time: dayjs({
            hour: 0,
            minute: 0,
            seconds: 0
          }).format('HH:mm:ss')
        }
      }

      return object
    },

    transformSubject () {
      return new Promise((resolve) => {
        this.data.subject = {
          name: `${this.subjectData.subjectName}${
              this.getRouteQueryStatus != true ? ' (DESATIVADOS)' : ''
            }`,
          identifier: this.subjectData.subjectIdentifier.toLowerCase(),
          id: this.subjectData.subjectId
        }

        this.data.context = this.context

        resolve()
      })
    },

    /* */

    callContext () {
      return new Promise((resolve, reject) => {
        const querys = []
        if (this.getRouteTemplate) {
          querys.push('templateId=' + this.getRouteTemplate)
        }
        if (this.getRouteTalk) querys.push('talkId=' + this.getRouteTalk)
        this.$http
          .get(
              `${this.$store.getters.api}/context/web/${
                this.getRouteScenario == 'talk' ||
                this.getRouteScenario == 'form'
                  ? 'talkmodal'
                  : this.getRouteScenario
              }/${this.getRouteSubject}${
                querys.length ? '?' + querys.join('&') : ''
              }`
          )
          .then(({ data }) => {
            this.context = data.contextId
            this.subjectData = data.subject

            const actions = {
              read: !!data.subject.actions.Read,
              insert: !!data.subject.actions.Create, // deve ser false o ultimo
              update: !!data.subject.actions.Update, // deve ser false o ultimo
              delete: !!data.subject.actions.Delete, // deve ser false o ultimo
              deactivate: !!data.subject.actions.Deactivate // deve ser false o ultimo
            }

            if (this.getRouteSubject == 'filtro') {
              actions.deactivate = false
            }

            if (data.subjectsThatReferThis) {
              this.subjectsRelateds = data.subjectsThatReferThis.map((x) => {
                return {
                  attributeId: x.requestedSubjectAttributeId,
                  subjectIcon: x.requestedSubjectIcon,
                  subjectIdentifier: x.requestSubjectIdentifier,
                  subjectName: x.requestedSubjectName
                }
              })
            }

            if (this.getRouteSubject == 'posicionamentoservico') {
              if (!this.subjectsRelateds) this.subjectsRelateds = []

              const check = this.subjectsRelateds.filter(
                (x) =>
                  x.subjectIcon === 'cancel' &&
                    x.subjectIdentifier === 'posicionamentoservico' &&
                    x.subjectName === 'Solicitar Cancelamento'
              )

              if (check.length === 0) {
                this.subjectsRelateds.push({
                  attributeId: 0,
                  subjectIcon: 'cancel',
                  subjectIdentifier: 'posicionamentoservico',
                  subjectName: 'Solicitar Cancelamento',
                  templateIdEdit: 2395
                })
              }
            }

            this.tempActions = actions

            /* */

            if (this.type == 'parent') {
              const query = Object.entries(this.$route.query)
                .map(([key, val]) => `${key}=${val}`)
                .join('&')

              /* */

              let url = ''
              let context = ''

              if (
                !this.$route.params.talk &&
                  !['modal', 'filter'].includes(this.mode) &&
                  this.talkParent == null
              ) {
                if (this.getRouteFilters) {
                  url = `/context/${this.getRouteScenario}/${
                      this.getRouteSubject
                    }${query ? `/?${query}` : ''}`

                  if (this.getRouteFilters && this.getRouteTemplate) {
                    context = 'context_template_filter'
                  } else if (this.getRouteFilters) {
                    context = 'context_filter'
                  } else {
                    context = 'context'
                  }

                  this.$store.commit('openPage', {
                    name: 'context',
                    fullPath: url,
                    params: {
                      scenario: this.getRouteScenario,
                      subject: this.getRouteSubject
                    },
                    query: this.$route.query,
                    meta: {
                      label: `${
                          this.$route.params.name ||
                          this.subjectData.subjectName
                        }${
                          this.getRouteQueryStatus == 'false'
                            ? ' (DESATIVADOS)'
                            : ''
                        }`,
                      icon: this.subjectData.icon
                    }
                  })

                  if (this.talkParent == null) {
                    this.$store.commit(
                      'setCurrentPage',
                      this.$store.getters.pages.find((x) => x.fullPath == url)
                    )
                  }
                } else {
                  url = `/context/${this.getRouteScenario}/${
                      this.getRouteSubject
                    }${query ? `/?${query}` : ''}`

                  context = 'context'

                  this.$store.commit('openPage', {
                    name: context,
                    fullPath: url,
                    params: {
                      scenario: this.getRouteScenario,
                      subject: this.getRouteSubject
                    },
                    query: this.$route.query,
                    meta: {
                      label: `${
                          this.$route.params.name ||
                          this.subjectData.subjectName
                        }${
                          this.getRouteQueryStatus == 'false'
                            ? ' (DESATIVADOS)'
                            : ''
                        }`,
                      icon: this.subjectData.icon
                    }
                  })

                  if (this.talkParent == null) {
                    this.$store.commit(
                      'setCurrentPage',
                      this.$store.getters.pages.find((x) => x.fullPath == url)
                    )
                  }
                }
              }
            }

            /* */

            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    callTemplate () {
      this.$store.commit('setCertifyError', false)

      return new Promise((resolve, reject) => {
        const services =
            this.$store.getters.user.data.requireDigitalSignature.length > 0
              ? this.$store.getters.user.data.requireDigitalSignature.map(
                (item) => item.toLowerCase()
              )
              : []

        let certificate

        if (
          this.getRouteScenario === 'talk' &&
            services.length > 0 &&
            services.includes(this.getRouteSubject) &&
            this.$store.getters.validadeCertificado < Date.now()
        ) {
          certificate = this.$http
            .get(
                `${this.$store.getters.apiClientCertificate}/certificado.aspx`
            )
            .then(({ data, status, request }) => {
              if (data.valid === true) {
                const user = data.user.split(':')
                this.$store.commit('setNomeCertificado', user[0])
                this.$store.commit('setCpfCertificado', user[1])
                this.$store.commit(
                  'setValidadeCertificado',
                  dayjs(Date.now()).add(24, 'h')
                )
              } else {
                reject(data)
                switch (DataTransfer.accountLogTypeId) {
                  case 3: // Token JWT Ausente
                    this.$store.commit('setCertifyError', {
                      active: true,
                      valid: data.valid,
                      message: `${data.message} - Falha na requisição do certificado.`,
                      orientation:
                          'Não foi possível realizar a requisição do certificado digital selecionado, por favor entre em contato com a Localfrio.'
                    })
                    break
                  case 4: // Token JWT Inválido
                    this.$store.commit('setCertifyError', {
                      active: true,
                      valid: data.valid,
                      message: `${data.message} - Falha na requisição do certificado.`,
                      orientation:
                          'Não foi possível realizar a requisição do certificado digital selecionado, por favor entre em contato com a Localfrio.'
                    })
                    break
                  case 5: // Certificado não localizado na requisição
                    this.$store.commit('setCertifyError', {
                      active: true,
                      valid: data.valid,
                      message: data.message,
                      orientation:
                          'Não foi encontrado um certificado digital vinculado ao seu navegador, por favor realize a instalação de um certificado digital válido para seguir com a solicitação de serviços.'
                    })
                    break
                  case 6: // Certificado Inválido
                    this.$store.commit('setCertifyError', {
                      active: true,
                      valid: data.valid,
                      message: data.message,
                      orientation:
                          'O certificado digital selecionado não é válido, por favor realize a validação do certificado em seu navegador para seguir com a solicitação de serviços.'
                    })
                    break
                  case 7: // Sem dados ICP-Brasil
                    this.$store.commit('setCertifyError', {
                      active: true,
                      valid: data.valid,
                      message: `${data.message} - Certificado não válido no ICP-Brasil`,
                      orientation:
                          'Não foi possível utilizar o certificado digital selecionado pois ele é inválido para o ICP-Brasil, por favor realize a regularização do certificado junto ao ICP-Brasil para seguir com a solicitação do serviço.'
                    })
                    break
                  default:
                    this.$store.commit('setCertifyError', {
                      active: true,
                      valid: data.valid,
                      message:
                          'Ops! O certificado digital disponível para utilização não é válido ou está inabilitado para utilização.',
                      orientation:
                          ' Por favor, feche o navegador, abra-o novamente e acesse  o serviço utilizando um certificado digital válido.'
                    })
                    break
                }
              }
            })
            .catch((error) => {
              reject(error)
              this.$store.commit('setCertifyError', {
                active: true,
                valid: 'false',
                message:
                    'Ops! O seu certificado de cliente foi revogado ou o status da revogação não pôde ser determinado.',
                orientation:
                    'Por favor, feche o navegador, abra-o novamente e acesse  o serviço utilizando um certificado digital válido.'
              })
            })
        } else {
          certificate = Promise.resolve()
        }

        certificate
          .then(() => {
            const querys = []

            if (this.getRouteTemplate && this.getRouteScenario == 'grid') {
              querys.push('templateid=' + this.getRouteTemplate)
            }

            if (this.templateId && this.mode == 'modal') {
              querys.push('templateid=' + this.templateId)
            }

            if (
              this.getRouteSubject == 'posicionamentoservico' &&
                !this.templateId
            ) {
              querys.push('templateid=2394') // Produção
            }

            if (this.getRouteScenario != 'grid') {
              if (this.getRouteTalk == 0) {
                querys.push('IsNewTalk=true')
              } else {
                querys.push('IsNewTalk=false')
              }
            }
            // Evita mandar dois templateId na mesma string BUG
            if (querys.length > 1) {
              if (querys[1].match(/templateid/)) querys.splice(0, 1)
            }

            this.$http
              .get(
                  `${this.$store.getters.api}/context/${this.context}/template${
                    querys.length ? '?' + querys.join('&') : ''
                  }`
              )
              .then(({ data }) => {
                Promise.resolve()
                  .then(() => {
                    if (data) {
                      this.$store.commit('setTemplateName', data.templateName)
                      this.$store.commit('setTemplateId', data.templateId)

                      if (data.templateType == 6) {
                        this.$store.commit(
                          'setTemplateType',
                          data.templateType
                        )
                      }

                      if (this.getRouteScenario == 'grid') {
                        this.template = data.viewTemplateAreaConfigs
                        this.templateIdDefault = data.templateId

                        this.showTalkIdOnGrid = data.showTalkIdOnGrid || false

                        const templateNames = data.templateAreas

                        if (templateNames) {
                          this.templateNames = templateNames.map((item) => {
                            return {
                              subjectAttributeId: item.subjectAttributeId,
                              templateAreaName: item.templateAreaName
                            }
                          })
                        }

                        const templateRelateds = data.relatedTemplates
                          ? data.relatedTemplates
                          : []

                        if (templateRelateds.length) {
                          this.templateRelateds = templateRelateds
                        }

                        if (data.order) {
                          if (data.order.length > 0) {
                            this.orderTalks = data.order[0]
                          }
                        }
                      } else {
                        this.template = data
                        this.templateAreaTalk = this.template
                          ? this.template.templateAreas
                          : []
                      }
                    } else {
                      this.template = {
                        templateAreas: []
                      }

                      this.templateAreaTalk = []
                    }

                    return Promise.resolve()
                  })
                  .then(() => {
                    resolve()
                  })
              })
              .catch((error) => {
                reject(error)
              })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    callTalks (id) {
      return new Promise((resolve, reject) => {
        if (id == 0) {
          resolve()
        } else {
          const idLink = typeof id != 'undefined' ? id : ''

          const isActive = this.getRouteQueryStatus

          const params =
              idLink == ''
                ? {
                    isActive: isActive,
                    page: this.data.pagination.page,
                    fetch: this.data.pagination.per_page,
                    scenario: this.scenario,
                    // verificação de modal realizada devido a bug de passagem de filtro no modal Ocorrências - Pré-Cálculo
                    filterIds:
                      this.getRouteFilters && this.mode != 'modal'
                        ? this.getRouteFilters.split(',').map(Number)
                        : []
                  }
                : null

          if (this.filterId) {
            params.filterIds.push(this.filterId)
          }

          if (this.filterJson.length) {
            params.filterJson = JSON.stringify(this.filterJson)
          }

          if (this.talkParent != null && this.getRouteScenario == 'grid') {
            params.talkParent = JSON.parse(JSON.stringify(this.talkParent))
          }

          if (this.orderTalks != null && this.getRouteScenario == 'grid') {
            params.order = [this.orderTalks]
          }

          const list = idLink == '' ? 'filter/' : ''

          if (
            this.getRouteSubject == 'reportmodeltemplate' &&
              this.getRouteScenario == 'form'
          ) {
            this.$http
              .get(`${this.$store.getters.api}/report/model/${idLink}`)
              .then(({ data }) => {
                if (data.itens || typeof data === 'object') {
                  const template = data

                  const values = []
                  const subjects = []

                  template.subjects.forEach((subject) =>
                    subjects.push(subject)
                  )

                  template.relatedSubjects.forEach((related) =>
                    subjects.push(related)
                  )

                  values.push({
                    tags: [],
                    subjectAttributeId: template.nameSubjectAttributeId,
                    value: template.name
                  })
                  values.push({
                    tags: [],
                    subjectAttributeId: template.subjectsSubjectAttributeId,
                    value: subjects
                  })
                  values.push({
                    tags: [],
                    subjectAttributeId: template.columnsSubjectAttributeId,
                    value: template.columns
                  })

                  const object = {
                    itens: [
                      {
                        active: true,
                        subject: 'ReportModelTemplate',
                        tags: [],
                        talkId: data.templateId,
                        talkValues: values
                      }
                    ]
                  }

                  this.talks = object

                  resolve()
                } else {
                  reject()
                }
              })
              .catch((error) => {
                reject(error)
              })
          } else if (this.getRouteSubject !== null) {
            this.$http[idLink != '' ? 'get' : 'post'](
                `${this.$store.getters.api}/talk/${list}${this.getRouteSubject}/${idLink}`,
                params
            )
              .then(({ data }) => {
                if (data.itens || typeof data === 'object') {
                  if (idLink) {
                    const object = { itens: [] }
                    object.itens.push(data)
                    this.talks = object

                    if (data?.isReadOnly) this.setTalkReadOnly()
                  } else {
                    this.talks = data

                    /* */

                    this.data.pagination.page = data.currentPage
                    this.data.pagination.total = data.totalResults
                  }

                  if (
                    this.type == 'parent' &&
                      this.$route.params.talk &&
                      !['modal', 'filter'].includes(this.mode) &&
                      (this.$route.params.scenario == 'talk' ||
                        this.$route.params.scenario == 'form')
                  ) {
                    const query = Object.entries(this.$route.query)
                      .map(([key, val]) => `${key}=${val}`)
                      .join('&')

                    /* */

                    this.$store.commit('openPage', {
                      name: 'context_id',
                      fullPath: `/context/${this.getRouteScenario}/${
                          this.getRouteSubject
                        }/${this.$route.params.talk}${
                          query ? `/?${query}` : ''
                        }`,
                      params: {
                        scenario: this.getRouteScenario,
                        subject: this.getRouteSubject,
                        talk: this.$route.params.talk
                      },
                      query: this.$route.query,
                      meta: {
                        label: data.displayName,
                        icon: this.subjectData.icon
                      }
                    })

                    if (this.talkParent == null) {
                      this.$store.commit(
                        'setCurrentPage',
                        this.$store.getters.pages.find(
                          (x) =>
                            x.fullPath ==
                              `/context/${this.getRouteScenario}/${
                                this.getRouteSubject
                              }/${this.$route.params.talk}${
                                query ? `/?${query}` : ''
                              }`
                        )
                      )
                    }
                  }

                  /* */

                  resolve()
                } else {
                  reject()
                }
              })
              .then(({ data }) => {
                if (idLink) {
                  const object = {
                    itens: []
                  }

                  object.itens.push(data)

                  this.talks = object
                } else {
                  this.talks = data

                  /* */

                  this.data.pagination.page = data.currentPage
                  this.data.pagination.total = data.totalResults
                }

                /* */

                resolve()
              })
              .catch((error) => {
                reject(error)
              })
          }
        }
      })
    },

    async callGridApi2 (pagination) {
      if (!pagination) this.resetContext()
      this.$store.commit('setLoading', true)
      const _this = this

      this.loading.active = true
      this.loading.ready = false
      this.loading.error = false

      if (pagination) {
        this.loading.render = true
      } else {
        this.loading.render = false
      }

      this.loading.error = false

      // problema das abas é aqui //
      // precisa terminar
      try {
        !pagination ? await this.callContext2() : false
        !pagination ? this.transformSubject2() : false
        ;(await !pagination) ? this.transformAttributes('grid') : false

        this.loading.render = true
        this.$store.commit('setLoading', false)
        await this.callTalks()
        this.transformTalks2()

        this.loading.ready = true
        this.loading.active = false

        this.actions = this.tempActions
      } catch (error) {
        this.loading.active = false
        this.loading.error = true
        this.loading.response = 'Erro'
        this.$store.commit('setLoading', false)
      }
    },

    transformSubject2 (subject, context) {
      this.data.subject = {
        name: `${
            !subject ? this.subjectData.subjectName : subject.subjectName
          }${this.getRouteQueryStatus != true ? ' (DESATIVADOS)' : ''}`,
        identifier: !subject
          ? this.subjectData.subjectIdentifier.toLowerCase()
          : subject.subjectIdentifier.toLowerCase(),
        id: this.subjectData.subjectId
      }

      this.data.context = !context ? this.context : context
    },

    callSubjectsRelateds () {
      return new Promise((resolve, reject) => {
        this.$http
          .get(
              `${this.$store.getters.api}/subject/related/other/${this.getRouteSubject}`
          )
          .then(({ data }) => {
            if (data.length > 0) {
              this.subjectsRelateds = data.map((x) => {
                return {
                  attributeId: x.subjectAttributeId,
                  subjectIcon: x.relatedSubject.icon || 'view_column',
                  subjectIdentifier: x.relatedSubject.subjectIdentifier,
                  subjectName: x.relatedSubject.subjectName
                }
              })
            }

            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    returnTalkMenu (value) {
      let subItens = null

      const item = {}

      item.id = value.templateAreaId
      item.order = value.order
      item.name = value.templateAreaName
      item.widgetId = value.widgetId
      item.widgetIdentifier = value.widgetIdentifier

      if (typeof value.templateAreaConfigs != 'undefined') {
        if (value.templateAreaConfigs.length > 0) {
          const icon = value.templateAreaConfigs.filter(
            (x) => x.configIdentifier == 'icon'
          )

          if (icon.length > 0) item.icon = icon[0].value
        }
      }

      item.subject = {}
      item.scenario = {}

      if (value.templateAreaTalkModal) {
        if (
          typeof value.templateAreaTalkModal.scenarioId != 'undefined' &&
            (value.templateAreaTalkModal.template == null ||
              (value.templateAreaTalkModal.template &&
                item.widgetIdentifier == 'BtnLoadContext'))
        ) {
          item.scenario.id = value.templateAreaTalkModal.scenarioId

          item.scenario.identifier =
              value.templateAreaTalkModal.scenarioIdentifier
        } else if (value.templateAreaTalkModal.template) {
          subItens = value.templateAreaTalkModal.template.templateAreas.map(
            (areaChildren) => {
              return this.returnTalkMenu(areaChildren)
            }
          )

          item.templateAreas = subItens
        }

        if (typeof value.templateAreaTalkModal.subjectId != 'undefined') {
          item.subject.id = value.templateAreaTalkModal.subjectId

          item.subject.identifier =
              value.templateAreaTalkModal.subjectIdentifier

          item.subject.attributeId =
              value.templateAreaTalkModal.subjectAttributeId

          item.subject.attributeIdentifier =
              value.templateAreaTalkModal.subjectAttributeIdentifier
        }
      }

      if (typeof value.templateAreas != 'undefined') {
        subItens = value.templateAreas.map((areaChildren) => {
          return this.returnTalkMenu(areaChildren)
        })

        item.templateAreas = subItens
      }

      return item
    },

    transformTalkMenu () {
      return new Promise((resolve) => {
        let array = []

        const menu = this.template.templateAreas.map((value) => {
          return this.returnTalkMenu(value)
        })

        array = sortBy(menu, 'order')

        this.talkMenu = array

        resolve()
      })
    },

    async callTalkApi2 () {
      this.resetContext()

      const _this = this

      this.loading.active = true
      this.loading.ready = false
      this.loading.render = false
      this.loading.error = false

      try {
        await this.callContext2()
        await this.transformTalkMenu()
        _this.loading.ready = true
        _this.loading.active = false

        this.actions = this.tempActions
      } catch (error) {
        console.log(error)

        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error
      }
    },

    async callFormApi2 () {
      this.resetContext()

      const _this = this

      this.loading.active = true
      this.loading.ready = false
      this.loading.render = false
      this.loading.error = false

      this.subjectData = this.attributesTalkContext

      try {
        if (this.getRouteSubject == 'filtro') {
          await this.callContext()
        }
        await this.transformSubject()
        await this.transformAttributes('form')
        await this.callTalks(this.getRouteTalk)
        await this.transformTalks(this.getRouteTalk)

        _this.loading.ready = true
        _this.loading.active = false

        this.actions = this.tempActions
      } catch (error) {
        console.log(error)

        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error
      }
    },

    callTimelineApi () {
      this.resetContext()

      /* */

      const _this = this

      /* */

      this.loading.active = true
      this.loading.ready = false
      this.loading.render = false
      this.loading.error = false

      Promise.resolve()
        .then(() => {
          return _this.callContext()
        })
        .then(() => {
          return _this.callTemplate('time')
        })
        .then(() => {
          return _this.callTalks(this.getRouteTalk)
        })
        .then(() => {
          return _this.transformTalkTimeline()
        })
        .then(() => {
          _this.loading.ready = true
          _this.loading.active = false

          this.actions = this.tempActions
        })
        .catch((error) => {
          _this.loading.active = false
          _this.loading.error = true
          _this.loading.response = error
        })
    },

    transformTalkTimeline () {
      this.template.templateAreas.forEach((item, key) => {
        this.data.talks[key] = {}
        this.data.talks[key].name = item.templateAreaName
        this.data.talks[key].itens = []
        this.data.talks[key].active = false

        item.templateAreaSubjectAttributes.forEach((subItem, subKey) => {
          const talkValue = this.talks.itens[0].talkValues.filter(
            (x) => x.subjectAttributeId == subItem.subjectAttributeId
          )[0]

          if (talkValue) {
            this.data.talks[key].itens[subKey] = {}

            this.data.talks[key].itens[subKey].name =
                subItem.templateAreaSubjectAttributeName

            this.data.talks[key].itens[subKey].value = talkValue.value
          }
        })
      })
    },

    changeTitleModal (args) {
      this.$emit('change-title-modal', args)
    },

    /* */

    changeAttributeItens (args) {
      const index = this.data.attributes.findIndex(
        (x) => x.id === args.attributeId
      )

      this.data.attributes[index].component.itens = args.itens
    },

    addAttributeItens (args) {
      let index

      if (args.attributeId) {
        index = this.data.attributes.findIndex(
          (x) => x.id === args.attributeId
        )
      } else {
        index = this.data.attributes.findIndex(
          (x) => x.id === args.component.id
        )
      }

      const itens = this.data.attributes[index].component.itens

      args.itens.forEach((element) => {
        itens.push(element)
      })

      this.data.attributes[index].component.itens = itens
    },

    /* */

    sharedTags (tags) {
      this.$emit('shared-tags', tags)
    },

    close () {
      if (typeof this.mode == 'undefined') {
        this.$store.commit('closePage', this.$route.fullPath)
      } else {
        if (this.type == 'parent') {
          if (this.getRouteTalk == 0) {
            this.$store.commit(
              'closePage',
                `/context/${this.getRouteScenario}/${this.getRouteSubject}`
            )
          } else {
            this.$store.commit(
              'closePage',
                `/context/${this.getRouteScenario}/${this.getRouteSubject}/${this.getRouteTalk}`
            )
          }

          if (this.mode == 'modal') this.$emit('close')
        } else {
          this.$emit('close')
        }
      }
    },
    deleteFilter (index) {
      this.data.talks.splice(index, 1)
    },

    closeModalFilter ($event) {
      this.$emit('close-modal', $event)
    },

    deleteAttributeConfigProperty ({ attributeId, property }) {
      const attribute = this.data.attributes.find(
        (val) => val.id === attributeId
      )
      if (attribute) delete attribute.component.config[property]
    }
  },

  computed: {
    talkType () {
      let talkType = null
      if (this.getRouteScenario === 'talk') talkType = this.talk !== 0 ? 'update' : 'insert'
      return talkType
    },
    getTemplate () {
      return this.getRouteScenario === 'talk' ? this.$store.getters.getTemplates?.[this.getRouteSubject]?.[this.getRouteScenario]?.[this.talkType] : this.$store.getters.getTemplates?.[this.getRouteSubject]?.[this.getRouteScenario]
    },
    filterJsonClean () {
      const _this = this
      const subjects = JSON.parse(JSON.stringify(this.filter))

      /* */

      subjects.map((subject) => {
        delete subject.id

        _this.exclude.forEach((exclude) => {
          delete subject[exclude]
        })

        /* */

        if (subject.attributes) {
          subject.attributes.map((attribute) => {
            _this.exclude.forEach((exclude) => {
              delete attribute[exclude]
            })

            /* */

            if (attribute.operators) {
              attribute.operators.map((operator) => {
                delete operator.attr_id

                _this.exclude.forEach((exclude) => {
                  delete operator[exclude]
                })

                /* */

                if (operator.values) {
                  operator.values.map((value) => {
                    delete value.type.label
                    delete value.attribute.label
                    delete value.attribute.list

                    /* */

                    if (value.type.id == null) delete value.type
                    if (value.attribute.id == null) {
                      delete value.attribute
                    }
                    if (value.value.id == null && value.value.label == null) {
                      delete value.value
                    } else {
                      value.value.label = _this.$options.filters.striptags(
                        value.value.label
                      )
                    }

                    _this.exclude.forEach((exclude) => {
                      delete value[exclude]
                    })
                  })
                }
              })
            }
          })
        }
      })

      /* */

      return subjects
    },

    getRouteScenario () {
      return this.scenario
        ? this.scenario.toLowerCase()
        : this.$route.params.scenario
          ? this.$route.params.scenario.toLowerCase()
          : null
    },

    getRouteSubject () {
      return this.subjectProp
        ? this.subjectProp.toLowerCase()
        : this.$route.params.subject
          ? this.$route.params.subject.toLowerCase()
          : null
    },

    getRouteTalk () {
      return this.talk
        ? this.talk
        : this.$route.params.talk != 'add' &&
            typeof this.$route.params.talk != 'undefined'
          ? this.$route.params.talk
          : 0
    },

    getRouteQueryStatus () {
      return this.$route.query.isActive
        ? this.$route.query.isActive
        : this.isActiveList
    },

    getRouteTemplate () {
      return this.$route.query.template ? this.$route.query.template : false
    },

    getRouteFilters () {
      return this.$route.query.filters ? this.$route.query.filters : null
    }
  },

  watch: {
    templateConfigs: {
      handler: function () {
        this.transformAttributes('form')
      },
      deep: true
    },
    scenario: function () {
      this.initialized = true
      this.init()
    },

    subjectProp: function () {
      // If para evitar requisições em duplicidade quando scenario e subjectProp mudam
      if (!this.initialized) this.init()
      this.initialized = false
    },

    isActiveList: function () {
      this.init()
    },

    filterJson: {
      handler: async function () {
        await this.callGridApi2(true)
      },
      deep: true
    },

    filterId: {
      handler: async function () {
        await this.callGridApi2(true)
      },
      deep: true
    }
  }
}
</script>
