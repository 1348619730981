<template>
  <app-config>
    <app-view>
      <app-confirm-promise ref="confirm" />

      <app-box>
        <app-loader
          v-if="!ready"
          key="loading"
          :active="loading.active"
          :error="loading.error"
          :response="loading.response"
          @reload="init()"
          background
          :class="$style.loading"
        />

        <form
          action="/"
          v-else
          @submit.prevent="accountPut()"
          :id="$style.id_form"
        >
          <div :class="$style.padding">
            <app-box margin>
              <app-title label="Editar informações do Usuário" />
            </app-box>

            <div class="row">
              <div class="col">
                <transition-group name="fade-right" mode="out-in" tag="div">
                  <app-box margin key="room">
                    <app-box background title="Grupo de Acesso">
                      <div class="row">
                        <div class="col-6">
                          <app-select
                            :id="$style.id_roomId"
                            reference="room"
                            @change-value="changeProfile"
                            @handle-scroll="handleScroll"
                            label="Perfil de Acesso"
                            :selected="profile.roomName"
                            :value="account.profileId"
                            :data="getRoomsSelect.data"
                            :object-key="{
                              id: 'roomId',
                              label: 'roomName',
                            }"
                            :loadingPage="room.loading"
                            :required="true"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box
                    margin
                    v-if="account.accountType == 1"
                    key="use"
                    class="mb-5"
                  >
                    <app-box background title="Dados Pessoais">
                      <div class="row">
                        <div class="col-6 col-xl-4">
                          <app-input
                            label="Primeiro Nome"
                            required
                            v-model:value="account.firstName"
                            :maxlength="150"
                            :id="$style.id_firstName"
                          />
                        </div>

                        <div class="col-6 col-xl-4">
                          <app-input
                            label="Sobrenome"
                            required
                            v-model:value="account.lastName"
                            :maxlength="250"
                            :id="$style.id_lastName"
                          />
                        </div>

                        <div class="col-6 col-xl-4">
                          <app-input
                            label="Nome para Exibição"
                            required
                            v-model:value="account.displayName"
                            :maxlength="50"
                            :id="$style.id_displayName"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <app-input
                            label="Telefone"
                            :mask="'tel'"
                            required
                            v-model:value="account.phoneNumber"
                            :maxlength="50"
                            :id="$style.id_displayName"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box
                    margin
                    v-if="account.accountType == 2"
                    key="app"
                    class="mb-5"
                  >
                    <app-box background title="Dados do Aplicativo">
                      <div class="row">
                        <div class="col-6 col-xl-3">
                          <app-input
                            label="Nome do Aplicativo"
                            required
                            v-model:value="account.appName"
                            :maxlength="150"
                            :id="$style.id_appName"
                            ref="appName"
                          />
                          <span
                            :class="$style.errormail"
                            v-if="validationAppName"
                            name="validation-message"
                            >Nome do Aplicativo já cadastrado</span
                          >
                        </div>

                        <div class="col-6 col-xl-3">
                          <app-input
                            label="Nome de Exibição"
                            required
                            v-model:value="account.displayName"
                            :maxlength="150"
                            :id="$style.id_displayName"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>
                </transition-group>

                <app-box margin v-if="account.accountType == 1">
                  <app-box background title="Imagem do perfil">
                    <div class="row">
                      <div class="col-12 col-xl-7">
                        <upload-image
                          @change-file="changeFile"
                          @remove="removeFile"
                          :image="account.profileImage"
                          :clear="clearImage"
                        />
                      </div>
                    </div>
                  </app-box>
                </app-box>

                <app-box margin v-if="account.accountType == 1">
                  <app-box background title="Dados de Acesso">
                    <div class="row">
                      <div class="col-12 col-xl-4">
                        <app-input
                          label="E-mail"
                          required
                          v-model:value="account.email"
                          :maxlength="150"
                          :id="$style.id_email"
                          ref="email"
                        />
                        <span
                          :class="$style.errormail"
                          v-if="validationEmail"
                          name="validation-message"
                          >Esse e-mail já possui cadastro</span
                        >
                      </div>
                    </div>
                  </app-box>
                </app-box>

                <app-box margin>
                  <app-box background title="Configuração de campos">
                    <attribute-filter
                      :attributes="attributes"
                      :attrSelecteds="attrSelecteds"
                      @add-attr="addAttr"
                      @select-attr="selectAttr"
                      @delete-attr="deleteAttr"
                      @add-attr-values="addAttrValues"
                      @change-attr-values="changeAttrValues"
                      @select-attr-value="selectAttrValue"
                    />
                  </app-box>
                </app-box>

                <delimited-filter
                  v-show="false"
                  :sharedTalks="account.sharedTalks"
                  @sharedTalks="setSharedTalks"
                />
              </div>
            </div>
          </div>

          <app-actions-box>
            <div class="row justify-content-center">
              <div class="col-auto">
                <app-button
                  label="Salvar"
                  icon="save"
                  type="submit"
                  :id="$style.id_submit"
                  :loading="accountPutStatus.loading"
                />
              </div>

              <div class="col-auto">
                <app-button
                  label="Cancelar"
                  icon="close"
                  color="red-500"
                  :id="$style.id_submit"
                  @click="closePage"
                />
              </div>

              <div class="col-auto">
                <app-button
                  label="Limpar"
                  icon="replay"
                  light
                  color="grey-800"
                  :id="$style.id_clear"
                  @click="clearPage"
                />
              </div>
            </div>
          </app-actions-box>
        </form>
      </app-box>
    </app-view>
  </app-config>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import AttributeFilter from '@/views/config/users/AttributeFilter.vue'
import DelimitedFilter from '@/views/config/DelimitedFilter'
import AppConfig from '@/views/config/Index.vue'
const uploadImage = defineAsyncComponent(() =>
  import('@/components/UploadImage.vue')
)

export default {
  components: {
    AppConfig,
    DelimitedFilter,
    AttributeFilter,
    uploadImage
  },

  computed: {
    getRoomsSelect () {
      return this.$store.getters.getRoomsSelect
    }
  },

  data () {
    return {
      ready: false,
      loading: {
        active: true,
        error: false,
        response: {}
      },
      account: {
        accountId: null,
        displayName: null,
        accountType: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        profileId: null,
        profileImage: null,
        sharedTalks: []
      },

      room: {
        pagination: {
          page: 1,
          per_page: 10
        },
        loading: false
      },

      accountPutStatus: {
        loading: false
      },

      profile: false,

      attributes: [],
      attrSelecteds: [],

      validationEmail: false,
      validationAppName: false,

      clearImage: false
    }
  },

  created () {
    this.init()
  },

  methods: {
    setSharedTalks (data) {
      this.account.sharedTalks = data
    },

    async clearPage () {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente limpar todos os campos do formulário?', 'red')
        .then((response) => {
          confirm.loading = true

          this.account.displayName = null
          this.account.firstName = null
          this.account.lastName = null
          this.account.appName = null
          this.account.email = null
          this.account.phoneNumber = null
          this.account.profileId = null
          this.account.profileImage = null
          this.account.sharedTalks = {}

          this.clearImage = true

          setTimeout(() => {
            this.clearImage = false
          }, 500)

          this.attrSelecteds = []
        })
        .catch((error) => false)

      confirm.close()
    },

    closePage () {
      this.$store.commit(
        'closePage',
        '/config/users/edit/' + this.$route.params.id
      )

      this.$router.push({
        name: 'config_users_list'
      })
    },

    changeProfile (room) {
      this.account.profileId = room
    },

    async init () {
      const _this = this

      _this.ready = false
      _this.loading.active = true
      _this.loading.error = false

      try {
        _this.callRooms()
        await _this.callAccountDetails()
        await _this.loadAttributes()

        _this.ready = true
        _this.loading.active = false
      } catch (error) {
        console.log(error)

        this.$store.dispatch('notification', {
          type: 'error',
          text: 'Ocorreu um erro no carregamento da página. Por favor, tente novamente.',
          error
        })

        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error.response
      }
    },

    async callRooms () {
      this.room.loading = true
      try {
        await this.$store.dispatch('loadRooms', {
          page: this.room.pagination.page,
          fetch: this.room.pagination.per_page,
          type: 'select'
        })
        this.room.loading = false
      } catch (e) {
        this.room.loading = false
        console.log(e)
      }
    },

    async handleScroll () {
      if (this.room.pagination.page < this.getRoomsSelect.total) {
        this.room.pagination.page++
        await this.callRooms()
      }
    },

    async callAccountDetails () {
      this.account = await this.$store.dispatch(
        'loadUserById',
        this.$route.params.id
      )
      this.attrSelecteds = this.account.attrSelecteds
      this.profile = this.account.room
    },

    changeFile (image) {
      this.account.profileImage = image
    },

    removeFile () {
      this.account.profileImage = null
    },

    async loadAttributes () {
      const data = await this.$store.dispatch('loadAttributesSelect')

      this.attributes = data.map(({ attribute }) => ({
        attributeName: attribute.attributeName,
        attributeId: attribute.attributeId,
        subjectAttributeId: attribute.subjectAttributeId,
        data: {
          id: attribute.subjectAttributeId,
          component: {
            type: attribute.componentId,
            config: {
              readonly: false,
              required: true,
              actions: {
                insert: true,
                update: true
              }
            },
            itens: []
          }
        },
        isDiscreteValue: 0
      }))
      this.attrSelecteds.forEach((item, index) => {
        const attr = this.attributes.find(
          ({ attributeId }) => attributeId === item.attributeId
        )
        if (attr) {
          this.attrSelecteds[index].data = attr.data
          this.attrSelecteds[index].data.id = attr.subjectAttributeId
          this.attrSelecteds[index].subjectAttributeId =
              attr.subjectAttributeId
        }
      })
    },

    addAttr () {
      this.attrSelecteds.push({
        attributeId: 0,
        data: {},
        value: '',
        identifier: parseInt(Math.random() * 10000, 10)
      })
    },

    selectAttr ({ attr, index }) {
      this.attrSelecteds[index] = attr
    },

    deleteAttr (index) {
      this.attrSelecteds.splice(index, 1)
    },

    addAttrValues ({ itens, index }) {
      Array.prototype.push.apply(
        this.attrSelecteds[index].data.component.itens,
        itens
      )
    },

    changeAttrValues ({ itens, index }) {
      this.attrSelecteds[index].data.component.itens = itens
    },

    selectAttrValue ({ value, index }) {
      this.attrSelecteds[index].value = value
    },

    accountPut () {
      let attrSelecteds = []

      if (this.attrSelecteds.length > 0) {
        if (this.attrSelecteds[0].attributeId !== 0) {
          attrSelecteds = this.attrSelecteds.map(
            ({ attributeId, value, data }) => {
              let val = value

              if (data.component.type == 4) {
                val = Array.isArray(value) ? value[0] : value
              }

              return {
                attributeId,
                value: val
              }
            }
          )
        }
      }

      this.validationEmail = false
      this.validationAppName = false

      this.accountPutStatus.loading = true

      this.$http
        .put(this.$store.getters.api + '/account/' + this.account.accountId, {
          firstName: this.account.firstName,
          lastName: this.account.lastName,
          displayName: this.account.displayName,
          phoneNumber: this.account.phoneNumber,
          appName: this.account.appName || '',
          email: this.account.email,
          roomId: this.account.profileId,
          profileImage: this.account.profileImage,
          sharedTalks: this.account.sharedTalks,
          attributes: attrSelecteds
        })
        .then((response) => {
          this.accountPutStatus.loading = false
          this.$store.commit(
            'closePage',
            '/config/users/edit/' + this.account.accountId
          )

          this.$router.push({
            name: 'config_users_list',
            params: { refresh: true }
          })

          this.$store.dispatch('notification', {
            text: 'Usuário editado com sucesso!'
          })
        })
        .catch((error) => {
          console.log(error)
          this.accountPutStatus.loading = false

          if (error.response.status == 422) {
            if (
              error.response.data.errors[0].message ==
                'Nome do Aplicativo já cadastrado'
            ) {
              this.validationAppName = true

              this.$refs.appName.$refs.input.focus()
            } else {
              this.validationEmail = true

              this.$refs.email.$refs.input.focus()
            }
          } else {
            this.$store.dispatch('notification', {
              type: 'error',
              title: 'Ocorreu um erro ao editar o usuário',
              text: error.response?.data?.errors[0]?.Message || ''
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" module>
  #id {
    &_form,
    &_firstName,
    &_lastName,
    &_displayName,
    &_email,
    &_submit,
    &_clear {
      text-decoration: none;
    }
  }

  .loading {
    min-height: calc(100vh - 65px) !important;
  }

  .padding {
    padding: $theme-padding * 4 $theme-padding * 5 $theme-padding * 14;
    min-height: calc(100vh - 135px);
  }

  .user {
    &__name {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(20);
      font-weight: 500;
      color: black;
      text-align: center;
    }

    &__email {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      font-style: italic;
      color: $md-grey-700;
      text-align: center;
    }
  }
</style>
