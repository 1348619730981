export default {
  methods: {
    validator (job) {
      const name = this.validateName(job.name)
      const events = this.validateEvents(job.events)
      const works = this.validateWorks(job.works)

      /* */

      if (name && events && works) {
        return true
      } else {
        return false
      }
    },

    validateName (name) {
      return !!name
    },

    validateEvents (events) {
      return events.every(event => {
        return event.steps.every(step => {
          if (step.eventStepType && step.filterName && step.filter) {
            return true
          } else {
            return false
          }
        })
      })
    },

    validateWorks (works) {
      return works.every(work => {
        return work.steps.every(step => {
          if (
            step.workStepType == 'SetTalkValueByEvent' ||
            step.workStepType == 'NewTalk'
          ) {
            return this.validateSetTalkValue(step)
          } else if (step.workStepType == 'SetTalkValueByFilter') {
            return this.validateSetTalkValueWithFilter(step)
          } else if (step.workStepType == 'SetTalkTag') {
            return this.validateSetTalkTag(step)
          } else if (step.workStepType == 'RemoveTalkTag') {
            return this.validateRemoveTalkTag(step)
          } else if (step.workStepType == 'ExecuteParentJob') {
            return this.validateExecuteJob(step)
          } else if (step.workStepType == 'SendEmail') {
            return this.validateSendEmail(step)
          } else if (step.workStepType == 'Notification') {
            return this.validateSendNotification(step)
          } else if (step.workStepType == 'SetTalkDialog') {
            return this.validateSetTalkDialog(step)
          } else if (step.workStepType == 'SetTalkDialogByFilter') {
            return this.validateSetTalkDialogByFilter(step)
          } else if (
            [
              'SitaIntegration',
              'ComprovanteCarregamento',
              'ComprovanteAverbacao',
              'ExtratoDesova',
              'ExtratoAvaria'
            ].includes(step.workStepType)
          ) {
            return true
          } else if (step.workStepType == 'HttpRequest') {
            return true
          } else {
            return false
          }
        })
      })
    },

    validateExecuteJob (data) {
      return true
    },

    validateSetTalkValueByEvent (data) {
      return true
    },

    validateSetTalkDialog (data) {
      const valid =
        data.configs[0].value != null &&
        typeof data.configs[0].accountIds != 'undefined'

      return valid
    },

    validateSetTalkDialogByFilter (data) {
      const filter = {
        json: data.filter || null,
        filterId: data.filter || null,
        filterName: data.filter || null
      }

      const validConfigs =
        data.configs[0].value != null &&
        typeof data.configs[0].accountIds != 'undefined'

      return !!(validConfigs && filter.json != null)
    },

    validateHttpRequest (data) {
      return true
    },

    validateEmail (email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      return re.test(String(email).toLowerCase())
    },

    validateSendEmail (data) {
      if (!data.configs.length) return false
      if (!data.configs[0].subject) return false

      let returnValid = true

      /* */

      const array = Array.isArray(data.configs[0].to)
        ? data.configs[0].to
        : data.configs[0].to.split(';')

      array.forEach(mail => {
        if (mail.address == null || mail.address.trim() == '') {
          if (data.configs[0].sendToCreatedBy) {
            returnValid = true
          } else {
            returnValid = false
          }
        } else {
          if (returnValid == true) {
            returnValid = this.validateEmail(mail.address)
          }
        }
      })

      return returnValid
    },

    validateSendNotification (data) {
      const valid = data.configs.every(
        config =>
          config.notificationId != null &&
          config.workStepNotificationReceiverModel.every(
            receiver =>
              receiver.receiverId != null &&
              receiver.workStepNotificationReceiverType != null
          )
      )

      return valid
    },

    validateSetTalkTag (data) {
      const returnValid = !data.configs.some(x => x.tagId == null)

      return returnValid
    },

    validateRemoveTalkTag (data) {
      const returnValid = !data.configs.some(x => x.tagId == null)

      return returnValid
    },

    validateSetTalkValue (data) {
      const returnValid =
        !data.configs.some(x =>
          x.subjectAttributeId == null ||
          x.subjectId == null ||
          (typeof x.value != 'undefined' && x.value != null)
            ? typeof x.value == 'object' && x.value.id
              ? !x.value.id
              : x.value.toString().trim() == '' && !x.checked
            : !(x.setAttributeValueType == 2 || x.clearValue)
        ) && data.configs.length > 0

      return returnValid
    },

    validateSetTalkValueWithFilter (data) {
      const configs = data.configs
      const filter = {
        json: data.filter || null,
        filterId: data.filter || null,
        filterName: data.filter || null
      }

      if (filter.json == null) return false

      const returnValid = !configs.some(x =>
        x.subjectAttributeId == null ||
        x.subjectId == null ||
        (typeof x.value != 'undefined' && x.value != null)
          ? typeof x.value == 'object'
            ? !x.value.id
            : x.value.toString().trim() == '' && !x.checked
          : !(x.setAttributeValueType == 2 || x.clearValue)
      )

      return returnValid
    },

    validateFilter (data) {
      if (data.eventStepType === 2) if (!data.cronString) return false

      if (data.filter) return true

      return false
    }
  }
}
