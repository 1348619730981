const moduleNotification = {
  state: () => ({
    items: []
  }),
  mutations: {
    ADD_NOTIFICATION ({ items }, item) {
      item.color = !item.color ? item.type === 'error' ? 'red' : item.type === 'alert' ? 'blue' : 'green' : item.color
      item.title = !item.title ? 'Notificação' : item.title
      item.icon = !item.icon ? 'warning' : item.icon
      items.push(item)
    },
    REMOVE_NOTIFICATION ({ items }, index) {
      items.splice(index, 1)
    }
  },
  actions: {
    async notification ({ state, commit }, data) {
      if (state.items.length === 3) state.items.splice(0, 1)
      const id = state.items.length + 1
      commit('ADD_NOTIFICATION', { id, ...data })

      setTimeout(() => {
        const index = state.items.findIndex((item) => item.id === id)
        if (index > -1) {
          commit('REMOVE_NOTIFICATION', index)
        }
      }, 5000)
    },
    closeNotification ({ state, commit }, notificationId) {
      const index = state.items.findIndex((item) => item.id === notificationId)
      commit('REMOVE_NOTIFICATION', index)
    }
  }
}

export default moduleNotification
