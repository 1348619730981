<script setup>
import { ref, reactive, computed, watch, nextTick, onMounted } from 'vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  columnIndex: {
    type: Number,
    default: 0
  },
  subjectList: {
    type: Object,
    default: () => {}
  },
  focus: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'change-attr',
  'change-name'
])

const targetEditable = ref(null)
const targetInput = ref(null)
const targetAttributes = ref([])
const content = ref('')

watch(content, () => {
  const regexp = /\$\{([a-zA-Z0-9.]*)\}/g
  const array = regexp.exec(content.value)

  if (array === null) {
    targetInput.value.setCustomValidity('Nenhum atributo selecionado')
  } else {
    targetInput.value.setCustomValidity('')
  }

  emit('change-attr', { value: content.value })
})

watch(
  () => props.columnIndex,
  () => {
    selectAttribute('', false)
  }
)

onMounted(() => {
  const observer = new MutationObserver(() => {
    content.value = ''
    targetEditable.value.childNodes.forEach((elem) => {
      convertHTMLToString(elem)

      if (elem.childNodes.length > 0 && elem.nodeName !== 'SPAN') {
        elem.childNodes.forEach((elemChild) => {
          convertHTMLToString(elemChild)
        })
      }
    })
  })

  observer.observe(targetEditable.value, {
    childList: true,
    subtree: true,
    characterData: true
  })

  if (props.data.value) {
    convertStringToHTML(props.data.value)
  }

  if (props.focus) {
    targetEditable.value.innerText = '${'
    nextTick(() => {
      targetEditable.value.focus()
      replaceAttribute()
    })
  }
})

const handleSwitch = (event) => {
  emit('change-attr', { isGrouped: event })
}

const changeValue = () => {
  replaceAttribute()
}

const handleSpace = () => {
  const selection = window.getSelection()
  const range = selection.getRangeAt(0)
  const space = document.createTextNode('\u00A0')

  range.deleteContents()
  range.insertNode(space)
  range.setStartAfter(space)
  range.setEndAfter(space)
  range.collapse(false)

  selection.removeAllRanges()
  selection.addRange(range)
}

const convertStringToHTML = (value) => {
  const regexp = /(\$\{[A-Za-z0-9.]*\})/g
  let HTML = value.replace(regexp, '<span contenteditable="false">$1</span>')
  HTML = HTML.split('/n')

  HTML.forEach((item, index) => {
    if (index > 0 && item.length > 0) {
      HTML[index] = `<div>${item}</div>`
    }
  })

  targetEditable.value.innerHTML = HTML.join('')
}

const convertHTMLToString = (elem) => {
  if (elem?.nodeType === 3) {
    content.value += elem.textContent.replace(/\xA0/g, ' ')
  }

  if (elem?.nodeName === 'SPAN') {
    content.value += elem.innerText
  }

  if (elem?.nodeName === 'DIV') {
    content.value += '/n'
  }
}

const replaceAttribute = (value, focus) => {
  targetEditable.value.childNodes.forEach((elem) => {
    findStringToReplace(elem, value, focus)

    if (elem.childNodes.length > 0 && elem.nodeName !== 'SPAN') {
      elem.childNodes.forEach((elemChild) => {
        findStringToReplace(elemChild, value, focus)
      })
    }
  })
}

const findStringToReplace = (elem, value, focus) => {
  if (elem.nodeType === 3) {
    const position = elem.textContent.indexOf('${')

    if (position >= 0) {
      if (typeof value !== 'undefined') {
        if (focus) {
          const selection = window.getSelection()
          const range = selection.getRangeAt(0)
          range.setStartAfter(elem)
          range.setEndAfter(elem)
          range.collapse(false)

          selection.removeAllRanges()
          selection.addRange(range)
        }

        elem.textContent = elem.textContent.replace('${', '') // eslint-disable-line no-param-reassign

        if (value !== '') {
          replaceWithAttribute(value.identifier)
          emit('change-name', value.attributeName)
        }
      } else {
        const range = document.createRange()
        range.selectNode(elem)

        nextTick(() => {
          const textRect = range.getBoundingClientRect()
          const elementRect = document.getElementById('grid_columns_template_report').getBoundingClientRect()

          listAttributes.position.top = textRect.top - elementRect.top + 23
          listAttributes.position.left = textRect.left - elementRect.left + 14

          showList()
        })
      }
    }
  }
}

const replaceWithAttribute = (value) => {
  const selection = window.getSelection()
  const range = selection.getRangeAt(0)
  const span = document.createElement('span')
  const text = document.createTextNode(`$\{${value}}`)

  span.appendChild(text)
  span.setAttribute('contenteditable', false)

  range.deleteContents()
  range.insertNode(span)
  range.setStartAfter(span)
  range.setEndAfter(span)
  range.collapse(false)

  selection.removeAllRanges()
  selection.addRange(range)
}

// LISTA
const targetSearch = ref(null)
const targetListAttributes = ref(null)

const listAttributes = reactive({
  active: false,
  filter: null,
  position: {
    top: 0
  },
  items: computed(() => {
    const subjectAttributes = []

    props.subjectList.forEach((subject) => {
      subject.subjectAttributes.forEach((item) => {
        subjectAttributes.push({
          identifier: `${subject.subjectIdentifier}.${item.subjectAttributeIdentifier}`,
          attributeName: item.subjectAttributeName,
          subjectName: subject.subjectName
        })
      })
    })

    return subjectAttributes
  })
})

const filteredList = computed(() => {
  if (listAttributes.filter) {
    return listAttributes.items.filter((item) => {
      const term = listAttributes.filter.toLowerCase()
      const string = `${item.attributeName}${item.subjectName}${item.identifier}`
      return string.toLowerCase().indexOf(term) >= 0
    })
  }
  return listAttributes.items
})

onClickOutside(targetListAttributes, () => {
  selectAttribute('', false)
})

const showList = () => {
  listAttributes.active = true

  nextTick(() => {
    targetSearch.value?.focus()
  })
}

const selectAttribute = (item, focus = true) => {
  listAttributes.active = false
  listAttributes.filter = null

  replaceAttribute(item, focus)
}

const filterList = (event) => {
  listAttributes.filter = event.target.value
}

const focusAtribute = (direction) => {
  let indexFocused = targetAttributes.value.findIndex((elem) => elem === document.activeElement)

  indexFocused += direction === 'up' ? -1 : 1

  indexFocused = indexFocused >= targetAttributes.value.length
    ? targetAttributes.value.length - 1
    : indexFocused

  if (indexFocused >= 0) {
    targetAttributes.value[indexFocused].focus()
  } else {
    targetSearch.value.focus()
  }
}
</script>

<template>
  <div :class="$style.attributes">
    <div
      ref="targetEditable"
      spellcheck="false"
      :contenteditable="true"
      :class="$style.attributesTextarea"
      @paste.prevent
      @keydown.space.prevent="handleSpace"
      @input="changeValue"
    />
    <input
      ref="targetInput"
      type="text"
      required
      :class="$style.attributesTextareaInput"
      :value="content"
    >

    <div
      :class="$style.attributesSwitch"
    >
      <app-switch
        :active="props.data.isGrouped"
        @change="handleSwitch"
      />
      <span>campo agrupador</span>
    </div>

    <Teleport to="#grid_columns_template_report">
      <div
        v-if="listAttributes.active"
        ref="targetListAttributes"
        :class="$style.attributesList"
        :style="{
          top: `${listAttributes.position.top}px`,
          left: `${listAttributes.position.left}px`,
        }"
        @keydown.esc="selectAttribute('')"
      >
        <div :class="$style.attributesListHeader">
          <app-icon
            :class="$style.attributesListIcon"
            glyph="search"
          />

          <input
            ref="targetSearch"
            :class="$style.attributesListInput"
            type="text"
            @input="filterList"
            @keydown.enter.prevent
            @keydown.down.prevent="focusAtribute('down')"
          >

          <button
            :class="$style.attributesListClose"
            type="button"
            title="Cancelar"
            tabindex="-1"
            @click="selectAttribute('')"
          >
            <app-icon
              glyph="close"
              size="20"
            />
          </button>
        </div>

        <div
          :class="$style.attributesListItems"
          @keydown.up.prevent="focusAtribute('up')"
          @keydown.down.prevent="focusAtribute('down')"
        >
          <span
            v-if="!filteredList.length"
            :class="$style.attributesListNofound"
          >
            Nenhum item encontrado
          </span>

          <button
            v-for="(item, key) in filteredList"
            :key="key"
            ref="targetAttributes"
            type="button"
            :title="`${item.subjectName} - ${item.attributeName}`"
            :class="$style.attributesListItem"
            @click="selectAttribute(item)"
          >
            {{ `${item.subjectName} - ${item.attributeName}` }}
          </button>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" module>
.attributes{
  position: relative;
  margin: 0 0 10px;
  width: 100%;

  &__textarea {
    --textarea-color: #000;
    --textarea-background-color: #fff;
    --textarea-border-color: #{$md-grey-400};
    --textarea-scrollbar-color: #{$md-grey-400};
    padding: 8px;
    width: 100%;
    height: 74px;
    background: var(--textarea-background-color);
    color: var(--textarea-color);
    border: 2px solid var(--textarea-border-color);
    border-radius: 5px;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    word-break: break-all;
    transition: 0.2s;
    transition-property: border;
    outline: 0;
    overflow: auto;

    :global(.nightlymode) & {
      --textarea-color: #fff;
      --textarea-background-color: #212121;
      --textarea-scrollbar-color: #{$md-grey-700};
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;

      &-track {
        background-color: #fff;

        :global(.nightlymode) & {
          background-color: #212121;
        }
      }

      &-thumb {
        background-color: var(--textarea-scrollbar-color);
        border: 2px solid var(--textarea-background-color);
      }
    }

    &:focus {
      border-color: var(--theme-color-1);
    }

    span{
      color: var(--md-indigo-a700);
      font-weight: 600;
    }

    &__input{
      position: absolute;
      left: 20px;
      width: 240px;
      height: 0;
      opacity: 0;
    }
  }

  &__list {
    position: absolute;
    left: 30px;
    z-index: 2;
    padding: 0 0 5px;
    width: 250px;
    background: #000;
    color: #fff;
    border-radius: 5px;

    &__header {
      display: flex;
      align-items: center;
      padding: 2px 2px 2px 5px;
      border-bottom: 1px solid $md-grey-800;
    }

    &__nofound {
      display: block;
      font-size: 1.2rem;
      padding: 4px 10px;
    }

    &__icon {
      fill: #fff;
      margin: 0 3px;
    }

    &__input {
      width: 100%;
      height: 30px;
      background: transparent;
      color: #fff;
      font-size: 1.2rem;
    }

    &__close {
      width: 30px;
      height: 30px;
      background: none;
      cursor: pointer;

      &:focus{
        outline: 0;
      }

      svg {
        fill: #fff;
      }
    }

    &_items {
      max-height: 130px;
      overflow: hidden auto;
      scrollbar-width: thin;
      scrollbar-color: $md-grey-800 $md-grey-900;

      &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: $md-grey-900;

        &-thumb {
          background-color: $md-grey-600;
        }
      }
    }

    &__item {
      overflow: hidden;
      display: block;
      width: 100%;
      padding: 5px 10px;
      background: none;
      color: #fff;
      border: none;
      font-size: 1.2rem;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      outline: 0;
      cursor: pointer;

      &:hover, &:focus{
        background-color: $md-grey-700;
        outline: 0;
      }
    }
  }

  &__switch {
    display: flex;
    gap: 5px;
    margin: 10px 0 0;
    color: $md-grey-800;
    font-size: 1.2rem;
    line-height: 1.2;

    :global(.nightlymode) & {
      color: $md-grey-200;
    }
  }

}
</style>
