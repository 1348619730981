<template>
  <div :class="[$style.checkbox, readonly ? $style.checkboxDisabled : null]">
    <input
      type="checkbox"
      :checked="controller"
      :required="config.required"
      :style="{
        position: 'absolute',
        'z-index': -1,
        'pointer-events': 'none',
        opacity: 0,
        left: '5px',
      }"
      v-if="config.required"
    />

    <app-switch :active="controller" v-on:change="changeState" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, String],
      default: () => false
    },

    mode: String,
    focus: Boolean,
    config: Object,
    talkId: Number,
    emitInit: Boolean
  },

  data () {
    return {
      controller: false
    }
  },

  computed: {
    readonly () {
      return (
        this.config.readonly ||
        ((this.talkId != 0) & (typeof this.talkId != 'undefined')
          ? !this.config.actions.update
          : !this.config.actions.insert)
      )
    }
  },

  beforeMount () {
    if ((this.value || this.value == 'true') && this.value != 'false') {
      this.controller = true
    } else {
      this.controller = false
    }

    if (this.emitInit) {
      this.$emit('change-input', this.controller)
    }
  },

  methods: {
    changeState (payload) {
      if (payload) {
        this.controller = true

        this.$emit('change-input', 'true')
      } else {
        this.controller = false

        this.$emit('change-input', 'false')
      }
    }
  },

  watch: {
    value: function () {
      if ((this.value || this.value == 'true') && this.value != 'false') {
        this.controller = true
      } else {
        this.controller = false
      }
    }
  }
}
</script>

<style lang="scss" module>

/* */

.checkbox {
  margin-top: math.div($theme-padding, 2);
  margin-right: math.div($theme-padding, 2);

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
