<template>
  <app-view>
    <app-confirm-promise ref="confirm" size="640" />

    <app-box padding>
      <app-box>
        <app-loader
          v-if="loading.active || loading.error"
          :error="loading.error"
          :response="loading.response"
          :active="loading.active"
          key="loading"
          v-on:reload="init()"
        />

        <div
          class="row justify-content-center"
          v-if="!loading.active && !loading.error"
        >
          <div class="col-12 col-xl-8 mb-5">
            <app-box margin>
              <app-title
                :label="
                  !getRouteJobId ? 'Criar nova automação' : 'Editar automação'
                "
              />
            </app-box>
          </div>

          <div class="col-12 col-xl-8 mb-5">
            <app-input
              label="Título da automação"
              required
              v-model:value="job.name"
            />
          </div>

          <div class="col-12 col-xl-8">
            <sequence
              :job="job"
              v-on:add="addEventOrWork"
              v-on:toogle-active="toogleActive"
              v-on:remove-active="removeActive"
              v-on:remove-step="removeStep"
            />
          </div>

          <!-- <div class="col-12">

                        <app-button label="Salvar" icon="save" v-on:click="save()" :loading="saveLoading" />

                    </div> -->
        </div>
      </app-box>
    </app-box>

    <app-actions-box v-if="!loading.active && !loading.error">
      <div class="row justify-content-center">
        <div class="col-auto">
          <app-button
            label="Salvar"
            icon="save"
            v-on:click="save()"
            :loading="saveLoading"
            :disabled="!valid || !getStatusSteps"
          />
        </div>

        <div
          class="col-auto"
          v-if="getRouteJobId && getRouteQuery.delete !== 'true'"
        >
          <app-button
            label="Salvar Como"
            icon="save_alt"
            color="cyan"
            v-on:click="saveAs()"
            :disabled="!valid || !getStatusSteps"
          />
        </div>

        <div class="col-auto">
          <app-button
            label="Cancelar"
            icon="close"
            color="red-500"
            :id="$style.id_cancel"
            v-on:click="cancel"
          />
        </div>

        <div class="col-auto" v-if="getRouteQuery.delete == 'true'">
          <app-button
            label="Excluir"
            icon="delete"
            color="red-900"
            :id="$style.id_delete"
            v-on:click="remove"
          />
        </div>
      </div>
    </app-actions-box>
  </app-view>
</template>

<script>
import Validator from './validator'
import striptags from 'striptags'
import Sequence from './components/Sequence.vue'

export default {
  components: {
    Sequence
  },

  mixins: [Validator],

  data: () => ({
    striptags,
    job: {
      jobId: null,
      name: null,

      events: [
        {
          eventId: null,
          name: '',
          once: true,
          negate: false,
          steps: [
            {
              eventStepId: null,
              eventStepType: null,
              filterName: null,
              filter: null,
              filterId: null,
              dialogTypeId: null
            }
          ]
        }
      ],

      works: [
        {
          workId: null,
          name: '',
          steps: [
            {
              configs: [],
              workStepId: null,
              workStepType: null,
              name: '',
              filterName: null,
              filter: null,
              filterId: null
            }
          ]
        }
      ]
    },

    originalName: false,

    loading: {
      active: false,
      error: false
    },

    saveLoading: false,
    saveAsActive: false,

    statusSteps: {
      events: [],
      works: []
    }
  }),

  computed: {
    getRouteJobId () {
      return this.$route.params.jobId
    },

    getRouteQuery () {
      return this.$route.query
    },

    valid () {
      return this.validator(JSON.parse(JSON.stringify(this.job)))
    },

    getStatusSteps () {
      const events = this.statusSteps.events.some((x) => x == true)
      const works = this.statusSteps.works.some((x) => x == true)

      if (events || works) {
        return false
      } else {
        return true
      }
    }
  },

  beforeMount () {
    this.init()
  },

  methods: {
    async remove () {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente excluir esse item?', 'red')
        .then((response) => {
          confirm.loading = true

          return this.$http
            .delete(`${this.$store.getters.api}/job/${this.getRouteJobId}`)
            .then(({ data }) => {
              this.$store.commit(
                'closePage',
                '/automation/editor' +
                    (this.getRouteJobId
                      ? `/${this.getRouteJobId}?delete=true`
                      : '')
              )

              /* */

              this.$store.dispatch('notification', {
                text: 'Automação excluida com sucesso!',
                color: 'green'
              })
            })
            .catch((error) => false)
        })
        .catch((error) => false)

      confirm.close()
    },

    removeStep ({ mode, index }) {
      this.job[mode][0].steps.splice(index, 1)
    },

    toogleActive (local) {
      this.statusSteps[local.type === 1 ? 'events' : 'works'][local.index] =
          local.value
    },

    removeActive (payload) {
      this.statusSteps[payload.mode].splice(payload.index, 1)
    },

    async init () {
      if (this.getRouteJobId) {
        this.loading.active = true

        await this.$http
          .get(`${this.$store.getters.api}/job/${this.getRouteJobId}`)
          .then(({ data }) => {
            this.job = data

            this.originalName = data.name
          })
          .catch((error) => {
            this.loading.response = error.response

            this.loading.active = false
            this.loading.error = true
          })

        this.loading.active = false
      }
    },

    async saveAs () {
      const confirm = this.$refs.confirm

      confirm.sizeTemp = '4'

      await confirm
        .open(
          'Essa ação ira duplicar todas as configurações dessa automação. Deseja continuar?',
          'orange',
          null
        )
        .then((response) => {
          this.saveAsActive = true

          confirm.close()

          this.job.jobId = null

          if (this.originalName && this.originalName == this.job.name) {
            this.job.name = this.job.name + ' (Cópia)'
          }

          this.job.events.forEach((event) => {
            event.eventId = null

            event.steps.forEach((step) => {
              step.eventStepId = null
            })
          })

          this.job.works.forEach((work) => {
            work.workId = null

            work.steps.forEach((step) => {
              step.workStepId = null
            })
          })

          return this.save()
        })
        .catch((error) => {
          this.saveAsActive = false
        })
    },

    async save () {
      const confirm = this.$refs.confirm

      let executeImmediately

      const promise =
          this.saveAsActive || this.job.events[0].steps[0].eventStepType == 3
            ? Promise.resolve()
            : confirm.open(
              'Executar esta automação para os registro existentes?',
              'green',
              'automation'
            )

      await promise
        .then((response) => {
          executeImmediately = response == 'saveExecute'

          /* */

          this.saveLoading = true

          const job = JSON.parse(JSON.stringify(this.job))

          /* */

          job.events.forEach((event) => {
            event.steps.forEach((step) => {
              const json = JSON.parse(step.filter)

              json.map((val) => {
                delete val.raw
              })

              step.filter = this.striptags(JSON.stringify(json))
            })
          })

          job.works.forEach((work) => {
            work.steps.forEach((step) => {
              if (step.filter) {
                const json = JSON.parse(step.filter)

                json.map((val) => {
                  delete val.raw
                })

                step.filter = this.striptags(
                  JSON.stringify(json)
                )
              }

              if (step.workStepType == 'SendEmail') {
                step.configs = step.configs.map(
                  ({
                    content,
                    subject,
                    to,
                    attachments,
                    attributes,
                    relatedSubjects,
                    sendToCreatedBy,
                    notifyPropertyChanged,
                    sendEmptyReport,
                    sendToRelated: { send, subjectId }
                  }) => ({
                    content,
                    subject,
                    to:
                        typeof to == 'string'
                          ? to
                          : [
                              ...to
                                .filter(({ address }) => address !== '')
                                .map(({ address }) => address)
                                .filter(Boolean)
                            ].join(';'),
                    attachments,
                    attributes,
                    relatedSubjects,
                    sendToCreatedBy,
                    notifyPropertyChanged,
                    sendEmptyReport,
                    sendToRelated: {
                      send,
                      subjectId
                    }
                  })
                )
              } else if (
                step.workStepType == 'SetTalkValueByEvent' ||
                  step.workStepType == 'SetTalkValueByFilter'
              ) {
                step.configs = step.configs.map(
                  ({
                    checked,
                    subjectAttributeId,
                    subjectId,
                    value,
                    setAttributeValueType
                  }) => ({
                    subjectAttributeId,
                    subjectId,
                    value,
                    setAttributeValueType: checked
                      ? 2
                      : typeof setAttributeValueType !== 'undefined'
                        ? setAttributeValueType
                        : 1
                  })
                )
              } else if (
                step.workStepType == 'SetTalkDialog' ||
                  step.workStepType == 'SetTalkDialogByFilter'
              ) {
                step.configs = step.configs.map(({ value, accountIds }) => ({
                  value,
                  accountIds
                }))
              } else if (step.workStepType == 'ExecuteParentJob') {
                step.configs = step.configs.map(
                  ({
                    forceExecution,
                    jobId,
                    jobName,
                    subjectAttributeId,
                    subjectId
                  }) => ({
                    forceExecution,
                    jobId,
                    jobName,
                    subjectAttributeId,
                    subjectId
                  })
                )
              }
            })
          })

          confirm.close()

          /* */

          const http = this.job.jobId
            ? this.$http.put(
                  `${this.$store.getters.api}/job/${this.getRouteJobId}?executeImmediately=${executeImmediately}`,
                  job
            )
            : this.$http.post(
                  `${this.$store.getters.api}/job?executeImmediately=${executeImmediately}`,
                  job
            )

          http
            .then(({ data }) => {
              if (this.getRouteQuery.delete == 'true') {
                this.$store.commit(
                  'closePage',
                  '/automation/editor' +
                      (this.getRouteJobId
                        ? `/${this.getRouteJobId}?delete=true`
                        : '')
                )
              } else if (this.saveAsActive) {
                const resourceId = data.resourceId

                this.$store.commit('closePage', '/automation/list')
                this.$store.commit(
                  'closePage',
                  '/automation/editor' +
                      (this.getRouteJobId ? `/${this.getRouteJobId}` : '')
                )

                this.$router.push({
                  name: 'automation_list',
                  params: {
                    refresh: true
                  }
                })

                this.$router.push({
                  name: 'automation_editor',
                  params: {
                    jobId: resourceId
                  }
                })
              } else {
                this.$store.commit('closePage', '/automation/list')
                this.$store.commit(
                  'closePage',
                  '/automation/editor' +
                      (this.getRouteJobId ? `/${this.getRouteJobId}` : '')
                )

                this.$router.push({
                  name: 'automation_list',
                  params: {
                    refresh: true
                  }
                })
              }

              this.$store.dispatch('notification', {
                text: `Automação ${
                    this.job.jobId ? 'atualizada' : 'cadastrada'
                  } com sucesso!`
              })
            })
            .catch((error) => {
              this.saveLoading = false

              this.$store.dispatch('notification', {
                type: 'error',
                text: 'Ocorreu um erro na persistência dos dados. Por favor, tente novamente.',
                error
              })
            })
        })
        .catch((error) => false)
    },

    addEventOrWork (mode) {
      if (mode == 'events') {
        this.job.events.push({
          eventId: null,
          once: false,
          steps: [
            {
              eventStepId: null,
              eventStepType: null,
              filterName: null,
              filter: null
            }
          ]
        })
      } else if (mode == 'works') {
        this.job.works[0].steps.push({
          configs: [],
          workStepId: null,
          workStepType: null,
          name: '',
          filterName: null,
          filter: null,
          filterId: null
        })
      }
    },

    changeTypeDialog (id) {
      this.job.events[0].steps[0].dialogTypeId = id
    },

    cancel () {
      if (this.getRouteQuery.delete == 'true') {
        this.$store.commit(
          'closePage',
          '/automation/editor' +
              (this.getRouteJobId ? `/${this.getRouteJobId}?delete=true` : '')
        )
      } else {
        this.$store.commit(
          'closePage',
          '/automation/editor' +
              (this.getRouteJobId ? `/${this.getRouteJobId}` : '')
        )
      }
    }
  }

}
</script>

<style lang="scss" module>
  /* */
</style>
