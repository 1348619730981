import axios from 'axios'

const moduleTables = {
  state: () => ({
    tables: [],
    attributes: []
  }),
  getters: {
    getTables ({ tables }) {
      return tables
        .filter((value) => {
          return (
            value.subjectAttributes.length &&
                 value.subjectAttributes.every(
                   ({
                     subjectAttributeId,
                     subjectAttributeName,
                     attributeName
                   }) =>
                     typeof subjectAttributeId !== 'undefined' &&
                     (typeof subjectAttributeName !== 'undefined' ||
                       typeof attributeName !== 'undefined')
                 )
          )
        })
        .map((value) => ({
          id: parseInt(value.subjectId),
          label: value.subjectName.trim(),
          identifier: value.subjectIdentifier.toLowerCase(),
          icon: value.icon,
          columns: value.subjectAttributes.map(
            ({
              subjectAttributeId,
              attributeName,
              subjectAttributeName,
              componentId,
              subjectAttributeIdentifier,
              relatedSubject,
              icon
            }) => {
              const component = {}

              component.type = componentId

              component.config = {}
              component.config.readonly = false
              component.config.required = true
              component.config.actions = {
                update: true,
                insert: true
              }

              return {
                id: parseInt(subjectAttributeId),
                label: (subjectAttributeName || attributeName).trim(),
                icon,
                identifier: subjectAttributeIdentifier,
                relatedSubject,
                component
              }
            }
          )
        }))
    },
    getAttributes ({ attributes }) {
      return attributes.map((value) => ({
        id: parseInt(value.attributeId),
        label: value.attributeName.trim(),
        icon: value.icon
      }))
    }
  },
  mutations: {
    SET_TABLES (state, tables) {
      state.tables = tables
    },
    SET_ATTRIBUTES (state, attributes) {
      state.attributes = attributes
    }
  },
  actions: {
    async loadTables ({ getters, commit }) {
      await axios
        .get(`${getters.api}/subject/`)
        .then(({ data }) => {
          commit('SET_TABLES', data.itens)
        })
        .catch((error) => {
          throw error
        })
    },
    async loadAttributes ({ getters, commit }) {
      await axios
        .get(`${getters.api}/attribute/`)
        .then(({ data }) => {
          commit('SET_ATTRIBUTES', data)
        })
        .catch((error) => {
          throw error
        })
    }
  }
}

export default moduleTables
