import DynamicRender from '@/components/dynamicRender/DynamicRender.vue'
import { createRouter as _createRouter, createWebHashHistory } from 'vue-router'

const isAdmin = () => {
  const user = JSON.parse(
    localStorage.getItem('talkture_user') ||
      sessionStorage.getItem('talkture_user')
  )
  return user?.data?.isAdmin || false
}

export function createRouter () {
  return _createRouter({
    history: createWebHashHistory(),
    routes: [
      {
        path: '/',
        name: 'dashboard',
        component: DynamicRender,
        meta: {
          icon: 'emix',
          label: 'Dashboard'
        }
      },

      {
        path: '/search/',
        name: 'search',
        component: DynamicRender,
        meta: {
          icon: 'search',
          label: 'Resultados da Busca'
        }
      },

      /* Tags */

      {
        path: '/tags/list/',
        name: 'tags_list',
        component: DynamicRender,
        meta: {
          icon: 'bookmark',
          label: 'Tags'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/tags/editor/:tagId?',
        name: 'tags_editor',
        component: DynamicRender,
        meta: {
          icon: 'bookmark',
          label: 'Editor de tags'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      /* automation */

      {
        path: '/automation/editor/:jobId?',
        name: 'automation_editor',
        component: DynamicRender,
        meta: {
          icon: 'history',
          label: 'Editor de Automações'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/automation/list',
        name: 'automation_list',
        component: DynamicRender,
        meta: {
          icon: 'history',
          label: 'Automações'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/context/:scenario/:subject/:talk',
        name: 'context_id',
        component: DynamicRender
      },

      {
        path: '/context/:scenario/:subject/:template?/:filters?',
        name: 'context_template_filter',
        component: DynamicRender
      },

      {
        path: '/context/:scenario/:subject/:filters?',
        name: 'context_filter',
        component: DynamicRender
      },

      {
        path: '/context/:scenario/:subject/',
        name: 'context',
        component: DynamicRender
      },

      {
        path: '/notifications',
        name: 'notifications',
        component: DynamicRender,
        meta: {
          icon: 'notification_important',
          label: 'Notificações'
        }
      },

      /* ---------------- CONFIG ---------------- */

      {
        path: '/config/menu',
        name: 'menu',
        component: DynamicRender,
        meta: {
          icon: 'menu',
          label: 'Menu'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/config/users',
        name: 'config_users_list',
        component: DynamicRender,
        meta: {
          icon: 'account_circle',
          label: 'Todos os Usuários'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/config/users/add',
        name: 'config_users_add',
        component: DynamicRender,
        meta: {
          icon: 'account_circle',
          label: 'Adicionar novo usuário'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/config/users/edit/:id',
        name: 'config_users_edit',
        component: DynamicRender,
        meta: {
          icon: 'account_circle',
          label: 'Editando informações do usuário'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/config/theme',
        name: 'config_theme',
        component: DynamicRender,
        meta: {
          icon: 'color_lens',
          label: 'Temas'
        }
      },

      {
        path: '/config/dark-mode',
        name: 'config_dark_mode',
        component: DynamicRender,
        meta: {
          icon: 'brightness_2',
          label: 'Modo escuro'
        }
      },

      {
        path: '/config/access_profile',
        name: 'config_access_profile_list',
        component: DynamicRender,
        meta: {
          icon: 'group',
          label: 'Todos os perfis de acesso'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/config/access_profile/room',
        name: 'config_access_profile_add',
        component: DynamicRender,
        meta: {
          icon: 'group',
          label: 'Adicionar novo perfil de acesso'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/config/access_profile/room/:id',
        name: 'config_access_profile_edit',
        component: DynamicRender,
        meta: {
          icon: 'group',
          label: 'Editando informações do perfil de acesso'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/usuario',
        name: 'user',
        component: DynamicRender,
        meta: {
          icon: 'account_edit',
          label: 'Informações do usuário'
        }
      },

      {
        path: '/template/list/custom',
        name: 'template_list_custom',
        component: DynamicRender,
        meta: {
          icon: 'view_carousel',
          label: 'Templates Personalizados'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/template/list/default',
        name: 'template_list_default',
        component: DynamicRender,
        meta: {
          icon: 'view_carousel',
          label: 'Templates Padrões'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/template/list/users',
        name: 'template_list_users',
        component: DynamicRender,
        meta: {
          icon: 'view_carousel',
          label: 'Templates de Usuários'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/notification/list',
        name: 'notification_list',
        component: DynamicRender,
        meta: {
          icon: 'notifications',
          label: 'Notificações'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: '/notification/:notificationId?',
        name: 'notificationPage',
        component: DynamicRender,
        meta: {
          icon: 'notifications',
          label: 'Notificação'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/channels',
        name: 'channels',
        component: DynamicRender,
        meta: {
          icon: 'notifications',
          label: 'Canais'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/admin/:mode',
        name: 'admin_mode',
        component: DynamicRender,
        meta: {
          icon: 'group',
          label: 'Administrador'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/template/editor/:type/:templateId',
        name: 'template_editor',
        component: DynamicRender,
        meta: {
          icon: 'view_carousel',
          label: 'Editor de template'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      {
        path: '/template/editor/:type',
        name: 'template_editor_new',
        component: DynamicRender,
        meta: {
          icon: 'view_carousel',
          label: 'Editor de template'
        },
        beforeEnter: (to, from, next) => {
          if (isAdmin()) {
            next()
          } else {
            next('/')
          }
        }
      },

      /* -------------- CONFIG - END -------------- */

      {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: DynamicRender
      }
    ],

    scrollBehavior () {
      return { y: 0 }
    }
  })
}
