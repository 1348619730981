<template>
  <app-view>
    <div :class="$style.dashboard">
      <div :class="$style.dashboardLogo">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2190 512"
        >
          <path
            fill="#58c4c3"
            d="M1407.85 0h148.654v511.979h-148.654v-511.979z"
          ></path>
          <path
            fill="#58c4c3"
            d="M733.485 0v510.733h148.633v-245.084l151.126 88.349 151.084-88.349v245.083h148.633v-510.733l-299.759 175.306z"
          ></path>
          <path
            fill="#58c4c3"
            d="M605.148 204.867c-28.584 0-51.767 23.162-51.767 51.767 0 28.584 23.183 51.767 51.767 51.767 28.605 0 51.767-23.183 51.767-51.767s-23.163-51.767-51.767-51.767z"
          ></path>
          <path
            fill="#58c4c3"
            d="M409.546 69.591c-44.87-45.639-101.914-68.49-171.11-68.49-69.216 0-126.26 22.851-171.131 68.49-44.87 45.66-67.305 107.793-67.305 186.316v0.063c0 77.526 22.311 139.368 66.911 185.589 44.6 46.179 104.323 69.258 179.212 69.258 78.939 0 141.238-26.798 186.877-80.413l-0.041-0.041 0.041-0.041-106.068-72.976c-22.352 23.723-50.043 33.964-80.808 33.964-34.879 0-61.281-8.621-79.209-25.884-16.016-15.393-26.714-31.326-28.459-47.8l0.021-0.478-0.021-5.401h332.997c3.594-24.949 5.401-43.541 5.401-55.755v-0.083c-0.021-78.523-22.456-140.656-67.305-186.316zM345.585 206.549h-207.38l-0.021-0.81c-0.27-22.456 8.87-42.191 27.524-59.225 18.862-17.242 43.935-25.883 75.22-25.883 31.264 0 56.877 8.642 76.841 25.883 19.921 17.221 29.353 37.246 28.314 60.055h-0.498z"
          ></path>
          <path
            fill="#58c4c3"
            d="M1959.921 441.952l48.256 70.048h181.683l-140.033-194.729z"
          ></path>
          <path
            fill="#58c4c3"
            d="M2189.861 0h-181.684l-48.256 70.027 89.429 125.159z"
          ></path>
          <path
            fill="#f1ec67"
            d="M1820.698 0h-180.852l182.036 255.989-182.036 256.010h180.852l183.22-256.031z"
          ></path>
        </svg>
      </div>
    </div>
  </app-view>
</template>

<script>
</script>

<style lang="scss" module>

/* */

.dashboard {
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &__logo {
    svg {
      $original-width: 2190px;
      $original-height: 512px;

      $new-height: 40px;

      /* */

      width: (math.div($original-width, $original-height)) * $new-height;
      height: $new-height;

      path {
        fill: rgba(black, 0.1);

        :global(.nightlymode) & {
          fill: rgba(black, 0.5);
        }
      }
    }
  }
}
</style>
