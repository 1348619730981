const moduleReportModel = {
  state: () => ({
    reportModelColumnSubjects: []
  }),
  getters: {
    getReportModelColumnSubjects: (state) => state.reportModelColumnSubjects
  },
  mutations: {
    SET_REPORT_MODEL_COLUMN_SUBJECTS (state, subjects) {
      state.reportModelColumnSubjects = subjects
    }
  }
}

export default moduleReportModel
