<template>
  <app-config>
    <app-view>
      <transition-group name="fade" tag="span">
        <app-modal size="480" v-if="modal.exclude.active" key="confirm_exclude">

          <app-confirm
            :title="modal.exclude.title"
            :loading="modal.exclude.loading"
            id="id_delete_confirm"
            color="red"
            @ok="handleExclude"
            @cancel="closeExcludeModal"
          />
        </app-modal>
      </transition-group>

      <app-box padding>
        <app-box margin>
          <div
            class="row align-items-center mt-5 justify-content-between"
            style="width: 100%"
          >
            <app-title label="Todos os usuários" />
          </div>
        </app-box>
        <div class="row mt-5 justify-content-end">
          <div class="col-4 mb-5">
            <app-search
              :maxLength="25"
              @do-search="loadUsers"
              @clean-search="cleanSearch"
            />
          </div>
        </div>
          <app-loader
            v-if="loading"
            key="loading"
            :active="loading"
            :error="false"
            v-on:reload="loadUsers()"
          />

          <app-text v-else-if="error" key="error">
            >Ocorreu um erro ao tentar carregar a lista de usuários.</app-text
          >

          <app-box v-else-if="users.length" key="results">
            <div :class="$style.usuarios">
              <ul :class="$style.usuariosRow" :id="$style.id_usuarios">
                <li
                  :class="$style.usuariosList"
                  v-for="data in users"
                  :key="data.accountId"
                >
                  <div
                    :class="[
                      $style.usuariosBox,
                      data.inativo ? $style.usuariosBoxDisabled : null,
                    ]"
                  >
                    <div
                      class="d-flex align-items-center justify-content-between no-gutters flex-nowrap"
                    >
                      <router-link
                        class="d-flex justify-content-between align-items-center no-gutters flex-nowrap"
                        :to="{
                          name: 'config_users_edit',
                          params: {
                            id: data.accountId,
                          },
                        }"
                      >
                        <div :class="$style.usuariosImage">
                          <img
                            v-if="data.profileImage"
                            :src="`${$store.getters.api}${data.profileImage.url}`"
                            :alt="`Avatar de ${data.displayName}`"
                          />
                          <img
                            v-else
                            src="@/assets/images/placeholder-user.png"
                            :alt="`Avatar de ${data.displayName}`"
                          />
                        </div>

                        <div>
                          <div
                            :class="$style.usuariosType"
                            v-if="data.accountType == 2"
                          >
                            Integração
                          </div>
                          <div :class="$style.usuariosName">
                            {{ data.firstName }}
                            {{ data.lastName }}
                          </div>
                          <div :class="$style.usuariosEmail">
                            ({{ data.displayName }})
                          </div>
                          <div :class="$style.usuariosEmail" v-if="data.email">
                            {{ data.email }}
                          </div>
                        </div>
                      </router-link>

                      <div :class="$style.usuariosActions">
                        <ul :class="$style.usuariosActionsRow">
                          <li
                            :class="$style.usuariosActionsList"
                            v-if="data.canEdit"
                          >
                            <router-link
                              :to="{
                                name: 'config_users_edit',
                                params: {
                                  id: data.accountId,
                                },
                              }"
                            >
                              <app-tooltip label="Editar">
                                <app-icon
                                  glyph="edit"
                                  :class="$style.usuariosActionsIcon"
                                />
                              </app-tooltip>
                            </router-link>
                          </li>
                          <!-- <li
                            :class="$style.usuariosActionsList"
                            v-if="data.canDelete"
                          >
                            <app-tooltip
                              label="Excluir"
                              v-on:click="openExcludeModal(data)"
                            >
                              <app-icon
                                glyph="delete"
                                :class="$style.usuariosActionsIcon"
                              />
                            </app-tooltip>
                          </li> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="row justify-content-center mt-5">
                <div class="col-auto">
                  <app-pagination
                    :length="total"
                    :current-page="pagination.page"
                    :per-page="pagination.per_page"
                    :option-per-page="pagination.option_per_page"
                    v-on:change-page="changePage"
                    v-on:change-per-page="changePerPage"
                  />
                </div>
              </div>
            </div>
          </app-box>

          <app-text v-else key="empty"
            >Nenhum usuário encontrado. Cadastre usuários no sistema e eles
            aparecerão aqui!</app-text
          >
      </app-box>
    </app-view>
  </app-config>
</template>

<script setup>
import AppConfig from '../Index.vue'
import { useStore } from 'vuex'
import { ref, computed, watchEffect } from 'vue'
import { useMagicKeys, whenever } from '@vueuse/core'
import AppSearch from '@/components/AppSearch.vue'

const store = useStore()

const loading = ref(false)
const error = ref(false)

const pagination = ref({
  page: 1,
  per_page: 10,
  option_per_page: [10, 25, 50, 100]
})
const changePage = (value) => {
  pagination.value.page = value
}
const changePerPage = (value) => {
  pagination.value.per_page = value
  changePage(1)
}

const users = computed(() => store.getters.getUsers.data)
const total = computed(() => store.getters.getUsers.total)

const cleanSearch = (searchTerm) => {
  pagination.value.page = 1
  loadUsers()
}

const loadUsers = async (searchTerm = '') => {
  try {
    loading.value = true
    error.value = false

    if (searchTerm && typeof searchTerm === 'string') {
      pagination.value.page = 1
      await store.dispatch('loadUsers', {
        page: pagination.value.page,
        fetch: pagination.value.per_page,
        term: searchTerm
      })
    } else {
      await store.dispatch('loadUsers', {
        page: pagination.value.page,
        fetch: pagination.value.per_page
      })
    }
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
}

watchEffect(loadUsers)

const modal = ref({
  exclude: {
    active: false,
    title: false,
    loading: false,
    item: false
  }
})

const openExcludeModal = (item) => {
  modal.value.exclude.active = true
  modal.value.exclude.title = `Deseja excluir o usuário <b>${item.displayName} (${item?.email || ''})</b>?`
  modal.value.exclude.item = item
}

const closeExcludeModal = () => {
  modal.value.exclude.active = false
  modal.value.exclude.title = false
  modal.value.exclude.item = false
}

const handleExclude = async () => {
  try {
    modal.value.exclude.loading = true

    await store.dispatch('deleteUser', modal.value.exclude.item.accountId)
    store.dispatch('notification', {
      type: 'success',
      text: `O usuário <b>${modal.value.exclude.item.displayName}${
          modal.value.exclude.item.email
            ? ` (${modal.value.exclude.item.email})`
            : ''
        }</b> foi excluído com sucesso!`
    })

    if (pagination.value.page > 1 && users.value.length === 1) {
      pagination.value.page--
    }
  } catch (e) {
    const message =
        e?.response?.data?.errors[0]?.Message ||
        'Ocorreu um erro ao excluir o usuário. Por favor, tente novamente.'

    store.dispatch('notification', {
      type: 'error',
      text: message
    })
  } finally {
    modal.value.exclude.loading = false
    closeExcludeModal()
  }
}

const { current } = useMagicKeys()

whenever(current, () => {
  if (current.has('Escape') && current.size == 1 && modal.value.exclude.active) {
    closeExcludeModal()
  }
})
</script>

<style lang="scss" module>
  #id {
    &_delete_confirm,
    &_usuarios {
      text-decoration: none;
    }
  }
  .usuarios {
    &__row {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__list {
      margin-bottom: $theme-padding;
    }

    &__box {
      padding: $theme-padding $theme-padding * 2.5 $theme-padding $theme-padding;
      background: white;
      border-radius: 5px;

      &--disabled {
        opacity: 0.5;
      }
      :global(.nightlymode) & {
        background: $md-grey-900;

        &--disabled {
          opacity: 0.3;
        }
      }
    }

    &__image {
      width: 64px;
      height: 64px;
      margin-right: $theme-padding * 1.5;
      background: var(--theme-color-1);
      background-size: cover;
      border-radius: 50%;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
      }
    }

    &__type {
      padding: 3px 10px;
      margin-bottom: 5px;
      font-family: 'Raleway', sans-serif;
      @include font-sizer(9);
      text-transform: uppercase;
      line-height: 2;
      letter-spacing: 1px;
      color: white;
      font-weight: 400;
      background: $md-purple-500;
      border-radius: 20px;
      display: inline-block;
    }

    &__name {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(24);
      line-height: 1.1;
      color: black;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      :global(.nightlymode) & {
        color: white;
      }
    }

    &__email {
      margin-top: 5px;
      font-family: 'Raleway', sans-serif;
      @include font-sizer(14);
      line-height: 1.1;
      color: $md-grey-500;
      font-weight: 300;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__actions {
      &__row {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-end;
      }

      &__list {
        display: inline-block;

        &:not(:last-of-type) {
          margin-right: $theme-padding;
        }
      }

      &__icon {
        $size: 24px;
        width: $size;
        height: $size;
        fill: $md-grey-700 !important;
        cursor: pointer;
        :global(.nightlymode) & {
          fill: white !important;
        }
      }
    }
  }
</style>
