import axios from 'axios'

const moduleRoom = {
  state: () => ({
    rooms: {
      data: [],
      total: 0
    },
    roomsSelect: {
      data: [],
      total: 0
    },
    currentPagination: {
      page: 1,
      fetch: 10
    },
    roomsByRoomId: {
      data: []
    }
  }),
  getters: {
    getRooms: (state) => state.rooms,
    getRoomsSelect: (state) => state.roomsSelect,
    getCurrentPagination: (state) => state.currentPagination,
    getRoomsByRoomId: (state) => state.roomsByRoomId
  },
  mutations: {
    SET_ROOMS (state, { rooms, total }) {
      state.rooms.data = rooms
      state.rooms.total = total
    },
    SET_ROOMS_SELECT (state, { rooms, total }) {
      Array.prototype.push.apply(state.roomsSelect.data, rooms)
      state.roomsSelect.total = total
    },
    DELETE_ROOM (state, id) {
      const index = state.rooms.data.findIndex((room) => room.roomId === id)
      if (index > -1) {
        state.rooms.data.splice(index, 1)
        state.rooms.total--
      }
    },
    UPDATE_PAGINATION (state, pagination) {
      state.currentPagination.page = pagination.page
      state.currentPagination.fetch = pagination.fetch
    },
    SET_ROOMS_BY_ROOMID (state, data) {
      state.roomsByRoomId.data = data
    }
  },
  actions: {
    async loadRooms ({ getters, commit }, { page, fetch, type }) {
      commit('UPDATE_PAGINATION', { page, fetch })
      await axios
        .get(`${getters.api}/Room`, {
          params: {
            page,
            fetch
          }
        })
        .then(({ data }) => {
          commit(type === 'select' ? 'SET_ROOMS_SELECT' : 'SET_ROOMS', {
            rooms: data.itens,
            total: data.totalResults
          })
        })
        .catch((e) => {
          throw e
        })
    },
    async deleteRoom ({ getters, commit }, id) {
      await axios
        .delete(`${getters.api}/room/delete/${id}`)
        .then(() => {
          commit('DELETE_ROOM', id)
        })
        .catch((e) => {
          throw e
        })
    },
    async roomsByRoomId ({ state, getters, commit }, id) {
      await axios
        .get(`${getters.api}/room/${getters.user.data.roomId}`)
        .then(({ data }) => {
          let rooms = []

          const connectedRooms = data.connectedRooms
            ? data.connectedRooms.map((item) => {
              item.roomColor = item.color || '#757575'
              return item
            })
            : []

          if (connectedRooms.length > 1) {
            rooms = [
              {
                roomId: 0,
                displayName: 'Geral',
                roomName: 'Geral',
                roomColor: 'var(--theme-color-1)'
              }
            ]
          }

          commit('SET_ROOMS_BY_ROOMID', [...rooms, ...connectedRooms])
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }
}

export default moduleRoom
