/*
Uso:
  <button v-tooltip="'string'">Salvar</button>
  <button v-tooltip.right="variable">Salvar</button>
  <button v-tooltip.bottom.limited="condition ? 'string' : null">Salvar</button>

  - você pode usar os modificadores: bottom, left, right, limited
  - sem modificador, o tooltip fica acima do elemento
  - limited: limita o tamanho do tooltip para 360px e pode ser usado com os modificadores de posição
*/
const tooltipDirective = {
  mounted (el, binding) {
    if (!document.querySelector('.vtooltip')) {
      const createTooltip = document.createElement('div')
      createTooltip.classList.add('vtooltip')
      document.body.appendChild(createTooltip)
    }

    el.setAttribute('data-vtooltip', binding.value || '')

    applyEvents(el, binding)
  },

  updated (el, binding) {
    el.setAttribute('data-vtooltip', binding.value || '')
  }
}

const applyEvents = (el, binding) => {
  const tooltip = document.querySelector('.vtooltip')
  let setTimeoutTooltip

  const tooltipShow = () => {
    const value = el.getAttribute('data-vtooltip')

    if (!value) return

    const offset = el.getBoundingClientRect()
    const gutter = 8
    const position = {
      top: offset.top - gutter,
      left: offset.x + offset.width / 2,
      translateX: '-50%',
      translateY: '-100%',
      class: 'top'
    }

    if (binding.modifiers?.bottom) {
      position.top = offset.bottom + gutter
      position.translateY = '0'
      position.class = 'bottom'
    } else if (binding.modifiers?.left) {
      position.top = offset.top + offset.height / 2
      position.left = offset.left - gutter
      position.translateX = '-100%'
      position.translateY = '-50%'
      position.class = 'left'
    } else if (binding.modifiers?.right) {
      position.top = offset.top + offset.height / 2
      position.left = offset.right + gutter
      position.translateX = '0'
      position.translateY = '-50%'
      position.class = 'right'
    }

    tooltip.textContent = value
    tooltip.classList.add(`vtooltip--${position.class}`)
    tooltip.style.top = `${position.top}px`
    tooltip.style.left = `${position.left}px`
    tooltip.style.transform = `translate(${position.translateX}, ${position.translateY})`

    if (binding.modifiers?.limited) tooltip.classList.add('vtooltip--limited')

    clearTimeout(setTimeoutTooltip)
    setTimeoutTooltip = setTimeout(() => { tooltip.classList.add('vtooltip--active') }, 500)
  }

  const tooltipHide = () => {
    clearTimeout(setTimeoutTooltip)
    tooltip.classList = 'vtooltip'
    tooltip.textContent = ''
  }

  el.addEventListener('mouseover', tooltipShow)
  el.addEventListener('mouseleave', tooltipHide)
  el.addEventListener('mousedown', tooltipHide)
}

export default tooltipDirective
