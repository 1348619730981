<template>
  <app-config>
    <app-view>
      <app-confirm-promise ref="confirm" />

      <transition name="fade">
        <app-modal
          size="1180"
          v-if="modalIcon"
          key="icon_modal"
          v-on:close="toggleModalIcon"
          title="Ícone"
        >
          <app-list-icon v-on:change-icon="changeIcon" />
        </app-modal>
      </transition>

      <app-loader
        v-if="loading.active || loading.error"
        :error="loading.error"
        :response="loading.response"
        key="loading"
        :active="loading.active"
        v-on:reload="init()"
      />

      <div class="row no-gutters" v-if="!loading.active && !loading.error">
        <div class="col">
          <div :class="$style.container">
            <p :class="$style.title">Menu</p>

            <div :class="$style.list" class="mt-5">
              <app-loader
                v-if="loading"
                :error="false"
                key="loading"
                :active="loading"
                v-on:reload="init()"
              />

              <div class="row no-gutters" v-else :class="$style.scrollerall">
                <div class="col">
                  <div :class="$style.scroller">
                    <div :class="$style.scrollerMenu">
                      <app-menu v-if="menus.length > 0" v-model:menus="menus" parent />

                      <p :class="$style.scrollerEmpty" v-else>
                        Não há nenhum menu definido, clique no botão abaixo para
                        inserir um novo menu
                      </p>
                    </div>

                    <app-button
                      :class="$style.newitem"
                      label="Novo item"
                      color="green"
                      small
                      v-on:click="newMenu()"
                    />
                  </div>
                </div>

                <transition>
                  <div class="col-auto" v-if="menuEdit">
                    <div :class="$style.edit">
                      <div
                        class="row"
                        v-if="editActive"
                        :class="$style.editPosition"
                      >
                        <div class="col-12">
                          <app-input
                            v-model:value="menuEdit.menuName"
                            label="Nome"
                            :maxlength="80"
                            ref="name"
                            :focus="inputFocus"
                          />
                        </div>

                        <div
                          class="col-12 mb-4"
                          v-if="menuEdit.menus.length == 0"
                        >
                          <app-select
                            label="Cenário"
                            reference="scenario"
                            v-on:change-value="changeScenario"
                            :value="menuEdit.scenario"
                            :data="scenarios"
                            :object-key="{
                              id: 'scenarioId',
                              label: 'scenarioName',
                            }"
                            :required="false"
                          />
                        </div>

                        <div
                          class="col-12 mb-4"
                          v-if="menuEdit.menus.length == 0"
                        >
                          <app-select
                            label="Assunto"
                            reference="subjects"
                            v-on:change-value="changeSubject"
                            :value="menuEdit.subject"
                            :data="subjectsFilter"
                            :object-key="{
                              id: 'subjectId',
                              label: 'subjectName',
                            }"
                            haveSearch
                            v-on:search="searchSubjects"
                            :required="false"
                            :labelSelected="
                              subjects.find(
                                (x) => x.subjectId == menuEdit.subject
                              )
                                ? subjects.find(
                                    (x) => x.subjectId == menuEdit.subject
                                  ).subjectName
                                : null
                            "
                          />
                        </div>

                        <div
                          class="col-12"
                          v-if="
                            menuEdit.menus.length == 0 &&
                            menuEdit.scenario &&
                            menuEdit.subject &&
                            templatesFilter
                          "
                        >
                          <app-select
                            label="Template"
                            reference="template"
                            v-on:change-value="changeTemplate"
                            :value="menuEdit.templateId"
                            :data="templatesFilter"
                            :object-key="{
                              id: 'templateId',
                              label: 'templateName',
                            }"
                            haveSearch
                            v-on:search="searchTemplate"
                            :required="false"
                          />
                        </div>

                        <div
                          :class="$style.editFilters"
                          v-if="
                            menuEdit.subject &&
                            filters.length &&
                            menuEdit.menus.length == 0
                          "
                        >
                          <app-input-label>Filtros</app-input-label>

                          <div
                            class="row align-items-center no-gutters"
                            v-for="(filter, index) in menuEdit.filterIds"
                            :key="filter.filterId"
                          >
                            <div class="col">
                              <app-select
                                :reference="'filter' + index"
                                v-on:change-value="
                                  changeFilter(index, $event)
                                "
                                :value="filter"
                                :data="filtersSearch"
                                :object-key="{
                                  id: 'filterId',
                                  label: 'filterName',
                                }"
                                :required="false"
                                haveSearch
                                v-on:search="searchFilters"
                                :labelSelected="
                                  filters.find((x) => x.filterId == filter)
                                    ? filters.find((x) => x.filterId == filter)
                                        .filterName
                                    : null
                                "
                              />
                            </div>

                            <div class="col-auto">
                              <app-icon
                                glyph="close"
                                :class="$style.editFiltersRemove"
                                v-on:click="removeFilter(index)"
                              />
                            </div>
                          </div>

                          <p
                            v-if="menuEdit.filterIds.length == 0"
                            :class="$style.editFiltersEmpty"
                          >
                            Nenhum filtro selecionado
                          </p>

                          <p
                            v-on:click="addFilter()"
                            :class="$style.editFiltersAdd"
                          >
                            + Adicionar filtro
                          </p>
                        </div>

                        <div class="col-12 mt-4">
                          <app-input-label>Ícone</app-input-label>

                          <!-- <app-icon glyph="close" :class="$style.editIconRemove" v-if="menuEdit.icon" /> -->

                          <div class="row no-gutters">
                            <div class="col-auto pr-4" v-if="menuEdit.icon">
                              <app-icon
                                :glyph="menuEdit.icon"
                                :class="$style.editIconSelected"
                              />
                            </div>

                            <div class="col">
                              <div
                                class="row justify-content-between no-gutters align-items-center"
                              >
                                <p
                                  v-on:click="toggleModalIcon"
                                  :class="$style.editIconAdd"
                                  v-html="
                                    menuEdit.icon != '' && menuEdit.icon != null
                                      ? 'Alterar ícone'
                                      : 'Adicionar ícone'
                                  "
                                ></p>

                                <p
                                  v-if="menuEdit.icon"
                                  v-on:click="removeIcon"
                                  :class="$style.editIconRemove"
                                  v-html="'Remover icone'"
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="row justify-content-center flex-grow-1 mt-3"
                        >
                          <div class="col-auto">
                            <app-button
                              :class="$style.editRemove"
                              label="Remover menu"
                              color="red"
                              small
                              v-on:click="removeMenu"
                            />
                          </div>
                        </div>
                      </div>

                      <app-loader-spinner v-else :class="$style.loader" />
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-actions-box>
        <div class="row justify-content-center">
          <div class="col-auto">
            <app-button
              label="Salvar"
              icon="save"
              color="blue-500"
              v-on:click="save"
              :disabled="!valid"
              :loading="loadingSave"
            />
          </div>

          <div class="col-auto">
            <app-button
              label="Cancelar"
              icon="close"
              color="red-500"
              v-on:click="cancel"
            />
          </div>
        </div>
      </app-actions-box>
    </app-view>
  </app-config>
</template>

<script>
import AppMenu from './components/Menu.vue'
import AppListIcon from '@/components/listIcon/ListIcon.vue'
import EventBus from '../eventBus'
import getUrl from '@/mixins/getUrlFilter'
import AppConfig from '@/views/config/Index.vue'

export default {
  components: {
    AppMenu,
    AppListIcon,
    AppConfig
  },

  provide: function () {
    return {
      menuNewSubitem: this.newSubitem,
      transformParent: this.transformParent
    }
  },
  data () {
    return {
      loading: false,

      menus: [],
      editActive: false,

      subjects: [],
      subjectsFilter: [],
      filters: [],
      filtersSearch: [],
      scenarios: [],
      templates: [],
      templatesFilter: [],

      newItem: {
        menuName: '',
        subjectId: null,
        scenarioId: null,
        icon: null,
        templateId: null,
        filterIds: [],
        menus: []
      },

      modalIcon: false,

      menuEdit: null,
      menuEditParent: null,
      menuEditKey: 0,

      valid: true,

      loadingSave: false,

      inputFocus: false
    }
  },

  created () {
    this.init()
  },

  mounted () {
    EventBus.$on('open-edit', (value) => {
      // if(this.menuEditKey != value.menuKey) {

      const obj = value

      this.editActive = false

      this.menuEdit = obj.menu
      this.menuEditParent = obj.parent
      this.menuEditKey = obj.menuKey

      if (obj.menu.scenario && typeof obj.menu.scenario == 'string') {
        this.menuEdit.scenario = this.scenarios.filter(
          (x) =>
            x.scenarioIdentifier.toLowerCase() ==
              obj.menu.scenario.toLowerCase()
        )[0].scenarioId
      }

      if (obj.menu.subject && typeof obj.menu.subject == 'string') {
        this.menuEdit.subject = this.subjects.filter(
          (x) =>
            x.subjectIdentifier.toLowerCase() ==
              obj.menu.subject.toLowerCase()
        )[0].subjectId
      }

      if (this.menuEdit.subject && this.menuEdit.scenario) {
        this.getTemplates()
      }

      setTimeout(() => {
        this.editActive = true

        this.searchFilters('')
        this.searchSubjects('')
      }, 1000)

      // }
    })

    EventBus.$on('transform-parent', (menu) => {
      const copy = JSON.parse(JSON.stringify(menu))
      const copy2 = JSON.parse(JSON.stringify(menu))

      menu.menuName = copy.menuName
      menu.id = copy.id
      menu.subject = null
      menu.scenario = null
      menu.templateId = null
      menu.filterIds = []
      menu.menus = []

      copy2.id = null

      menu.menus.push(copy2)

      /* */

      this.editActive = false

      this.menuEdit = menu.menus[0]
      this.menuEditParent = menu
      this.menuEditKey = 0

      if (
        this.menuEdit.scenario &&
          typeof this.menuEdit.scenario == 'string'
      ) {
        this.menuEdit.scenario = this.scenarios.filter(
          (x) =>
            x.scenarioName.toLowerCase() ==
              this.menuEdit.scenario.toLowerCase()
        )[0].scenarioId
      }

      if (this.menuEdit.subject && typeof this.menuEdit.subject == 'string') {
        this.menuEdit.subject = this.subjects.filter(
          (x) =>
            x.subjectIdentifier.toLowerCase() ==
              this.menuEdit.subject.toLowerCase()
        )[0].subjectId
      }

      setTimeout(() => {
        this.editActive = true
      }, 500)
    })

    EventBus.$on('new-subitem', (menu) => {
      menu.menus.push(JSON.parse(JSON.stringify(this.newItem)))

      this.editActive = true

      setTimeout(() => {
        this.menuEdit = menu.menus[menu.menus.length - 1]
      }, 200)
    })
  },

  methods: {
    newSubitem (menu) {
      menu.menus.push(JSON.parse(JSON.stringify(this.newItem)))

      this.editActive = true

      setTimeout(() => {
        this.menuEdit = menu.menus[menu.menus.length - 1]
      }, 200)
    },
    transformParent (menu) {
      const copy = JSON.parse(JSON.stringify(menu))
      const copy2 = JSON.parse(JSON.stringify(menu))

      menu.menuName = copy.menuName
      menu.id = copy.id
      menu.subject = null
      menu.scenario = null
      menu.templateId = null
      menu.filterIds = []
      menu.menus = []

      copy2.id = null

      menu.menus.push(copy2)

      /* */

      this.editActive = false

      this.menuEdit = menu.menus[0]
      this.menuEditParent = menu
      this.menuEditKey = 0

      if (
        this.menuEdit.scenario &&
          typeof this.menuEdit.scenario == 'string'
      ) {
        this.menuEdit.scenario = this.scenarios.filter(
          (x) =>
            x.scenarioName.toLowerCase() ==
              this.menuEdit.scenario.toLowerCase()
        )[0].scenarioId
      }

      if (this.menuEdit.subject && typeof this.menuEdit.subject == 'string') {
        this.menuEdit.subject = this.subjects.filter(
          (x) =>
            x.subjectIdentifier.toLowerCase() ==
              this.menuEdit.subject.toLowerCase()
        )[0].subjectId
      }

      setTimeout(() => {
        this.editActive = true
      }, 500)
    },
    async init () {
      this.loading = true

      await this.$http
        .get(`${this.$store.getters.api}/subject`)
        .then(({ data }) => {
          this.subjects = data.itens.map((item) => {
            return {
              subjectId: item.subjectId,
              subjectIdentifier: item.subjectIdentifier,
              subjectName: item.subjectName
            }
          })

          this.subjectsFilter = this.subjects
        })
        .catch((error) => error)

      await this.$http
        .get(`${this.$store.getters.api}/presentation/menu/all/`)
        .then(({ data }) => {
          const menus = this.transformMenu(data.Menus)

          this.menus = menus

          if (this.menus.length == 0) this.newMenu()

          this.loading = false
        })

      await this.$http
        .get(`${this.$store.getters.api}/scenario/all/`)
        .then(({ data }) => {
          this.scenarios = data
        })
        .catch(() => {})
    },

    /* */

    async save () {
      const menus = this.transformMenuToSync(this.menus)

      const companyId = this.$store.getters.user.data.companyId

      this.loadingSave = true

      await this.$http
        .put(`${this.$store.getters.api}/presentation/menu/${companyId}`, {
          Menus: menus
        })
        .then(({ data }) => {
          this.$store.dispatch('notification', {
            text: 'Menus atualizada com sucesso!.',
            color: 'green'
          })

          this.loadingSave = false

          this.$store.commit('closePage', '/config/menu')
        })
        .catch((error) => {
          this.loadingSave = false

          this.$store.dispatch('notification', {
            type: 'error',
            text:
                error?.response?.data?.errors[0]?.message ||
                'Ocorreu um erro ao salvar o menu. Por favor, tente novamente.',
            error: true
          })
        })
    },

    async getTemplates () {
      this.templates = []
      this.templatesFilter = []

      await this.$http
        .get(
            `${this.$store.getters.api}/template?subjectId=${this.menuEdit.subject}&scenarioId=${this.menuEdit.scenario}&templateType=7`,
            {
              params: {
                page: 1,
                fetch: 10000
              }
            }
        )
        .then(({ data }) => {
          this.templates = data.itens
            .filter((x) => x.templateName)
            .map((item) => {
              return {
                templateId: item.templateId,
                templateName: item.templateName
              }
            })

          this.templatesFilter = this.templates
        })
    },

    transformMenuToSync (data) {
      const menuToSync = data.map((item) => {
        let subject = null
        let scenario = null

        if (typeof item.subject == 'number') {
          const array1 = this.subjects.filter(
            (x) => x.subjectId == item.subject
          )

          if (array1.length) {
            subject = array1[0].subjectIdentifier
          }
        } else {
          subject = item.subject
        }

        if (typeof item.scenario == 'number') {
          const array2 = this.scenarios.filter(
            (x) => x.scenarioId == item.scenario
          )

          if (array2.length) {
            scenario = array2[0].scenarioIdentifier
          }
        } else {
          scenario = item.scenario
        }

        return {
          Id: item.id,
          Subject: subject,
          Name: item.menuName,
          Scenario: scenario,
          Icon: item.icon,
          TemplateId: item.templateId,
          Filters: item.filterIds,
          Menus: item.menus ? this.transformMenuToSync(item.menus) : []
        }
      })

      return menuToSync
    },

    cancel () {
      this.$store.commit('closePage', '/config/menu')
    },

    /* */

    transformMenu (data) {
      const menu = data.map((item) => {
        return {
          id: item.Id,
          menuName: item.Name,
          subject: item.Subject || null,
          scenario: item.Scenario || null,
          icon: item.Icon || null,
          templateId: item.TemplateId || null,
          filterIds: item.Filters,
          menus: item.Menus ? this.transformMenu(item.Menus) : []
        }
      })

      return menu
    },

    searchSubjects (e) {
      this.subjectsFilter = this.subjects.filter(({ subjectName }) =>
        subjectName.toLowerCase().includes(e.toLowerCase().trim())
      )
    },

    searchFilters (e) {
      this.filtersSearch = this.filters.filter(
        ({ filterName, filterId }) =>
          filterName.toLowerCase().includes(e.toLowerCase().trim()) &&
            !this.menuEdit.filterIds.includes(filterId)
      )
    },

    searchTemplate (e) {
      this.templatesFilter = this.templates.filter(({ templateName }) =>
        templateName.toLowerCase().includes(e.toLowerCase().trim())
      )
    },

    changeSubject (e) {
      this.menuEdit.subject = e

      this.menuEdit.filterIds = []
      this.menuEdit.templateId = null

      if (this.menuEdit.subject && this.menuEdit.scenario) {
        this.getTemplates()
      }
    },

    changeScenario (e) {
      this.menuEdit.scenario = e

      if (this.menuEdit.subject && this.menuEdit.scenario) {
        this.getTemplates()
      }
    },

    changeFilter (index, value) {
      this.menuEdit.filterIds[index] = value
    },

    changeIcon (icon) {
      this.menuEdit.icon = icon

      this.toggleModalIcon()
    },

    changeTemplate (e) {
      this.menuEdit.templateId = e
    },

    /* */

    addFilter () {
      this.menuEdit.filterIds.push(0)
    },

    async removeFilter (index) {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente remover esse filtro desse menu?', 'red')
        .then((response) => {
          this.menuEdit.filterIds.splice(index, 1)
        })
        .catch((error) => false)

      confirm.close()
    },

    /* */

    newMenu () {
      this.menus.push(JSON.parse(JSON.stringify(this.newItem)))

      this.editActive = false

      setTimeout(() => {
        this.editActive = true

        this.menuEditKey = this.menus.length - 1

        this.menuEdit = this.menus[this.menus.length - 1]

        setTimeout(() => {
          this.inputFocus = true
        }, 100)
      }, 500)
    },

    /* */

    toggleModalIcon () {
      this.modalIcon = !this.modalIcon
    },

    async removeIcon () {
      this.menuEdit
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente remover o ícone desse menu', 'red')
        .then(() => {
          this.menuEdit.icon = null
        })
        .catch(() => false)

      confirm.close()
    },

    /* */

    async removeMenu () {
      const confirm = this.$refs.confirm

      const message = this.menuEdit.menuName
        ? `Deseja realmente remover o menu ${this.menuEdit.menuName}?`
        : 'Deseja realmente remover esse menu?'

      await confirm
        .open(message, 'red')
        .then(() => {
          if (this.menuEditParent == null) {
            this.menus.splice(this.menuEditKey, 1)
          } else {
            this.menuEditParent.menus.splice(this.menuEditKey, 1)
          }

          this.editActive = false
          this.menuEdit = null
          this.menuEditParent = null
          this.menuEditKey = 0

          setTimeout(() => {
            this.editActive = true
          }, 500)
        })
        .catch(() => false)

      confirm.close()
    },

    validate (menus) {
      let valid = true

      menus.forEach((m) => {
        if (m.menus.length == 0) {
          if (m.menuName == '' || !m.scenario || !m.subject) {
            valid = false
          } else if (m.filterIds.length > 0) {
            const some = m.filterIds.some((x) => x == 0)

            if (some) {
              valid = false
            }
          } else {
            this.validate(m.menus)
          }
        }
      })

      this.valid = valid
    }
  },

  watch: {
    'menuEdit.subject': {
      handler: function (value) {
        this.filters = []
        this.filtersSearch = []

        if (value) {
          const subject = this.subjects.filter((x) => x.subjectId == value)[0]
            .subjectIdentifier

          const params = {
            page: 1,
            fetch: 1000
          }

          const origin = getUrl()
          this.$http
            .get(
                `${this.$store.getters.api}/Filter/${subject}?origin=${origin}`,
                params
            )
            .then(({ data }) => {
              this.filters = data.itens.map((item) => {
                return {
                  filterId: item.filterId,
                  filterName: item.filterName
                }
              })

              this.filtersSearch = this.filters.filter(
                ({ filterId }) => !this.menuEdit.filterIds.includes(filterId)
              )
            })
        }
      },

      deep: true
    },

    menus: {
      handler: function () {
        this.validate(this.menus)
      },
      deep: true
    },

    'menuEdit.filterIds': {
      handler: function () {
        this.filtersSearch = this.filters.filter(
          ({ filterId }) => !this.menuEdit.filterIds.includes(filterId)
        )
      },
      deep: true
    }

  }
}
</script>

<style lang="scss" module>
  .container {
    width: 100%;
    height: calc(100vh - 135px);
    overflow: hidden;

    padding: $theme-padding * 5 $theme-padding * 4;

    background: $md-grey-100;

    :global(.nightlymode) & {
      background: $md-grey-900;
    }
  }

  .title {
    @include font-sizer(28);
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    color: var(--theme-color-1);
  }

  .scroller {
    min-width: 100%;
    min-height: 100%;
    overflow: auto;

    padding: $theme-padding;

    background: $md-grey-300;

    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;

    :global(.nightlymode) & {
      background: $md-grey-800;
    }

    &::-webkit-scrollbar {
      width: 7px;

      &-thumb {
        background-color: inherit;
        border-radius: 100px;
      }
    }

    &__empty {
      padding: $theme-padding;
      margin: 0 0 $theme-padding 0;

      color: $md-grey-900;
      @include font-sizer(14);
      font-family: 'Raleway';

      :global(.nightlymode) & {
        color: $md-grey-100;
      }
    }

    // &__menu {

    //     margin-left: (-$theme-padding);

    // }
  }

  .scrollerall {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(100vh - 330px);

    border-radius: 7px;

    &::-webkit-scrollbar {
      width: 7px;

      &-thumb {
        background-color: $md-grey-400;
        border-radius: 100px;
      }
    }
  }

  .newitem {
    margin: 0 $theme-padding $theme-padding;
  }

  .edit {
    width: 330px;

    background: $md-grey-50;

    min-height: 100%;

    // border-top-right-radius: 7px;
    // border-bottom-right-radius: 7px;

    :global(.nightlymode) & {
      background: rgba(255, 255, 255, 0.2);
    }

    &__position {
      padding: $theme-padding * 2;
      padding-bottom: 130px;

      position: sticky;
      top: 0;
    }

    &__filters {
      width: calc(100% - 30px);
      margin: $theme-padding auto 0;
      padding-top: $theme-padding;
      padding-left: $theme-padding;
      padding-right: $theme-padding;

      background: $md-grey-200;
      border-radius: 8px;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }

      &__remove {
        fill: black;

        margin-left: $theme-padding;

        cursor: pointer;

        :global(.nightlymode) & {
          fill: #fff;
        }
      }

      &__empty {
        color: black;
        font-family: 'Raleway';
        @include font-sizer(13);

        :global(.nightlymode) & {
          color: white;
        }
      }

      &__add {
        color: black;
        font-family: 'Raleway';
        @include font-sizer(12);

        margin-top: $theme-padding;

        cursor: pointer;

        :global(.nightlymode) & {
          color: white;
        }
      }
    }

    &__icon {
      &__add {
        color: white;
        font-family: 'Raleway';
        @include font-sizer(12);
        margin-top: 3px;
        cursor: pointer;
        width: 46%;
        background: $md-green-800;
        text-align: center;
        padding: 2px;
        border-radius: 25px;

        cursor: pointer;
      }

      &__remove {
        color: white;
        font-family: 'Raleway';
        @include font-sizer(12);
        margin-top: 3px;
        cursor: pointer;
        width: 46%;
        background: $md-red-500;
        text-align: center;
        padding: 2px;
        border-radius: 25px;
      }

      &__selected {
        fill: $md-grey-900;

        :global(.nightlymode) & {
          width: 27px;
          height: 27px;

          fill: #fff;
        }
      }
    }

    &__remove {
      margin-top: $theme-padding;
    }
  }

  .loader {
    position: sticky;
    top: 50%;
    left: 0;
  }
</style>
