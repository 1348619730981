<template>
  <app-config>
    <app-view>
      <app-confirm-promise ref="confirm" />

      <app-box>
        <div class="row no-gutters">
          <div class="col">
            <div :class="$style.scroller">
              <p :class="$style.title">Automações</p>

              <div class="row mt-5">
                <div class="col">
                  <app-button
                    small
                    @click="
                      router.push({
                        name: 'automation_editor',
                      })
                    "
                    color="blue"
                    light
                    label="Configurar nova automação"
                    icon="add"
                  />
                </div>
              </div>

              <app-loader
                v-if="loading || error.active"
                key="loading"
                :active="loading"
                :error="error.active"
                :response="error.response"
                v-on:reload="loadJobs"
              />

              <div v-else>
                <div :class="$style.list" class="mt-5">
                  <p v-if="jobs.length == 0" :class="$style.listEmpty">
                    Não há nenhuma automação configurada
                  </p>

                  <div
                    class="row no-gutters mb-4"
                    :class="$style.listItem"
                    v-for="(job, index) in jobs"
                    :key="index"
                  >
                    <router-link
                      :class="$style.listItemName"
                      class="col"
                      :to="{
                        name: 'automation_editor',
                        params: {
                          jobId: job.jobId,
                        },
                      }"
                    >
                      {{ job.name }}
                    </router-link>
                    <div class="col-auto">
                      <app-icon
                        glyph="delete"
                        @click="removeJob(job.jobId)"
                        :class="$style.listItemDelete"
                      />
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center mt-5" v-if="jobs.length">
                  <div class="col-auto">
                    <app-pagination
                      :length="total"
                      :current-page="pagination.page"
                      :per-page="pagination.per_page"
                      :option-per-page="pagination.option_per_page"
                      @change-page="changePage"
                      @change-per-page="changePerPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-box>
    </app-view>
  </app-config>
</template>

<script setup>
import AppConfig from '../config/Index.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, computed, isRef, unref, watchEffect } from 'vue'

const store = useStore()
const router = useRouter()

const loading = ref(false)
const error = ref({ active: false, response: null })

const pagination = ref({
  page: 1,
  per_page: 10,
  option_per_page: [10, 25, 50, 100]
})
const changePage = (page) => {
  pagination.value.page = page
}
const changePerPage = (fetch) => {
  pagination.value.per_page = fetch
  changePage(1)
}

const jobs = computed(() => store.getters.getJobs.data || [])
const total = computed(() => store.getters.getJobs.total)

const loadJobs = async () => {
  const page = unref(pagination)?.page || 1
  const fetch = unref(pagination)?.per_page || 100

  try {
    loading.value = true
    error.value.active = false

    await store.dispatch('loadJobs', {
      page,
      fetch
    })
  } catch (e) {
    error.value.active = true
    error.value.response = e.response
  } finally {
    loading.value = false
  }
}

isRef(pagination) ? watchEffect(loadJobs) : loadJobs()

const confirm = ref(null)
const removeJob = async (id) => {
  await confirm.value
    .open('Deseja realmente excluir esse item?', 'red')
    .then(async (response) => {
      try {
        confirm.value.loading = true
        await store.dispatch('deleteJob', id)
        store.dispatch('notification', {
          text: 'Automação excluída com sucesso!'
        })
      } catch (e) {
        const message =
            e?.response?.data?.errors[0]?.Message ||
            'Ocorreu um erro ao excluir a automação. Por favor, tente novamente.'

        store.dispatch('notification', {
          type: 'error',
          text: message
        })
      } finally {
        confirm.value.close()
      }
    })
    .catch((e) => false)
}
</script>

<style lang="scss" module>
  .loader {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -15px;
  }
  .search {
    position: relative;

    &__input {
      position: absolute !important;
      right: 20px;
      width: 100%;
      z-index: 1;
    }

    &__icon {
      position: absolute;
      right: 27px;
      top: 7px;
      fill: black;
      cursor: pointer;
      z-index: 2;

      :global(.nightlymode) & {
        fill: white;
      }
    }
  }
  .scroller {
    min-width: 100%;
    overflow: auto;
    padding: 60px 75px 120px;
    min-height: calc(100vh - 65px);
    background: $md-grey-100;
    :global(.nightlymode) & {
      background: $md-grey-900;
    }
  }
  .title {
    @include font-sizer(28);
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    color: var(--theme-color-1);
  }
  .list {
    &__empty {
      @include font-sizer(12);
      font-family: 'Raleway';
      color: black;

      :global(.nightlymode) & {
        color: white;
      }
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0px $theme-padding * 1.5;
      border-radius: 5px;
      background: white;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }

      &--active {
        background: $md-grey-400;
        box-shadow: inset 0 0 0 2px rgba(black, 0.15);
        cursor: pointer;
        :global(.nightlymode) & {
          background: lighten($md-grey-900, 5%);
          box-shadow: inset 0 0 0 2px rgba(black, 0.15);
        }
      }

      &__name {
        min-height: 60px;
        padding: 5px 0;
        display: flex;
        align-items: center;
        @include font-sizer(15);
        font-family: 'Raleway';
        color: black;
        word-break: break-word;
        cursor: pointer;
        :global(.nightlymode) & {
          color: white;
        }

        &--active {
          color: var(--theme-color-1) !important;
          font-weight: 500;
        }
      }

      &__delete {
        fill: $md-red-500;
        cursor: pointer;
      }
    }
  }
</style>
