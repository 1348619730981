<template>
  <div :class="$style.toolcolor">
    <h2 :class="$style.toolcolorSubtitle">Temas Personalizados para Você</h2>
    <palette :data="clientColors" :selecteds="colorSelected" :idPalette="1" />

    <h2 :class="$style.toolcolorSubtitle">e.Mix</h2>
    <palette :data="emixColors" :selecteds="colorSelected" :idPalette="2" />

    <h2 :class="$style.toolcolorSubtitle">Cores Padrão</h2>
    <palette :data="materialColors" :selecteds="colorSelected" :idPalette="3" />
  </div>
</template>

<script>
import colors from 'material-colors'
import { defineAsyncComponent } from 'vue'
const Palette = defineAsyncComponent(() => import('@/views/config/theme/Palette.vue'))

export default {
  components: {
    Palette
  },

  data () {
    return {
      clientColors: [
        {
          id: 1,
          color: '#153886',
          name: 'Nome da Cor 1'
        },

        {
          id: 2,
          color: '#e77741',
          name: 'Nome da Cor 2'
        }
      ],

      emixColors: [
        {
          id: 1,
          color: colors.cyan['600'],
          name: 'Cyan'
        }
      ],

      ignoreColors: [
        '50',
        '100',
        '200',
        '300',
        '400',
        '800',
        '900',
        'a100',
        'a200',
        'a400',
        'a700'
      ]
    }
  },

  computed: {
    materialColors () {
      const array = []

      /* */

      const _this = this

      Object.keys(colors).forEach(i => {
        Object.keys(colors[i]).forEach(j => {
          if (!_this.ignoreColors.includes(j)) {
            array.push({
              id: i + j,
              color: colors[i][j]
            })
          }
        })
      })

      /* */

      return array.slice(0, 51)
    },

    colorSelected () {
      const color = this.$store.getters.primaryColor

      const colorSelected = {
        palette: color.idPalette,
        color: color.id
      }

      return colorSelected
    }
  }
}
</script>

<style lang="scss" module>

$title-color: black;
$title-color-nightly: white;

.lineselect {
  margin-top: $theme-padding * 2;
}

.toolcolor {
  &__subtitle {
    padding: $theme-padding 0;
    margin-bottom: math.div($theme-padding, 2);

    font-family: 'Barlow', sans-serif;
    @include font-sizer(14);
    color: $title-color;
    font-weight: 700;

    text-transform: uppercase;

    :global(.nightlymode) & {
      color: $title-color-nightly;
    }
  }
}
</style>
