<template>
  <div :class="$style.tags">
    <div :class="$style.tagsSearch">
      <div class="row align-items-center no-gutters">
        <div class="col-auto pr-2">
          <app-icon glyph="search" :class="$style.tagsSearchIcon" />
        </div>

        <div class="col">
          <input
            type="text"
            v-model="search"
            placeholder="Pesquise aqui por uma variável"
            :class="$style.tagsSearchInput"
          />
        </div>
      </div>
    </div>

    <ul :class="$style.tagsRow">
      <li
        :class="$style.tagsList"
        v-for="data in itemsFiltered"
        :key="data.id"
        v-on:click="selectVariable(data.id)"
      >
        <div class="row align-items-center">
          <div class="col">
            <span :class="$style.tagsLabel">{{ data.label }}</span>
          </div>

          <div class="col">
            <span :class="$style.tagsVariable">
              <span :class="$style.tagsVariableExpression">$</span>
              <span :class="$style.tagsVariableLimiter">{</span>
              <span :class="$style.tagsVariableExpression">{{
                data.variable
              }}</span>
              <span :class="$style.tagsVariableLimiter">}</span>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    search: ''
  }),

  computed: {
    itemsFiltered () {
      let array = []

      if (this.search.length) {
        const keywords = this.search.toLowerCase().split(' ')
        let items = this.variables.filter(({ label }) =>
          this.textMatch(keywords, label)
        )

        if (this.search.length) {
          items = items.slice(0, 100)
        }

        array = items
      } else {
        array = this.variables
      }

      return array
    }
  },

  props: {
    variables: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    textMatch (keywords, value) {
      return keywords.every(keyword => {
        if (keyword) {
          const normalizedText = this.normalizeText(value)
          const normalizedKeyword = this.normalizeText(keyword)

          return normalizedText.includes(normalizedKeyword)
        } else {
          return false
        }
      })
    },

    normalizeText (value) {
      return value
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    },

    selectVariable (variableId) {
      const variable = this.itemsFiltered.find(({ id }) => id === variableId)

      if (typeof variable !== 'undefined') {
        this.$emit('select', variable.variable)
      }
    }
  }
}
</script>

<style lang="scss" module>

/* */

.tags {
  position: relative;

  height: 500px;

  overflow-y: auto;

  :global(.nightlymode) & {
    // background: red
  }

  &__search {
    position: sticky;

    top: 0;

    padding: $theme-padding;

    background: white;

    border-bottom: 1px solid $md-grey-100;

    z-index: 10;

    :global(.nightlymode) & {
      background: $md-grey-900;

      border-bottom-color: transparent;
    }

    &__icon {
      $size: 24px;

      width: $size;
      height: $size;

      fill: $md-grey-400;
    }

    &__input {
      width: 100%;

      background: transparent;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(14);
      color: black;

      :global(.nightlymode) & {
        color: white;
      }
    }
  }

  &__row {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__list {
    padding: $theme-padding;

    cursor: pointer;

    &:nth-child(even) {
      background: $md-grey-50;

      :global(.nightlymode) & {
        background: darken($md-grey-800, 5%);
      }
    }

    &:hover {
      background: $md-blue-50;

      :global(.nightlymode) & {
        background: rgba($md-blue-50, 0.1);
      }
    }
  }

  &__label {
    font-family: 'Raleway', sans-serif;
    @include font-sizer(13);
    color: $md-grey-800;

    :global(.nightlymode) & {
      color: $md-grey-400;
    }
  }

  &__variable {
    font-family: monospace;
    @include font-sizer(14);

    &--limiter {
      color: $md-grey-500;
    }

    &--expression {
      color: $md-pink-500;
    }
  }
}
</style>
