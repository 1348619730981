<template>
  <app-config>
    <app-view>
      <transition name="fade">
        <app-modal
          title="Filtro"
          v-if="modal.filter.active"
          fullscreen
          @close="closeEditFilter()"
        >
          <form
            @submit.prevent="applyEditFilter()"
            :class="$style.filtermodal"
          >
            <app-input-label>Filtros disponíveis</app-input-label>

            <select-box
              class="mt-2 mb-4"
              :options="filters"
              :selected="modal.filter.data.filterId"
              :loading="loadingFilter"
              placeholder="Selecione um filtro já cadastrado"
              @select="selectFilter"
              unselect
            />

            <app-input
              label="Nome do filtro"
              v-model:value="modal.filter.data.filterName"
              required
              :maxlength="100"
            />

            <app-input-label required>Filtro</app-input-label>

            <filter-component
              :data="{
                filter: JSON.parse(modal.filter.data.filter),
                filterJson: [{}],
              }"
              :style="{
                position: 'relative',
              }"
              @change-filter="updateValue"
              :key="filterKey"
            />

            <automation-footer @cancel="closeEditFilter()" />
          </form>
        </app-modal>
      </transition>

      <app-confirm-promise ref="confirm" />

      <app-box>
        <transition name="fade-up" mode="out-in">
          <app-loader
            v-if="!ready"
            key="loading"
            :active="loading.active"
            :error="loading.error"
            :response="loading.response"
            @reload="init()"
            background
            :class="$style.loading"
          />

          <form
            action="/"
            v-else
            @submit.prevent="submitProfile()"
            :id="$style.id_form"
          >
            <div :class="$style.padding">
              <app-box margin>
                <app-title
                  :label="
                    this.getRouteId() == null
                      ? 'Adicionar Novo Perfil de Acesso'
                      : 'Editar Perfil de Acesso'
                  "
                />
              </app-box>

              <div class="row">
                <div class="col">
                  <app-box margin>
                    <app-box background title="Informações do perfil">
                      <div class="row">
                        <div class="col-md-5">
                          <app-input
                            label="Título"
                            type="text"
                            required
                            :maxlength="150"
                            v-model:value="info.title"
                          />
                        </div>
                        <div class="col-md-5">
                          <app-input
                            label="Canal"
                            type="text"
                            :maxlength="150"
                            v-model:value="info.displayName"
                          />
                        </div>
                        <div class="col-auto">
                          <app-input-label>Cor</app-input-label>
                          <div :class="$style.colorWrapper">
                            <app-tooltip
                              label="Cor da tag"
                              :class="$style.color"
                            >
                              <button
                              :class="$style.colorButton"
                                @click.stop="info.colorPicker.active = !info.colorPicker.active"
                                name="button_colorpicker"
                                type="button"
                              >
                                <svg viewBox="0 0 36 36">
                                  <path
                                    :class="$style.colorBucket"
                                    d="M22.080 11.92l-10.987-10.987c-0.52-0.52-1.36-0.52-1.88 0v0c-0.52 0.52-0.52 1.36 0 1.88l2.24 2.24-6.867 6.867c-0.787 0.787-0.787 2.053 0 2.827l7.333 7.333c0.387 0.387 0.907 0.587 1.413 0.587s1.027-0.2 1.413-0.587l7.333-7.333c0.787-0.773 0.787-2.040 0-2.827zM6.947 13.333l6.387-6.387 6.387 6.387h-12.773zM25.333 15.333c0 0-2.667 2.893-2.667 4.667 0 1.467 1.2 2.667 2.667 2.667s2.667-1.2 2.667-2.667c0-1.773-2.667-4.667-2.667-4.667z"
                                  ></path>

                                  <path
                                    :class="$style.colorBucketBorder"
                                    opacity="1"
                                    :style="{
                                      fill: info.colorPicker.preview || info.colorPicker.value,
                                    }"
                                    d="M 3.0659042,28.674237 H 32.949572 c 1.643954,0 2.988703,1.651572 2.988703,3.67062 v 0 c 0,2.019045 -1.344749,3.670618 -2.988703,3.670618 H 3.0659042 c -1.6439546,0 -2.98870275,-1.651573 -2.98870275,-3.670618 v 0 c 0,-2.019048 1.34474815,-3.67062 2.98870275,-3.67062 z"
                                  ></path>
                                </svg>
                              </button>
                            </app-tooltip>
                            <transition name="fade-down-short">
                              <div
                                :class="$style.colorPicker"
                                v-if="info.colorPicker.active"
                              >
                                <color-picker
                                  @select="selectColor"
                                  @preview="info.colorPicker.preview= $event"
                                  @close="info.colorPicker.active = false"
                                  :selected="info.colorPicker.value"
                                  defaultColor="#ffffff"
                                />
                              </div>
                            </transition>
                          </div>
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box margin>
                    <app-box background title="Filtros">
                      <div class="row">
                        <div class="col-md-12">
                          <div :class="$style.filter">
                            <div :class="$style.filterDescription">
                              Defina abaixo os filtros para esse perfil de
                              acesso
                            </div>
                            <div
                              :class="$style.filterDescription"
                              v-if="getArrayFilters.length == 0"
                            >
                              Nenhum filtro vinculado a esse perfil de acesso
                            </div>

                            <ul
                              :class="$style.filterRow"
                              v-for="(subject, index) in getArrayFilters"
                              :key="index"
                            >
                              <li :class="$style.filterTitle">
                                {{ subject.label }}
                              </li>

                              <li
                                :class="$style.filterList"
                                v-for="data in subject.itens"
                                @dblclick="editFilter(data.key)"
                                :key="data.key"
                              >
                                <div class="row no-gutters align-items-center">
                                  <div class="col-auto pl-2 pr-3">
                                    <app-icon
                                      :class="$style.filterIcon"
                                      glyph="account_tree"
                                    />
                                  </div>

                                  <div class="col">
                                    <div
                                      :class="[
                                        $style.filterName,
                                        !data.filterName
                                          ? $style.filterNameEmpty
                                          : null,
                                      ]"
                                    >
                                      {{
                                        data.filterName
                                          ? data.filterName
                                          : 'Filtro sem título'
                                      }}
                                    </div>
                                  </div>

                                  <div class="col-auto pl-3">
                                    <app-tooltip
                                      label="Editar filtro"
                                      @click="editFilter(data.key)"
                                    >
                                      <app-icon
                                        :class="[
                                          $style.filterIcon,
                                          $style.filterIconAction,
                                        ]"
                                        glyph="edit"
                                      />
                                    </app-tooltip>
                                  </div>

                                  <div class="col-auto pl-3">
                                    <app-tooltip
                                      label="Remover filtro"
                                      @click="removeFilter(data.key)"
                                    >
                                      <app-icon
                                        :class="[
                                          $style.filterIcon,
                                          $style.filterIconAction,
                                        ]"
                                        glyph="delete"
                                      />
                                    </app-tooltip>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <app-button
                              label="Adicionar filtro"
                              icon="add"
                              small
                              @click="newFilter"
                            />
                          </div>
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <delimited-filter
                    :filters="getArrayFilters"
                    @sharedTalks="setSharedTalks"
                    :sharedTalks="sharedTalks"
                  />

                  <app-box margin>
                    <app-box background title="Menu">
                      <div class="row">
                        <div class="col-md-12">
                          <security-matrix
                            :itens="dataMenu"
                            :actions="actionsMenu"
                            :actionsChildren="actionsMenu"
                            type="menu"
                            :loading="loading"
                            @reload="init()"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box margin>
                    <app-box background title="Perfis de Acesso Relacionados">
                      <div class="row">
                        <div class="col-md-12">
                          <security-matrix
                            :itens="itemRooms"
                            :actions="actionsRooms"
                            :actionsChildren="actionsRooms"
                            type="canais"
                            :loading="loading"
                            @reload="init()"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box margin>
                    <app-box background title="Assuntos">
                      <div class="row">
                        <div class="col-md-12">
                          <security-matrix
                            :itens="dataSubject"
                            :actionsChildren="actionsAttributes"
                            :actions="actionsSubject"
                            type="subject"
                            :loading="loading"
                            @reload="init()"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box margin>
                    <app-box background title="Notificações">
                      <div class="row">
                        <div class="col-md-12">
                          <room-notifications-matrix
                            :notifications="notifications"
                            :channels="channels"
                            :roomNotifications="roomNotifications"
                            @change-check="changeCheck"
                            @change-requirement="changeRequirement"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>
                </div>
              </div>
            </div>

            <app-actions-box>
              <div class="row justify-content-center">
                <div class="col-auto">
                  <app-button
                    label="Salvar"
                    icon="save"
                    type="submit"
                    name="button_ok"
                    :loading="loadingSubmit"
                  />
                </div>

                <div class="col-auto">
                  <app-button
                    label="Cancelar"
                    icon="close"
                    color="red-500"
                    name="button_cancel"
                    @click="closePage"
                  />
                </div>

                <div class="col-auto">
                  <app-button
                    label="Limpar"
                    icon="replay"
                    light
                    color="grey-800"
                    name="button_clear"
                    @click="clearPage"
                  />
                </div>
              </div>
            </app-actions-box>
          </form>
        </transition>
      </app-box>
    </app-view>
  </app-config>
</template>

<script>
import SelectBox from '@/components/select/SelectBox.vue'
import AutomationFooter from '../../automation/components/AutomationFooter.vue'
import getUrl from '@/mixins/getUrlFilter'
import ColorPicker from '@/components/colorPicker/ColorPicker.vue'
import eventbus from '../../eventBus'
import { defineAsyncComponent } from 'vue'
const AppConfig = defineAsyncComponent(() => import('@/views/config/Index.vue'))
const SecurityMatrix = defineAsyncComponent(() => import('@/views/config/access-profile/SecurityMatrix.vue'))
const RoomNotificationsMatrix = defineAsyncComponent(() => import('@/views/config/access-profile/RoomNotificationsMatrix.vue'))
const DelimitedFilter = defineAsyncComponent(() => import('@/views/config/DelimitedFilter.vue'))

const striptags = require('striptags')

/* */

export default {
  components: {
    AppConfig,
    DelimitedFilter,
    SecurityMatrix,
    RoomNotificationsMatrix,
    SelectBox,
    AutomationFooter,
    ColorPicker
  },

  data () {
    return {
      ready: false,
      loading: {
        active: false,
        error: false,
        response: {}
      },
      actionsRooms: [
        {
          actionIdentifier: 'SELECT',
          actionName: 'Interagir',
          resourceTypeActionId: 29
        }
      ],
      itemRooms: [],
      loadingSubmit: false,
      actions: [],
      info: {
        title: '',
        displayName: '',
        colorPicker: {
          active: false,
          value: '',
          preview: ''
        }
      },
      sharedTalks: [],
      data: [],
      dataSubject: [],
      actionsSubject: [],
      dataMenu: [],
      actionsMenu: [],
      dataMenuOriginal: [],
      dataSubjectOriginal: [],
      dataAttributes: [],
      actionsAttributes: [],
      selectedSubjects: [],
      modal: {
        filter: {
          active: false,
          filterId: null,
          index: null,
          data: {}
        }
      },
      filterArray: [],
      filters: [],
      loadingFilter: false,
      filterKey: +new Date(),
      notifications: [],
      channels: [],
      roomNotifications: []
    }
  },

  async created () {
    this.init()
  },

  computed: {
    getArrayFilters () {
      const subjects = []

      this.filterArray.forEach((item, key) => {
        const index = subjects.findIndex(
          (x) => x.identifier == item.filter[0].identifier
        )

        item.key = key

        if (index < 0) {
          subjects.push({
            identifier: item.filter[0].identifier,
            label: striptags(
              item.filter[0].label.substring(11, item.filter[0].label.length)
            ),
            itens: [item]
          })
        } else {
          subjects[index].itens.push(item)
        }
      })

      return subjects
    }
  },

  methods: {
    setSharedTalks (data) {
      this.sharedTalks = data
    },

    async applyEditFilter () {
      if (this.modal.filter.index !== null) {
        const key = this.modal.filter.index

        this.filterArray[key].filter = JSON.parse(
          this.modal.filter.data.filter
        )
        this.filterArray[key].filterId = this.modal.filter.data.filterId
        this.filterArray[key].filterName = this.modal.filter.data.filterName

        this.closeEditFilter()
      } else {
        if (this.modal.filter.data.filterId) {
          const index = this.filterArray.findIndex(
            (x) => x.filterId == this.modal.filter.data.filterId
          )

          if (index >= 0) {
            const confirm = this.$refs.confirm

            await confirm
              .open(
                'Esse perfil já possui esse filtro cadastrado, deseja atualiza-lo?',
                'red'
              )
              .then((response) => {
                confirm.loading = true

                this.filterArray[index].filter = JSON.parse(
                  this.modal.filter.data.filter
                )
                this.filterArray[index].filterId =
                    this.modal.filter.data.filterId
                this.filterArray[index].filterName =
                    this.modal.filter.data.filterName

                this.closeEditFilter()
              })
              .catch((error) => false)

            confirm.close()
          } else {
            this.filterArray.push({
              filter: JSON.parse(this.modal.filter.data.filter),
              filterId: this.modal.filter.data.filterId,
              filterName: this.modal.filter.data.filterName
            })

            this.closeEditFilter()
          }
        } else {
          this.filterArray.push({
            filter: JSON.parse(this.modal.filter.data.filter),
            filterId: null,
            filterName: this.modal.filter.data.filterName
          })

          this.closeEditFilter()
        }
      }
    },

    closeEditFilter () {
      this.modal.filter.filterId = null
      this.modal.filter.index = null
      this.modal.filter.data.filter = null
      this.modal.filter.data.filterName = null
      this.modal.filter.data.filterId = null
      this.modal.filter.active = false
    },

    updateValue (data) {
      this.modal.filter.data.filter = data
    },

    async selectFilter (value) {
      if (value && this.filterArray.find((x) => x.filterId == value)) {
        const confirm = this.$refs.confirm

        await confirm
          .open(
            'Esse filtro já está sendo usado nesse perfil. Deseja realmente continuar?',
            'orange'
          )
          .then((response) => {
            const filter = this.filters.find((x) => x.id == value)

            this.modal.filter.data.filter = filter.json
            this.modal.filter.data.filterName = filter.label
            this.modal.filter.data.filterId = filter.id

            this.filterKey = +new Date()
          })
          .catch((error) => false)

        confirm.close()
      } else {
        if (value) {
          const filter = this.filters.find((x) => x.id == value)

          this.modal.filter.data.filter = filter.json
          this.modal.filter.data.filterName = filter.label
          this.modal.filter.data.filterId = filter.id

          this.filterKey = +new Date()
        } else {
          const confirm = this.$refs.confirm

          await confirm
            .open(
              'Removendo o filtro selecionado, a árvore abaixo será cadastrada como um novo filtro. Deseja realmente continuar?',
              'orange'
            )
            .then((response) => {
              this.modal.filter.data.filterId = null
            })
            .catch((error) => false)

          confirm.close()
        }
      }
    },

    getFilter () {
      this.loadingFilter = true

      let jsonAttrId
      let nameAttrId

      return this.$http
        .get(`${this.$store.getters.api}/context/web/grid/filtro/`)
        .then(({ data }) => {
          jsonAttrId = data.subject.subjectAttributes.find(
            (x) => x.subjectAttributeName.toLowerCase() == 'filtro'
          ).subjectAttributeId
          nameAttrId = data.subject.subjectAttributes.find(
            (x) => x.subjectAttributeName.toLowerCase() == 'nome'
          ).subjectAttributeId

          /* */

          let array = []

          const origin = getUrl()

          return this.$http
            .post(
                `${this.$store.getters.api}/talk/filter/filtro?origin=${origin}`,
                {
                  fetch: 10000,
                  filterIds: [],
                  isActive: true,
                  page: 1
                }
            )
            .then(({ data }) => {
              array = data.itens.map((item) => {
                return {
                  id: item.talkId,
                  label: item.talkValues.find(
                    (x) => x.subjectAttributeId == nameAttrId
                  ).value,
                  json: item.talkValues.find(
                    (x) => x.subjectAttributeId == jsonAttrId
                  ).value
                }
              })

              this.filters = array

              this.loadingFilter = false
            })
            .catch((error) => {})
        })
        .catch((error) => {})
    },

    newFilter () {
      this.modal.filter.data = {}

      this.modal.filter.filterId = null
      this.modal.filter.index = null
      this.modal.filter.data.filter =
          '[{"id":null,"label":"Selecione um assunto","condition":"E","raw":null,"collapse":true,"options":false,"conditions":false}]'
      this.modal.filter.data.filterName = null
      this.modal.filter.active = true

      if (this.filters.length == 0) {
        this.getFilter()
      }
    },

    editFilter (key) {
      this.modal.filter.data.filter = JSON.stringify(
        this.filterArray[key].filter
      )
      this.modal.filter.data.filterId = this.filterArray[key].filterId
      this.modal.filter.data.filterName = this.filterArray[key].filterName
      this.modal.filter.active = true
      this.modal.filter.index = key

      if (this.filters.length == 0) {
        this.getFilter()
      }
    },

    async removeFilter (key) {
      const confirm = this.$refs.confirm

      await confirm
        .open(
            `Deseja realmente remover o filtro '${this.filterArray[key].filterName}' do perfil de acesso?`,
            'red'
        )
        .then((response) => {
          confirm.loading = true

          this.filterArray.splice(key, 1)
        })
        .catch((error) => false)

      /* */

      confirm.close()
    },

    async init () {
      const _this = this

      _this.ready = false
      _this.loading.active = true
      _this.loading.error = false

      try {
        await _this.callActionsResource()
        await _this.callChannels()
        await _this.callNotifications()
        await _this.callRoom()

        if (_this.getRouteId() != null) {
          await _this.callRoomDetails()
        } else {
          const roomNotifications = this.channels.map((channel) => {
            return {
              channel: {
                channelId: channel.channelId,
                isEnable: false
              },
              notifications: []
            }
          })

          this.roomNotifications = roomNotifications
        }

        _this.ready = true
        _this.loading.active = false
      } catch (error) {
        console.log(error)

        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error.response
      }
    },

    async callRoom () {
      return new Promise((resolve, reject) => {
        const params = {
          fetch: 999,
          page: 1
        }
        this.$http
          .get(this.$store.getters.api + '/room/', { params })
          .then(({ data }) => {
            const currentUser = this.$store.getters.user.data.roomId

            const rooms = data.itens.filter((el) => {
              return (
                el.roomId != this.getRouteId()
              )
            })
            rooms.forEach((el, index) => {
              this.itemRooms[index] =

                {
                  Actions: {
                    29: false
                  },
                  HasDefaultValue: false,
                  IsPrimary: false,
                  IsRequired: false,
                  Permissions: [],
                  ReferenceId: null,
                  ResourceId: el.roomId,
                  ResourceIdentifier: '',
                  ResourceName: `(${el.roomName}) ${
                      el.displayName ? el.displayName : ''
                    }`,
                  ResourceTypeId: null,
                  ResourceTypeIdentifier: '',
                  ResourceTypeName: ''
                }
            })
            resolve()
          })
          .catch((error) => {
            reject(error)

            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro no carregamento dos Canais. Por favor, tente novamente.',
              error
            })
            this.ready = true
          })
      })
    },

    async callRoomDetails () {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.$store.getters.api + '/room/' + this.getRouteId())
          .then(({ data }) => {
            this.info.title = data.roomName
            this.info.displayName = data.displayName
            this.info.colorPicker.value = data.color

            this.sharedTalks = data.sharedTalks

            this.selectedSubjects = data.requireDigitalSignature

            const roomNotifications = this.channels.map((channel) => {
              return {
                channel: {
                  channelId: channel.channelId,
                  isEnable: false
                },
                notifications: []
              }
            })

            const rns = data.roomNotifications

            if (rns.length > 0) {
              this.channels.forEach((channel) => {
                const findChannel = rns.find(
                  (rn) => rn.channel.channelId == channel.channelId
                )
                if (!findChannel) {
                  rns.push({
                    channel: {
                      channelId: channel.channelId,
                      isEnable: false
                    },
                    notifications: []
                  })
                }
              })
            }

            this.roomNotifications =
                data.roomNotifications.length > 0 ? rns : roomNotifications

            const dataMenu = data.menus

            const dataSubject = data.subjects

            const dataRoom = data.connectedRooms
            this.itemRooms.forEach((el, index) => {
              dataRoom.forEach((elm) => {
                if (el.ResourceId == elm.roomId) {
                  el.Actions['29'] = true
                }
              })
            })

            dataMenu.forEach((element) => {
              const key = this.data.Menus.findIndex(
                (x) => x.ResourceId == element.resourceId
              )

              if (key != -1) {
                this.data.Menus[key].Actions = element.actions
              }

              if (element.permissions) {
                element.permissions.forEach((subElement) => {
                  let subKey

                  if (key != -1) {
                    if (this.data.Menus[key].Permissions) {
                      subKey = this.data.Menus[key].Permissions.findIndex(
                        (x) => x.ResourceId == subElement.resourceId
                      )
                    }
                  }

                  if (key != -1 && subKey != -1) {
                    if (this.data.Menus[key].Permissions) {
                      this.data.Menus[key].Permissions[subKey].Actions =
                        subElement.actions

                      this.data.Menus[key].Permissions[subKey].filter =
                        subElement.filter
                    }
                  }
                })
              }
            })

            dataSubject.forEach((element) => {
              const key = this.data.Subjects.findIndex(
                (x) => x.ResourceId == element.resourceId
              )

              if (key != -1) {
                this.data.Subjects[key].Actions = element.actions
              }

              if (element.permissions) {
                element.permissions.forEach((subElement) => {
                  let subKey

                  if (key != -1) {
                    if (this.data.Subjects[key].Permissions) {
                      subKey = this.data.Subjects[key].Permissions.findIndex(
                        (x) => x.ResourceId == subElement.resourceId
                      )
                    }
                  }

                  if (key != -1 && subKey != -1) {
                    if (this.data.Subjects[key].Permissions) {
                      this.data.Subjects[key].Permissions[subKey].Actions =
                        subElement.actions

                      this.data.Subjects[key].Permissions[subKey].filter =
                        subElement.filter
                    }
                  }
                })
              }
            })

            this.filterArray = data.roomFilters
              .map((f) => {
                return {
                  filter: JSON.parse(f.filter),
                  filterId: f.filterId,
                  filterName: f.filterName
                }
              })
              .sort(function (a, b) {
                if (a.filter[0].identifier < b.filter[0].identifier) {
                  return -1
                } else if (a.filter[0].identifier > b.filter[0].identifier) {
                  return 1
                } else {
                  return 0
                }
              })

            this.ready = true
            resolve()
          })
          .catch((error) => {
            reject(error)

            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro no carregamento da página. Por favor, tente novamente.',
              error
            })

            this.ready = true
          })
      })
    },

    checkPermissionRecursive (element, actionsMenu) {
      if (element.Permissions != null) {
        element.Permissions.forEach((subElement, subKey) => {
          if (!element.Permissions[subKey].Action) {
            element.Permissions[subKey].Actions =
              JSON.parse(JSON.stringify(actionsMenu))
          }

          this.checkPermissionRecursive(subElement, actionsMenu)
        })
      }
    },

    changeCheck (args) {
      // args.notificationId
      // args.channelId

      this.roomNotifications.forEach((item) => {
        if (item.channel.channelId == args.channelId) {
          const index = item.notifications.findIndex(
            (notification) =>
              notification.notificationId == args.notificationId
          )
          if (index != -1) {
            item.notifications[index].isEnable =
              !item.notifications[index].isEnable
          } else {
            item.notifications.push({
              notificationId: args.notificationId,
              isEnable: true,
              isRequired: false
            })
          }
        }
      })
    },

    changeRequirement (args) {
      // args.notificationId
      // args.channelId

      this.roomNotifications.forEach((channel) => {
        if (channel.channel.channelId == args.channelId) {
          const index = channel.notifications.findIndex(
            (notification) =>
              notification.notificationId == args.notificationId
          )

          channel.notifications[index].isRequired = args.value
        }
      })
    },

    callNotifications () {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${this.$store.getters.api}/notification`)
          .then(({ data }) => {
            this.notifications = data.itens

            resolve()
          })
          .catch((error) => reject(error))
      })
    },

    callChannels () {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`${this.$store.getters.api}/company/channels`)
          .then(({ data }) => {
            this.channels = data.filter((item) => item.isEnable)

            resolve()
          })
          .catch((error) => reject(error))
      })
    },

    callActionsResource () {
      return new Promise((resolve) => {
        const actionsMenu = {}
        const actionsSubject = {}
        const actionsAttributes = {}

        this.$http
          .get(this.$store.getters.api + '/room/action')
          .then(({ data }) => {
            this.actions = data

            this.actionsMenu = this.actions.filter(
              (x) => x.resourceTypeId == 7
            )

            this.actionsSubject = this.actions.filter(
              (x) => x.resourceTypeId == 1
            )

            this.actionsAttributes = this.actions.filter(
              (x) => x.resourceTypeId == 3
            )

            this.actionsSubject.forEach((element, key) => {
              this.actionsSubject[key].selecteds = 0
              actionsSubject[element.resourceTypeActionId] = false
            })

            this.actionsAttributes.forEach((element, key) => {
              this.actionsAttributes[key].selecteds = 0

              actionsAttributes[element.resourceTypeActionId] =
                false
            })

            this.actionsMenu.forEach((element, key) => {
              this.actionsMenu[key].selecteds = 0
              actionsMenu[element.resourceTypeActionId] = false
            })
          })
          .catch((error) => {
            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro no carregamento da página. Por favor, tente novamente.',
              error
            })
          })

        this.$http
          .get(this.$store.getters.api + '/room/resource')
          .then(({ data }) => {
            this.data = data

            this.data.Menus.forEach((element, key) => {
              if (!this.data.Menus[key].Action) {
                this.data.Menus[key].Actions =
                  JSON.parse(JSON.stringify(actionsMenu))
              }

              this.checkPermissionRecursive(element, actionsMenu)
            })

            this.data.Subjects.forEach((element, key) => {
              if (!this.data.Subjects[key].Action) {
                this.data.Subjects[key].Actions =
                  JSON.parse(JSON.stringify(actionsSubject))
              }

              if (element.Permissions != null) {
                this.data.Subjects[key].Permissions.forEach(
                  (subElement, subKey) => {
                    if (!this.data.Subjects[key].Permissions[subKey].Action) {
                      this.data.Subjects[key].Permissions[subKey].Actions =
                        JSON.parse(JSON.stringify(actionsAttributes))
                    }
                  }
                )
              }
            })

            this.dataSubject = this.data.Subjects
            this.dataMenu = this.data.Menus

            this.dataMenuOriginal = JSON.parse(JSON.stringify(this.dataMenu))
            this.dataSubjectOriginal = JSON.parse(
              JSON.stringify(this.dataSubject)
            )

            this.dataSubject.forEach((element, key) => {
              this.dataSubject[key].selecteds = 0

              if (element.Permissions != null) {
                this.dataSubject[key].Permissions.forEach(
                  (subElement, subKey) => {
                    this.dataSubject[key].Permissions[subKey].selecteds =
                      0
                  }
                )
              }
            })

            this.dataMenu.forEach((element, key) => {
              this.dataMenu[key].selecteds = 0
            })

            resolve()
          })
          .catch((error) => {
            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro no carregamento da página. Por favor, tente novamente.',
              error
            })
          })
      })
    },

    getRouteId () {
      return this.$route.params.id ? this.$route.params.id : null
    },

    clearFilter (filter) {
      if (filter) {
        const json = filter

        json.map((val) => {
          delete val.raw

          val.attributes.map((attr) => {
            if (attr.operators) {
              attr.operators.map((o) => {
                o.values.map((v) => {
                  delete v.attribute.list
                })
              })
            }
          })
        })

        return striptags(JSON.stringify(json))
      } else {
        return ''
      }
    },

    submitProfile () {
      this.loadingSubmit = true

      const permissions = []

      this.dataMenu.forEach((element) => {
        permissions.push(element)
      })

      this.dataSubject.forEach((element) => {
        permissions.push(element)
      })
      const connectedRoomsId = []

      this.itemRooms.filter((el, index) => {
        if (el.Actions[29] === true) {
          connectedRoomsId.push({
            roomToId: el.ResourceId
          })
        }
      })

      const profile = {
        roomName: this.info.title,
        displayName: this.info.displayName,
        color: this.info.colorPicker.value,
        permissions: permissions,
        sharedTalks: this.sharedTalks,
        requireDigitalSignature: this.selectedSubjects,
        roomFilters: this.filterArray.map((f) => {
          return {
            filter: this.clearFilter(f.filter),
            filterId: f.filterId || null,
            filterName: f.filterName
          }
        }),
        connectedRooms: connectedRoomsId,
        roomNotifications: this.roomNotifications.filter(
          (x) => x.notifications.length > 0
        )
      }

      if (this.getRouteId() != null) {
        this.$http
          .put(
            this.$store.getters.api + '/room/' + `${this.getRouteId()}`,
            profile
          )
          .then((response) => {
            if (this.getRouteId() == this.$store.getters.user.data.roomId) this.$store.commit('RESET_TEMPLATES')

            this.$store.dispatch('notification', {
              text: `O perfil de acesso <b>${this.info.title}</b> foi editado com sucesso!`
            })

            this.$store.commit(
              'closePage',
              '/config/access_profile/room/' + this.getRouteId()
            )

            this.$router.push({
              name: 'config_access_profile_list',
              params: { refresh: true }
            })
            eventbus.$emit('changeProfile')

            this.loadingSubmit = false
          })
          .catch((error) => {
            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro ao editar o perfil de acesso. Por favor, tente novamente.',
              error
            })

            this.loadingSubmit = false
          })
      } else {
        this.$http
          .post(this.$store.getters.api + '/room', profile)
          .then(async (response) => {
            const currentPagination = this.$store.getters.getCurrentPagination
            await this.$store.dispatch('loadRooms', {
              ...currentPagination,
              type: 'list'
            })
            this.$store.dispatch('notification', {
              text: 'Perfil de acesso inserido com sucesso!'
            })

            eventbus.$emit('changeProfile')

            this.$store.commit('closePage', '/config/access_profile/room')
            this.$router.push({
              name: 'config_access_profile_list',
              params: { refresh: true }
            })

            this.loadingSubmit = false
          })
          .catch((error) => {
            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro ao inserir um perfil de acesso. Por favor, tente novamente.',
              error
            })

            this.loadingSubmit = false
          })
      }
    },

    closePage () {
      if (this.getRouteId() == null) {
        this.$store.commit('closePage', '/config/access_profile/room')
      } else {
        this.$store.commit(
          'closePage',
          '/config/access_profile/room/' + this.getRouteId()
        )
      }

      this.$router.push({
        name: 'config_access_profile_list'
      })
    },

    async clearPage () {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente limpar todos os campos do formulário?', 'red')
        .then((response) => {
          confirm.loading = true

          this.info.title = ''
          this.info.displayName = ''
          this.info.colorPicker.value = ''
          this.info.colorPicker.preview = ''

          this.dataSubject = JSON.parse(
            JSON.stringify(this.dataSubjectOriginal)
          )
          this.dataMenu = JSON.parse(JSON.stringify(this.dataMenuOriginal))
        })
        .catch((error) => false)

      /* */

      confirm.close()
    },

    selectColor (color) {
      this.info.colorPicker.value = color
      this.info.colorPicker.active = false
    }

  }
}
</script>

<style lang="scss" module>
  .loading {
    min-height: calc(100vh - 65px) !important;
  }
  .subject {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7px 0;
    padding: 7px;
    border-radius: 3px;
    width: 95%;
    background: $md-grey-200;
    color: #222;
    list-style: none;
    font-size: 1.4rem;

    &__icon {
      $size: 20px;
      width: $size;
      height: $size;
      fill: $md-grey-500;

      &--action {
        cursor: pointer;
        transition: 0.2s ease;
        transition-property: fill;

        &:hover {
          fill: $md-grey-700;
          :global(.nightlymode) & {
            fill: $md-grey-400;
          }
        }

        &--delete {
          fill: $md-red-500;

          &:hover {
            fill: $md-red-700;
          }
        }
      }
    }
  }
  .padding {
    padding: $theme-padding * 4 $theme-padding * 5;
    min-height: calc(100vh - 135px);
    color: red;

    @media screen and (max-width: 1280px) {
      padding: $theme-padding * 4 $theme-padding;
    }
  }
  .filtermodal {
    padding: $theme-padding * 2;
  }
  .filter {
    &__row {
      margin: 0;
      margin-bottom: 20px;
      padding: 0;
      list-style: none;
    }

    &__list {
      padding: 10px math.div($theme-padding, 2);
      background: $md-grey-100;
      border-radius: 10px;
      :global(.nightlymode) & {
        background: $md-grey-800;
      }

      &:not(:last-of-type) {
        margin-bottom: $theme-padding;
      }
    }

    &__icon {
      $size: 20px;
      width: $size;
      height: $size;
      fill: $md-grey-500;

      &--action {
        cursor: pointer;
        transition: 0.2s ease;
        transition-property: fill;

        &:hover {
          fill: $md-grey-700;
          :global(.nightlymode) & {
            fill: $md-grey-400;
          }
        }

        &--delete {
          fill: $md-red-500;

          &:hover {
            fill: $md-red-700;
          }
        }
      }
    }

    &__description {
      margin-bottom: $theme-padding * 2;
      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      line-height: 1;
      color: black;
      :global(.nightlymode) & {
        color: white;
      }
    }

    &__title {
      margin-top: $theme-padding * 2;
      margin-bottom: $theme-padding;
      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      line-height: 1;
      color: black;
      :global(.nightlymode) & {
        color: white;
      }
    }

    &__name {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      color: black;
      :global(.nightlymode) & {
        color: white;
      }

      &--empty {
        font-style: italic;
        color: $md-grey-500;
      }
    }

    &__wrapper {
      padding: $theme-padding * 1.5;
    }
  }

  .certified {
    &__description {
      margin-bottom: $theme-padding * 2;
      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      line-height: 1;
      color: black;
      :global(.nightlymode) & {
        color: white;
      }
    }
  }

  .color {
    cursor: pointer;

    &__button{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 51px;
      height: 51px;
      border-radius: 6px;
      border: 2px solid $md-grey-400;
      background: none;
      cursor: pointer;

      :global(.nightlymode) & {
        border-color: $md-grey-600;
      }

      &:focus{
        border-color: var(--theme-color-1);
        outline: 0;
      }

      svg{
        width: 28px;
        height: 28px;
        pointer-events: none;
      }
    }

    &__bucket {
      fill: $md-grey-600;
      :global(.nightlymode) & {
        fill: white;
      }

      &__border {
        stroke: black;
        stroke-width: 0.6px;
        stroke-opacity: 0.5;
        :global(.nightlymode) & {
          stroke: white;
        }
      }
    }

    &__picker {
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: math.div($theme-padding, 2);
      background: white;
      padding: $theme-padding;
      border-radius: 7px;
      z-index: 999;
      filter: drop-shadow(0 0 30px rgba(black, 0.1));
      :global(.nightlymode) & {
        background: darken($md-grey-800, 5%);
      }

      &::before {
        $size: $theme-padding;
        content: '';
        position: absolute;
        top: -$size;
        right: $size - 2;
        width: $size;
        height: $size;
        border: math.div($size, 2) solid transparent;
        border-bottom-color: white;
        :global(.nightlymode) & {
          border-bottom-color: darken($md-grey-800, 5%);
        }
      }
    }
  }
</style>
