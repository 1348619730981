export default function getUrl () {
  const url = window.location.href

  let origin = ''

  if (url.includes('automation')) {
    origin = 'job'
  }

  if (url.includes('grid')) {
    origin = 'grid'
  }

  if (url.includes('menu')) {
    origin = 'menu'
  }

  if (url.includes('tags')) {
    origin = 'tags'
  }

  if (url.includes('access_profile')) {
    origin = 'room'
  }

  if (url.includes('users')) {
    origin = 'users'
  }

  return origin
}
