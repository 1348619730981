<template>
  <div>
    <table :class="$style.securityTable">
      <thead :class="$style.securityTableHeader">
        <tr>
          <th :class="$style.securityTableHeaderTitle" colspan="2">
            <label>Canais</label>
          </th>

          <th :class="$style.securityTableHeaderTitle">
            <label :class="$style.securityTableHeaderActions">Sim</label>
          </th>
          <th :class="$style.securityTableHeaderTitle">
            <label :class="$style.securityTableHeaderActions"
              >Sim, Obrigatório</label
            >
          </th>
        </tr>
      </thead>
      <tbody :class="$style.securityTableBody">
        <tr
          v-for="(channel, index) in itens"
          :key="channel.channelId"
          :class="$style.securityTableBodyLine"
        >
          <td :class="$style.securityTableBodyTitle" :colspan="1">
            <label>{{
              channel.channelId == 1
                ? 'Aplicação'
                : channel.channelId == 2
                ? 'E-mail'
                : channel.channelDescription
            }}</label>
          </td>
          <th></th>
          <td :class="$style.securityTableBodyCell">
            <app-tooltip
              bottom
              :label="channel.checked ? 'Desmarcar item' : 'Selecionar item'"
              :class="$style.securityTableTootip"
            >
              <app-icon
                :glyph="
                  !channel.checked ? 'check_box_outline_blank' : 'check_box'
                "
                :class="
                  channel.checked
                    ? $style.securityTableCheckboxActived
                    : $style.securityTableCheckbox
                "
                :name="'check_resource_' + channel.channelId"
                v-on:click="changeCheck(channel, index)"
              />
            </app-tooltip>
          </td>
          <td :class="$style.securityTableBodyCell">
            <app-tooltip
              v-if="channel.checked"
              bottom
              :label="channel.required ? 'Desmarcar item' : 'Selecionar item'"
              :class="$style.securityTableTootip"
            >
              <app-icon
                :glyph="
                  !channel.checked
                    ? 'check_box_outline_blank'
                    : channel.required
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
                :class="
                  channel.required
                    ? $style.securityTableCheckboxActived
                    : $style.securityTableCheckbox
                "
                :name="'check_resource_' + channel.channelId"
                v-on:click="changeRequire(channel, index)"
              />
            </app-tooltip>
            <app-tooltip
              v-if="!channel.checked"
              bottom
              :label="channel.required ? 'Desmarcar item' : 'Selecionar item'"
              :class="$style.securityTableTootip"
            >
              <app-icon
                :glyph="
                  !channel.required ? 'check_box_outline_blank' : 'check_box'
                "
                :class="$style.securityTableCheckboxDisabled"
                :name="'check_resource_' + channel.channelId"
              />
            </app-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      itens: []
    }
  },

  props: {
    channels: Array
  },

  mounted () {
    this.createChannelObject()
  },

  methods: {
    changeCheck (channel, index) {
      this.itens[index].checked = !channel.checked

      if (!this.itens[index].checked) {
        if (this.itens[index].required) this.itens[index].required = false
      }
    },
    changeRequire (channel, index) {
      this.itens[index].required = !channel.required
    },

    async createChannelObject () {
      return new Promise(resolve => {
        this.$http
          .get(`${this.$store.getters.api}/company/channels`)
          .then(response => {
            const itens = response.data.filter(
              ({ isEnable }) => isEnable === true
            )

            if (this.channels) {
              itens.forEach(item => {
                const channel = this.channels.find(
                  channel => channel.channelId == item.channelId
                )

                if (channel) {
                  item.checked = true
                  if (channel.isRequired) {
                    item.required = true
                  } else {
                    item.required = false
                  }
                } else {
                  item.checked = false
                  item.required = false
                }
              })
            } else {
              itens.forEach(item => {
                item.checked = false
                item.required = false
              })
            }

            this.itens = itens
          })
      })
    }
  },

  watch: {
    itens: {
      handler: function () {
        const channels = this.itens
          .filter(channel => channel.checked)
          .map(item => {
            return {
              channelId: item.channelId,
              isRequired: item.required
            }
          })

        this.$emit('channelMatrixChecked', channels)
      },

      deep: true
    }
  }
}
</script>

<style lang="scss" module>

$background-header: $md-grey-200;
$background-header-nightly: lighten($md-grey-900, 5%);

$background-header-checkcol: darken($md-grey-200, 4%);
$background-header-checkcol-nightly: lighten($md-grey-900, 2%);

$background-lines: $md-grey-100;
$background-lines-nightly: darken($md-grey-800, 16%);

$border-lines: $md-grey-200;
$border-lines-nightly: lighten($md-grey-900, 5%);

$color-label: $md-grey-700;
$color-label-nightly: $md-grey-100;

$color-checkbox: $md-grey-600;
$color-checkbox-nightly: $md-grey-300;

.security {
  &--modal {
    overflow: hidden;
  }

  &__table {
    width: 100%;

    border: 2px solid $background-header;

    :global(.nightlymode) & {
      border: 2px solid $background-header-nightly;
    }

    &__header {
      background: $background-header;

      :global(.nightlymode) & {
        background: $background-header-nightly;
      }

      tr:first-child th {
        position: sticky;
        top: -1px;
        background: $background-header;
        z-index: 98;

        :global(.nightlymode) & {
          background: $background-header-nightly;
        }
      }

      &__checkcol {
        height: 50px;

        background: $background-header-checkcol;

        :global(.nightlymode) & {
          background: $background-header-checkcol-nightly;
        }
      }

      &__cell {
        text-align: center;
      }

      &__actions {
        padding: $theme-padding;
        text-align: center;
        width: 100%;
        margin-bottom: 0;

        font-family: 'Raleway', sans-serif;
        @include font-sizer(13);
        font-weight: 600;
        color: $color-label;

        :global(.nightlymode) & {
          color: $color-label-nightly;
        }
      }

      &__title {
        padding: $theme-padding $theme-padding * 2;

        font-family: 'Raleway', sans-serif;
        @include font-sizer(13);
        font-weight: 600;
        color: $color-label;

        :global(.nightlymode) & {
          color: $color-label-nightly;
        }

        label {
          margin-bottom: 0;
        }
      }
    }

    &__body {
      &__cell {
        position: relative;

        border-right: 1px solid $border-lines;

        text-align: center;

        &:last-child {
          border-right: 0px solid $border-lines;
        }

        :global(.nightlymode) & {
          border-right: 1px solid $border-lines-nightly;

          &:last-child {
            border-right: 0px solid $border-lines-nightly;
          }
        }
      }

      &__line {
        background: transparent;
        border-bottom: 1px solid $border-lines;

        &:nth-of-type(2n) {
          background: $background-lines;
        }

        &:last-child {
          border-bottom: 0px solid $border-lines;
        }

        :global(.nightlymode) & {
          border-bottom: 1px solid $border-lines-nightly;

          &:nth-of-type(2n) {
            background: $background-lines-nightly;
          }

          &:last-child {
            border-bottom: 0px solid $border-lines-nightly;
          }
        }
      }

      &__title {
        padding: $theme-padding $theme-padding * 2;

        font-family: 'Raleway', sans-serif;
        @include font-sizer(13);
        font-weight: 600;
        color: $color-label;

        border-right: 1px solid $border-lines;

        label {
          margin-bottom: 0;
        }

        :global(.nightlymode) & {
          color: $color-label-nightly;

          border-right: 1px solid $border-lines-nightly;
        }
      }

      &__icon {
        fill: $color-label;

        cursor: pointer;

        width: 20px;
        height: 20px;

        :global(.nightlymode) & {
          fill: $color-label-nightly;
        }
      }

      &__options {
        position: absolute;
        top: 90%;
        left: calc(50% - 12px);

        min-width: 180px;
        padding: math.div($theme-padding, 2);

        background: $md-grey-900;
        border-radius: 5px;
        -webkit-transition-delay: 0s !important;
        transition-delay: 0s !important;
        list-style: none;

        z-index: 9;

        :global(.nightlymode) & {
          background: $md-grey-800;
        }

        &:before {
          content: '';
          position: absolute;
          top: -14px;
          left: 15px;
          margin-left: -10px;
          border: 7px solid transparent;
          border-bottom-color: $md-grey-900;

          :global(.nightlymode) & {
            border-bottom-color: $md-grey-800;
          }
        }

        &__link {
          width: 100%;

          text-align: center;
          font-family: 'Raleway', sans-serif;
          @include font-sizer(12);
          color: white;

          cursor: pointer;
        }
      }
    }

    &__tooltip {
      width: 24px;
      margin: 0 auto;
    }

    &__checkbox {
      margin: 0 auto;

      fill: $color-checkbox;

      cursor: pointer;

      :global(.nightlymode) & {
        fill: $color-checkbox-nightly;
      }

      &--actived {
        fill: $md-blue-500;
        cursor: pointer;
      }

      &--disabled {
        fill: #bbbbbb;
      }
    }
  }
}
.checkBoxLine {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
