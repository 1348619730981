const signalR = require('@microsoft/signalr')

export default function useWebSocket (store) {
  const ws = new signalR.HubConnectionBuilder()
    .withUrl(`${store.getters.api}/websocket`, {
      accessTokenFactory: () => store.getters.token,
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .withAutomaticReconnect()
    .configureLogging(process.env.NODE_ENV === 'development' ? signalR.LogLevel.Information : signalR.LogLevel.None)
    .build()

  return { ws }
}
