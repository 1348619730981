<template>
  <div :class="$style.picker" ref="target">
    <div :class="$style.pickerBox">
      <ul
        :class="$style.pickerRow"
        v-for="(color, index) in colors"
        :key="index"
      >
        <li
          :class="$style.pickerList"
          v-for="pallete in color.pallete"
          v-on:mouseenter="previewColor(pallete.hex)"
          v-on:mouseout="resetColor()"
          v-on:click="selectColor(pallete.hex)"
          :title="pallete.title"
          :key="pallete.hex"
        >

          <div :class="$style.pickerColor">
            <div
              :class="$style.pickerColorHex"
              :style="{
                backgroundColor: `${pallete.hex}${(
                  Math.round(-alpha * 255) + 0x10000
                )
                  .toString(16)
                  .substr(-2)
                  .toUpperCase()}`,
              }"
            ></div>

            <app-icon
              glyph="done"
              :class="$style.pickerColorSelected"
              :style="{
                fill:
                  pallete.weight < 500 || ['white'].includes(pallete.weight)
                    ? 'black'
                    : null,
              }"
              v-if="
                [selected, selected ? selected.slice(0, -2) : ''].includes(
                  pallete.hex,
                )
              "
            />
          </div>

        </li>
      </ul>
    </div>

    <app-button
      label="Reverter para padrão"
      color="grey-700"
      light
      small
      full
      class="mt-3"
      icon="format_color_reset"
      v-on:click="selectColor(defaultColor)"
    />
  </div>
</template>

<script>
import colors from 'material-colors'
import { onClickOutside } from '@vueuse/core'

/* */

export default {
  data () {
    return {
      preview: false,
      colorSelected: '',
      custom: false,

      alpha: 255,

      ignore: {
        palletes: [
          'indigo',
          'teal',
          'lightBlue',
          'yellow',
          'orange',
          'deepOrange',
          'darkIcons',
          'darkText',
          'lightText',
          'lightIcons',
          'black',
          'white',
          'grey'
        ],

        weights: ['a100', 'a200', 'a400', 'a700']
      }
    }
  },

  props: {
    selected: {
      type: String,
      default: ''
    },
    defaultColor: String
  },

  beforeMount () {
    if (this.selected) {
      this.colorSelected = this.selected
    }
  },

  mounted () {
    onClickOutside(this.$refs.target, (event) => {
      if (event.target.getAttribute('name') !== 'button_colorpicker') this.$emit('close')
    })
  },

  computed: {
    colors () {
      const array = []

      /* */

      const _this = this

      let x = 0

      Object.keys(colors).forEach(i => {
        if (!_this.ignore.palletes.includes(i)) {
          const temp = {
            pallete: []
          }

          const keys = JSON.parse(JSON.stringify(colors[i]))
          const c500 = colors[i]['500']

          delete keys['500']

          /* */

          let title = ''

          switch (i) {
            case 'red':
              title += 'Vermelho'
              break
            case 'pink':
              title += 'Rosa'
              break
            case 'purple':
              title += 'Roxo'
              break
            case 'deepPurple':
              title += 'Roxo Escuro'
              break
            case 'blue':
              title += 'Azul'
              break
            case 'cyan':
              title += 'Ciano'
              break
            case 'green':
              title += 'Verde'
              break
            case 'lightGreen':
              title += 'Verde Claro'
              break
            case 'lime':
              title += 'Lima'
              break
            case 'amber':
              title += 'Amarelo'
              break
            case 'brown':
              title += 'Marrom'
              break
            case 'blueGrey':
              title += 'Azul-Cinza'
              break
          }

          /* */

          Object.keys(keys).forEach(j => {
            if (!_this.ignore.weights.includes(j)) {
              /* */

              temp.pallete.push({
                title: title + ` (${j})`,
                weight: parseInt(j),
                hex: colors[i][j]
              })
            }
          })

          temp.pallete.unshift({
            title: title + ' (500)',
            weight: 500,
            hex: c500
          })

          /* */

          if (x == 0) {
            temp.pallete.unshift({
              title: 'Preto',
              weight: 'black',
              hex: '#000000'
            })
          } else if (x == 1) {
            temp.pallete.unshift({
              title: 'Cinza (900)',
              weight: 900,
              hex: colors.grey['900']
            })
          } else if (x == 2) {
            temp.pallete.unshift({
              title: 'Cinza (800)',
              weight: 800,
              hex: colors.grey['800']
            })
          } else if (x == 3) {
            temp.pallete.unshift({
              title: 'Cinza (700)',
              weight: 700,
              hex: colors.grey['700']
            })
          } else if (x == 4) {
            temp.pallete.unshift({
              title: 'Cinza (600)',
              weight: 600,
              hex: colors.grey['600']
            })
          } else if (x == 5) {
            temp.pallete.unshift({
              title: 'Cinza (500)',
              weight: 500,
              hex: colors.grey['500']
            })
          } else if (x == 6) {
            temp.pallete.unshift({
              title: 'Cinza (400)',
              weight: 400,
              hex: colors.grey['400']
            })
          } else if (x == 7) {
            temp.pallete.unshift({
              title: 'Cinza (300)',
              weight: 300,
              hex: colors.grey['300']
            })
          } else if (x == 8) {
            temp.pallete.unshift({
              title: 'Cinza (200)',
              weight: 200,
              hex: colors.grey['200']
            })
          } else if (x == 9) {
            temp.pallete.unshift({
              title: 'Cinza (100)',
              weight: 100,
              hex: colors.grey['100']
            })
          } else if (x == 10) {
            temp.pallete.unshift({
              title: 'Cinza (50)',
              weight: 50,
              hex: colors.grey['50']
            })
          } else if (x == 11) {
            temp.pallete.unshift({
              title: 'Branco',
              weight: 'white',
              hex: '#ffffff'
            })
          }

          array.push(temp)

          /* */

          x++
        }
      })

      /* */

      return array
    }
  },

  methods: {
    openCustom () {
      this.custom = true
    },

    closeCustom () {
      this.custom = false
    },

    selectColor (color) {
      this.colorSelected = color
      this.preview = false

      this.$emit('select', color)

      this.closeCustom()
    },

    previewColor (color) {
      this.$emit('preview', color)
      this.preview = color
    },

    resetColor (color) {
      this.$emit('preview', false)
      this.preview = false
    }
  }
}
</script>

<style lang="scss" module>

/* */

.picker {
  min-width: 230px;

  &__custom {
    padding: $theme-padding * 2;
  }

  &__box {
    margin: 0 -2.5px;

    display: flex;
  }

  &__row {
    padding: 0;
    margin: 0;

    list-style: none;
  }

  &__list {
    padding: 2.5px;

    cursor: pointer;

    &:nth-child(1) {
      margin-bottom: math.div($theme-padding, 2);
    }

    &:nth-child(2) {
      padding-bottom: $theme-padding;
      margin-bottom: $theme-padding;

      border-bottom: 1px solid rgba(black, 0.1);

      :global(.nightlymode) & {
        border-bottom-color: rgba(white, 0.1);
      }
    }
  }

  &__list:hover &__color__hex {
    box-shadow: inset 0 0 0 2px rgba(black, 0.05), 0 0 0 2px black;

    :global(.nightlymode) & {
      box-shadow: inset 0 0 0 2px rgba(white, 0.15), 0 0 0 2px white;
    }
  }

  &__color {
    position: relative;

    $size: 20px;

    width: $size;
    height: $size;

    background-image: url('./pattern.svg');
    background-size: 10px;

    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    &__hex {
      position: absolute;

      width: $size;
      height: $size;

      border-radius: 100%;

      z-index: 0;

      box-shadow: inset 0 0 0 2px rgba(black, 0.05), 0 0 0 2px transparent;

      transition: 0.2s ease;
      transition-property: box-shadow;

      :global(.nightlymode) & {
        box-shadow: inset 0 0 0 2px rgba(white, 0.15), 0 0 0 2px transparent;
      }
    }

    &__selected {
      position: absolute;

      width: $size - 5px;
      height: $size;

      border-radius: $size;

      fill: white;
    }
  }
}
</style>
