import axios from 'axios'

const moduleUser = {
  state: () => ({
    users: {
      data: [],
      total: 0
    }
  }),
  getters: {
    getUsers: (state) => state.users
  },
  mutations: {
    SET_USERS (state, { users, total }) {
      state.users.data = users
      state.users.total = total
    },
    DELETE_USER (state, id) {
      const index = state.users.data.findIndex((user) => user.accountId === id)
      if (index > -1) {
        state.users.data.splice(index, 1)
        state.users.data.total--
      }
    }
  },
  actions: {
    async loadUsers ({ getters, commit }, { page, fetch, term }) {
      await axios
        .get(`${getters.api}/account`, {
          params: {
            CurrentPage: page,
            ResultsPerPage: fetch,
            term
          }
        })
        .then(({ data }) => {
          commit('SET_USERS', { users: data.itens, total: data.totalResults })
        })
        .catch((e) => {
          throw e
        })
    },
    async loadUserById ({ getters }, id) {
      const { data } = await axios.get(`${getters.api}/account/${id}`)
      return {
        ...data,
        profileId: data.room.roomId,
        attrSelecteds: data.attributes.map(({ attributeId, value }) => ({
          attributeId,
          value,
          identifier: parseInt(Math.random() * 10000, 10)
        }))
      }
    },
    async deleteUser ({ getters, commit }, id) {
      await axios
        .delete(`${getters.api}/account/delete/${id}`)
        .then(() => {
          commit('DELETE_USER', id)
        })
        .catch((e) => {
          throw e
        })
    }
  }
}

export default moduleUser
