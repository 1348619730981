<script setup>
import { ref } from 'vue'
import PasswordMeter from 'vue-simple-password-meter'

const props = defineProps(
  {
    password: {
      type: String,
      required: true
    }
  }
)

const emit = defineEmits(['change-password'])

const score = ref(null)
const message = ref('')
const passwordTest = ref('weakpass')

const onScore = (payload) => {
  if (props.password.length >= 10) {
    const messages = [
      'Não segura',
      'Muito fraca',
      'Fraca',
      'Forte',
      'Muito forte'
    ]

    score.value = payload.score
    passwordTest.value = props.password
    message.value = messages[score.value]
  } else {
    score.value = 0
    passwordTest.value = 'weakpass'
    message.value = 'Digite no mínimo 10 caracteres'
  }

  emit('change-password', score.value)
}
</script>

<template>
  <div>
    <div
      v-if="password.length > 0"
      :class="$style.password"
    >
      <div :class="$style.passwordMessage">{{ message }}</div>
      <div :class="$style.passwordBar">
        <password-meter
          :password="passwordTest"
          @score="onScore"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.password {
  &__message {
    margin-bottom: 5px;
    color: #888;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
  }

  &__bar {
    background: #eee;
    border-radius: 10px;
  }
}

:global {
  .po-password-strength-bar.risky {
      background-color: $md-red-500;
  }

  .po-password-strength-bar.guessable {
      background-color: $md-orange-500;
  }

  .po-password-strength-bar.weak {
      background-color: $md-amber-500;
  }

  .po-password-strength-bar.safe {
      background-color: $md-lime-500
  }

  .po-password-strength-bar.secure {
      background-color: $md-green-500;
  }
}
</style>
