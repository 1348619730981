<template>
  <app-config>
    <app-view>
      <app-confirm-promise ref="confirm" />

      <app-confirm-promise ref="credentials" size="640">
        <div :class="$style.credentials">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <app-icon :class="$style.credentialsTitleIcon" glyph="vpn_key" />
            </div>

            <div class="col">
              <div :class="$style.credentialsTitle">Credenciais de acesso</div>
            </div>
          </div>

          <div :class="$style.credentialsText">
            Aqui estão as credenciais de acesso para esse usuário. Certifique-se
            de copiar agora as informações abaixo pois elas não serão exibidas
            novamente. Caso necessite de novas credenciais, entre em contato com
            o seu administrador.
          </div>

          <div class="row no-gutters align-items-center mb-5">
            <div class="col-auto mr-3">
              <app-button
                label="CSV"
                icon="get_app"
                small
                v-on:click="credentialsDownload('csv')"
              />
            </div>

            <div class="col-auto">
              <app-button
                label="JSON"
                icon="get_app"
                small
                v-on:click="credentialsDownload('json')"
              />
            </div>
          </div>

          <div class="row align-items-center no-gutters">
            <div class="col pr-5">
              <label :class="$style.credentialsLabel">Hash</label>
              <div :class="$style.credentialsInputWrapper">
                <input
                  :class="$style.credentialsInput"
                  readonly
                  :value="credentials.hash"
                  v-on:click="credentialsCopy($event)"
                />
              </div>
            </div>

            <div class="col pr-4">
              <label :class="$style.credentialsLabel">Senha</label>
              <div :class="$style.credentialsPassword">
                <div :class="$style.credentialsInputWrapper">
                  <input
                    :class="$style.credentialsInput"
                    readonly
                    :type="credentials.passwordView ? 'text' : 'password'"
                    :value="credentials.password"
                    v-on:click="credentialsCopy($event)"
                  />
                </div>

                <div :class="$style.credentialsIcons">
                  <app-tooltip
                    :label="
                      credentials.passwordView
                        ? 'Ocultar senha'
                        : 'Mostrar senha'
                    "
                    left
                    v-on:click="toggleCredentialsPasswordView()"
                  >
                    <app-icon
                      :class="$style.credentialsIcon"
                      :glyph="
                        credentials.passwordView
                          ? 'visibility_off'
                          : 'visibility'
                      "
                    />
                  </app-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-confirm-promise>

      <transition name="fade">
        <app-modal size="480" v-if="add.modal" key="confirm_add">
          <app-confirm
            :title="addTitle"
            color="green"
            v-on:ok="accountPost()"
            v-on:cancel="closeAddModal()"
            :loading="add.loading"
            :id="$style.id_add_confirm"
          />
        </app-modal>
      </transition>

      <app-box>
        <transition name="fade-up" mode="out-in">
          <app-loader
            v-if="!ready"
            key="loading"
            :active="loading.active"
            :error="loading.error"
            :response="loading.response"
            v-on:reload="init()"
            background
            :class="$style.loading"
          />

          <form
            action="/"
            v-on:submit.prevent="openAddModal()"
            :id="$style.id_form"
            v-else
          >
            <div :class="$style.padding">
              <app-box margin>
                <app-title label="Adicionar Novo Usuário" />
              </app-box>

              <div class="row">
                <!-- <div class="col md-3">

                                    <app-box background>

                                        <div :class="$style.user">

                                            <div class="row justify-content-center">

                                                <div class="col md-12">

                                                    <div :class="$style.userAvatar">

                                                        <div :class="$style.userAvatarChange" v-on:click="openModalAvatar()">

                                                            <app-icon glyph="add_a_photo" :class="$style.userAvatarChangeIcon" />

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                            <div :class="$style.userName">{{ account.FirstName || 'Nome do Usuário' }}</div>
                                            <div :class="$style.userEmail">{{ account.Email || 'E-mail do Usuário' }}</div>

                                        </div>

                                    </app-box>

                                </div> -->

                <div class="col">
                  <app-box margin>
                    <app-box background title="Grupo de Acesso">
                      <div class="row">
                        <div class="col-6 col-xl-4">
                          <app-select
                            :id="$style.id_accountType"
                            reference="account"
                            v-on:change-value="changeAccountType"
                            label="Tipo"
                            :value="account.accountType"
                            :data="[
                              {
                                accountId: 1,
                                accountName: 'Usuário',
                              },

                              {
                                accountId: 2,
                                accountName: 'Integração',
                              },
                            ]"
                            :object-key="{
                              id: 'accountId',
                              label: 'accountName',
                            }"
                            :required="true"
                          />
                        </div>

                        <div class="col-6 col-xl-4">
                          <app-select
                            :id="$style.id_roomId"
                            reference="room"
                            v-on:change-value="changeProfile"
                            v-on:handle-scroll="handleScroll"
                            label="Perfil de Acesso"
                            :value="account.profileId"
                            :data="room.data"
                            :object-key="{
                              id: 'roomId',
                              label: 'roomName',
                            }"
                            :loadingPage="room.loadingPage"
                            :required="true"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <transition name="fade-right" mode="out-in">
                    <app-box margin v-if="account.accountType == 1" key="use">
                      <app-box background title="Dados Pessoais">
                        <div class="row">
                          <div class="col-6 col-xl-4">
                            <app-input
                              label="Primeiro Nome"
                              required
                              v-model:value="account.firstName"
                              :maxlength="150"
                              :id="$style.id_firstName"
                            />
                          </div>

                          <div class="col-6 col-xl-4">
                            <app-input
                              label="Sobrenome"
                              required
                              v-model:value="account.lastName"
                              :maxlength="250"
                              :id="$style.id_lastName"
                            />
                          </div>

                          <div class="col-6 col-xl-4">
                            <app-input
                              label="Nome para Exibição"
                              required
                              v-model:value="account.displayName"
                              :maxlength="50"
                              :id="$style.id_displayName"
                            />
                          </div>
                          <div class="col-6 col-xl-4">
                            <app-input
                              label="Telefone"
                              :mask="'tel'"
                              required
                              v-model:value="account.phoneNumber"
                              :maxlength="50"
                              :id="$style.id_displayName"
                            />
                          </div>
                        </div>
                      </app-box>
                    </app-box>

                    <app-box
                      margin
                      v-else-if="account.accountType == 2"
                      key="app"
                    >
                      <app-box background title="Dados do Aplicativo">
                        <div class="row">
                          <div class="col-6 col-xl-4">
                            <app-input
                              label="Nome do Aplicativo"
                              required
                              v-model:value="account.appName"
                              :maxlength="150"
                              :id="$style.id_appName"
                              ref="appName"
                            />
                            <span
                              :class="$style.errormail"
                              v-if="validationAppName"
                              name="validation-message"
                              >Nome do Aplicativo já cadastrado</span
                            >
                          </div>

                          <div class="col-6 col-xl-4">
                            <app-input
                              label="Nome de Exibição"
                              required
                              v-model:value="account.displayName"
                              :maxlength="150"
                              :id="$style.id_displayName"
                            />
                          </div>
                        </div>
                      </app-box>
                    </app-box>
                  </transition>

                  <app-box margin v-if="account.accountType == 1">
                    <app-box background title="Imagem do perfil">
                      <div class="row">
                        <div class="col-12 col-xl-7">
                          <upload-image
                            v-on:change-file="changeFile"
                            v-on:remove="removeFile"
                            :image="account.profileImage"
                          />
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box margin v-if="account.accountType == 1">
                    <app-box background title="Dados de Acesso">
                      <div class="row">
                        <div class="col-12 col-xl-4">
                          <app-input
                            label="E-mail"
                            type="email"
                            :isValid="validEmail"
                            required
                            v-model:value="account.email"
                            :maxlength="150"
                            :id="$style.id_email"
                            ref="email"
                          />
                          <span
                            :class="$style.errormail"
                            v-if="validationEmail"
                            name="validation-message"
                            >Esse e-mail já possui cadastro</span
                          >
                          <span
                            :class="$style.errormail"
                            v-if="validaDominio"
                            name="validation-message"
                            >Insira um Email válido</span
                          >
                        </div>
                      </div>
                    </app-box>
                  </app-box>

                  <app-box margin>
                    <app-box background title="Configuração de campos">
                      <attribute-filter
                        :attributes="attributes"
                        :attrSelecteds="attrSelecteds"
                        @add-attr="addAttr"
                        @select-attr="selectAttr"
                        @delete-attr="deleteAttr"
                        @add-attr-values="addAttrValues"
                        @change-attr-values="changeAttrValues"
                        @select-attr-value="selectAttrValue"
                      />
                    </app-box>
                  </app-box>

                  <delimited-filter
                    v-show="false"
                    :sharedTalks="[]"
                    v-on:sharedTalks="setSharedTalks"
                  />
                </div>
              </div>
            </div>

            <app-actions-box>
              <div class="row justify-content-center">
                <div class="col-auto">
                  <app-button
                    label="Salvar"
                    icon="save"
                    type="submit"
                    :id="$style.id_submit"
                  />
                </div>

                <div class="col-auto">
                  <app-button
                    label="Cancelar"
                    icon="close"
                    color="red-500"
                    :id="$style.id_cancel"
                    v-on:click="closePage"
                  />
                </div>

                <div class="col-auto" v-if="checksChange">
                  <app-button
                    label="Limpar"
                    icon="replay"
                    light
                    color="grey-800"
                    :id="$style.id_clear"
                    v-on:click="clearPage"
                  />
                </div>
              </div>
            </app-actions-box>
          </form>
        </transition>
      </app-box>
    </app-view>
  </app-config>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import { onKeyStroke } from '@vueuse/core'
import AttributeFilter from '@/views/config/users/AttributeFilter.vue'
import DelimitedFilter from '@/views/config/DelimitedFilter'
import AppConfig from '@/views/config/Index.vue'
const uploadImage = defineAsyncComponent(() => import('@/components/UploadImage.vue'))

export default {
  setup () {
    const add = ref({
      loading: false,
      modal: false,
      data: null
    })
    const closeAddModal = () => {
      add.value.modal = false
      add.value.data = null
    }

    onKeyStroke(
      'Escape',
      (e) => {
        closeAddModal()
      },
      { eventName: 'keydown', target: document }
    )

    return { add, closeAddModal }
  },
  components: {
    AppConfig,
    AttributeFilter,
    DelimitedFilter,
    uploadImage
  },
  data: () => ({
    validaDominio: false,
    validaEmail: false,
    credentials: {
      passwordView: false,

      hash: '',
      password: ''
    },
    ready: false,
    loading: {
      active: true,
      error: false,
      response: {}
    },
    modal: {
      avatar: false
    },
    account: {
      displayName: null,
      firstName: null,
      lastName: null,
      appName: null,
      accountType: null,
      phoneNumber: null,
      email: null,
      profileId: null,
      profileImage: null,
      sharedTalks: []
    },
    room: {
      pagination: {
        page: 1,
        per_page: 10,
        total: 0,
        totalPage: 0
      },
      loadingSearch: false,
      loadingPage: false,
      data: []
    },
    validationEmail: false,
    validationAppName: false,
    attributes: [],
    attrSelecteds: []
  }),

  beforeMount () {
    this.init()
  },

  watch: {
    'account.email': function () {
      this.validationEmail = false
    },

    'account.appName': function () {
      this.validationAppName = false
    }

  },

  computed: {
    validEmail () {
      if (this.account.email !== null) {
        const regExp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const email = this.account.email.toLowerCase()
        if (regExp.test(email)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    addTitle () {
      return `Deseja realmente inserir o usuário <b>${
          this.add.data?.displayName || this.account.appName
        }${this.add.data.email ? ` (${this.add.data.email})` : ''}</b>?`
    },

    checksChange () {
      if (
        this.account.displayName ||
          this.account.firstName ||
          this.account.lastName ||
          this.account.appName ||
          this.account.accountType ||
          this.account.email ||
          this.account.profileId ||
          this.account.profileImage
      ) {
        return true
      } else if (this.attrSelecteds.length > 0) {
        if (this.attrSelecteds[0].attributeId) {
          return true
        }
      }

      return false
    }
  },

  methods: {
    async handleScroll () {
      if (this.room.pagination.page < this.room.pagination.totalPage) {
        this.room.pagination.page++
        await this.callRooms()
      }
    },
    toggleCredentialsPasswordView () {
      this.credentials.passwordView = !this.credentials.passwordView
    },

    setSharedTalks (data) {
      this.account.sharedTalks = data
    },

    credentialsCopy (e) {
      const input = document.createElement('input')
      input.value = e.target.value

      document.querySelector('body').appendChild(input)

      input.select()

      const copy = document.execCommand('copy')

      if (copy) {
        const parent = e.target.closest(
            `.${this.$style.credentialsInputWrapper}`
        )

        parent.classList.remove(this.$style.credentialsInputWrapperCopied)
        e.target.classList.remove(this.$style.credentialsInputCopied)

        setTimeout(
          () => {
            parent.classList.add(this.$style.credentialsInputWrapperCopied)
            e.target.classList.add(this.$style.credentialsInputCopied)
          },
          1,
          this
        )

        document.querySelector('body').removeChild(input)
      }
    },

    credentialsDownload (format) {
      let data
      const a = document.createElement('a')

      if (format == 'json') {
        data = JSON.stringify(
          {
            hash: this.credentials.hash,
            password: this.credentials.password
          },
          0,
          4
        )
      } else {
        data = `Hash;Password;\r\n${this.credentials.hash};${this.credentials.password};`
      }

      const file = new Blob([data], {
        type: format == 'json' ? 'application/json' : 'text/csv'
      })

      a.href = URL.createObjectURL(file)

      a.download = `talkture_credentials_${+new Date()}.${format}`

      a.click()
    },
    async init () {
      const _this = this

      _this.ready = false
      _this.loading.active = true
      _this.loading.error = false

      try {
        await _this.callRooms()
        await _this.loadAttributes()

        _this.ready = true
        _this.loading.active = false
      } catch (error) {
        console.log(error)

        this.$store.dispatch('notification', {
          type: 'error',
          text: 'Ocorreu um erro no carregamento da página. Por favor, tente novamente.',
          error
        })

        _this.loading.active = false
        _this.loading.error = true
        _this.loading.response = error.response
      }
    },

    callRooms () {
      return new Promise((resolve, reject) => {
        this.room.loadingPage = true
        this.$http
          .get(this.$store.getters.api + '/Room', {
            params: {
              fetch: this.room.pagination.per_page,
              page: this.room.pagination.page
            }
          })
          .then(({ data }) => {
            Array.prototype.push.apply(this.room.data, data.itens)

            this.room.pagination.total = data.totalResults
            this.room.pagination.page = data.currentPage
            this.room.pagination.totalPage = Math.ceil(
              data.totalResults / this.room.pagination.per_page
            )

            this.room.loadingPage = false

            resolve()
          })
          .catch((error) => {
            this.room.loadingPage = false

            reject(error)
          })
      })
    },

    async loadAttributes () {
      const data = await this.$store.dispatch('loadAttributesSelect')

      this.attributes = data.map(({ attribute }) => ({
        attributeName: attribute.attributeName,
        attributeId: attribute.attributeId,
        subjectAttributeId: attribute.subjectAttributeId,
        data: {
          id: attribute.subjectAttributeId,
          component: {
            type: attribute.componentId,
            config: {
              readonly: false,
              required: true,
              actions: {
                insert: true,
                update: true
              }
            },
            itens: []
          }
        },
        isDiscreteValue: 0
      }))
    },

    changeFile (image) {
      this.account.profileImage = image
    },

    removeFile () {
      this.account.profileImage = null
    },

    addAttr () {
      this.attrSelecteds.push({
        attributeId: 0,
        data: {},
        value: '',
        identifier: parseInt(Math.random() * 10000, 10)
      })
    },

    selectAttr ({ attr, index }) {
      this.attrSelecteds[index] = attr
    },

    deleteAttr (index) {
      this.attrSelecteds.splice(index, 1)
    },

    addAttrValues ({ itens, index }) {
      Array.prototype.push.apply(
        this.attrSelecteds[index].data.component.itens,
        itens
      )
    },

    changeAttrValues ({ itens, index }) {
      this.attrSelecteds[index].data.component.itens = itens
    },

    selectAttrValue ({ value, index }) {
      this.attrSelecteds[index].value = value
    },

    closePage () {
      this.$store.commit('closePage', '/config/users/add')

      this.$router.push({
        name: 'config_users_list'
      })
    },

    async clearPage () {
      const confirm = this.$refs.confirm

      await confirm
        .open('Deseja realmente limpar todos os campos do formulário?', 'red')
        .then((response) => {
          confirm.loading = true

          this.account.displayName = null
          this.account.firstName = null
          this.account.lastName = null
          this.account.appName = null
          this.account.accountType = null
          this.account.email = null
          this.account.profileId = null
          this.account.profileImage = null
          this.account.sharedTalks = [{}]

          this.attrSelecteds = []

          // this.addAttr();
        })
        .catch((error) => false)

      /* */

      confirm.close()
    },

    changeProfile (room) {
      this.account.profileId = room
    },

    changeAccountType (account) {
      this.account.accountType = account
    },

    changeCompany (company) {
      this.account.companyId = company
    },

    /* */

    openAddModal () {
      if (this.validaEmail && this.account.accountType == 1) {
        this.validaDominio = true
        this.$refs.email.$el.children[1].focus()
      } else {
        this.add.modal = true
        this.add.data = this.account
      }
    },

    openModalAvatar () {
      this.modal.avatar = true
    },

    closeModalAvatar () {
      this.modal.avatar = false
    },

    /* */

    accountPost () {
      let attrSelecteds = []

      if (this.attrSelecteds.length > 0) {
        if (this.attrSelecteds[0].attributeId !== 0) {
          attrSelecteds = this.attrSelecteds.map(
            ({ attributeId, value, data }) => {
              let val = value

              if (data.component.type == 4) {
                val = value[0]
              }

              return {
                attributeId,
                value: val
              }
            }
          )
        }
      }

      this.validationEmail = false
      this.validationAppName = false
      this.add.loading = true
      this.$http
        .post(
          this.$store.getters.api + '/account/',
          {
            displayName: this.account.displayName || '',
            firstName: this.account.firstName || '',
            lastName: this.account.lastName || '',
            appName: this.account.appName,
            phoneNumber: this.account.phoneNumber,
            companyId: this.account.companyId,
            accountType: this.account.accountType,
            email: this.account.email,
            roomId: this.account.profileId,
            actorType: this.account.accountType,
            profileImage: this.account.profileImage,
            sharedTalks: this.account.sharedTalks,
            attributes: attrSelecteds
          }
        )
        .then(async ({ data }) => {
          this.add.loading = false
          if (this.account.accountType == 2) {
            this.credentials.hash = data.hash
            this.credentials.password = data.password

            const credentials = this.$refs.credentials

            this.add.modal = false
            await credentials
              .open(null, 'red', true)
              .then((response) => true)
              .catch((error) => false)
            this.closeAddModal()
          }

          this.$router.push({
            name: 'config_users_list',
            params: {
              refresh: true
            }
          })

          this.$store.commit('closePage', this.$route.fullPath)

          this.$store.dispatch('notification', {
            text: 'Usuário cadastrado com sucesso!'
          })
        })
        .catch((error) => {
          this.add.loading = false

          this.closeAddModal()

          if (error.response.status == 422) {
            if (
              error.response.data.errors[0].message ==
                'Nome do Aplicativo já cadastrado'
            ) {
              this.validationAppName = true

              this.$refs.appName.$el.$refs.input.focus()
            } else {
              this.validationEmail = true

              this.$refs.email.$refs.input.focus()
            }
          } else {
            this.$store.dispatch('notification', {
              type: 'error',
              text: 'Ocorreu um erro ao cadastrar o usuário.',
              error
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" module>
  /* */

  #id {
    &_form,
    &_firstName,
    &_lastName,
    &_displayName,
    &_appName,
    &_email,
    &_accountType,
    &_companyId,
    &_submit,
    &_roomId,
    &_cancel,
    &_clear {
      text-decoration: none;
    }
  }

  .loading {
    min-height: calc(100vh - 65px) !important;
  }

  .padding {
    padding: $theme-padding * 4 $theme-padding * 5 $theme-padding * 14;
    min-height: calc(100vh - 135px);
  }

  .credentials {
    margin-bottom: $theme-padding * 2;

    &__title {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(22);
      font-weight: 500;
      color: $md-grey-500;

      &__icon {
        $size: 50px;

        margin-right: $theme-padding;

        width: $size;
        height: $size;

        fill: $md-amber-500;
      }
    }

    &__text {
      margin: $theme-padding * 1.5 0 $theme-padding * 2;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(14);
      color: $md-grey-900;

      :global(.nightlymode) & {
        color: white;
      }
    }

    &__label {
      width: 100%;

      margin-bottom: math.div($theme-padding, 2);

      font-family: 'Raleway', sans-serif;
      @include font-sizer(14);
      color: $md-grey-900;

      :global(.nightlymode) & {
        color: white;
      }
    }

    &__input {
      width: 100%;

      padding: $theme-padding;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(14);
      color: $md-grey-900;

      background: white;

      border-radius: 5px;
      border: 2px solid $md-grey-300;

      cursor: pointer;

      :global(.nightlymode) & {
        background: $md-grey-900;
        color: white;

        border-color: $md-grey-700;
      }

      &--copied {
        animation: borderColor 2s ease;

        @keyframes borderColor {
          from {
            border-color: $md-green-500;
          }

          to {
            border-color: $md-grey-300;
          }
        }

        :global(.nightlymode) & {
          animation: borderColorNight 2s ease;

          @keyframes borderColorNight {
            from {
              border-color: $md-green-500;
            }

            to {
              border-color: $md-grey-700;
            }
          }
        }
      }

      &__wrapper {
        position: relative;

        width: 100%;

        &__copied {
          &::before {
            content: 'Copiado!';

            position: absolute;

            top: -25px;
            right: 0;

            padding: 3px 10px;

            font-family: 'Raleway', sans-serif;
            @include font-sizer(11);
            font-weight: 500;
            color: white;

            background: $md-green-500;

            border-radius: 10px;

            opacity: 0;

            animation: opacity 2s ease;

            @keyframes opacity {
              from {
                opacity: 1;
                transform: translateY(0);
              }

              to {
                opacity: 0;
                transform: translateY(-10px);
              }
            }
          }
        }
      }
    }

    &__password {
      display: flex;
      align-items: center;
    }

    &__icon {
      fill: $md-grey-700;

      cursor: pointer;

      transition: 0.2s ease;
      transition-property: fill;

      &:hover {
        fill: $md-blue-500;
      }
    }

    &__icons {
      margin-left: -($theme-padding + 24);
    }
  }

  .user {
    &__avatar {
      position: relative;

      padding-bottom: 100%;
      margin-bottom: $theme-padding * 2;

      background: $md-grey-200;
      background-size: cover;

      border-radius: 100%;

      overflow: hidden;

      &__change {
        position: absolute;

        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &__icon {
          $size: 50px;

          width: $size;
          height: $size;

          fill: $md-grey-400;
        }
      }
    }

    &__name {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(20);
      font-weight: 500;
      color: black;
      text-align: center;
    }

    &__email {
      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      font-style: italic;
      color: $md-grey-700;
      text-align: center;
    }
  }

  .errormail {
    padding: 7.5px;

    position: absolute;
    bottom: 0;
    right: 25px;

    background: $md-red-500;
    color: #fff;
    border-radius: 5px;
    @include font-sizer(11);
  }
</style>
