<template>
  <div :class="$style.column">
    <div :class="[$style.columnDraggable, 'draggable-handler']">
      <app-icon glyph="drag_horizontal" />
    </div>

    <app-tooltip
      label="Remover coluna"
      left
      :class="$style.columnClose"
      @click="removeColum"
    >
      <app-icon
        glyph="delete"
        :size="20"
        :class="$style.columnCloseIcon"
      />
    </app-tooltip>

    <div class="row no-gutters" style="min-height: 55px">
      <div class="col-12">
        <app-input
          ref="targetInput"
          type="text"
          required
          small
          placeholder="Nome da coluna"
          :readonly="readonly"
          :value="name"
          @update:value="changeName"
        />
      </div>
    </div>

    <div class="row no-gutters" style="min-height: 70px">
      <h3 :class="$style.columnAttributesTitle">
        Largura da coluna: {{ width }}
      </h3>

      <div class="col d-flex">
        <input
          type="range"
          name="width_column"
          min="25"
          max="200"
          step="5"
          :class="[
            $style.columnAttributesWidth,
            readonly ? $style.columnAttributesWidthDisabled : null,
          ]"
          :value="width"
          @input="changeWidth"
        />
      </div>

      <div class="col-auto">
        <app-tooltip
          label="Cor de fundo"
          :id="'tooltipColor' + index"
          :class="[
            $style.columnColor,
            readonly ? $style.columnColorDisabled : null,
          ]"
          @click="toggleBgColor"
        >
          <svg viewBox="0 0 32 32" :id="'svgColor' + index">
            <path
              :class="$style.columnColorIcon"
              d="M22.080 11.92l-10.987-10.987c-0.52-0.52-1.36-0.52-1.88 0v0c-0.52 0.52-0.52 1.36 0 1.88l2.24 2.24-6.867 6.867c-0.787 0.787-0.787 2.053 0 2.827l7.333 7.333c0.387 0.387 0.907 0.587 1.413 0.587s1.027-0.2 1.413-0.587l7.333-7.333c0.787-0.773 0.787-2.040 0-2.827zM6.947 13.333l6.387-6.387 6.387 6.387h-12.773zM25.333 15.333c0 0-2.667 2.893-2.667 4.667 0 1.467 1.2 2.667 2.667 2.667s2.667-1.2 2.667-2.667c0-1.773-2.667-4.667-2.667-4.667z"
            ></path>
            <path
              :class="$style.columnColorBar"
              :style="{ fill: bgColor }"
              d="M2.667 26.667h26.667c1.467 0 2.667 1.2 2.667 2.667v0c0 1.467-1.2 2.667-2.667 2.667h-26.667c-1.467 0-2.667-1.2-2.667-2.667v0c0-1.467 1.2-2.667 2.667-2.667z"
            ></path>
          </svg>
        </app-tooltip>

        <div
          v-if="openBgColor"
          ref="targetBgColor"
          :class="$style.columnColorPicker"
        >
          <color-picker
            defaultColor="#ffffff"
            :selected="bgColor"
            @preview="previewBgColor"
            @select="selectBgColor"
          />
        </div>
      </div>

      <div class="col-auto">
        <app-tooltip
          label="Cor da fonte"
          :class="[
            $style.columnColor,
            readonly ? $style.columnColorDisabled : null,
          ]"
          :id="'tooltipColor' + index"
          @click="toggleFontColor"
        >
          <svg viewBox="0 0 32 32" :id="'svgColor' + index">
            <path
              :class="$style.columnColorIcon"
              d="M14.173 5.24l-6.093 15.533c-0.36 0.907 0.307 1.893 1.293 1.893v0c0.573 0 1.093-0.36 1.307-0.907l1.147-3.093h8.333l1.16 3.093c0.2 0.547 0.72 0.907 1.307 0.907v0c0.973 0 1.653-0.987 1.293-1.893l-6.093-15.533c-0.307-0.747-1.027-1.24-1.827-1.24v0c-0.8 0-1.533 0.493-1.827 1.24zM12.827 16l3.173-8.44 3.173 8.44h-6.347z"
            ></path>
            <path
              :class="$style.columnColorBar"
              :style="{ fill: fontColor }"
              d="M2.667 26.667h26.667c1.467 0 2.667 1.2 2.667 2.667v0c0 1.467-1.2 2.667-2.667 2.667h-26.667c-1.467 0-2.667-1.2-2.667-2.667v0c0-1.467 1.2-2.667 2.667-2.667z"
            ></path>
          </svg>
        </app-tooltip>

        <div
          v-if="openFontColor"
          ref="targetFontColor"
          :class="$style.columnColorPicker"
        >
          <color-picker
            defaultColor="#000000"
            :selected="fontColor"
            @preview="previewFontColor"
            @select="selectFontColor"
          />
        </div>
      </div>
    </div>

    <transition name="fade-down">
      <div
        v-if="lockedValues"
        :class="$style.columnAttributes"
      >
        <app-input-label
          :class="$style.columnAttributesTitle"
          tooltip="Digite <strong>${</strong> para adicionar campos"
        >
          Valores:
        </app-input-label>

        <div v-if="data.attributes.length">
          <template v-for="(item, key) in data.attributes" :key="key">
            <grid-columns-attributes
              :data="item"
              :columnIndex="props.index"
              :subjectList="subjectList"
              :focus="data.focus"
              @change-attr="changeAttr(key, $event)"
              @change-name="changeNameFromAttribute"
            />
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { onClickOutside, onKeyStroke } from '@vueuse/core'
import ColorPicker from '@/components/colorPicker/ColorPicker.vue'
import GridColumnsAttributes from '@/components/GridColumnsAttributes.vue'

const props = defineProps({
  data: Object,
  index: Number,
  subjectList: Array,
  readonly: Boolean
})

const emit = defineEmits([
  'select-bg-color',
  'select-bg-color',
  'select-font-color',
  'preview-bg-color',
  'preview-font-color',
  'remove-column',
  'change-name',
  'change-width',
  'change-attr',
  'change-subject'
])

const store = useStore()

const targetBgColor = ref(null)
const openBgColor = ref(false)
const targetFontColor = ref(null)
const openFontColor = ref(false)
const targetInput = ref(null)

const lockedValues = computed(() => {
  return props.subjectList[0] &&
    props.subjectList[0].subjectId !== 999999
})

const nightMode = computed(() => {
  return store.getters.nightlyMode
})

const bgColor = computed(() => {
  return props.data.previewBgColor
    ? props.data.previewBgColor
    : props.data.configs.filter(
      (item) => item.configIdentifier.toLowerCase() == 'backgroundcolor'
    )[0].value
})

const fontColor = computed(() => {
  return props.data.previewFontColor
    ? props.data.previewFontColor
    : props.data.configs.filter(
      (item) => item.configIdentifier.toLowerCase() == 'fontcolor'
    )[0].value
})

const name = computed(() => {
  return props.data.configs.filter(
    (item) => item.configIdentifier.toLowerCase() == 'name'
  )[0].value
})

const width = computed(() => {
  return props.data.configs.filter(
    (item) => item.configIdentifier.toLowerCase() == 'width'
  )[0].value
})

onClickOutside(targetBgColor, () => closeBgColor())

onClickOutside(targetFontColor, () => closeFontColor())

onKeyStroke('Escape', () => {
  closeBgColor()
  closeFontColor()
}, {
  eventName: 'keydown',
  target: document
})

const closeFontColor = () => {
  openFontColor.value = false
}

const closeBgColor = () => {
  openBgColor.value = false
}

const selectBgColor = (color) => {
  emit('select-bg-color', color)
  closeBgColor()
}

const selectFontColor = (color) => {
  emit('select-font-color', color)
  closeFontColor()
}

const previewBgColor = (color) => {
  emit('preview-bg-color', color)
}

const previewFontColor = (color) => {
  emit('preview-font-color', color)
}

const toggleFontColor = () => {
  openFontColor.value = !openFontColor.value
  openBgColor.value = false
}

const toggleBgColor = () => {
  openBgColor.value = !openBgColor.value
  openFontColor.value = false
}

const removeColum = () => {
  emit('remove-column')
}

const changeName = (value) => {
  emit('change-name', value)
}

const changeNameFromAttribute = (value) => {
  if (name.value === '') {
    emit('change-name', value)
  }
}

const changeWidth = (e) => {
  const value = e.target.value

  emit('change-width', value)
}

const changeAttr = (indexAttr, data) => {
  emit('change-attr', { indexAttr, data })
}

const changeSubject = (indexAttr, id) => {
  const obj = {
    indexAttr,
    id
  }

  emit('change-subject', obj)
}
</script>

<style lang="scss" module>
.column {
  position: relative;
  padding: 0 $theme-padding $theme-padding;
  width: 300px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.05);

  :global(.nightlymode) & {
    background: rgba(0, 0, 0, 0.2);
  }

  &__draggable {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24px;
    cursor: move;

    svg {
      fill: $md-grey-600;
    }
  }

  &__close {
    position: absolute !important;
    right: 5px;
    top: -15px;
    padding: 4px;
    border-radius: 50%;
    background: $md-grey-500;
    cursor: pointer;
    transition: 0.2s;
    transition-property: background;

    :global(.nightlymode) & {
      background: $md-grey-600;
    }

    &:hover {
      background: var(--md-red-600);
    }

    &__icon {
      fill: $md-grey-100;

      :global(.nightlymode) & {
        fill: white;
      }
    }
  }

  &__color {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: math.div($theme-padding, 3);
    padding: 4px;
    width: 40px;
    height: 40px;
    border: 2px solid $md-grey-300;
    border-radius: 5px;
    cursor: pointer;

    :global(.nightlymode) & {
      border: 2px solid $md-grey-600;
    }

    &--disabled {
      pointer-events: none;
    }

    &__icon {
      fill: #616161;

      :global(.nightlymode) & {
        fill: #e0e0e0;
      }
    }

    &__bar {
      stroke: #000;
      stroke-width: 0.6px;
      stroke-opacity: 0.5;
    }

    &__picker {
      position: absolute;
      top: 100%;
      left: 10px;
      z-index: 99999;
      padding: $theme-padding;
      border-radius: 5px;
      background: $md-grey-900;

      &::before {
        content: '';
        position: absolute;
        top: -20px;
        left: 15px;
        margin-left: -10px;
        border: 10px solid transparent;
        border-bottom-color: $md-grey-900;
      }

      :global(.nightlymode) & {
        background: $md-grey-700;

        &::before {
          border-bottom-color: $md-grey-700;
        }
      }
    }
  }

  &__attributes {
    padding-top: 10px;

    &__title {
      margin-bottom: math.div($theme-padding, 2);
      padding: 0;
      width: 100%;
      color: $md-grey-800 !important;
      font-size: 1.4rem;
      font-family: 'Roboto', sans-serif !important;
      font-weight: 600 !important;

      :global(.nightlymode) & {
        color: #fff !important;
      }
    }

    &__width {
      flex: 0.9;

      &--disabled {
        pointer-events: none;
      }
    }
  }
}
</style>
