import dayjs from 'dayjs'

export function setRulesAttributes (
  subject,
  attributes,
  originalValues,
  talkParent,
  store
) {
  const action = {
    chamado: () => setRulesAttributesChamado(attributes, originalValues, store),
    tarefa: () => setRulesAttributesTarefa(
      attributes,
      originalValues.length > 0 ? 'update' : 'insert',
      talkParent,
      store
    ),
    cliente: () => setRulesAttributesCliente(attributes)
  }

  return action[subject] ? action[subject]() : attributes
}

function setRulesAttributesChamado (attributes, originalValues, store) {
  return attributes.map((item) => {
    const attr = item

    // tipo de perfil que seja diferente de admin e cliente para
    // aplicar regras específicas aos perfis de atendimento ao cliente
    const isProfileTypeAtendimento = store.getters.user.data.roomId !== '1' && store.getters.user.data.roomId !== '1196'

    const config = {
      titulo: () => {
        attr.component.config.minWords = 2
      },
      cliente: () => {
        if (store.getters.user.data.customer) {
          attr.component.config.defaultValue = store.getters.user.data.customer
        }
        if (store.getters.user.data.roomId === '1196') {
          attr.component.config.readonly = true
        }
      },
      solicitante: () => {
        if (store.getters.user.data.customer) {
          const id = parseInt(store.getters.user.data.accountId, 10)
          const { name } = store.getters.user.data
          attr.component.config.defaultValue = { id, name }
        }
        if (store.getters.user.data.roomId === '1196') {
          attr.component.config.readonly = true
        }
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'cliente',
                type: 'filled'
              }
            ],
            config: 'paramForSelectAPI',
            resetValue: null
          }
        ]
      },
      telefone: () => {
        if (store.getters.user.data.customer) {
          attr.component.config.defaultValue =
            store.getters.user.data.phoneNumber
        }
        attr.component.config.readonly = true
        attr.component.config.tooltip = 'Para editar seu telefone clique na foto no topo direito da página e selecione o menu "Informações do usuário"'
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'solicitante',
                type: 'filled'
              }
            ],
            config: 'paramForInputAPI',
            api: '/account/#idSolicitante',
            resetValue: ''
          }
        ]
      },
      statusdochamado: () => {
        if (!originalValues.length) attr.component.config.readonly = true
        else {
          attr.component.config.params = [
            {
              identifier: 'statusId',
              value: originalValues[0].values[attr.id].value.id
            }
          ]

          if (store.getters.user.data.roomId === '1196') {
            if (originalValues[0].values[attr.id].value.name !== 'Concluído, aguardando confirmação do cliente') {
              attr.component.config.readonly = true
            } else {
              const responsavelPelaEtapa = originalValues[0].values.find((value) => value?.subjectAttributeIdentifier === 'Responsavelpelaetapa')
              if (responsavelPelaEtapa?.value?.id) {
                attr.component.config.params = [
                  ...attr.component.config.params,
                  {
                    identifier: 'accountIdResponsible',
                    value: responsavelPelaEtapa.value.id
                  }
                ]
              }
              attr.component.config.warning = 'Para concluir o chamado, altere o status para "Concluído"'
            }
          }
        }
      },
      prioridade: () => {
        if (originalValues.length && isProfileTypeAtendimento) attr.component.config.required = true
      },
      tipodechamado: () => {
        if (originalValues.length && isProfileTypeAtendimento) attr.component.config.required = true
      },
      software: () => {
        if (originalValues.length && isProfileTypeAtendimento) attr.component.config.required = true
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'cliente',
                type: 'filled'
              }
            ],
            config: 'paramForSelectAPI',
            resetValue: null
          }
        ]
      },
      categoria: () => {
        if (originalValues.length && isProfileTypeAtendimento) attr.component.config.required = true
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'software',
                type: 'filled'
              }
            ],
            config: 'paramForSelectAPI',
            resetValue: null
          }
        ]
      },
      subcategoria: () => {
        if (originalValues.length && isProfileTypeAtendimento) attr.component.config.required = true
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'categoria',
                type: 'filled'
              }
            ],
            config: 'paramForSelectAPI',
            resetValue: null
          }
        ]
      },
      diagnostico: () => {
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'statusdochamado',
                type: 'includes',
                value: [
                  'cancelado',
                  'concluído',
                  'finalizado',
                  'concluído, aguardando confirmação do cliente',
                  'proposta comercial recusada'
                ]
              }
            ],
            config: 'isRequired'
          }
        ]
      },
      detalhessobreodiagnostico: () => {
        attr.component.config.minWords = 2
        attr.component.config.minChars = 20
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'statusdochamado',
                type: 'includes',
                value: [
                  'cancelado',
                  'concluído',
                  'finalizado',
                  'concluído, aguardando confirmação do cliente',
                  'proposta comercial recusada'
                ]
              }
            ],
            config: 'isRequired'
          }
        ]
      },
      ofensor: () => {
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'statusdochamado',
                type: 'includes',
                value: [
                  'cancelado',
                  'concluído',
                  'finalizado',
                  'concluído, aguardando confirmação do cliente',
                  'proposta comercial recusada'
                ]
              }
            ],
            config: 'isRequired'
          }
        ]
      },
      previsaodeconclusaodaetapa: () => {
        attr.component.config.componentConfigs = {
          daysNotGreaterThanToday: true
        }
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'statusdochamado',
                type: 'startsWith',
                value: 'em'
              }
            ],
            config: 'isRequired'
          },
          {
            conditions: [
              {
                attribute: 'statusdochamado',
                type: 'modified'
              }
            ],
            config: 'resetValue',
            resetValue: ''
          },
          {
            conditions: [
              {
                attribute: 'previsaodeconclusaodochamado',
                type: 'daysGreaterThan'
              }
            ],
            config: 'daysGreaterThan'
          }
        ]
      },
      previsaodeconclusaodochamado: () => {
        attr.component.config.componentConfigs = {
          daysNotGreaterThanToday: true
        }
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'statusdochamado',
                type: 'includes',
                value: [
                  'aguardando desenvolvimento'
                ]
              }
            ],
            config: 'isRequired'
          },
          {
            conditions: [
              {
                attribute: 'datadeaberturadochamado',
                type: 'daysNotGreaterThan'
              }
            ],
            config: 'daysNotGreaterThan'
          }
        ]
      },
      conclusao: () => {
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'statusdochamado',
                type: 'startsWith',
                value: 'aguardando'
              }
            ],
            config: 'resetValue',
            resetValue: ''
          }
        ]
      },
      motivodealteracao: () => {
        attr.component.config.dependsOn = [
          {
            conditions: [
              [
                {
                  attribute: 'previsaodeconclusaodaetapa',
                  type: 'modifiedInFilled'
                },
                'and',
                {
                  attribute: 'statusdochamado',
                  type: 'notModified'
                }
              ],
              'or',
              {
                attribute: 'previsaodeconclusaodochamado',
                type: 'modifiedInFilled'
              }
            ],
            config: 'isRequired'
          }
        ]
      },
      idchamadoretrabalho: () => {
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'retrabalho',
                type: 'equal',
                value: true
              }
            ],
            config: 'isRequired'
          },
          {
            conditions: [
              {
                attribute: 'retrabalho',
                type: 'equal',
                value: true
              }
            ],
            config: 'isBlocked',
            resetValue: null
          }
        ]
      }
    }

    if (config[attr.identifier.toLowerCase()]) {
      config[attr.identifier.toLowerCase()]()
    }

    return attr
  })
}

function setRulesAttributesTarefa (attributes, type, talkParent, store) {
  return type === 'insert'
    ? setRulesAttributesTarefaInsert(attributes, store)
    : setRulesAttributesTarefaUpdate(attributes, store)
}

function setRulesAttributesTarefaInsert (attributes, store) {
  const filteredAttributes = []

  attributes.forEach((item) => {
    const attr = item
    // Campos do formulário de criação.
    if (
      [
        'titulo',
        'tipodeatividade',
        'talkid',
        'responsavel',
        'centrodecusto',
        'previsaodeinicio',
        'previsaodetermino',
        'duracaoestimada',
        'observacao',
        'statustarefa'
      ].includes(attr.identifier.toLowerCase())
    ) {
      if (attr.identifier.toLowerCase() === 'responsavel') {
        // Define valor de campo Responsável automaticamente.
        const user = store.getters.user.data
        const responsavel = {
          id: user.accountId,
          name: `${user.name} <${user.email}>`
        }
        attr.component.config.defaultValue = responsavel
      } else if (attr.identifier.toLowerCase() === 'previsaodeinicio') {
        // Adiciona regra para que previsão de início não possa ser maior que previsão de término.
        attr.component.config.componentConfigs = {
          daysNotGreaterThanToday: true
        }
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'previsaodetermino',
                type: 'daysGreaterThan'
              }
            ],
            config: 'daysGreaterThan'
          }
        ]
      } else if (attr.identifier.toLowerCase() === 'previsaodetermino') {
        attr.component.config.componentConfigs = {
          daysNotGreaterThanToday: true
        }
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'previsaodeinicio',
                type: 'daysNotGreaterThan'
              }
            ],
            config: 'daysNotGreaterThan'
          }
        ]
      } else if (attr.identifier.toLowerCase() === 'duracaoestimada') {
        attr.component.config.dependsOn = [
          {
            conditions: [
              [
                {
                  attribute: 'previsaodetermino',
                  type: 'filled'
                },
                'and',
                {
                  attribute: 'previsaodeinicio',
                  type: 'filled'
                }
              ],
              'and',
              {
                attribute: 'duracaoestimada',
                type: 'filled'
              }
            ],
            config: 'inBetween'
          }
        ]
      }

      filteredAttributes.push(attr)
    }
  })

  return filteredAttributes
}

function setRulesAttributesTarefaUpdate (attributes, store) {
  const filteredAttributes = []
  // Formulário de edição varia de acordo com essa variável.
  const { isAdmin } = store.getters.user.data

  attributes.forEach((item) => {
    const attr = item
    // Campos de formulário de edição.
    if (
      [
        'titulo',
        'tipodeatividade',
        'talkid',
        'responsavel',
        'centrodecusto',
        'previsaodeinicio',
        'inicio',
        'previsaodetermino',
        'termino',
        'duracaoestimada',
        'duracaototal',
        'motivodeatrasodaestimativa',
        'motivodeatrasodoprazo',
        'observacao',
        'statustarefa',
        'insertedbyaccountid',
        'creationdate',
        'updatedbyaccountid',
        'updatedin'
      ].includes(attr.identifier.toLowerCase())
    ) {
      // Atributos que serão definidos como somente leitura posteriormente
      // (independente do valor de "isAdmin").
      let attrsToBlock = [
        'inicio',
        'termino',
        'duracaototal',
        'insertedbyaccountid',
        'creationdate',
        'updatedbyaccountid',
        'updatedin'
      ]

      if (!isAdmin) {
        // Caso isAdmin seja falso, os campos 'previsaodeinicio', 'previsaodetermino',
        // 'duracaoestimada', 'statustarefa' são adicionados a "attrsToBlock".
        attrsToBlock = [
          ...attrsToBlock,
          'previsaodeinicio',
          'previsaodetermino',
          'duracaoestimada',
          'statustarefa'
        ]
        if (
          ['motivodeatrasodaestimativa', 'motivodeatrasodoprazo'].includes(
            attr.identifier.toLowerCase()
          )
        ) {
          // Além disso, os campos de motivo de alteração são configurados
          // para não aparecer no formulário.
          attr.component.config.isHidden = true
        }
      } else if (attr.identifier.toLowerCase() === 'previsaodeinicio') {
        // Caso isAdmin seja true, os campos de previsão e duração estimada poderão ser alterados.
        // Neste caso é necessário adicionar regra para que previsão de início não
        // seja maior que previsão de término.
        attr.component.config.componentConfigs = {
          daysNotGreaterThanToday: true
        }
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'previsaodetermino',
                type: 'daysGreaterThan'
              }
            ],
            config: 'daysGreaterThan'
          }
        ]
      } else if (attr.identifier.toLowerCase() === 'previsaodetermino') {
        attr.component.config.componentConfigs = {
          daysNotGreaterThanToday: true
        }
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'previsaodeinicio',
                type: 'daysNotGreaterThan'
              }
            ],
            config: 'daysNotGreaterThan'
          }
        ]
      } else if (attr.identifier.toLowerCase() === 'duracaoestimada') {
        attr.component.config.dependsOn = [
          {
            conditions: [
              [
                {
                  attribute: 'previsaodetermino',
                  type: 'filled'
                },
                'and',
                {
                  attribute: 'previsaodeinicio',
                  type: 'filled'
                }
              ],
              'and',
              {
                attribute: 'duracaoestimada',
                type: 'filled'
              }
            ],
            config: 'inBetween'
          }
        ]
      }

      if (attrsToBlock.includes(attr.identifier.toLowerCase())) {
        // Os atributos salvos no array attrsToBlock são configurados para serem somente leitura.
        attr.component.config.readonly = true
      }

      filteredAttributes.push(attr)
    }
  })

  return filteredAttributes
}

function setRulesAttributesCliente (attributes) {
  return attributes.map((item) => {
    const attr = item
    const config = {
      nome: () => {
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'tipodecliente',
                type: 'filled'
              }
            ],
            config: 'label',
            replaces: { 'pessoa jurídica': 'Razão Social' },
            originalName: attr.name
          }
        ]
      },

      identificacao: () => {
        attr.component.config.dependsOn = [
          {
            conditions: [
              {
                attribute: 'tipodecliente',
                type: 'filled'
              }
            ],
            config: 'label',
            replaces: {
              'pessoa física': 'CPF',
              'pessoa jurídica': 'CNPJ'
            },
            originalName: attr.name
          },
          {
            conditions: [
              {
                attribute: 'tipodecliente',
                type: 'filled'
              }
            ],
            config: 'resetValue',
            resetValue: ''
          }
        ]
      }
    }

    if (config[attr.identifier.toLowerCase()]) { config[attr.identifier.toLowerCase()]() }

    return attr
  })
}

export function getFieldsToObserve (array, attributes, subject) {
  const fields = {}
  const getFieldsBySubject = {
    chamado: () => [
      'cliente',
      'solicitante',
      'software',
      'categoria',
      'statusdochamado',
      'previsaodeconclusaodaetapa',
      'previsaodeconclusaodochamado',
      'datadeaberturadochamado',
      'retrabalho'
    ],
    tarefa: () => ['previsaodetermino', 'previsaodeinicio', 'duracaoestimada'],
    cliente: () => ['tipodecliente']
  }

  if (getFieldsBySubject[subject]) {
    attributes.forEach((x, i) => {
      if (getFieldsBySubject[subject]().includes(x.identifier.toLowerCase())) {
        fields[x.identifier.toLowerCase()] = Array.isArray(array[x.id])
          ? array[x.id][0]
          : array[x.id]
      }
    })
  }
  return fields
}

export function verifyCondition (
  conditions,
  values,
  originalValues,
  attributes
) {
  const results = []

  conditions.forEach((condition, index) => {
    if (Array.isArray(condition)) {
      results.push(
        verifyCondition(condition, values, originalValues, attributes)
      )
    } else if (index % 2 === 0) {
      const attr = attributes.find(
        (x) => x.identifier.toLowerCase() === condition.attribute
      )

      let originalValue = originalValues[attr.id]?.value || null
      let value = values[condition.attribute] || null

      if ([11, 32].includes(attr.component.type)) {
        originalValue = originalValue === null
          ? originalValue
          : transformDate(
            originalValue,
            getTransformedType(attr.component.type)
          )
        value = value ? transformDate(value, getTransformedType(attr.component.type)) : value
      }

      const action = {
        filled: () => !!value,
        includes: () => verifyIfIncludes(value, condition.value),
        equal: () => (
          JSON.stringify(JSON.parse(value)) === JSON.stringify(JSON.parse(condition.value))
        ),
        startsWith: () => verifyIfStartsWith(value, condition.value),
        modified: () => (
          originalValue
            ? JSON.stringify(value) !== JSON.stringify(originalValue)
            : false
        ),
        modifiedInFilled: () => (
          originalValue
            ? JSON.stringify(value) !== JSON.stringify(originalValue) && originalValue !== null
            : false
        ),
        notModified: () => (
          originalValue
            ? JSON.stringify(value) === JSON.stringify(originalValue)
            : true
        ),
        daysGreaterThan: () => true,
        daysNotGreaterThan: () => true
      }

      results.push(action[condition.type]())
    }
  })

  return conditions[1] === 'or'
    ? results.some((x) => x)
    : results.every((x) => x)
}

function verifyIfStartsWith (value, startsWith) {
  if (!value) return false // vazio

  const arrayValue = !Array.isArray(value) ? [value] : value // normaliza dado

  return arrayValue.some((x) => {
    const text = x?.name || x // se for select terá .name
    return typeof text === 'string'
      ? text.toLowerCase().startsWith(startsWith)
      : false
  })
}

function verifyIfIncludes (value, params) {
  if (!value) return false // vazio

  const arrayValue = !Array.isArray(value) ? [value] : value // normaliza dado

  return arrayValue.some((x) => {
    const text = x?.name || x // se for select terá .name
    return typeof text === 'string'
      ? params.includes(text.toLowerCase())
      : false
  })
}

function transformDate (value, type) {
  return dayjs(value).format(
    type === 'date' ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm:ss'
  )
}

function getTransformedType (type) {
  return type === 11 ? 'datetime' : 'date' // fazer para todos os tipos no futuro
}
