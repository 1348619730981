import { reactive, onMounted, computed } from 'vue'

const permissions = reactive({
  task: null,
  appointment: null,
  loading: false
})

const setPermissions = async (store) => {
  if (!permissions.task && !permissions.loading) {
    try {
      // permissions.loading = true
      // const data = await store.dispatch(
      //   'loadSubjectPermissions',
      //   ['Tarefa', 'Apontamento']
      // )
      // permissions.task = data
      //   .find(({ subjectIdentifier }) => subjectIdentifier === 'tarefa')?.actions
      // permissions.appointment = data
      //   .find(({ subjectIdentifier }) => subjectIdentifier === 'apontamento')?.actions

      // permissão default para desabilitar tasks temporariamente substituindo a requisicao acima
      const permissionsDenied = {
        create: false,
        deactivate: false,
        delete: false,
        read: false,
        update: false
      }
      permissions.task = permissionsDenied
      permissions.appointment = permissionsDenied
    } catch (e) {
      console.log(e)
    } finally {
      permissions.loading = false
    }
  }
}

export default function useTasks (store) {
  onMounted(async () => {
    await setPermissions(store)
  })

  const getTaskPermissions = computed(() => permissions.task)
  const getAppointmentPermissions = computed(() => permissions.appointment)

  return { getTaskPermissions, getAppointmentPermissions }
}
