<template>
  <app-config>
    <app-view>
      <app-confirm-promise ref="confirm" />
      <!-- GLOBAL-ENVENTS -->
      <app-box>
        <app-loader
          v-if="loading.active || loading.error"
          :error="loading.error"
          :response="loading.response"
          key="loading"
          :active="loading.active"
          v-on:reload="init()"
        />

        <div class="row no-gutters" v-else>
          <div class="col-12">
            <div :class="$style.scroller">
              <p :class="$style.title">
                {{ pageHeader ? pageHeader.name : 'Notificações' }}
              </p>

              <div class="row mt-5 justify-content-end">
                <div class="col">
                  <app-button
                    small
                    v-on:click="notificationPage"
                    color="blue"
                    light
                    :label="pageHeader ? pageHeader.btnAdd : 'Novo'"
                    icon="add"
                  />
                </div>

                <div class="col-4 mb-5" :class="$style.search">
                  <transition name="fade">
                    <app-input
                      small
                      :maxlength="25"
                      v-model:value="termSearchNotification"
                      :class="$style.searchInput"
                      v-if="activeSearch || termSearchNotification.length"
                      v-on:keypress.enter="callNOtifications(true)"
                    />
                  </transition>

                  <app-icon
                    glyph="close"
                    :class="[$style.searchIcon, $style.searchIconClose]"
                    v-on:click="cleanSearch"
                    v-if="termSearchNotification.length"
                  />
                  <app-icon
                    glyph="search"
                    :class="$style.searchIcon"
                    v-on:click="toggleSearch()"
                  />
                </div>
                <!-- TRANSITION -->
              </div>

              <app-loader
                v-if="loadingSearch.active || loadingSearch.error"
                :error="loadingSearch.error"
                :response="loadingSearch.response"
                key="loading"
                :active="loadingSearch.active"
                v-on:reload="init()"
              />

              <div v-if="!loadingSearch.active && !loadingSearch.error">
                <div :class="$style.list" class="mt-5">
                  <p
                    v-if="notificationsFilter.length == 0"
                    :class="$style.listEmpty"
                  >
                    Nenhuma notificação encontrado
                  </p>

                  <div
                    class="row no-gutters mb-4"
                    :class="[$style.listItem]"
                    v-for="(notification, i) in notifications"
                    :key="i"
                  >
                    <div
                      class="col"
                      :class="$style.listItemName"
                      v-on:click="selectNotification(notification)"
                    >
                      {{ notification.notificationName }}
                    </div>

                    <div class="col-auto">
                      <!-- <app-loader-spinner /> -->

                      <app-icon
                        glyph="delete"
                        v-on:click="
                          removeNotification(notification.notificationId)
                        "
                        :class="$style.listItemDelete"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- CRIAR PAGINAÇÃO -->
              <div
                class="row justify-content-center mt-5"
                v-if="notifications.length"
              >
                <div class="col-auto">
                  <app-pagination
                    :length="pagination.total"
                    :current-page="pagination.page"
                    :per-page="pagination.perPage"
                    :option-per-page="pagination.optionPerPage"
                    v-on:change-page="changePage"
                    v-on:change-per-page="changePerPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-box>
    </app-view>
  </app-config>
</template>

<script>
import AppConfig from '@/views/config/Index.vue'

export default {
  components: {
    AppConfig
  },

  data () {
    return {
      termSearchNotification: '',
      activeSearch: false,

      notifications: [],

      // loadingRemove = false,

      loading: {
        active: false,
        error: false
      },

      loadingSearch: {
        active: false,
        error: false,
        done: false
      },

      pagination: {
        total: 0,
        page: 1,
        perPage: 10,
        optionPerPage: [10, 25, 50, 100, 200, 500]
      }
    }
  },

  computed: {
    notificationsFilter () {
      return this.notifications
    },

    pageHeader () {
      return {
        name: 'Notificações',
        btnAdd: 'Nova notificação'
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    // #region SEARCH NOTIFICATION
    cleanSearch () {
      this.termSearchNotification = ''

      this.activeSearch = false
      this.pagination.page = 1

      this.init()
    },
    toggleSearch () {
      if (this.termSearchNotification == '') {
        this.activeSearch = !this.activeSearch
      } else {
        this.init()
      }
    },
    // #endregion

    // #region ROUTER
    notificationPage () {
      this.$router.push({
        name: 'notificationPage'
      })
    },
    // notificationId() {
    //   this.$router.push({
    //     name: 'notification',
    //     params: {
    //       type: this.routerParams.type,
    //     },
    //   })
    // },
    // #endregion

    // #region INIT
    async init () {
      this.callNOtifications()
    },
    // #endregion

    // #region NOTIFICATIONS
    callNOtifications (search) {
      this.loading.active = true

      if (search) {
        this.pagination.page = 1
      }

      this.$http
        .get(
          `${this.$store.getters.api}/notification?page=${
            this.pagination.page
          }&fetch=${this.pagination.perPage}${
            this.termSearchNotification
              ? '&NotificationName=' + this.termSearchNotification
              : ''
          }`
        )
        .then((response) => {
          const notifications = response.data.itens

          notifications.map((notification, index) => {
            notification.labelTitle = notification.notificationName
          })
          this.pagination.total = response.data.totalResults
          this.notifications = notifications

          this.loading.active = false
        })
    },

    selectNotification (notification) {
      this.$router.push({
        name: 'notificationPage',
        params: {
          notificationId: notification.notificationId
        }
      })
    },

    async removeNotification (notificationId) {
      const confirm = this.$refs.confirm
      await confirm
        .open('Deseja realmente excluir essa notificação?', 'red')
        .then((response) => {
          this.$http
            .delete(`${this.$store.getters.api}/notification`, {
              data: { notificationId: notificationId }
            })
            .then(({ data }) => {
              const index = this.notifications.findIndex(
                (x) => x.notificationId == notificationId
              )

              this.notifications.splice(index, 1)

              this.pagination.total--

              if (this.notifications.length == 0 && this.pagination.page > 1) {
                this.changePage(this.pagination.page - 1)
              }

              this.$store.dispatch('notification', {
                text: 'Notificação removida com sucesso!',
                color: 'green'
              })
            })
            .catch((error) => {
              this.$store.dispatch('notification', {
                type: error.response.status === 422 ? 'alert' : 'error',
                text:
                  error.response.status === 422
                    ? 'Existem automações associadas à esta notificação!'
                    : 'Ocorreu um erro ao remover a notificação. Por favor, tente novamente.',
                error: true
              })
            })
        })
        .catch((error) => false)
      confirm.close()
    },
    // #endregion

    // #region PAGINATION
    changePage (value) {
      this.pagination.page = value

      this.init()
    },

    changePerPage (value) {
      this.pagination.perPage = value

      this.changePage(1)
    }
    // #endregion
  },

  watch: {
    $route (to, from) {
      if (to.params.refresh) this.init()
    }
  }
}
</script>

<style lang="scss" module>
.loader {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -15px;
}

.search {
  position: relative;

  &__input {
    position: absolute !important;
    right: 20px;

    width: 100%;

    z-index: 1;
  }

  &__icon {
    position: absolute;
    right: 27px;
    top: 7px;

    fill: black;
    cursor: pointer;

    z-index: 2;

    :global(.nightlymode) & {
      fill: white;
    }

    &--close {
      width: 18px;
      height: 18px;

      top: 10px;
      right: 27px * 2;
    }
  }
}

.scroller {
  min-width: 100%;
  overflow: auto;

  padding: 60px 75px 120px;
  min-height: calc(100vh - 65px);

  background: $md-grey-100;

  :global(.nightlymode) & {
    background: $md-grey-900;
  }
}

.title {
  @include font-sizer(28);
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: var(--theme-color-1);
}

.list {
  &__empty {
    @include font-sizer(12);
    font-family: 'Raleway';

    color: black;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__subject {
    @include font-sizer(16);
    font-family: 'Barlow';
    text-transform: uppercase;

    color: black;

    margin-bottom: 30px;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px $theme-padding * 1.5;

    border-radius: 5px;

    background: white;

    :global(.nightlymode) & {
      background: $md-grey-800;
    }

    &--active {
      background: $md-grey-400;
      box-shadow: inset 0 0 0 2px rgba(black, 0.15);

      cursor: pointer;

      :global(.nightlymode) & {
        background: lighten($md-grey-900, 5%);
        box-shadow: inset 0 0 0 2px rgba(black, 0.15);
      }
    }

    &--highlight {
      background: rgba(0, 0, 0, 0.1) !important;

      :global(.nightlymode) & {
        background: rgba(255, 255, 255, 0.5) !important;
      }
    }

    &__name {
      min-height: 60px;
      padding: 5px 0;
      display: flex;
      align-items: center;

      @include font-sizer(15);
      font-family: 'Raleway';

      color: black;
      word-break: break-word;

      cursor: pointer;

      :global(.nightlymode) & {
        color: white;
      }

      &--active {
        color: var(--theme-color-1) !important;
        font-weight: 500;
      }
    }

    &__delete {
      fill: $md-red-500;

      cursor: pointer;
    }
  }
}

.filter {
  position: absolute;
  top: 40px;
  right: $theme-padding;

  width: 300px;
  padding: $theme-padding * 1.5;

  border-radius: 7px;
  z-index: 9;

  background: white;

  filter: drop-shadow(0 0 40px rgba(black, 0.3));

  &::before {
    content: '';

    position: absolute;
    top: -14px;
    right: 17px;
    margin-left: -14px;
    border: 7px solid transparent;
    border-bottom-color: white;
  }

  :global(.nightlymode) & {
    background: $md-grey-700;

    &::before {
      border-bottom-color: $md-grey-700;
    }
  }
}
</style>
