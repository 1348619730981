export default function getScrollableParent (el) {
  if (!el || el === document.body || el.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
    return window
  }

  if (isScrollable(el)) {
    return el
  }

  return getScrollableParent(el.parentNode)
}

const isScrollable = (el) => {
  const regex = /(auto|scroll)/
  const hasScrollableContent = el.scrollHeight > el.clientHeight

  const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop)
  const overflow = (_node) => style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x')
  const scroll = (_node) => regex.test(overflow(_node))

  return hasScrollableContent && scroll(el)
}
