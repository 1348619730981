import { createStore as _createStore } from 'vuex'
import { findIndex } from 'lodash-es'
import CryptoJS from 'crypto-js'
import hashes from '@/hashes'
import EventBus from '@/eventBus'
import axios from 'axios'
import slug from 'slug'
import moduleSubjectAttribute from './modules/moduleSubjectAttribute'
import moduleNotification from './modules/moduleNotification'
import moduleTables from './modules/moduleTables'
import moduleAttribute from './modules/moduleAttribute'
import moduleUser from './modules/moduleUser'
import moduleRoom from './modules/moduleRoom'
import moduleJob from './modules/moduleJob'
import moduleReportModel from './modules/moduleReportModel'
import moduleTag from './modules/moduleTag'
import moduleTemplate from './modules/moduleTemplate'
import modulePermissions from './modules/modulePermissions'
import moduleGridFilter from './modules/moduleGridFilter'

let api = 'https://api.talkture.com'
let apiTracking = 'https://cron.tracking.emix.space'
let apiClientCertificate = 'https://cert.talkture.com'

if (
  process.env.NODE_ENV === 'development' ||
  window.location.host.includes('dev.talkture')
) {
  api = 'https://api.test.talkture.com'
  apiTracking = 'https://tracking.emix.space'
  apiClientCertificate = 'https://cert.talkture.emix.dev'
} else if (window.location.host.includes('test.talkture')) {
  api = 'https://api.test.talkture.com'
  apiTracking = 'https://tracking.emix.space'
  apiClientCertificate = 'https://cert.talkture.emix.dev'
} else if (window.location.host.includes('staging.talkture')) {
  api = 'https://api.staging.talkture.com'
  apiTracking = 'https://tracking.emix.space'
  apiClientCertificate = 'https://cert.talkture.emix.us'
} else if (window.location.host.includes('preview.talkture')) {
  api = 'https://api.preview.talkture.com'
  apiTracking = 'https://tracking.emix.space'
  apiClientCertificate = 'https://cert.talkture.emix.us'
}

export function createStore (router) {
  return _createStore({
    strict: true,
    modules: {
      notification: moduleNotification,
      tables: moduleTables,
      attribute: moduleAttribute,
      moduleUser,
      room: moduleRoom,
      job: moduleJob,
      reportModel: moduleReportModel,
      tag: moduleTag,
      template: moduleTemplate,
      permissions: modulePermissions,
      gridFilter: moduleGridFilter,
      subjectAttribute: moduleSubjectAttribute
    },
    state: {
      activeTalkModal: {
        id: null,
        displayName: null
      },
      ready: false,
      positionGrid: [],
      immutableStyleGrid: [],
      templateName: '',
      carrierList: [],

      // Filtro aplicado no template do grid aberto (era utilizado nos assuntos de Averbação do cliente LocalFrio)
      // Serve para aplicar um filtro à requisição que popula listas de select (mover para Context no futuro e passar para filhos via provide)
      templateFilterId: '',
      devmode: process.env.NODE_ENV === 'development',
      api,
      apiTracking,
      apiClientCertificate,
      logoff: false,
      token: false,
      user: {
        active: false,
        data: {}
      },
      menu: {
        active: true,
        options: []
      },
      gridWidth: 0,
      templateType: Number,
      identifier: String,
      bloqueiaProtocolo: {
        value: false,
        subjectAttributeId: ''
      },
      bloqueiaRetProtocolo: {
        subjectAttributeId: '',
        value: ''
      },
      pages: [],
      pageActive: {},
      refreshPages: [],
      fullscreenMode: false,
      nightlyMode: false,
      primaryColor: '',
      fontSize: 0,
      templateId: '',
      averbacaoTalkId: '',
      logo: '',
      loading: false,
      loadingTalkMenu: false,
      nomeCertificado: '',
      cpfCertificado: '',
      nomeCertificadoId: '',
      cpfCertificadoId: '',
      validadeCertificado: Date.now(),
      certifyError: {
        active: false,
        message: '',
        orientation: ''
      },
      taskSubjectData: null, // Armazena resposta da requisição context/web/talkmodal/tarefa
      talkDialogsUsers: {
        subject: null,
        room: null,
        items: []
      }
    },

    getters: {
      activeTalkModal: ({ activeTalkModal }) => activeTalkModal,
      ready: ({ ready }) => ready,
      api: ({ api }) => api,
      apiTracking: ({ apiTracking }) => apiTracking,
      apiClientCertificate: ({ apiClientCertificate }) => apiClientCertificate,
      token: ({ token }) => token,
      user: ({ user }) => user,
      menu: ({ menu }) => menu,
      gridWidth: ({ gridWidth }) => gridWidth,
      fullscreenMode: ({ fullscreenMode }) => fullscreenMode,
      nightlyMode: ({ nightlyMode }) => nightlyMode,
      pages: ({ pages, menu }) => {
        return menu.options.length
          ? pages.map((page) => {
            const pageName = menu.options.find(({ FullPath }) => FullPath === page.fullPath)?.Name

            return {
              ...page,
              meta: {
                ...page.meta,
                label: pageName || page.meta.label
              }
            }
          })
          : pages
      },
      refreshPages: ({ refreshPages }) => refreshPages,
      primaryColor: ({ primaryColor }) => primaryColor,
      logoff: ({ logoff }) => logoff,
      fontSize: ({ fontSize }) => fontSize,
      pageActive: ({ pageActive }) => pageActive,
      bloqueiaProtocolo: ({ bloqueiaProtocolo }) => bloqueiaProtocolo,
      bloqueiaRetProtocolo: ({ bloqueiaRetProtocolo }) => bloqueiaRetProtocolo,
      identifier: ({ identifier }) => identifier,
      positionGrid: ({ positionGrid }) => positionGrid,
      immutableStyleGrid: ({ immutableStyleGrid }) => immutableStyleGrid,
      carrierList: ({ carrierList }) => carrierList,
      templateType: ({ templateType }) => templateType,
      templateFilterId: ({ templateFilterId }) => templateFilterId,
      valueCarga: ({ valueCarga }) => valueCarga,
      templateName: ({ templateName }) => templateName,
      templateId: ({ templateId }) => templateId,
      averbacaoTalkId: ({ averbacaoTalkId }) => averbacaoTalkId,
      loading: ({ loading }) => loading,
      loadingTalkMenu: ({ loadingTalkMenu }) => loadingTalkMenu,
      nomeCertificado: ({ nomeCertificado }) => nomeCertificado,
      cpfCertificado: ({ cpfCertificado }) => cpfCertificado,
      validadeCertificado: ({ validadeCertificado }) => validadeCertificado,
      certifyError: ({ certifyError }) => certifyError,
      nomeCertificadoId: ({ nomeCertificadoId }) => nomeCertificadoId,
      cpfCertificadoId: ({ cpfCertificadoId }) => cpfCertificadoId,
      logo: ({ logo }) => logo,
      taskSubjectData: ({ taskSubjectData }) => taskSubjectData,
      talkDialogsUsers: ({ talkDialogsUsers }) => talkDialogsUsers
    },

    mutations: {
      initialiseStore (state) {
        if (
          localStorage.getItem('talkture_token') &&
          !sessionStorage.getItem('talkture_token')
        ) {
          state.token = localStorage.getItem('talkture_token')
        }

        if (
          localStorage.getItem('talkture_user') &&
          !sessionStorage.getItem('talkture_user')
        ) {
          state.user = JSON.parse(localStorage.getItem('talkture_user'))
        }

        /* */

        if (
          !localStorage.getItem('talkture_token') &&
          sessionStorage.getItem('talkture_token')
        ) {
          state.token = sessionStorage.getItem('talkture_token')
        }

        if (
          !localStorage.getItem('talkture_user') &&
          sessionStorage.getItem('talkture_user')
        ) {
          state.user = JSON.parse(sessionStorage.getItem('talkture_user'))
        }
      },

      loginUser (state, data) {
        state.user.active = true
        state.user.data = data
        /* */

        const storage = state.user.data.keepConnected
          ? localStorage
          : sessionStorage

        /* */

        storage.setItem('talkture_user', JSON.stringify(state.user))

        if (data.token !== undefined) {
          state.token = data.token
          delete data.token

          storage.setItem('talkture_token', state.token)
        }
      },

      setActiveTalkModal (state, data) {
        state.activeTalkModal = data
      },

      setAverbacaoTalkId (state, data) {
        state.averbacaoTalkId = data
      },

      setCertifyError (state, data) {
        state.certifyError = data
      },

      setLogo (state, data) {
        state.logo = data
      },

      setNomeCertificado (state, data) {
        state.nomeCertificado = data
      },

      setCpfCertificado (state, data) {
        state.cpfCertificado = data
      },

      setNomeCertificadoId (state, data) {
        state.nomeCertificadoId = data
      },

      setCpfCertificadoId (state, data) {
        state.cpfCertificadoId = data
      },

      setLoading (state, data) {
        state.loading = data
      },

      setLoadingTalkMenu (state, data) {
        state.loadingTalkMenu = data
      },

      setValidadeCertificado (state, data) {
        state.validadeCertificado = data
      },

      setTemplateId (state, data) {
        state.templateId = data
      },

      setTemplateName (state, data) {
        state.templateName = data
      },

      setValueCarga (state, data) {
        state.valueCarga = data
      },

      setTemplateFilterId (state, data) {
        state.templateFilterId = data
      },

      setTemplateType (state, data) {
        state.templateType = data
      },

      setPositionGrid (state, data) {
        const index = state.positionGrid.findIndex((item) => item.fullPath === data.fullPath)

        if (index > -1) {
          state.positionGrid[index] = {
            fullPath: data.fullPath,
            left: data.left,
            top: data.top
          }
        } else {
          return state.positionGrid.push(data)
        }

        sessionStorage.setItem(
          hashes.TALKTURE_POSITION_HASH,
          CryptoJS.AES.encrypt(
            JSON.stringify(state.positionGrid),
            hashes.TALKTURE_ENCRYPT_HASH
          )
        )
      },

      setImmutableStyleGrid (state, data) {
        const index = state.immutableStyleGrid.findIndex((item) => item.fullPath === data.fullPath)

        if (index > -1) {
          state.immutableStyleGrid[index] = {
            fullPath: data.fullPath,
            attributes: data.attributes
          }
        } else {
          return state.immutableStyleGrid.push(data)
        }
      },

      setGridWidth (state, data) {
        state.gridWidth = data
      },

      deletePositionGrid (state, idx) {
        state.positionGrid.splice(idx, 1)
        sessionStorage.setItem(
          hashes.TALKTURE_POSITION_HASH,
          CryptoJS.AES.encrypt(
            JSON.stringify(state.positionGrid),
            hashes.TALKTURE_ENCRYPT_HASH
          )
        )
      },

      setCarrier (state, data) {
        state.carrierList = data
      },

      removeCarrier (state) {
        state.carrierList = []
      },

      setBloqueiaProtocolo (state, data) {
        state.bloqueiaProtocolo = data
      },

      setBloqueiaRetProtocolo (state, data) {
        state.bloqueiaRetProtocolo = data
      },

      setUserInfo (state, data) {
        state.user.data.phoneNumber = data.phoneNumber
        state.user.data.name = data.name
        state.user.data.email = data.email
        state.user.data.customer =
          data.attributes
            ? data.attributes.find(
              ({ attributeIdentifier }) => attributeIdentifier === 'Customer'
            )?.value || null
            : null

        if (data.image) state.user.data.image = data.image
      },

      removeUserImage (state) {
        state.user.data.image = null
      },

      logoutUser (state, reload) {
        state.token = false
        state.user.active = false
        state.user.data = {}
        state.pages = []
        this.state.template.templates = {}

        localStorage.removeItem('talkture_token')
        localStorage.removeItem('talkture_user')
        sessionStorage.removeItem('talkture_token')
        sessionStorage.removeItem('talkture_user')
        localStorage.removeItem(hashes.TALKTURE_TABS_STORAGE)

        /* */

        if (typeof reload !== 'undefined') {
          state.logoff = true

          setTimeout(() => {
            window.location = '/'
          }, 300)
        }
      },

      toggleFullscreenMode (state) {
        state.fullscreenMode = !state.fullscreenMode

        /* */

        if (state.fullscreenMode) {
          localStorage.setItem(
            hashes.TALKTURE_FULLSCREENMODE_STORAGE,
            CryptoJS.AES.encrypt(
              state.fullscreenMode.toString(),
              hashes.TALKTURE_ENCRYPT_HASH
            )
          )
        } else {
          localStorage.removeItem(hashes.TALKTURE_FULLSCREENMODE_STORAGE)
        }
      },

      setFullscreenMode (state, value) {
        state.fullscreenMode = value == 'true'
      },

      toggleNightlyMode (state) {
        state.nightlyMode = !state.nightlyMode

        /* */

        if (state.nightlyMode) {
          localStorage.setItem(
            hashes.TALKTURE_NIGHTLYMODE_STORAGE,
            CryptoJS.AES.encrypt(
              state.nightlyMode.toString(),
              hashes.TALKTURE_ENCRYPT_HASH
            )
          )
        } else {
          localStorage.removeItem(hashes.TALKTURE_NIGHTLYMODE_STORAGE)
        }
      },

      setNightlyMode (state, value) {
        state.nightlyMode = value == 'true'
      },

      toggleMenuActive ({ menu }) {
        menu.active = !menu.active

        /* */

        if (!menu.active) {
          localStorage.setItem(
            hashes.TALKTURE_MENUACTIVE_STORAGE,
            CryptoJS.AES.encrypt(
              menu.active.toString(),
              hashes.TALKTURE_ENCRYPT_HASH
            )
          )
        } else {
          localStorage.removeItem(hashes.TALKTURE_MENUACTIVE_STORAGE)
        }
      },

      setMenuActive ({ menu }, value) {
        menu.active = value == 'true'
      },

      setMenuOpen ({ menu }) {
        menu.active = true
      },

      setMenuOptions (state, data) {
        state.menu.options = data
      },

      changePrimaryColor (state, data) {
        state.primaryColor = data

        localStorage.setItem(
          hashes.TALKTURE_THEMECOLOR_STORAGE,
          CryptoJS.AES.encrypt(
            JSON.stringify(state.primaryColor),
            hashes.TALKTURE_ENCRYPT_HASH
          )
        )

        /* */

        document.documentElement.style.setProperty(
          '--theme-color-1',
          data.color
        )
        document.documentElement.style.setProperty(
          '--theme-color-2',
          `${data.color}1a`
        )
        document.documentElement.style.setProperty(
          '--theme-color-3',
          `${data.color}4d`
        )
      },

      setOpenedPages (state, value) {
        state.pages = []
        state.pages = value
      },

      setCurrentPage (state, value) {
        state.pageActive = value
      },

      openPage (state, value) {
        const pages = state.pages

        if (value.fullPath === '/') return

        if (pages.findIndex((x) => x.fullPath === value.fullPath) == -1) {
          pages.unshift(value)

          /* */

          const array = []

          pages.forEach(({ fullPath, name, params, query, meta }) => {
            array.push({
              fullPath,
              name,
              params,
              query,
              meta
            })
          })

          localStorage.setItem(
            hashes.TALKTURE_TABS_STORAGE,
            CryptoJS.AES.encrypt(
              JSON.stringify(array),
              hashes.TALKTURE_ENCRYPT_HASH
            )
          )
        }
      },

      setStorageTabs ({ pages }) {
        /* */

        const array = []

        pages.forEach(({ fullPath, name, params, query, meta }) => {
          array.push({
            fullPath,
            name,
            params,
            query,
            meta
          })
        })

        localStorage.setItem(
          hashes.TALKTURE_TABS_STORAGE,
          CryptoJS.AES.encrypt(
            JSON.stringify(array),
            hashes.TALKTURE_ENCRYPT_HASH
          )
        )
      },

      closePage ({ pages, refreshPages }, path) {
        refreshPages.push(path)

        const index = findIndex(pages, {
          fullPath: path
        })
        if (index > -1) {
          EventBus.$emit(`router_close${slug(pages[index].fullPath)}`)
          if (router.currentRoute.value.fullPath == pages[index].fullPath) {
            if (typeof pages[index - 1] !== 'undefined') {
              router.push({
                name: pages[index - 1].name,
                params: pages[index - 1].params,
                query: pages[index - 1].query
              })

              pages.splice(index, 1)
            } else if (typeof pages[index + 1] !== 'undefined') {
              router.push({
                name: pages[index + 1].name,
                params: pages[index + 1].params,
                query: pages[index + 1].query
              })

              pages.splice(index, 1)
            } else {
              pages.splice(index, 1)

              router.push({
                name: 'dashboard'
              })
            }
          } else {
            pages.splice(index, 1)
          }

          const array = []

          pages.forEach(({ fullPath, name, params, query, meta }) => {
            array.push({
              fullPath,
              name,
              params,
              query,
              meta
            })
          })

          localStorage.setItem(
            hashes.TALKTURE_TABS_STORAGE,
            CryptoJS.AES.encrypt(
              JSON.stringify(array),
              hashes.TALKTURE_ENCRYPT_HASH
            )
          )

          if (!array.length) {
            localStorage.removeItem(hashes.TALKTURE_TABS_STORAGE)
          }
        }
      },

      removeFromRefreshPages ({ refreshPages }, path) {
        const index = refreshPages.findIndex((x) => x === path)

        if (index >= 0) {
          refreshPages.splice(index, 1)
        }
      },

      setReady (state) {
        state.ready = true
      },

      setIdentifier (state, data) {
        state.identifier = data
      },

      setFontSize (state, data) {
        state.fontSize = data

        if (state.fontSize == 0) {
          document.documentElement.style.fontSize = '10px'
        } else if (state.fontSize == 1) {
          document.documentElement.style.fontSize = '12px'
        } else {
          document.documentElement.style.fontSize = '13px'
        }
      },

      changeFontSize (state, action) {
        if (action == 'up') {
          if (state.fontSize < 2) state.fontSize++
        } else {
          if (state.fontSize > 0) state.fontSize--
        }

        if (state.fontSize == 0) {
          document.documentElement.style.fontSize = '10px'
        } else if (state.fontSize == 1) {
          document.documentElement.style.fontSize = '12px'
        } else {
          document.documentElement.style.fontSize = '13px'
        }

        localStorage.setItem(
          hashes.TALKTURE_FONTSIZE_STORAGE,
          CryptoJS.AES.encrypt(
            JSON.stringify(state.fontSize),
            hashes.TALKTURE_ENCRYPT_HASH
          )
        )
      },

      setTaskSubjectData (state, data) {
        state.taskSubjectData = data
      },

      setTalkDialogsUsers (state, [subject, roomId, data]) {
        state.talkDialogsUsers = {
          subject: subject,
          room: roomId,
          items: data
        }
      }
    },

    actions: {
      setReady ({ commit }) {
        commit('setReady')
      },
      updateStyle ({ getters }, { context, query, styles }) {
        return axios.put(
          `${getters.api}/context/${context}/template${query}`,
          styles
        )
      },
      async getTalk ({ getters }, { subject, talkId }) {
        const { data } = await axios.get(`${getters.api}/talk/${subject}/${talkId}`)
        return data
      }
    }
  })
}
