<template>
  <div :class="$style.fieldWrapper">
    <input
      v-if="type !== 'date' && type !== 'datetime'"
      :class="[$style.field, small ? $style.fieldSmall : null]"
      :type="type == 'time' ? 'time' : 'text'"
      :placeholder="placeholder"
      :value="value != '' ? value : labelNoTags"
      v-on:input="emitData($event)"
      v-on:focus="$emit('focus', $event)"
      v-on:blur="$emit('blur')"
      v-on:keydown.enter.prevent="$emit('enter')"
      v-on:keydown.up.prevent="$emit('up')"
      v-on:keydown.down.prevent="$emit('down')"
      :readonly="readonly"
      :required="required"
      ref="input"
    />

    <transition v-else name="fade">
      <app-datepicker
        :readonly="readonly"
        :required="required"
        :value="value"
        @change="selectDate"
      />
    </transition>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import AppDatepicker from '@/components/datepicker/AppDatepicker.vue'

const striptags = require('striptags')

export default {
  components: {
    AppDatepicker
  },

  props: {
    placeholder: String,
    value: String,
    focus: Boolean,
    readonly: Boolean,
    small: Boolean,
    label: String,
    type: {
      type: String,
      default: 'text'
    },

    required: Boolean
  },

  mounted () {
    this.focusInput()
  },

  activated () {
    this.focusInput()
  },

  methods: {
    selectDate (value) {
      this.$emit('update:value', dayjs(value).format('DD/MM/YYYY'))
    },

    focusInput () {
      if (this.focus) {
        setTimeout(
          () => {
            this.$refs.input.focus()
          },
          100,
          this
        )
      }
    },

    emitData (e) {
      const value = e.target.value

      this.$emit('update:value', value)
    }
  },

  computed: {
    labelNoTags () {
      return striptags(this.label)
    }
  }
}
</script>

<style lang="scss" module>

/* */

$field-input-background: white;
$field-input-background-nightly: $md-grey-900;

$field-input-color: black;
$field-input-color-nightly: white;
$field-input-border-color: $md-grey-400;
$field-input-border-color-nightly: $md-grey-600;
$field-input-border-color-focus: var(--theme-color-1);

/* */

.field {
  width: 100%;

  padding: $theme-padding + 1;

  background: $field-input-background;

  border-radius: 5px;
  border: 2px solid $field-input-border-color;

  font-family: 'Roboto', sans-serif;
  @include font-sizer(14);
  font-weight: 400;
  line-height: 1.1;
  color: $field-input-color;

  transition: 0.2s ease;
  transition-property: border-color;
  transition-delay: 0.2s;

  :global(.nightlymode) & {
    color: $field-input-color-nightly;

    background: $field-input-background-nightly;

    border-color: $field-input-border-color-nightly;
  }

  &--small {
    padding: 11px;
    @include font-sizer(12);
  }

  &__wrapper {
    position: relative;
  }

  &:hover {
    transition-delay: 0s;
  }

  &:focus {
    transition-delay: 0s;

    border-color: $field-input-border-color-focus;
  }

  &[readonly] {
    cursor: pointer;

    // &::placeholder {
    //
    //     color: white;
    //
    // }
  }
}
</style>
