<template>
  <div :class="$style.sequence">
    <app-confirm-promise ref="confirm" />

    <ul :class="$style.sequenceRow">
      <template v-for="i in 2">
        <li
          :class="$style.sequenceList"
          v-for="(data, index) in i == 1 ? getStepsEvents : getStepsWorks"
          :key="`${i == 1 ? 'events' : 'works'}${index}`"
        >
          <step
            :mode="i == 1 ? 'events' : 'works'"
            :data="data"
            :events-data-raw="i == 1 ? job.events[index] : null"
            :works-data-raw="job.works[0]"
            :events-data="getStepsEvents"
            :length="(i == 1 ? getStepsEvents : getStepsWorks).length"
            v-on:remove="removeStep(index, i == 1 ? 'events' : 'works')"
            v-on:reset-works="resetWorks()"
            v-on:toogle-active="toogleActive(i, index, $event)"
          />

          <div
            :class="$style.sequenceAdd"
            v-if="index == getStepsWorks.length - 1 && i == 2"
          >
            <div
              :class="$style.sequenceAddButton"
              v-on:click="addEventOrWork(i == 1 ? 'events' : 'works')"
            >
              <app-icon glyph="add" />
            </div>
          </div>
        </li>
      </template>

      <li :class="$style.sequenceList">
        <div :class="$style.sequenceLabel" data-label="FIM"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import Step from './Step.vue'

export default {
  components: {
    Step
  },

  props: {
    job: {
      type: Object,
      required: true
    }
  },

  computed: {
    getStepsEvents () {
      return this.job.events[0].steps
    },

    getStepsWorks () {
      return this.job.works[0].steps
    }
  },

  methods: {
    removeActive (index, mode) {
      this.$emit('remove-active', {
        index,
        mode
      })
    },

    toogleActive (type, index, value) {
      this.$emit('toogle-active', {
        type,
        index,
        value
      })
    },

    addEventOrWork (mode) {
      this.$emit('add', mode)
    },

    async removeStep (index, mode) {
      const confirm = this.$refs.confirm

      await confirm
        .open(
          `Deseja realmente remover ${
            mode == 'events' ? 'esse evento' : 'essa ação'
          }?`,
          'red'
        )
        .then(response => {
          this.$emit('remove-step', { mode, index })
          this.removeActive(index, mode)
        })
        .catch(error => false)

      confirm.close()
    },

    resetWorks () {
      this.job.works[0].steps = [
        {
          configs: [],
          workStepId: null,
          workStepType: null,
          name: '',
          filterName: null,
          filter: null,
          filterId: null
        }
      ]
    }
  }
}
</script>

<style lang="scss" module>

/* */

.sequence {
  &__row {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__list {
    position: relative;

    &:not(:last-of-type) {
      $margin: 60px;

      margin-bottom: $margin;

      &::before {
        content: '';

        position: absolute;

        top: 100%;
        left: 50%;

        width: 2px;
        height: $margin - (math.div($margin, 2));

        margin-top: math.div($margin - math.div($margin, 2), 2);
        margin-left: -1px;

        background: $md-grey-400;

        border-radius: 2px;
      }

      &::after {
        $size: 14px;

        content: '';

        position: absolute;

        top: 100%;
        left: 50%;

        width: $size;
        height: $size;

        margin-top: (($margin - (math.div($margin, 2))) + math.div(($margin - (math.div($margin, 2))), 2)) -
          (math.div($size, 2)) + 1;
        margin-left: (math.div(-$size, 2));

        border: math.div($size, 2) solid transparent;
        border-top-color: $md-grey-400;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: attr(data-label);

      padding: 5px 15px 4px;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(13);
      line-height: 1;
      font-weight: 700;
      color: $md-grey-500;

      border: 2px solid $md-grey-400;
      border-radius: 5px;
    }
  }

  &__add {
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    &__button {
      $size: 28px;

      width: $size;
      height: $size;

      background: white;

      border-radius: $size;
      border: 1px solid rgba(black, 0.05);

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      svg {
        width: $size;
        height: $size;

        fill: $md-blue-500;
      }
    }
  }
}
</style>
