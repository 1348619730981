<template>
  <div>
    <app-modal
      size="840"
      v-if="modal.active"
      v-on:close="closeTimeWindow()"
      title="Janela de Disponibilidade"
    >
      <div v-if="!idCarga && !loading" class="pd-5 pl-5 mt-5 mb-5">
        <app-input-label>Selecionie uma carga antes</app-input-label>
      </div>

      <div v-else>
        <app-loader-spinner v-if="loading" class="mt-5 mb-5" />

        <transition name="fade" mode="out-in" v-else>
          <div :class="$style.container" v-if="!contingency">
            <app-confirm-promise ref="confirm" />

            <div class="row justify-content-between">
              <p :class="$style.containerInfo">
                Em caso de dúvidas entre em contato com a equipe Localfrio (13)
                3389-8037 / (13) 3389-8026
              </p>

              <div class="col-auto">
                <p :class="$style.containerTitle" v-if="this.data.length">
                  {{ monthRange }}
                </p>
                <app-input-label v-else
                  >Nenhum horario disponível</app-input-label
                >
              </div>

              <div class="col-auto">
                <!--app-button small label="Carregamento Contingencial" color="blue" name="button_contingencial" v-on:click="contingencyLoading()"/-->
              </div>
            </div>

            <div :class="$style.containerDates" v-if="this.data.length">
              <div
                v-for="(item, index) in dateListSlice"
                :key="index"
                :class="[
                  $style.containerDatesItem,
                  item.hours.length == 0
                    ? $style.containerDatesItemDisabled
                    : null,
                ]"
              >
                <div :class="$style.containerDatesItemHeader">
                  <p
                    :class="[
                      $style.containerDatesItemHeaderWeek,
                      item.hours.length == 0
                        ? $style.containerDatesItemHeaderWeekDisabled
                        : null,
                    ]"
                  >
                    {{ week[item.date.getDay()] }}
                  </p>
                  <p
                    :class="[
                      $style.containerDatesItemHeaderDay,
                      item.hours.length == 0
                        ? $style.containerDatesItemHeaderDayDisabled
                        : null,
                    ]"
                  >
                    {{ item.date.getDate() }}
                  </p>
                </div>

                <div
                  :class="$style.containerDatesItemHours"
                  v-if="item.hours.length"
                >
                  <div
                    :class="[
                      $style.containerDatesItemHoursItem,
                      item.idDay == activeHour.day &&
                      indexH == activeHour.hour &&
                      !valueDate
                        ? $style.containerDatesItemHoursItemActived
                        : '',
                    ]"
                    :style="{
                      cursor: item.idDay == null ? 'default' : 'pointer',
                    }"
                    v-for="(h, indexH) in item.hours"
                    :key="indexH + h"
                    v-on:click="changeHour(item.idDay, indexH)"
                  >
                    <!--{{ h }}-->
                    <p :class="$style.hour">{{ h.horario }}</p>

                    <p :class="$style.windows">
                      <b>Janelas disponíveis:</b> {{ h.janelas }}
                    </p>
                  </div>
                </div>
              </div>

              <app-tooltip
                label="Voltar"
                right
                :class="[$style.containerArrow, $style.containerArrowLeft]"
                v-if="currentPage > 0"
              >
                <app-icon glyph="arrow_back_ios" v-on:click="prev()" />
              </app-tooltip>

              <app-tooltip
                label="Avançar"
                left
                :class="[$style.containerArrow, $style.containerArrowRight]"
                v-if="currentPage < totalPage"
              >
                <app-icon
                  glyph="arrow_forward_ios"
                  v-on:click="next()"
                />
              </app-tooltip>
            </div>
          </div>

          <div
            v-else
            :class="$style.container"
            :style="{ paddingBottom: '400px' }"
          >
            <div :class="$style.containerBack">
              <app-icon
                glyph="arrow_back"
                v-on:click="backTimeWindow()"
              />
              <p v-on:click="backTimeWindow()">
                Voltar para as datas disponíveis
              </p>
            </div>

            <app-input-label class="mt-4"
              >Selecione a data que deseja solicitar o carregamento
              contingencial</app-input-label
            >
            <div :class="$style.containerInputdate">
              <date-time-edit
                :value="valueDate"
                :activeHours="true"
                :config="{ actions: { insert: true, update: true } }"
                mode="form"
                v-on:change-input="changeValue"
              />
            </div>
          </div>
        </transition>
      </div>

      <app-actions-box>
        <div class="row justify-content-center">
          <div class="col-auto">
            <app-button
              icon="save"
              label="Salvar"
              color="blue"
              name="button_ok"
              v-on:click="save()"
              :disabled="
                (!contingency && selectData == '') ||
                (contingency && valueDate == '')
                  ? true
                  : false
              "
            />
          </div>

          <div class="col-auto">
            <app-button
              icon="close"
              label="Cancelar"
              color="red"
              name="button_cancel"
              v-on:click="closeTimeWindow()"
            />
          </div>
        </div>
      </app-actions-box>
    </app-modal>

    <input
      required
      :value="valueTransformed"
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        zIndex: -1,
        pointerEvents: 'none',
        opacity: 0,
      }"
    />

    <div :class="$style.input" v-on:click="openTimeWindow">
      {{ valueTransformed }}
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { defineAsyncComponent } from 'vue'
const DateTimeEdit = defineAsyncComponent(() => import('@/talkture/scenario/components/date-time/DateTimeEdit.vue'))

export default {
  components: {
    DateTimeEdit
  },

  props: {
    value: [Object, String, Date]
  },

  data () {
    return {
      modal: {
        active: false
      },

      currentDate: null,
      currentPage: 0,

      week: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],

      data: [],

      activeHour: {},
      selectData: '',

      contingency: false,
      valueDate: null,

      loading: false
    }
  },

  beforeMount () {
    const d = new Date().getDate()
    const m = new Date().getMonth() + 1
    const y = new Date().getFullYear()

    const date = y + '/' + m + '/' + d

    this.currentDate = date

    if (this.value.includes('T')) {
      const array = this.value.split('T')
      const array2 = array[0].split('-')
      const array3 = array[1].split(':')

      const date = array2[2] + '/' + array2[1] + '/' + array2[0]
      const hour = array3[0] + ':' + array3[1]

      this.valueDate = JSON.parse(JSON.stringify(date + ' ' + hour))
    } else {
      this.valueDate = JSON.parse(JSON.stringify(this.value))
    }

    this.checkValue()

    if (this.idCarga) {
      this.getAvailableTimes(this.idCarga)
    }
  },

  computed: {
    valueTransformed () {
      if (this.value.includes('T')) {
        const array = this.value.split('T')
        const array2 = array[0].split('-')

        const date = array2[2] + '/' + array2[1] + '/' + array2[0]
        const hour = array[1]

        return date + ' ' + hour
      } else if (this.value) {
        const array = this.value.split(' ')
        const array2 = array[0].split('/')

        const date = array2[2] + '/' + array2[1] + '/' + array2[0]
        const hour = array[1]

        return date + ' ' + hour
      } else {
        return ''
      }
    },

    idCarga () {
      // let id = window.location.host.includes('test.talkture') || window.location.host.includes('localhost') ? 1482 : 1561
      // const id = 1561

      // const value = ControllerReference.fromSelected.find(x => x.attr == id)

      // return value ? value.val.join(',') : null

      return null
    },

    daysAvailable () {
      const available = this.data.map(i => {
        return new Date(i.date)
      })

      return available
    },

    minDate () {
      return new Date(Math.min.apply(null, this.daysAvailable))
    },

    maxDate () {
      return new Date(Math.max.apply(null, this.daysAvailable))
    },

    activeIndex () {
      return this.data.map(item => {
        const day = new Date(item.date).getDate()
        const month = new Date(item.date).getMonth() + 1
        const year = new Date(item.date).getFullYear()

        const index = this.dateList.findIndex(
          x =>
            x.getFullYear() == year &&
            x.getMonth() + 1 == month &&
            x.getDate() == day
        )

        return {
          index: index,
          data: item
        }
      })
    },

    monthRange () {
      const month = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]

      const min = this.minDate.getMonth()
      const max = this.maxDate.getMonth()

      const current = new Date(this.currentDate)

      const check = min == current.getMonth()

      if (min === max) {
        return month[min] + ' ' + this.minDate.getFullYear()
      } else {
        if (check) {
          return (
            month[min] +
            ' ' +
            this.minDate.getFullYear() +
            ' - ' +
            month[max] +
            ' ' +
            this.maxDate.getFullYear()
          )
        } else {
          if (max == current.getMonth()) {
            return month[current.getMonth()] + ' ' + current.getFullYear()
          } else {
            return (
              month[current.getMonth()] +
              ' ' +
              current.getFullYear() +
              ' - ' +
              month[max] +
              ' ' +
              this.maxDate.getFullYear()
            )
          }
        }
      }
    },

    dateList () {
      let cDate = this.minDate
      const dDate = this.maxDate

      const dArray = []

      while (cDate <= dDate) {
        dArray.push(new Date(cDate))

        cDate = this.addDays(cDate, 1)
      }

      if (dArray.length < 6) {
        const dif = 7 - dArray.length

        let x = 0

        while (x < dif) {
          dArray.push(new Date(cDate))

          cDate = this.addDays(cDate, 1)

          x++
        }
      }
      // else {

      //     dArray.push(new Date (cDate));

      // }

      return dArray.filter(x => x >= new Date(this.currentDate))
    },

    dateListSlice () {
      const array = this.dateList.map((item, index) => {
        const h = this.activeIndex.find(x => x.index == index)

        return {
          date: item,
          hours: h ? h.data.hours : [{}],
          idDay: h ? h.data.id : null
        }
      })

      if (this.currentPage == this.totalPage) {
        return array.slice(array.length - 7, array.length)
      }

      return array.slice(this.currentPage * 7, this.currentPage * 7 + 7)
    },

    totalPage () {
      return Math.ceil(this.dateList.length / 7) - 1
    },
    currentYear () {
      return dayjs(this.convertDate(this.currentDate), 'DD/MM/YYYY').year()
    },

    holidays () {
      const easter = dayjs(this.currentYear + this.calcEaster(this.currentYear))
      const holiday = []
      holiday.push({
        data: dayjs(this.currentYear + '0101').format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(easter)
          .subtract(48, 'days')
          .format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(easter)
          .subtract(47, 'days')
          .format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(easter).format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(easter)
          .subtract(2, 'days')
          .format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(easter)
          .add(60, 'days')
          .format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(this.currentYear + '0421').format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(this.currentYear + '0501').format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(this.currentYear + '0907').format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(this.currentYear + '1012').format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(this.currentYear + '1102').format('DD/MM/YYYY')
      })
      holiday.push({
        data: dayjs(this.currentYear + '1225').format('DD/MM/YYYY')
      })
      return holiday
    },

    isHoliday () {
      let cont = 0
      this.holidays.forEach(({ data }) => {
        if (
          dayjs(this.convertDate(this.selectData)).format('DD/MM/YYYY') == data
        ) { cont++ }
      })
      return cont > 0
    },

    isAfterHoliday () {
      const dayBefore = dayjs(this.convertDate(this.selectData))
        .subtract(1, 'days')
        .format('DD/MM/YYYY')
      let cont = 0
      this.holidays.forEach(({ data }) => {
        if (dayBefore == data) cont++
      })
      return cont > 0
    }
  },

  methods: {
    checkValue () {
      this.currentPage = 0

      if (this.value && this.data.length) {
        const array = this.value.split(' ')

        const date = array[0].split('/')
        const dateT = date[2] + '/' + date[1] + '/' + date[0]

        const indexDate = this.data.findIndex(
          x =>
            new Date(x.date).toDateString() == new Date(dateT).toDateString()
        )

        if (indexDate > -1) {
          const hour = []

          for (let index = 1; index < array.length; index++) {
            hour.push(array[index])
          }

          const indexHour = this.data[indexDate].hours.findIndex(
            x => x == /* hour.join(' ') */ hour.horario.join(' ')
          )

          if (indexHour > -1) {
            this.activeHour.day =
              JSON.parse(JSON.stringify(this.data[indexDate].id))

            this.activeHour.hour =
              JSON.parse(JSON.stringify(indexHour))

            const indexActived = this.dateList.findIndex(
              x => new Date(x).toDateString() == new Date(dateT).toDateString()
            )

            if (indexActived > -1) {
              this.currentPage =
                indexActived == 0 ? 0 : Math.ceil(indexActived / 7) - 1
            }
          }
        }
      }
    },

    // 2019/11/13 21:00

    convertDate (value) {
      const array = JSON.parse(JSON.stringify(value)).split(' ')

      const array2 = array[0].split('/')

      const hour = array[1]
      const date = array2[2] + '/' + array2[1] + '/' + array2[0]

      return date + ' ' + hour
    },

    async save () {
      if (this.contingency) {
        if (this.valueDate) {
          const day = new Date(this.valueDate).getDate()
          const month = new Date(this.valueDate).getMonth() + 1
          const year = new Date(this.valueDate).getFullYear()

          const hour = new Date(this.valueDate).getHours()
          const minu = new Date(this.valueDate).getMinutes()

          const date =
            day +
            '/' +
            month +
            '/' +
            year +
            ' ' +
            hour +
            ':' +
            (minu == 0 ? '00' : minu < 10 ? '0' + minu : minu)

          this.$emit('change', this.convertDate(date))

          this.selectData = ''
        } else {
          this.$emit('change', '')
        }
      } else {
        this.valueDate = ''
        this.$emit('change', this.convertDate(this.selectData))

        // eslint-disable-next-line no-console

        if (
          (dayjs(this.convertDate(this.selectData)).format('dddd') ===
            'sábado' &&
            dayjs(this.convertDate(this.selectData)).format('LT') > '11:00') ||
          dayjs(this.convertDate(this.selectData)).format('dddd') ===
            'domingo' ||
          ((dayjs(this.convertDate(this.selectData)).format('dddd') ===
            'segunda-feira' ||
            this.isAfterHoliday) &&
            dayjs(this.convertDate(this.selectData)).format('LT') < '07:00') ||
          this.isHoliday
        ) {
          const confirm = this.$refs.confirm

          confirm.alert = true

          await confirm
            .open(
              '<p style="font-size: 16px">Os documentos deverão ser entregues no setor de registro até as 11:00 horas de sábado. </p>',
              'blue'
            )
            .then(response => {
              // eslint-disable-next-line no-unused-vars
            })
            .catch(error => false)

          confirm.close()

          this.$emit('change-contingency', this.contingency)

          this.closeTimeWindow()
        } else {
          this.$emit('change-contingency', this.contingency)

          this.closeTimeWindow()
        }
      }
    },

    /* */

    addDays (date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    },

    /* */

    openTimeWindow () {
      this.checkValue()

      this.modal.active = true
      this.$emit('change-zindex', 10000)
    },

    closeTimeWindow () {
      this.modal.active = false
      this.$emit('change-zindex', 0)

      this.contingency = false
    },

    /* */

    prev () {
      this.currentPage--
    },

    next () {
      this.currentPage++
    },

    /* */

    changeHour (idDay, indexH) {
      if (idDay !== null) {
        const data = this.data.find(x => x.id == idDay)
        // let hour = data.hours[indexH]
        const hour = data.hours[indexH].horario

        this.activeHour.day = JSON.parse(JSON.stringify(idDay))
        this.activeHour.hour = JSON.parse(JSON.stringify(indexH))

        const day = new Date(data.date).getDate()
        const month = new Date(data.date).getMonth() + 1
        const year = new Date(data.date).getFullYear()

        this.selectData = day + '/' + month + '/' + year + ' ' + hour

        this.valueDate = ''
      }
    },

    changeValue (value) {
      this.valueDate = value
    },

    /* */

    contingencyLoading () {
      this.contingency = true
    },

    backTimeWindow () {
      this.contingency = false
    },

    // getAvailableTimes (value) {
    //   if (!value.includes(',')) {
    //     this.loading = true

    //     this.$http
    //       .get(
    //         `${
    //           this.$store.getters.api
    //         }/sita/janeladisponibilidade?carga=${value}&cargaSolta=${
    //           this.$store.getters.tipoCarga == 'Carga Solta'
    //         }`
    //       )
    //       .then(({ data }) => {
    //         this.data = data

    //         this.loading = false

    //         // eslint-disable-next-line no-unused-vars
    //       })
    //       .catch(error => {
    //         this.loading = false
    //       })
    //   }
    // },

    calcEaster (Y) {
      const C = Math.floor(Y / 100)
      const N = Y - 19 * Math.floor(Y / 19)
      const K = Math.floor((C - 17) / 25)
      let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15
      I = I - 30 * Math.floor(I / 30)
      I =
        I -
        Math.floor(I / 28) *
          (1 -
            Math.floor(I / 28) *
              Math.floor(29 / (I + 1)) *
              Math.floor((21 - N) / 11))
      let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4)
      J = J - 7 * Math.floor(J / 7)
      const L = I - J
      const M = 3 + Math.floor((L + 40) / 44)
      const D = L + 28 - 31 * Math.floor(M / 4)

      return this.padout(M) + '' + this.padout(D)
    },

    padout (number) {
      return number < 10 ? '0' + number : number
    }
  },

  watch: {
    idCarga: function (value, oldValue) {
      if (value != oldValue) {
        this.activeHour = {}

        this.$emit('change', '')

        this.getAvailableTimes(value)
      }
    }
  }
}
</script>

<style lang="scss" module>

.windows {
  margin: 5px;
}

.hour {
  font-size: 2.2rem;
}

.input {
  height: 50px;
  padding: math.div($theme-padding, 2);
  display: flex;
  align-items: center;

  box-shadow: inset 0 0 0 2px $md-grey-400;
  border-radius: 5px;

  font-family: 'Roboto', sans-serif;

  @include font-sizer(12);
  font-weight: 400;
  color: black;

  cursor: pointer;

  :global(.nightlymode) & {
    box-shadow: inset 0 0 0 2px $md-grey-600;
    background: $md-grey-900;
    color: white;
  }
}

.container {
  padding: $theme-padding * 2;

  &__title {
    font-family: 'Raleway', sans-serif;
    @include font-sizer(17);

    color: $md-grey-700;

    :global(.nightlymode) & {
      color: white;
    }
  }

  &_info {
    color: #17a2b8;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    font-weight: bold;
    width: 100%;

    @include font-sizer(17);
  }

  &__arrow {
    position: absolute;
    top: 20px;

    cursor: pointer;

    svg {
      fill: $md-grey-500;

      :global(.nightlymode) & {
        fill: $md-grey-200;
      }
    }

    &--left {
      left: -20px;
    }

    &--right {
      right: -20px;
    }
  }

  &__dates {
    position: relative;

    display: flex;

    margin-top: $theme-padding;

    &__item {
      flex: 1;
      padding: $theme-padding 0;
      margin: 0 1px;
      border-radius: 5px;

      &--disabled {
        background: rgba(0, 0, 0, 0.03);

        :global(.nightlymode) & {
          background: rgba(255, 255, 255, 0.04);
        }
      }

      &__header {
        width: 100%;
        display: flex;

        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        &__day {
          width: 100%;
          margin: 0;

          font-family: 'Raleway', sans-serif;
          @include font-sizer(17);
          line-height: 18px;
          text-align: center;

          color: $md-grey-700;

          &--disabled {
            opacity: 0.5;
          }

          :global(.nightlymode) & {
            color: white;
          }
        }

        &__week {
          width: 100%;
          margin: 0;

          font-family: 'Raleway', sans-serif;
          @include font-sizer(20);
          text-align: center;
          line-height: 18px;

          color: $md-grey-700;

          &--disabled {
            opacity: 0.5;
          }

          :global(.nightlymode) & {
            color: white;
          }
        }
      }

      &__hours {
        display: flex;
        flex-direction: column;

        padding: 0 math.div($theme-padding, 3);
        margin-top: $theme-padding;

        &__item {
          width: 100%;
          background: rgba(0, 0, 0, 0.06);
          padding: 5px 0;
          margin-bottom: math.div($theme-padding, 3);

          color: $md-grey-800;
          font-family: 'Raleway', sans-serif;
          @include font-sizer(12);
          text-align: center;

          border-radius: 5px;
          cursor: pointer;

          :global(.nightlymode) & {
            background: rgba(255, 255, 255, 0.09);
            color: white;
          }

          &--actived {
            position: relative;
            box-shadow: inset 0 0 0 2px var(--theme-color-1);

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: var(--theme-color-1);
              opacity: 0.1;

              :global(.nightlymode) & {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }

  &__back {
    display: flex;
    align-items: center;

    svg {
      fill: $md-grey-700;
      cursor: pointer;

      :global(.nightlymode) & {
        fill: white;
      }
    }

    p {
      margin: 0;

      font-family: 'Raleway', sans-serif;
      @include font-sizer(12);

      color: $md-grey-700;
      cursor: pointer;

      :global(.nightlymode) & {
        color: white;
      }
    }
  }

  &__inputdate {
    position: relative;
  }
}
</style>
