<script setup>
import { ref, computed } from 'vue'

const emit = defineEmits(['do-search', 'clean-search'])

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  maxLength: {
    type: Number,
    default: null
  }
})

const activeSearch = ref(false)
const searchTerm = ref('')
const searchWasDone = ref(false)

const inputRightPadding = computed(() => (searchWasDone.value ? '56px' : '32px'))

const cleanSearch = () => {
  searchTerm.value = ''
  searchWasDone.value = false
  activeSearch.value = false

  emit('clean-search')
}

const toggleAndDoSearch = () => {
  if (searchTerm.value !== '') {
    searchWasDone.value = true
    emit('do-search', searchTerm.value)
  } else {
    if (searchWasDone.value) cleanSearch()
    activeSearch.value = !activeSearch.value
  }
}
</script>

<template>
  <div :class="$style.search">
    <transition name="fade">
      <app-input
        v-if="activeSearch || searchTerm.length"
        v-model:value="searchTerm"
        small
        :maxlength="maxLength"
        :class="$style.searchInput"
        @keyup.enter="toggleAndDoSearch"
      />
    </transition>

    <app-icon
      v-if="searchWasDone && searchTerm.length"
      glyph="close"
      :class="[$style.searchIcon, $style.searchIconClose]"
      @click="cleanSearch"
    />
    <app-icon
      glyph="search"
      :class="$style.searchIcon"
      @click="toggleAndDoSearch"
    />
  </div>
</template>

<style lang="scss" module>
  .search {
    position: relative;
    height: 30px;

    &__input {
      position: absolute;
      z-index: 1;
      width: 100%;

      input {
        padding-right: v-bind(inputRightPadding) !important;
      }
    }

    &__icon {
      position: absolute;
      right: 7px;
      top: 8px;
      z-index: 2;
      fill: black;
      cursor: pointer;

      :global(.nightlymode) & {
        fill: white;
      }

      &--close {
        right: 32px;
      }
    }
  }
</style>
