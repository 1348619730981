<template>
  <app-config>
    <transition name="fade">
      <app-modal
        size="540"
        v-if="deleteErrors.active"
        @close="closeDeleteErrors()"
        title="Há registros relacionados com a tag"
        bg-color="alert"
      >
        <div :class="$style.delete">
          <app-text
            >Não foi possível excluir essa tag pois ela está vinculada aos itens
            abaixo. É necessário remover a relação das tags com os itens para
            excluir.</app-text
          >

          <ul
            :class="[$style.deleteRow, $style.deleteRowBackground]"
            class="mt-4 mb-4"
          >
            <li
              :class="[$style.deleteList, $style.deleteListParent]"
              v-if="getErrors.talks.length"
            >
              <span><b>Registros</b></span>

              <ul :class="$style.deleteRow" class="p-0">
                <li
                  :class="$style.deleteList"
                  v-for="(data, index) in getErrors.talks"
                  :key="index"
                >
                  <span
                    >{{ data.id }} -
                    {{ data.name || 'Registro sem título' }}</span
                  >
                </li>
              </ul>
            </li>

            <li
              :class="[$style.deleteList, $style.deleteListParent]"
              v-if="getErrors.works.length"
            >
              <span><b>Automações</b></span>

              <ul :class="$style.deleteRow" class="p-0">
                <li
                  :class="$style.deleteList"
                  v-for="(data, index) in getErrors.works"
                  :key="index"
                >
                  <span
                    >{{ data.id }} -
                    {{ data.name || 'Registro sem título' }}</span
                  >
                </li>
              </ul>
            </li>

            <li
              :class="[$style.deleteList, $style.deleteListParent]"
              v-if="getErrors.subjects.length"
            >
              <span><b>Assuntos</b></span>

              <ul :class="$style.deleteRow" class="p-0">
                <li
                  :class="$style.deleteList"
                  v-for="(data, index) in getErrors.subjects"
                  :key="index"
                >
                  <span
                    >{{ data.id }} -
                    {{ data.name || 'Registro sem título' }}</span
                  >
                </li>
              </ul>
            </li>
          </ul>

          <app-button
            label="OK, ENTENDI"
            color="red"
            @click="closeDeleteErrors()"
          />
        </div>
      </app-modal>
    </transition>

    <app-view>
      <app-confirm-promise ref="confirm" />

      <app-box>
        <app-loader
          v-if="loading.active || loading.error"
          :error="loading.error"
          key="loading"
          :active="loading.active"
          @reload="init()"
        />

        <div class="row no-gutters" v-if="!loading.active && !loading.error">
          <div class="col">
            <div :class="$style.scroller">
              <p :class="$style.title">Tags</p>

              <div class="row mt-5">
                <div class="col">
                  <app-button
                    small
                    @click="newTag"
                    color="blue"
                    light
                    label="Criar nova tag"
                    icon="add"
                  />
                </div>

                <div class="col-4" :class="$style.search">
                  <transition name="fade">
                    <app-input
                      v-if="search.active"
                      :class="$style.searchInput"
                      :value="search.term"
                      :maxlength="50"
                      @enter="search.term = input.value"
                      ref="input"
                      small
                    />
                  </transition>

                  <app-icon
                    v-if="filtered"
                    glyph="close"
                    :class="[$style.searchIcon, $style.searchIconClose]"
                    @click="cleanSearch"
                  />
                  <app-icon
                    glyph="search"
                    :class="$style.searchIcon"
                    @click="search.active = !search.active"
                  />
                </div>
              </div>

              <div v-if="!loading.active && !loading.error">
                <div :class="$style.list" class="mt-5">
                  <p v-if="tags.length === 0" :class="$style.listEmpty">
                    Nenhuma tag encontrada.
                  </p>

                  <div
                    v-for="(data, index) in tags"
                    class="row no-gutters mb-4"
                    :class="$style.listItem"
                    :key="index"
                  >
                    <div class="col-auto pr-3">
                      <div
                        :style="{
                          width: '24px',
                          height: '24px',
                          borderRadius: '24px',
                          backgroundColor: data.tagColor,
                          boxShadow: '0 0 0 1px rgba(0, 0, 0, .1)',
                        }"
                      ></div>
                    </div>

                    <div
                      class="col"
                      :class="$style.listItemName"
                      @click="selectTag(data)"
                    >
                      {{ data.tagName }}
                    </div>

                    <div class="col-auto">
                      <app-icon
                        glyph="delete"
                        @click="removeTag(data.tagId)"
                        :class="$style.listItemDelete"
                      />
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center mt-5" v-if="tags.length">
                  <div class="col-auto">
                    <app-pagination
                      :length="pagination.total"
                      :current-page="pagination.page"
                      :per-page="pagination.perPage"
                      :option-per-page="pagination.optionPerPage"
                      @change-page="changePage"
                      @change-per-page="changePerPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-box>
    </app-view>
  </app-config>
</template>

<script>
import AppConfig from '@/views/config/Index.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ref, computed, watch, watchEffect, unref } from 'vue'

export default {
  setup () {
    const store = useStore()
    const route = useRoute()

    const input = ref(null)

    const loading = ref({ active: false, error: false })

    const tags = computed(() => store.getters.getTags.items)
    const pagination = ref({
      total: computed(() => store.getters.getTags.total),
      page: 1,
      perPage: 10,
      optionPerPage: [10, 25, 50, 100, 200, 500]
    })
    const search = ref({
      active: false,
      term: ''
    })
    const filtered = ref(false)

    const loadTags = async () => {
      const term = unref(search).term
      const { page, perPage: fetch } = unref(pagination)

      loading.value.error = false
      loading.value.active = true

      try {
        await store.dispatch('loadTags', { page, fetch, term })
        filtered.value = !!term
      } catch (e) {
        console.log(e)
        loading.value.error = true
        store.dispatch('notification', {
          type: 'error',
          text: 'Ocorreu um erro ao tentar carregar as tags. Tente novamente ou entre em contato com o Administrador do sistema.',
          error: e
        })
      } finally {
        loading.value.active = false
      }
    }

    watchEffect(loadTags)
    watch(route, () => {
      if (route.params.refresh) {
        if (pagination.value.page === 1) loadTags()
        else pagination.value.page = 1
      }
    })

    return { input, loading, tags, pagination, search, filtered, loadTags }
  },
  components: {
    AppConfig
  },
  data () {
    return {
      deleteErrors: {
        active: false,
        data: null
      }
    }
  },

  computed: {
    getErrors () {
      return this.deleteErrors.data[0]
    }
  },

  methods: {
    cleanSearch () {
      this.search.term = ''
      this.search.active = false
      this.pagination.page = 1
    },

    newTag () {
      this.$router.push({
        name: 'tags_editor'
      })
    },

    selectTag (tag) {
      this.$router.push({
        name: 'tags_editor',
        params: {
          tagId: tag.tagId
        }
      })
    },

    async removeTag (id) {
      const tagIndex = this.tags.findIndex(({ tagId }) => id === tagId)

      if (tagIndex > -1) {
        const confirm = this.$refs.confirm

        await confirm
          .open('Deseja realmente excluir esse item?', 'red')
          .then(async () => {
            await this.$store.dispatch('deleteTag', id)
            this.$store.dispatch('notification', {
              text: 'Tag <b>excluída</b> com sucesso!',
              color: 'green'
            })
            if (!this.tags.length) this.pagination.page--
          })
          .catch((e) => {
            if (e !== false) {
              console.log(e)
              if (e?.response?.status === 422) {
                this.deleteErrors.active = true
                this.deleteErrors.data = e.response.data.errors
              } else {
                this.$store.dispatch('notification', {
                  error: e,
                  text: 'Ocorreu um erro ao tentar <b>excluir</b> a tag. Por favor, tente novamente!',
                  type: 'error'
                })
              }
            }
          })
        confirm.close()
      }
    },

    closeDeleteErrors () {
      this.deleteErrors.active = false
      this.deleteErrors.data = null
    },

    changePage (value) {
      this.pagination.page = value
    },

    changePerPage (value) {
      this.pagination.perPage = value
      this.pagination.page = 1
    }
  }
}
</script>

<style lang="scss" module>
  .loader {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -15px;
  }

  .delete {
    padding: $theme-padding;

    &__row {
      margin: 0;
      padding: $theme-padding;
      list-style: none;

      &--background {
        background: $md-grey-200;
        border-radius: 6px;

        :global(.nightlymode) & {
          background: $md-grey-700;
        }
      }
    }

    &__list {
      span {
        font-family: 'Raleway', sans-serif;
        @include font-sizer(14);
        color: $md-grey-600;

        :global(.nightlymode) & {
          color: white;
        }
      }

      &--item {
        &::before {
          content: '';
          $size: 6px;
          float: left;
          width: $size;
          height: $size;
          margin-top: 5px;
          margin-right: 5px;
          background: red;
          border-radius: $size;
        }
      }

      &--parent {
        &:not(:last-of-type) {
          margin-bottom: $theme-padding;
        }
      }
    }
  }

  .search {
    position: relative;

    &__input {
      position: absolute !important;
      width: 100%;
      right: 20px;
      margin: 0;

      input {
        border: none !important;
        border-radius: 20px !important;
        text-indent: 10px;

        :global(.nightlymode) & {
          background-color: $md-grey-800 !important;
        }
      }

    }

    &__icon {
      position: absolute;
      right: 27px;
      top: 7px;
      fill: $md-blue-500;
      cursor: pointer;
      z-index: 2;

      &--close {
        width: 18px;
        height: 18px;
        top: 10px;
        right: 27px * 2;
      }
    }
  }

  .scroller {
    min-width: 100%;
    overflow: auto;
    padding: 60px 75px 120px;
    min-height: calc(100vh - 65px);
    background: $md-grey-100;

    :global(.nightlymode) & {
      background: $md-grey-900;
    }
  }

  .title {
    @include font-sizer(28);
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    color: var(--theme-color-1);
  }

  .list {
    &__empty {
      @include font-sizer(12);
      font-family: 'Raleway';
      color: black;
      :global(.nightlymode) & {
        color: white;
      }
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0px $theme-padding * 1.5;
      border-radius: 5px;
      background: white;

      :global(.nightlymode) & {
        background: $md-grey-800;
      }

      &--active {
        background: $md-grey-400;
        box-shadow: inset 0 0 0 2px rgba(black, 0.15);
        cursor: pointer;

        :global(.nightlymode) & {
          background: lighten($md-grey-900, 5%);
          box-shadow: inset 0 0 0 2px rgba(black, 0.15);
        }
      }

      &__name {
        min-height: 60px;
        padding: 5px 0;
        display: flex;
        align-items: center;
        @include font-sizer(15);
        font-family: 'Raleway';
        color: black;
        word-break: break-word;
        cursor: pointer;
        :global(.nightlymode) & {
          color: white;
        }

        &--active {
          color: var(--theme-color-1) !important;
          font-weight: 500;
        }
      }

      &__delete {
        fill: $md-red-500;
        cursor: pointer;
      }
    }
  }
</style>
