const moduleTemplate = {
  state: () => ({
    templates: {}
  }),
  getters: {
    getTemplates: (state) => state.templates
  },
  mutations: {
    ADD_TEMPLATE (state, { subject, scenario, type, template }) {
      if (Object.prototype.hasOwnProperty.call(state.templates, subject)) {
        const temp = type ? { [type]: template } : template
        state.templates[subject][scenario] = { ...state.templates[subject][scenario], ...temp }
      } else {
        state.templates = { ...state.templates, [subject]: { [scenario]: type ? { [type]: template } : template } }
      }
    },
    RESET_TEMPLATES (state) {
      state.templates = {}
    },
    RESET_GRID_TEMPLATE (state, { subject }) {
      if (state.templates[subject]?.grid) delete state.templates[subject].grid
    }
  }
}

export default moduleTemplate
